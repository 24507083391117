/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const CALCULATION = {
	value: 'Calculation',
	labelKey: 'CALCULATION_LABEL',
	icon: 'icon-calculate',
};

export const IMPORT = {
	value: 'Import',
	labelKey: 'IMPORT_LABEL',
	icon: 'icon-input-forms',
};

export const MIGRATION = {
	value: 'Migration',
	labelKey: 'MIGRATION_LABEL',
	icon: 'icon-activity',
};

export const TASK = {
	value: 'Task',
	labelKey: 'TASK_LABEL',
	icon: 'icon-activity',
};

export const TIME = {
	value: 'Time',
	labelKey: 'TIME_LABEL',
	icon: 'icon-calendar2',
};

export const OPTIMIZATION = {
	value: 'Optimization',
	labelKey: 'OPTIMIZATION_LABEL',
	icon: 'icon-activity',
};

export const PREVIEW = {
	value: 'Preview',
	labelKey: 'PREVIEW_LABEL',
	icon: 'icon-activity',
};

export const BPM_WORKFLOW = {
	value: 'BPMWorkflow',
	labelKey: 'BPM_WORKFLOW_LABEL',
	icon: 'icon-activity',
};

export const BPM_WORKFLOW_ADD_MEMBER = {
	value: 'BPMWorkflowAddMember',
	labelKey: 'BPM_WORKFLOW_ADD_MEMBER_LABEL',
	icon: 'icon-activity',
};

export const PURGE_PERIODS = {
	value: 'PurgePeriods',
	labelKey: 'PURGE_PERIODS_LABEL',
	icon: 'icon-activity',
};

export const MAKE_EFFECTIVE = {
	value: 'MakeEffective',
	labelKey: 'MAKE_EFFECTIVE_LABEL',
	icon: 'icon-activity',
};

export const NOTIFY_USERS = {
	value: 'NotifyUsers',
	labelKey: 'NOTIFY_USERS_LABEL',
	icon: 'icon-activity',
};

export const SCHEDULER = {
	value: 'Scheduler',
	labelKey: 'SCHEDULER',
	icon: 'icon-activity',
};

export const PUBLICATION = {
	value: 'Publication',
	labelKey: 'PUBLICATION_LABEL',
	icon: 'icon-activity',
};

export const SCENARIO_PROMOTE = {
	value: 'ScenarioPromote',
	labelKey: 'SCENARIO_PROMOTE_LABEL',
	icon: 'icon-activity',
};

export const PUSH_ANALYTICS_DATA = {
	value: 'PushAnalyticsData',
	labelKey: 'PUSH_ANALYTIS_LABEL',
	icon: 'icon-activity',
};

export const REAPPLY_TEMPLATE = {
	value: 'ReapplyTemplate',
	labelKey: 'REAPPLY_TEMPLATE',
	icon: 'icon-activity',
};

export const TABLE_ALTER = {
	value: 'TableAlter',
	labelKey: 'TABLE_ALTER',
	icon: 'icon-activity',
};

export const TABLE_CLEAR = {
	value: 'TableClear',
	labelKey: 'TABLE_CLEAR',
	icon: 'icon-activity',
};

export const QUERY_TOOL = {
	value: 'QueryTool',
	labelKey: 'QUERY_TOOL',
	icon: 'icon-activity',
};

export const MODIFY_CALENDAR = {
	value: 'ModifyCalendar',
	labelKey: 'MODIFY_CALENDAR',
	icon: 'icon-activity',
};

export const INDEX_REBUILD = {
	value: 'IndexRebuild',
	labelKey: 'INDEX_REBUILD',
	icon: 'icon-activity',
};

export const SYNC = {
	value: 'Sync',
	labelKey: 'SYNC_LABEL',
	icon: 'icon-calculate',
};

export const BPM_INQUIRY_AUTOADJUST = {
	value: 'BPMInquiryAutoAdjust',
	labelKey: 'BPM_INQUIRY_AUTOADJUST_LABEL',
	icon: 'icon-activity',
};

export const ALL_PROGRESS_TYPES = [
	CALCULATION,
	IMPORT,
	MIGRATION,
	TASK,
	TIME,
	OPTIMIZATION,
	PREVIEW,
	BPM_WORKFLOW,
	PURGE_PERIODS,
	MAKE_EFFECTIVE,
	NOTIFY_USERS,
	SCHEDULER,
	PUBLICATION,
	SCENARIO_PROMOTE,
	REAPPLY_TEMPLATE,
	TABLE_ALTER,
	TABLE_CLEAR,
	QUERY_TOOL,
	BPM_WORKFLOW_ADD_MEMBER,
	MODIFY_CALENDAR,
	INDEX_REBUILD,
	SYNC,
	BPM_INQUIRY_AUTOADJUST,
];
