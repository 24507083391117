/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as LocalActionTypes from '../constants/localeActionTypes';
import moment from 'moment';
import {
	LOCALE_DEFAULT_MESSAGES,
	LOCALE_DEFAULT_SYSTEM_TRANSLATIONS,
} from '../constants/localeDefaults';
import * as SystemTranslations from '../localizations/systemTables/systemTranslations';

const initialState = {
	localeCode: undefined,
	messages: LOCALE_DEFAULT_MESSAGES,
	systemTranslations: LOCALE_DEFAULT_SYSTEM_TRANSLATIONS,
	status: 'pending',
};

const handlers = {};

// the reducer
export function locale(state = initialState, action) {
	const { type } = action;
	if (!handlers[type]) {
		return state;
	}
	return handlers[type](state, action);
}

handlers[LocalActionTypes.LOCALE_REQUESTED] = (state) => ({
	...state,
	status: 'pending',
});

handlers[LocalActionTypes.LOCALE_RECEIVED] = (
	state,
	{ payload: { localeCode, messages, systemTranslations } }
) => {
	moment.locale(localeCode);
	const {
		tableNames,
		reversedTableNames,
		columnNames,
		reversedColumnNames,
		calendarNames,
		reversedCalendarNames,
		calendarNamesTranslations,
	} = systemTranslations;
	SystemTranslations.setTranslations(
		tableNames,
		reversedTableNames,
		columnNames,
		reversedColumnNames,
		calendarNames,
		reversedCalendarNames,
		calendarNamesTranslations
	);
	return {
		...state,
		status: 'ok',
		localeCode,
		messages: {
			...(window.ICM_CONFIG.ENABLE_LOCALE_MESSAGE_FALLBACK
				? state.messages
				: {}),
			...messages,
		},
		systemTranslations,
	};
};
