/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/*
 * Example of a sort object:
 *
 * aSort = {
 * 	columnKey: '',
 * 	by: ORDER_BY_NONE | ORDER_BY_ASCENDING | ORDER_BY_DESCENDING
 * }
 */

export const ORDER_BY_NONE = 'ORDER_BY_NONE';
export const ORDER_BY_ASCENDING = 'ORDER_BY_ASCENDING';
export const ORDER_BY_DESCENDING = 'ORDER_BY_DESCENDING';

export const nextOrderBy = {
	ORDER_BY_NONE: ORDER_BY_ASCENDING,
	ORDER_BY_ASCENDING: ORDER_BY_DESCENDING,
	ORDER_BY_DESCENDING: ORDER_BY_NONE,
};

// helper to model sort by columnKeys

/*
 * aSort = new Sort(columnKey).ascending()
 * => {columnKey: columnKey, by: ORDER_BY_ASCENDING}
 * Sort.stringify(aSort) => '+columnKey'
 */

/*
 * aSort = new Sort(columnKey).descending()
 * => {columnKey: columnKey, by: ORDER_BY_DESCENDING}
 * Sort.stringify(aSort) => '-columnKey'
 */

export const getSortDirection = (sortDir) => {
	if (sortDir === 'ASC') {
		return ORDER_BY_ASCENDING;
	}
	if (sortDir === 'DESC') {
		return ORDER_BY_DESCENDING;
	}
	return ORDER_BY_NONE;
};

export default class Sort {
	constructor(columnKey, by = ORDER_BY_NONE) {
		this.columnKey = columnKey;
		this.by = by;
	}

	next() {
		return new Sort(this.columnKey, nextOrderBy[this.by]);
	}

	reset() {
		return new Sort(this.columnKey);
	}

	copy() {
		return new Sort(this.columnKey, this.by);
	}

	isActive() {
		return this.by !== ORDER_BY_NONE;
	}

	ascending() {
		return new Sort(this.columnKey, ORDER_BY_ASCENDING);
	}

	descending() {
		return new Sort(this.columnKey, ORDER_BY_DESCENDING);
	}

	static stringify(aSort) {
		switch (aSort.by) {
			case ORDER_BY_ASCENDING:
				return `+${aSort.columnKey}`;

			case ORDER_BY_DESCENDING:
				return `-${aSort.columnKey}`;

			case ORDER_BY_NONE:
			default:
				return '';
		}
	}

	/*
	 * standard query for orderBy="+column1Key,-column2Key"
	 * sorts = [sort1, sort2, ..]
	 */
	static makeOrderByQuery(sorts) {
		const str = sorts
			.map(Sort.stringify)
			.filter((e) => e !== '')
			.join(',');
		return str ? { orderBy: str } : {};
	}

	// Maintaining an array of sort objects, from highest to lowest priority

	static removeSort(list, sort) {
		return list.filter((s) => s.columnKey !== sort.columnKey);
	}

	static setSort(list, sort) {
		/*
		 * remove existing sort with columnKey,
		 * then prepend sort
		 */
		return [sort].concat(Sort.removeSort(list, sort));
	}
}
