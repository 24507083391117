/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';
import React from 'react';
import VBaseComponent from '../common/baseComponent';
import VIcon from 'components/common/icon';
import { css } from 'emotion';
import classnames from 'classnames';

export default class VModalHeader extends VBaseComponent {
	static propTypes = {
		modalClassName: PropTypes.string,
		className: PropTypes.string,
		titleClassName: PropTypes.string,
		captionClassName: PropTypes.string,
		onHide: PropTypes.func,
		onExpandToggle: PropTypes.func,
		closeIcon: PropTypes.string,
		closeButton: PropTypes.bool,
		expandButton: PropTypes.bool,
		title: PropTypes.node,
		caption: PropTypes.node,
		captionID: PropTypes.string,
		richCaption: PropTypes.any,
		extra: PropTypes.node,
		centered: PropTypes.bool,
	};

	/*
	 * Give a prop modalClassName for consistency between the various modal components
	 * i.e. ModalFooter and ModalBody from bootstrap both have modalClassName prop
	 * Used to identify each modal component since IE11 doesn't support Function.name
	 */
	static defaultProps = {
		modalClassName: 'modal-header',
	};

	render() {
		const {
			className,
			titleClassName,
			captionClassName,
			closeIcon,
			closeButton,
			expandButton,
			onHide,
			onExpandToggle,
			title,
			caption,
			captionID,
			richCaption,
			extra,
			centered,
		} = this.props;
		return (
			<div
				className={`v-modal-header ${className || ''}${
					centered ? ' v-modal-header-centered' : ''
				}`}
				data-test="modal-header-div"
			>
				{closeButton && (
					<VIcon
						alt="Icon"
						size="xsmall"
						type="normal"
						action={onHide}
						iconClass={closeIcon || 'icon-closemodal'}
						supportingClasses="v-modal-header-icons"
						data-test="modal-close-icon"
						ariaLabel={this.formatMessage('MODAL_CLOSE')}
					/>
				)}
				{expandButton && (
					<VIcon
						alt="Icon"
						size="xsmall"
						type="normal"
						action={onExpandToggle}
						iconClass="icon-expand"
						supportingClasses="v-modal-header-icons"
						data-test="modal-expand-toggle-icon"
						ariaLabel={this.formatMessage('EXPAND_MODAL')}
					/>
				)}
				<div className={`v-title ${titleClassName || ''}`}>{title}</div>
				{richCaption ||
					(caption && (
						<label
							className={classnames(
								`v-modal-caption v-big-body${captionClassName || ''}`,
								css`
									font-weight: normal;
									display: block;
								`
							)}
							htmlFor={captionID}
						>
							{caption}
						</label>
					))}
				{extra}
			</div>
		);
	}
}
