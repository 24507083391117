/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { createAction } from 'redux-act';

import {
	ERROR,
	CLEAR_MESSAGE,
	SHOW_MESSAGE,
	CONFIRM_MESSAGE,
	CONFIRM_MESSAGE_LINK,
	INFORMATION,
	MESSAGE_UPLOAD,
	MESSAGE_DOWNLOAD,
} from '../constants/actionTypes';
import { createErrorMessage } from '../helpers/errorMessageCreator';
import LocaleHelper from '../helpers/localeHelper';

export const error = createAction<
	any,
	string | undefined,
	any,
	boolean | undefined,
	string | undefined,
	{
		message: any;
		actionLinkLabel?: string;
		action?: any;
		messagesNeedTranslation?: boolean;
		requestId?: string;
	}
>(
	ERROR,
	(
		message,
		actionLinkLabel?,
		action?,
		messagesNeedTranslation?: boolean,
		requestId?: string
	) => ({
		message,
		actionLinkLabel,
		action,
		messagesNeedTranslation: messagesNeedTranslation === true,
		requestId,
	})
);

export function upload(fileName, progress) {
	return (dispatch, getState) =>
		dispatch({
			type: MESSAGE_UPLOAD,
			payload: {
				message: LocaleHelper.replaceText(
					getState(),
					'MESSAGE_TITLE_UPLOADING',
					{ fileName }
				),
				progress,
			},
		});
}

export function download(fileName, progress) {
	return (dispatch, getState) =>
		dispatch({
			type: MESSAGE_DOWNLOAD,
			payload: {
				message: LocaleHelper.replaceText(
					getState(),
					'MESSAGE_TITLE_DOWNLOADING',
					{ fileName }
				),
				progress,
			},
		});
}

export const showInfoMessage = createAction(
	INFORMATION,
	(
		message,
		actionLinkLabel,
		action,
		messagesNeedTranslation: boolean | undefined,
		msgParams: {}
	) => ({
		message,
		actionLinkLabel,
		action,
		messagesNeedTranslation: messagesNeedTranslation === true,
		msgParams,
	})
);

export const showTranslatedInfoMessage = (
	message,
	actionLinkLabel?,
	action?,
	msgParams?
) => showInfoMessage(message, actionLinkLabel, action, true, msgParams);

export const translatedError = (
	message,
	actionLinkLabel?,
	action?,
	requestId?
) => error(message, actionLinkLabel, action, true, requestId);

export const showMessage = createAction(SHOW_MESSAGE, (message) => ({
	message,
}));

export const showConfirmationMessage = createAction(
	CONFIRM_MESSAGE,
	(message, messagesNeedTranslation: boolean | undefined) => ({
		message,
		messagesNeedTranslation: messagesNeedTranslation === true,
	})
);

export const showTranslatedConfirmationMessage = (message) =>
	showConfirmationMessage(message, true);

export const showConfirmationMessageWithLink = createAction(
	CONFIRM_MESSAGE_LINK,
	(
		message,
		actionLinkLabel,
		actionLink,
		messagesNeedTranslation: boolean | undefined
	) => ({
		message,
		actionLinkLabel,
		actionLink,
		messagesNeedTranslation: messagesNeedTranslation === true,
	})
);

export const showTranslatedConfirmationMessageWithLink = (
	message,
	actionLinkLabel,
	actionLink
) =>
	showConfirmationMessageWithLink(message, actionLinkLabel, actionLink, true);

export function restApiError(
	data,
	res,
	actionLinkLabel,
	action,
	responseText,
	unknownErrorText?,
	requestId?
) {
	return translatedError(
		createErrorMessage(data, res, responseText, unknownErrorText, false),
		actionLinkLabel,
		action,
		requestId
	);
}

export function alert404RestApiError(
	data,
	res,
	actionLinkLabel,
	action,
	responseText,
	unknownErrorText?,
	requestId?
) {
	return translatedError(
		createErrorMessage(data, res, responseText, unknownErrorText, true),
		actionLinkLabel,
		action,
		requestId
	);
}

export function restApiConflictError(
	id,
	values,
	actionLinkLabel,
	action,
	requestId?
) {
	return translatedError({ id, values }, actionLinkLabel, action, requestId);
}

export const clearMessage = createAction(CLEAR_MESSAGE);
