/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { createAction } from 'redux-act';

import * as LocaleActionTypes from '../constants/localeActionTypes';
import LocaleService from '../services/localeService';

const DEFAULT_LOCALE_CODE = 'en';

export const requestLocale = createAction(LocaleActionTypes.LOCALE_REQUESTED);

export const receivedLocale = createAction<{
	localeCode: string;
	messages?: any[];
	systemTranslations?: any[];
	error?: any;
}>(LocaleActionTypes.LOCALE_RECEIVED);

export const setError = createAction<any>(LocaleActionTypes.LOCALE_ERROR);

// async
export function setLocale() {
	// This is for RPX build
	if (process.env.NODE_ENV === 'development') {
		if (
			(navigator.languages &&
				navigator.languages.length > 0 &&
				navigator.languages[0] === 'gl') ||
			(navigator.language && navigator.language === 'gl-ES')
		) {
			return (dispatch) => {
				LocaleService.getMessages('gl').then(
					(obj) => {
						dispatch(
							receivedLocale({
								localeCode: 'gl',
								messages: obj.messages,
								systemTranslations: obj.systemTranslations,
							})
						);
					},
					(error) => {
						dispatch(
							receivedLocale({
								localeCode: DEFAULT_LOCALE_CODE,
								error,
							})
						);
					}
				);
			};
		}
	}
	return (dispatch) => {
		dispatch(requestLocale);
		LocaleService.getLocaleCode().then(
			(localeCode) => {
				LocaleService.getMessages(localeCode).then(
					(obj) => {
						dispatch(
							receivedLocale({
								localeCode,
								messages: obj.messages,
								systemTranslations: obj.systemTranslations,
							})
						);
					},
					(error) => {
						dispatch(
							receivedLocale({
								localeCode: DEFAULT_LOCALE_CODE,
								error,
							})
						);
					}
				);
			},
			(error) => {
				dispatch(setError(error));
			}
		);
	};
}
