/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useState, useEffect } from 'react';
import { IntlProvider, injectIntl, InjectedIntl } from 'react-intl';
import { CustomIntlProvider } from './contexts/intlContext';

export const supportedLocales = [
	'fr-CA',
	'fr-FR',
	'zh',
	'zh-CN',
	'zh-TW',
	'pt',
	'pt-BR',
	'de',
	'es',
	'fr',
	'it',
	'ja',
	'ko',
	'nl',
	'sv',
	'tr',
	'pl',
	'hu',
	'sk',
	'en',
	'en-US',
	'en-GB',
	'en-ZA',
] as const;

export interface IProps {
	intl: InjectedIntl;
	loadLocale: (code: string) => Promise<Record<string, string>>;
	defaultMessages?: Record<string, string>;
}

export const getLocaleInfo = (localeCode: string) => {
	switch (localeCode) {
		case 'zh':
		case 'zh-CN':
		case 'zh-Hans':
			return {
				messages: 'zh-Hans',
				localeData: 'zh',
			};
		case 'zh-TW':
		case 'zh-Hant':
			return {
				messages: 'zh-Hant',
				localeData: 'zh',
			};
		case 'pt':
		case 'pt-BR':
			return {
				messages: 'pt-BR',
				localeData: 'pt',
			};
		case 'de':
		case 'es':
		case 'fr':
		case 'it':
		case 'ja':
		case 'ko':
		case 'nl':
		case 'sv':
		case 'tr':
		case 'pl':
		case 'hu':
		case 'sk':
			return {
				messages: localeCode,
				localeData: localeCode,
			};
		case 'en':
		default:
			if (localeCode.includes('-')) {
				return getLocaleInfo(localeCode.substring(0, localeCode.indexOf('-')));
			}
			return {
				messages: 'en',
				localeData: 'en',
			};
	}
};

/*
 * This provider is to be nested within another react-intl IntlProvider
 * as it pulls from the parent provider to determine the locale.
 */
const IntProviderInternal: React.FC<IProps> = ({
	intl,
	defaultMessages,
	children,
	loadLocale,
}) => {
	const [loaded, setLoaded] = useState(false);
	const [messages, setMessaages] = useState(defaultMessages);
	useEffect(() => {
		if (intl && intl.locale) {
			// We could expose more of this information but this is fine for now.
			const rolledUpLocale = getLocaleInfo(intl.locale);
			loadLocale(rolledUpLocale.messages).then(
				(newMessages) => {
					setMessaages((prevState) => ({
						...intl.messages,
						...prevState,
						...newMessages,
					}));
					setLoaded(true);
				},
				() => {
					// eslint-disable-next-line no-console
					console.warn(
						`Could not find localization for ${rolledUpLocale.messages}.`
					);
					setLoaded(true);
				}
			);
		}
		/*
		 * doing this to avoid the issue because react-intl doesn't maintain
		 * intl properly. Can be removed when upgrading react-intl.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (!loaded) {
		return null;
	}
	return (
		<IntlProvider locale={intl.locale} messages={messages}>
			<CustomIntlProvider>{children}</CustomIntlProvider>
		</IntlProvider>
	);
};

export default injectIntl(IntProviderInternal);
