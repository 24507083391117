/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { getJWTPayloadObject } from 'helpers/jwtHelper';

/**
 * @deprecated Pluse use proper featureUtils (src/utils/featureUtils) instead!
 * Do NOT use within reducer.
 */
export function isNewFeatureEnabled() {
	const state = window.store.getState();
	const currentModel = state.authentication.database;
	if (state.authentication.token !== '') {
		const jwtJson = getJWTPayloadObject(state.authentication.token);
		const found =
			jwtJson.is_v2 ||
			(jwtJson.models_v2 &&
				jwtJson.models_v2.find((model) => currentModel === model));
		return found !== undefined;
	}
	return false;
}
