import React, {
	useCallback,
	useContext,
	forwardRef,
	createContext,
	useRef,
} from 'react';
import styled, { StyledComponent } from 'react-emotion';
import {
	colorLightGray4,
	colorLightGray5,
	Toolbar,
	Pagination,
} from '@varicent/components';
import { useIntersection, useMount } from 'react-use';
import { createPortal } from 'react-dom';
import { Drawer } from '@blueprintjs/core';
import cx from 'classnames';
import { TilesWrapper } from './common/tilesWrapper';
import { detect } from 'detect-browser';
import { useIntl } from 'icm-core/lib/contexts/intlContext';

type ClassNamesArg = string | Record<string, boolean> | ClassNamesArg[];

function withClassNames<
	T extends StyledComponent<P, any, any>,
	P extends { className?: string }
>(
	classNames: ClassNamesArg,
	InternalComponent: T,
	options?: {
		refProp: string;
	}
) {
	const NewComp = React.forwardRef<any, P>(
		({ className, ...otherProps }, ref) => {
			const CastComp = InternalComponent as any;
			const newClassName = Array.isArray(classNames)
				? cx(className, ...classNames)
				: cx(className, classNames);
			const newProps = {
				...otherProps,
				className: newClassName,
				[options?.refProp ?? 'ref']: ref,
			};
			return <CastComp {...newProps} />;
		}
	) as any as T;
	return NewComp;
}

export const relativePortalContext = React.createContext<{
	setRefForCategory: (args: { category: string; ref: HTMLDivElement }) => void;
	getRefForCategory: (category: string) => HTMLDivElement | null;
}>({
	setRefForCategory: () => {},
	getRefForCategory: () => null,
});

export const RelativePortalProvider: React.FC = ({ children }) => {
	const [refs, setRefs] = React.useState<Record<string, HTMLDivElement | null>>(
		{}
	);
	const setRefForCategory = useCallback(
		({ category, ref }: { category: string; ref: HTMLDivElement }) => {
			setRefs((prev) => ({
				...prev,
				[category]: ref,
			}));
		},
		[]
	);
	const getRefForCategory = useCallback(
		(category: string) => {
			return refs[category];
		},
		[refs]
	);
	const cValue = React.useMemo(
		() => ({
			getRefForCategory,
			setRefForCategory,
		}),
		[setRefForCategory, getRefForCategory]
	);
	return (
		<relativePortalContext.Provider value={cValue}>
			{children}
		</relativePortalContext.Provider>
	);
};

export const RelativePortalMarker = ({
	category,
	...props
}: React.HTMLAttributes<HTMLDivElement> & { category: string }) => {
	const { setRefForCategory } = useContext(relativePortalContext);
	const domRef = React.useRef<HTMLDivElement>(null);
	useMount(() => {
		if (!domRef.current) {
			return;
		}
		setRefForCategory({ category, ref: domRef.current });
	});
	return <div {...props} ref={domRef} />;
};

export const RelativeDrawer: React.FC<
	Omit<React.ComponentPropsWithoutRef<typeof Drawer>, 'position'> & {
		category?: string;
	}
> = ({ category = 'under-content-right', ...props }) => {
	const { getRefForCategory } = useContext(relativePortalContext);
	const container = getRefForCategory(category);
	if (!container) {
		if (props.isOpen && !container) {
			// eslint-disable-next-line no-console
			console.warn(
				`Drawer with category ${category} attempted to open with invalid container.`
			);
		}
		return null;
	}
	return createPortal(
		<Drawer
			{...props}
			hasBackdrop={false}
			usePortal={false}
			// autofocus causes weird behavior with relative drawer layout
			// and the ref exposed doesn't allow getting the dom node
			autoFocus={false}
		/>,
		container
	);
};

const styleForSpacing = (spacing: 1 | 2 | 1.5) => `
	& > .vds-col.vds-padding-target-children:first-child > *:not(.vds-padding-target-override):not(.scroll-body),
	& > .vds-col.vds-padding-target:first-child,
	& > .vds-col:not(.vds-padding-target-children):first-child .vds-padding-target,
	& > .vds-col.vds-padding-target-children:first-child > .scroll-body:not(.vds-padding-target-override) > .scroll-shadow-cover
	{
		padding-left: ${spacing}rem;
	}

	& > .vds-col.vds-padding-target-children:last-child > *:not(.vds-padding-target-override):not(.scroll-body),
	& > .vds-col.vds-padding-target:last-child,
	& > .vds-col:not(.vds-padding-target-children):last-child .vds-padding-target,
	& > .vds-col.vds-padding-target-children:last-child > .scroll-body:not(.vds-padding-target-override) > .scroll-shadow-cover
	{
		padding-right: ${spacing}rem;
	}

	& > .vds-col > .scroll-body {
		&::before,
		&::after {
			left: ${spacing === 1 ? 0 : spacing / 2}rem;
		}
	}
`;

export const Content = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & {
		/**
		 * Set the marker category so this content will use
		 * those markers for relative nav/drawer content.
		 * You should leave this blank unless you know what you're
		 * doing.
		 */
		drawerMarkerCategory?: string;
		/**
		 * Set overflow for the main content. If you want scroll just within
		 * content, 'auto'. Otherwise use 'hidden' to allow other children
		 * to determine scroll positions.
		 *
		 * default: hidden
		 */
		overflow?: 'auto' | 'hidden';
		/**
		 * Adds some padding to the contained columns. Not necessary when toolbar included.
		 *
		 * @default false
		 */
		includePaddingTop?: boolean;
		/**
		 * Shortcut to control the flex direction of the content.
		 *
		 * default: column
		 */
		flexDirection?: React.CSSProperties['flexDirection'];
		/**
		 * A flag to indicate that the last column in body is responsible for
		 * scrolling.
		 *
		 * default: false
		 */
		pushScrollToLastColumn?: boolean;
		preventShrink?: boolean;
	}
>(
	(
		{
			children,
			className,
			drawerMarkerCategory = 'under-content',
			overflow = 'hidden',
			flexDirection = 'column',
			preventShrink,
			includePaddingTop,
			...props
		},
		ref
	) => {
		// only need a custom trigger width iff two navs exist of different sizes

		return (
			<div
				ref={ref}
				className={className}
				{...props}
				style={{
					display: 'flex',
					position: 'relative',
					flex: '1 1 auto',
					overflow: 'hidden',
					...props.style,
				}}
			>
				<RelativePortalMarker
					className="vds-drawer-container-right"
					category={`${drawerMarkerCategory}-right`}
				/>
				<RelativePortalMarker
					className="vds-drawer-container-left"
					category={`${drawerMarkerCategory}-left`}
				/>
				<div className="vds-content">
					<div
						className={cx('vds-content-inner', {
							'vds-add-top-padding': includePaddingTop,
						})}
						style={{
							overflow,
							display: 'flex',
							flexDirection,
						}}
					>
						{flexDirection.includes('column') ? (
							<Col
								size="remaining"
								// assumes a sticky toolbar if main content is auto flow
								withStickyToolbar={overflow === 'auto'}
								style={{
									overflow:
										overflow === 'auto'
											? isIE
												? 'scroll'
												: 'auto'
											: undefined,
									flexShrink: preventShrink ? 0 : undefined,
								}}
							>
								{children}
							</Col>
						) : (
							children
						)}
					</div>
				</div>
			</div>
		);
	}
);

// all the stuff for our application layout (overything around the header and main nav on the left)

type ColumnSpacing = 'small' | 'medium' | 'large';

const spacingToMeasure: Record<ColumnSpacing, string> = {
	small: '0.5rem',
	medium: '1rem',
	large: '2rem',
};

const MainInternal = styled.main<{
	maxWidth: number | null;
	columnSpacing: ColumnSpacing;
}>`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: hidden;
	// currently only needed because icm-web has an override on it's body
	// to set it to 16px.
	font-size: 0.875rem;
	& .vds-content {
		width: 100%;
	}

	& > .vds-content-header > * {
		width: 100%;
		padding-left: 1rem;
		padding-right: 1rem;
		margin-left: auto;
		margin-right: auto;
	}

	& .vds-content > .vds-content-inner {
		&.vds-add-top-padding > .vds-col:not(.vds-centered),
		&.vds-add-top-padding > .vds-col.vds-centered > .vds-col-centered-inner {
			padding-top: 1.5rem;
		}

		& > .vds-col:not(.vds-col-fixed-size) {
			// when calculated column sizing, padding must be excluded
			box-sizing: content-box;
		}

		& > .vds-col > .scroll-body {
			// hide overflow-x to make sure the scroll-shadow-cover get's cut off
			overflow-x: hidden;
		}
		& > .vds-col > .scroll-body > .scroll-shadow-cover {
			padding: 0;

			&::before,
			&::after {
				left: -100%;
				width: 300%;
				height: 0.25rem;
			}
		}

		${styleForSpacing(1)}

		& > .vds-col.vds-padding-target-children:not(:last-child) > *:not(.vds-padding-target-override):not(.scroll-body),
		& > .vds-col.vds-padding-target:not(:last-child),
		& > .vds-col:not(.vds-padding-target-children):not(:last-child) .vds-padding-target,
		& > .vds-col.vds-padding-target-children:not(:last-child) > .scroll-body:not(.vds-padding-target-override) > .scroll-shadow-cover {
			padding-right: ${({ columnSpacing }) => spacingToMeasure[columnSpacing]};
		}

		&
			> .vds-col.vds-padding-target-children:not(:first-child)
			> *:not(.vds-padding-target-override):not(.scroll-body),
		& > .vds-col.vds-padding-target:not(:first-child),
		&
			> .vds-col:not(.vds-padding-target-children):not(:first-child)
			.vds-padding-target,
		&
			> .vds-col.vds-padding-target-children:not(:first-child)
			> .scroll-body:not(.vds-padding-target-override)
			> .scroll-shadow-cover {
			padding-left: ${({ columnSpacing }) => spacingToMeasure[columnSpacing]};
		}

		width: 100%;
	}

	& .vds-content .vds-padding-target,
	&
		.vds-content
		.vds-col.vds-padding-target-children
		> *:not(.vds-padding-target-override):not(.scroll-body) {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	@media (min-width: 960px) {
		& > .vds-col > .scroll-body {
			&::before,
			&::after {
				left: 1rem;
			}
		}
		& > .vds-content-header > * {
			padding-left: 2rem;
			padding-right: 2rem;
		}

		.vds-content > .vds-content-inner {
			${styleForSpacing(2)}
		}
	}

	@media (max-width: 959px) and (min-width: 600px) {
		& > .vds-col > .scroll-body {
			&::before,
			&::after {
				left: 0.5rem;
			}
		}

		& > .vds-content-header > * {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}

		.vds-content > .vds-content-inner {
			${styleForSpacing(1.5)}
		}
	}

	& .vds-content {
		display: flex;
		flex: 1 1 auto;
		background-color: rgb(${colorLightGray5});
	}

	.vds-content > .vds-content-inner.vds-push-scroll-to-last-column {
		margin-right: 0;
	}

	${({ maxWidth }) =>
		maxWidth !== null &&
		`
  & > .vds-content-header > *,
  .vds-content .vds-padding-target,
	.vds-content .vds-col.vds-padding-target-children > *:not(.vds-padding-target-override):not(.scroll-body) {
    max-width: ${maxWidth}px;
  }
  `}

	& .vds-drawer-container-left,
	& .vds-drawer-container-right {
		position: absolute;
		height: 100%;
		width: 0;
		overflow: visible;
	}

	& .vds-drawer-container-right {
		right: 0;
	}

	& .vds-drawer-container-left {
		left: 0;
	}

	& .vds-portal-right-nav,
	& .vds-portal-left-nav {
		display: flex;
		overflow: hidden;
		flex-shrink: 0;
	}

	& .vds-content > .vds-content-inner > .vds-col:not(.with-sticky-toolbar) {
		.vds-padding-target-tiles > .vds-padding-target:last-child,
		.vds-padding-target.vds-padding-target-data-table
			> .vds--data-table-v2-container
			> *:last-child {
			margin-bottom: 1.5rem;
		}
	}

	& .vds-content > .vds-content-inner > .vds-col.with-sticky-toolbar {
		padding-bottom: 1.5rem;
	}
`;

const MainInternalFallback = MainInternal.withComponent('div');
const browser = detect();
/* istanbul ignore next */
const isIE = browser && browser.name === 'ie';
const MainInternalUniversal = isIE ? MainInternalFallback : MainInternal;

const maxWidthContext = createContext<number | null>(null);

export const Main = forwardRef<
	HTMLElement,
	React.HTMLAttributes<HTMLElement> & {
		limitMaxWidth?: boolean;
		/**
		 * A hint to provide a general structure for the underlying structure.
		 *
		 */
		columnSpacing?: ColumnSpacing;
	}
>(({ limitMaxWidth, columnSpacing = 'small', ...props }, ref) => {
	const maxWidth = limitMaxWidth ? 1599 : null;

	return (
		<maxWidthContext.Provider value={maxWidth}>
			<MainInternalUniversal
				maxWidth={maxWidth}
				columnSpacing={columnSpacing}
				{...props}
				innerRef={ref}
			/>
		</maxWidthContext.Provider>
	);
});

const sizeToPixel = {
	small: '200px',
	medium: '300px',
	large: '400px',
};

const InternalCol = styled.div<{
	size: number | 'small' | 'medium' | 'large' | 'remaining';
	overflow?: 'hidden' | 'auto';
}>(({ size, overflow = 'auto' }) => {
	return `
		display: flex;
		flex-direction: column;
		${
			typeof size === 'number'
				? `
			flex: ${(size / 12) * 100}%;
			max-width: ${(size / 12) * 100}%;
		`
				: size === 'remaining'
				? 'flex: 1 1 auto;'
				: `flex: 1 0 ${sizeToPixel[size]}; max-width: ${sizeToPixel[size]};`
		}

		overflow: ${overflow};
		min-height: 100%;
		${
			isIE
				? `
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		`
				: ''
		}
	`;
});

export const Col = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & {
		size: number | 'small' | 'medium' | 'large' | 'remaining';
		overflow?: 'hidden' | 'auto';
		centeredOffset?: number;
		startsAt?: number;
		paddingTop?: string;
		paddingBottom?: string;
		withStickyToolbar?: boolean;
	}
>(
	(
		{
			centeredOffset,
			children,
			size,
			startsAt,
			className,
			paddingTop,
			paddingBottom,
			overflow = 'hidden',
			withStickyToolbar,
			...props
		},
		ref
	) => {
		const widthCalc =
			typeof size === 'number'
				? `calc(${((size - (centeredOffset ?? 0) * 2) / size) * 100}% + 0.5rem)`
				: 'auto';
		return (
			<>
				{startsAt && (
					<Col
						size={startsAt}
						className={overflow === 'auto' ? 'vds-padding-target' : undefined}
					/>
				)}
				<InternalCol
					className={cx('vds-col', className, {
						'vds-padding-target': overflow === 'auto' && !withStickyToolbar,
						'vds-padding-target-children':
							overflow === 'hidden' || withStickyToolbar,
						'vds-col-fixed-size': typeof size !== 'number',
						'with-sticky-toolbar': withStickyToolbar,
						'vds-centered': centeredOffset !== undefined,
					})}
					size={size}
					{...(centeredOffset === undefined ? props : {})}
					overflow={centeredOffset === undefined ? overflow : undefined}
					innerRef={ref}
					style={{
						...(centeredOffset === undefined ? props.style : {}),
						alignItems: centeredOffset !== undefined ? 'center' : undefined,
						paddingTop,
						paddingBottom,
					}}
				>
					{centeredOffset !== undefined ? (
						<div
							className="vds-col-centered-inner"
							{...props}
							style={{
								...props.style,
								maxWidth: widthCalc,
								// IE11 suuuuucks. 100% makes more sense but this'll do.
								width: '100vw',
							}}
						>
							{children}
						</div>
					) : (
						children
					)}
				</InternalCol>
			</>
		);
	}
);

export const ContentHeader = withClassNames(
	'vds-content-header',
	styled.div`
		background-color: white;
		box-shadow: inset 0 -1px rgb(${colorLightGray4});
		> .header {
			background-color: transparent;
			box-shadow: none;
			display: flex;
		}
	`
);

const DataTableWrapperInternal = styled.div`
	display: flex;
	flex: 1 1 auto;
	overflow: hidden;
`;

export const DataTableWrapper: React.FC<{ flexFit?: boolean }> = ({
	flexFit = true,
	children,
}) => {
	if (!flexFit) {
		return <div className="vds-padding-target">{children}</div>;
	}
	return (
		<DataTableWrapperInternal className="vds-padding-target vds-padding-target-data-table">
			{children}
		</DataTableWrapperInternal>
	);
};

const intersectionOptions = {
	threshold: [1],
};

export const ContentToolbar: React.FC<
	React.ComponentPropsWithoutRef<typeof Toolbar> & {
		displayMode?: 'table' | 'tile';
	}
> = ({ displayMode = 'table', style, ...props }) => {
	const internalRef = useRef<HTMLDivElement>(null);
	const intersection = useIntersection(internalRef, intersectionOptions);
	const isStuck = intersection && intersection.intersectionRatio < 1;
	return (
		<StickyToolbarWrapper
			isStuck={!!isStuck}
			innerRef={internalRef}
			style={{
				backgroundColor: `rgb(${colorLightGray5})`,
			}}
		>
			<Toolbar
				{...props}
				style={{
					...style,
					paddingBottom: displayMode === 'tile' ? '0.5rem' : undefined,
					paddingTop: '1.5rem',
					gap: '0.25rem', // until we update varicent components toolbar to match
				}}
			/>
		</StickyToolbarWrapper>
	);
};

export const ContentTilesArea: React.FC<{
	id: string;
	paginationProps?: React.ComponentPropsWithoutRef<typeof Pagination>;
	columnCount?: number;
}> = ({ id, children, paginationProps, columnCount }) => {
	const { formatMessage } = useIntl();
	return (
		<>
			<TilesWrapper
				role="grid"
				id={id}
				initialColumnCount={columnCount}
				style={{
					flexShrink: 0,
					paddingTop: '0.5rem',
				}}
			>
				{children}
			</TilesWrapper>
			{paginationProps && (
				<Pagination
					{...paginationProps}
					t={({ id, values }) => formatMessage({ id }, values)}
					className={cx(paginationProps?.className)}
				/>
			)}
		</>
	);
};

const StickyToolbarWrapper = styled.div<{ isStuck: boolean }>`
	position: sticky;
	// -1px to adjust the element so our intersection observer will detect scroll stick
	top: -1px;
	// +1px padding to offset for the above top.
	padding-top: 1px;
	z-index: 1;

	.vds-padding-target {
		padding-top: 1rem;
		${({ isStuck }) => (isStuck ? `padding-top: 0.5rem;` : '')};
		transition: padding-top 100ms ease-in-out 100ms;
	}
	// this creates a box shadow below only. Magic numbers generated by feel.
	box-shadow: ${({ isStuck }) =>
		isStuck ? `0 2px 0.25rem -3px rgba(0,0,0,0.18)` : 'none'};
`;
