import { createAction } from 'redux-act';
import { remoteMigrationActionTypes as actionTypes } from 'constants/remoteMigrationActionTypes';
import MigrationService from 'services/migrationService';

export const storeJWT = createAction<{ jwt: string; models: string[] }>(
	actionTypes.REMOTE_MIGRATION_LOGIN,
	(token, models) => ({
		jwt: token,
		models,
	})
);

export const receiveRemoteMigrations = createAction(
	actionTypes.REMOTE_MIGRATION_GET,
	(remoteMigrations) => ({
		remoteMigrations,
	})
);

export const receiveTSUrl = createAction(
	actionTypes.REMOTE_MIGRATION_GET_TS_URL,
	(tenantServicesRemoteURL) => ({
		tenantServicesRemoteURL,
	})
);

export const receiveCreatedMigration = createAction<{
	model: string;
	dataCenter: string;
	remoteMigrationId: number;
}>(
	actionTypes.REMOTE_MIGRATION_CREATE,
	({ model, dataCenter, remoteMigrationId }) => ({
		model,
		dataCenter,
		remoteMigrationId,
	})
);

export const setCurrentMigration = createAction<{
	model: string;
	dataCenter: string;
	remoteMigrationId: number;
}>(
	actionTypes.REMOTE_MIGRATION_SET,
	({ model, dataCenter, remoteMigrationId }) => ({
		model,
		dataCenter,
		remoteMigrationId,
	})
);

export const clearRemoteMigrationData = createAction(
	actionTypes.REMOTE_MIGRATION_CLEAR
);

export function getRemoteMigrations() {
	return (dispatch) => {
		return MigrationService.getRemoteMigrations().then((data) => {
			dispatch(receiveRemoteMigrations(data));
		});
	};
}

export function createRemoteMigration(modelName, hostName, onCreate) {
	return (dispatch) => {
		return MigrationService.createRemoteMigration({
			remoteMigrationId: -1,
			model: modelName,
			dataCenter: hostName,
		}).then((data) => {
			dispatch(receiveCreatedMigration(data));
			dispatch(setCurrentMigration(data));
			if (onCreate) onCreate();
		});
	};
}

export function getTSUrl(): ThunkAction<Promise<string>> {
	return async (dispatch) => {
		const url = await MigrationService.getRemoteTSUrl();
		dispatch(receiveTSUrl(url));
		return url;
	};
}
