/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	HIDE_PASSWORD_CHANGE,
	SHOW_PASSWORD_CHANGE,
} from 'constants/actionTypes';
import { createAction } from 'redux-act';
import authService from 'services/authService';

export const showPasswordChange = createAction(SHOW_PASSWORD_CHANGE);
export const hidePasswordChange = createAction(HIDE_PASSWORD_CHANGE);

export const updatePassword =
	(
		username: string,
		password: string,
		newPassword: string,
		onSuccess: () => any
	): ThunkAction<void> =>
	() => {
		authService.updatePassword(username, password, newPassword).then(onSuccess);
	};
