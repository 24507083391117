/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { createContext } from 'react';
import { fromRenderProps } from 'recompose';

export const getNextTestId = (inheritedId, testId) =>
	inheritedId && inheritedId.length > 0 ? `${inheritedId}.${testId}` : testId;

const InternalQaContext = createContext(null);

const TestContext = ({ value, children, ...otherProps }) => (
	<InternalQaContext.Consumer>
		{(inheritedId) => {
			const nextId = value ? getNextTestId(inheritedId, value) : inheritedId;
			return (
				<InternalQaContext.Provider value={nextId} {...otherProps}>
					{typeof children === 'function' ? children(nextId) : children}
				</InternalQaContext.Provider>
			);
		}}
	</InternalQaContext.Consumer>
);

export default TestContext;

export const withTestContext = fromRenderProps(TestContext, (value) => ({
	testId: value,
}));
