/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { getJWT, getModels, getModelServers } from 'helpers/authHelper';
import axios from 'axios';
import { defaultRESTAPIErrorHandler } from 'services/httpService';
import ErrorLogService from 'services/errorLogService';
import { isNewFeatureEnabled } from 'utils/globalUtils';
import _ from 'lodash';
import configure from 'icm-rest-client/lib/utils/configure';
import { statusPage } from 'actions/authActions';

function logRequestId(res) {
	const requestId = res && res.headers && res.headers['x-request-id'];

	if (requestId) {
		ErrorLogService.logRequestId(requestId);
	}
	return res;
}

export const setAxiosDefaults = () => {
	axios.defaults.baseURL = `${ICM_CONFIG.API_URL}/api/v1`;
	axios.interceptors.request.use((config) => {
		const auth = window.store.getState().authentication;
		const jwt = getJWT();
		const isNewRestAPI = isNewFeatureEnabled().toString();

		const headers = {
			...config.headers,
			Model: (config.headers && config.headers.Model) || auth.database,
			IsNewRestAPI: isNewRestAPI,
		};

		if (jwt) headers.Authorization = headers.Authorization || `Bearer ${jwt}`;

		// in local debug mode, pass in models and servers in header
		if (!process.env.ICM10_REMOTE) {
			const models = getModels();
			const serversMap = getModelServers();
			const servers = models?.map((model) =>
				serversMap && serversMap[model] ? serversMap[model] : 'default'
			);
			headers.Models = models?.join(',') ?? '';
			headers.Servers = servers?.join(',') ?? '';
		}

		return { ...config, headers };
	});
	axios.interceptors.response.use(logRequestId, (error) => {
		if (
			error.response &&
			error.response.status === 503 &&
			error.response.data.type === 3 // Live Progress ProgressType: migration
		) {
			window.store.dispatch(statusPage(true));
		}
		if (error.response?.status === 503) {
			if (
				_.has(error, ['config', 'preventDefaultRESTAPIError']) &&
				!error.config.preventDefaultRESTAPIError
			) {
				return defaultRESTAPIErrorHandler(error);
			}
		} else if (
			_.has(error, ['config']) &&
			!error.config.preventDefaultRESTAPIError
		) {
			return defaultRESTAPIErrorHandler(error);
		}
		return Promise.reject(error);
	});
	/*
	 * We should be using an axios instance but this will do until we switch all our code to
	 * use a single instance instead of axios default.
	 */
	configure({ axios });
};
