// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 * Migration Dependency Info
 * url: `api/v1/rpc/migration/dependencies`
 */
export function retrieveMigrationDependencies(
	items: Varicent.RESTAPI.v1.DTOs.Migration.MigratableItemsDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/migration/dependencies',
		apiVersion: 'v1' as const,
		data: items,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Migration.MigratableItemsDTO>(
		request
	);
}

/**
 * required source table mappings
 * url: `api/v1/rpc/migration/requiredmappings`
 */
export function retrieveMigrationRequiredSourceTableMappings(
	query: Varicent.RESTAPI.v1.DTOs.Migration.MigrationRequiredMappingsQueryDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/migration/requiredmappings',
		apiVersion: 'v1' as const,
		data: query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Migration.MappingInfoDTO>(
		request
	);
}

/**
 * Migration comparison data
 * url: `api/v1/rpc/migration/comparisondata`
 */
export function retrieveMigrationComparisonData(
	query: Varicent.RESTAPI.v1.DTOs.Migration.MigrationComparisonDataQueryDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/migration/comparisondata',
		apiVersion: 'v1' as const,
		data: query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Migration.MigrationComparisonDataDTO>(
		request
	);
}

/**
 * Migration comparison data and mappings
 * url: `api/v1/rpc/migration/mappingscomparisondata`
 */
export function retrieveMigrationMappingsAndComparisonData(
	query: Varicent.RESTAPI.v1.DTOs.Migration.MigrationComparisonDataQueryDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/migration/mappingscomparisondata',
		apiVersion: 'v1' as const,
		data: query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Migration.MigrationComparisonDataDTO>(
		request
	);
}

/**
 * Get all Calendars
 * url: `api/v1/migration/calendars`
 */
export function getAllCalendars(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'migration/calendars',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO[]>(
		request
	);
}

/**
 * Get all Payee Groups
 * url: `api/v1/migration/payeegroups`
 */
export function getAllPayeeGroups(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'migration/payeegroups',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.Payee.PayeeGroupNameDTO[]
	>(request);
}

/**
 * List of activity endpoints to poll.
 * url: `api/v1/rpc/migration/migrate`
 */
export function migrate(
	query: Varicent.RESTAPI.v1.DTOs.Migration.RunMigrationQueryDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/migration/migrate',
		apiVersion: 'v1' as const,
		data: query,
		...options,
	};
	return configurableRequest<{ [key: string]: string }>(request);
}

/**
 * Migrate source table data
 * url: `api/v1/rpc/migration/${sourceModel}/migrate-table-data`
 */
export function migrateTableData(
	sourceModel: string,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `rpc/migration/${sourceModel}/migrate-table-data`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<boolean>(request);
}

/**
 *
 * url: `api/v1/rpc/migration/migrate-raw-model-to/${targetModel}`
 */
export function migrateRawModelTo(
	targetModel: string,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `rpc/migration/migrate-raw-model-to/${targetModel}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/rpc/migration/migrate-dbimport-syntax`
 */
export function migrateDbImportSyntax(
	query?: { overwrite?: boolean },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/migration/migrate-dbimport-syntax',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Migration.Postgres.DbImportSyntaxMigrationResultsDTO>(
		request
	);
}
