/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { AllFilterOperators } from '../../../constants/filterOperators';
import { toFilterString } from '../../../helpers/filter/filterToString';
import FilterOperator from './filterOperator';

export default class LikeFilter extends FilterOperator {
	constructor(left, right, parent) {
		super(AllFilterOperators.ContainedIn, left, right, parent);
	}

	toString() {
		return toFilterString(
			this.type,
			this.left.toString(),
			this.right.toString()
		);
	}

	// Apply WildCardseEscaped
	filter(row, columns, isCaseInsensitive) {
		const item = this.getRowValueForColumn(row, columns);
		// includes is not supported by the tests so make do with indexOf.

		if (
			item.found &&
			typeof item.rowValue === 'string' &&
			typeof item.filterValue === 'string'
		) {
			const addEscapedBracket = item.filterValue.replace(/.{1}/g, (match) => {
				switch (match) {
					case '\\':
						return '\\\\';
					case ']':
						return '\\]';
					default:
						return `[${match}]`;
				}
			});

			item.filterValue = addEscapedBracket.replace(
				/(\[~\])?\[[~?*]\]/g,
				function (match) {
					switch (match) {
						case '[~][~]':
							return '[~]';
						case '[~][?]':
							return '[?]';
						case '[~][*]':
							return '[*]';
						case '[?]':
							return '.';
						case '[*]':
							return '.*';
						default:
							return match;
					}
				}
			);

			if (addEscapedBracket === item.filterValue) {
				return isCaseInsensitive
					? item.rowValue.toLowerCase().match(item.filterValue.toLowerCase())
					: item.rowValue.match(item.filterValue);
			} else {
				return isCaseInsensitive
					? item.rowValue
							.toLowerCase()
							.match(`^${item.filterValue.toLowerCase()}$`)
					: item.rowValue.match(`^${item.filterValue}$`);
			}
		}

		return null;
	}

	copy(parent) {
		return new LikeFilter(this.left, this.right, parent);
	}
}
