/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { AllFilterOperators } from '../../../constants/filterOperators';
import { toFilterString } from '../../../helpers/filter/filterToString';
import FilterOperator from './filterOperator';

export default class EndsWithFilter extends FilterOperator {
	constructor(left, right, parent) {
		super(AllFilterOperators.EndsWith, left, right, parent);
	}

	toString() {
		return toFilterString(
			this.type,
			this.left.toString(),
			this.right.toString()
		);
	}

	filter(row, columns) {
		const item = this.getRowValueForColumn(row, columns);
		// endsWith is not supported by the tests so make do with indexOf.
		return (
			!item.found ||
			(item.rowValue !== null &&
				item.rowValue.indexOf(
					item.filterValue,
					item.filterValue.length - item.rowValue.length
				) !== -1)
		);
	}

	copy(parent) {
		return new EndsWithFilter(this.left, this.right, parent);
	}
}
