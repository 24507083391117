/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { createReducer } from 'redux-act';
import { dataRecieved } from 'actions/modelStatusActions';
import { LOGOUT, CHANGE_MODEL } from 'constants/actionTypes';
import { Varicent } from 'icm-rest-client';

type StateType = Nullable<Varicent.RESTAPI.v1.DTOs.LastCalculationInfoDTO>;

const initialState = {
	lastCalculationDate: null,
	lastFullCalculationDate: null,
	lastCalculationDuration: null,
};

export const modelStatus = createReducer<Readonly<StateType>>({}, initialState);

modelStatus.on(dataRecieved, (state, payload) => ({
	...state,
	...payload,
}));

modelStatus.on(LOGOUT, () => initialState);
modelStatus.on(CHANGE_MODEL, () => initialState);
