// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
	configurableRequestRaw,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 *
 * url: `api/v1/userswithpassword`
 */
export function getAllWithPassword(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'userswithpassword',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.VaricentUserDTO[]>(
		request
	);
}

/**
 *
 * url: `api/v1/users`
 */
export function getAll(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'users',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.AdminUserDTO[]>(request);
}

/**
 * Adds a new admin user to the model
 * url: `api/v1/users`
 */
export function newAdminUser(
	user: Varicent.RESTAPI.v1.DTOs.VaricentUserDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'users',
		apiVersion: 'v1' as const,
		data: user,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Synchronizes an admin user with the model
 * url: `api/v1/users/sync`
 */
export function syncFederatedAdminUser(
	user: Varicent.RESTAPI.v1.DTOs.VaricentUserDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: 'users/sync',
		apiVersion: 'v1' as const,
		data: user,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/users/${id}`
 */
export function deleteAdminUser(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `users/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/users/logadminadd`
 */
export function logAdminAdd(
	user: Varicent.RESTAPI.v1.DTOs.UserDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'users/logadminadd',
		apiVersion: 'v1' as const,
		data: user,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/users/logadmindelete`
 */
export function logAdminDelete(
	user: Varicent.RESTAPI.v1.DTOs.UserDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'users/logadmindelete',
		apiVersion: 'v1' as const,
		data: user,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/users/${id}`
 */
export function getAdminUser(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `users/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.UserDTO>(request);
}

/**
 *
 * url: `api/v1/payee/payeeNames`
 */
export function getPayeeNamesFromIDs(
	query: { payeeIds: string[] },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/payeeNames',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PayeeNameDTO[]>(request);
}

/**
 *
 * url: `api/v1/permissions/currentPermissions`
 */
export function getCurrentUserPermissions(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'permissions/currentPermissions',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<string[]>(request);
}

/**
 *
 * url: `api/v1/users/${id}/permissions`
 */
export function getUserPermissions(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `users/${id}/permissions`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.RolePermissionDTO[]>(
		request
	);
}

/**
 *
 * url: `api/v1/users/${id}`
 */
export function patchUser_Role(
	id: Varicent.ID,
	role: Varicent.RESTAPI.v1.DTOs.RoleDefinitionDTO,
	query?: { isNew?: boolean },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PATCH,
		url: `users/${id}`,
		apiVersion: 'v1' as const,
		data: role,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.UserDTO>(request);
}

/**
 *
 * url: `api/v1/roles`
 */
export function getAllRoles(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'roles',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.RoleDefinitionDTO[]>(
		request
	);
}

/**
 *
 * url: `api/v1/roles/${id}`
 */
export function getRole(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `roles/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.RoleDefinitionDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/roles`
 */
export function postRole(
	role: Varicent.RESTAPI.v1.DTOs.RoleDefinitionDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'roles',
		apiVersion: 'v1' as const,
		data: role,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.RoleDefinitionDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/roles/${id}`
 */
export function deleteRole(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `roles/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/roles/${id}`
 */
export function putRole(
	id: Varicent.ID,
	role: Varicent.RESTAPI.v1.DTOs.RoleDefinitionDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `roles/${id}`,
		apiVersion: 'v1' as const,
		data: role,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.RoleDefinitionDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/permissions`
 */
export function getAllPermissions(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'permissions',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.RolePermissionDTO[]>(
		request
	);
}

/**
 *
 * url: `api/v1/permissions/rolenames`
 */
export function getAllRoleNames(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'permissions/rolenames',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.RoleDefinitionNameDTO[]>(
		request
	);
}

/**
 *
 * url: `api/v1/payeetypes/${type}`
 */
export function getPayeeTypes(
	type: string,
	query?: { offset?: number; limit?: number; filter?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payeetypes/${type}`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<{
		[key: string]: Varicent.RESTAPI.v1.DTOs.PayeeNameDTO[];
	}>(request);
}

/**
 *
 * url: `api/v1/payeetypes/transfer/${type}`
 */
export function postPayeeTypesTransferQuery(
	type: string,
	queryDTO: Varicent.RESTAPI.v1.DTOs.RunQueryDTO,
	query?: {
		offset?: number;
		limit?: number;
		fields?: string;
		unique?: boolean;
		filter?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payeetypes/transfer/${type}`,
		apiVersion: 'v1' as const,
		data: queryDTO,
		query,
		...options,
	};
	return configurableRequestRaw<void>(request);
}

/**
 *
 * url: `api/v1/payeetypes/transfer`
 */
export function postPayeeTypesTransfer(
	transferDTO: Varicent.RESTAPI.v1.DTOs.PayeeTypeTransferDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'payeetypes/transfer',
		apiVersion: 'v1' as const,
		data: transferDTO,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payeetypes`
 */
export function putPayeeTypes(
	dto: Varicent.RESTAPI.v1.DTOs.PayeeTypeMoveDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: 'payeetypes',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<{
		[key: string]: Varicent.RESTAPI.v1.DTOs.PayeeNameDTO[];
	}>(request);
}

/**
 *
 * url: `api/v1/payee/webUserLoginDetails`
 */
export function getPayeeWebUserLoginDetails(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/webUserLoginDetails',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.WebUserLoginDetailsDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/payee/setPasswordExpirePromptShown`
 */
export function setPasswordExpirePromptShown(options?: RequestOptions) {
	const request = {
		method: HttpVerb.PUT,
		url: 'payee/setPasswordExpirePromptShown',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/payeeProfilePicture`
 */
export function getPayeeProfilePicture(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/payeeProfilePicture',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PayeeProfilePictureDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/payee/payeeProfilePicture/${id}`
 */
export function getPayeeProfilePictureFromID(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/payeeProfilePicture/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PayeeImageDTO>(request);
}

/**
 *
 * url: `api/v1/payee/payeeProfilePictures`
 */
export function getPayeeProfilePicturesFromIDs(
	query: { payeeIds: string[] },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/payeeProfilePictures',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PayeeImageDTO[]>(request);
}

/**
 *
 * url: `api/v1/payee/payeeProfilePicture`
 */
export function postPayeeProfilePicture(
	payeeProfilePictureDTO: Varicent.RESTAPI.v1.DTOs.PayeeProfilePictureDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'payee/payeeProfilePicture',
		apiVersion: 'v1' as const,
		data: payeeProfilePictureDTO,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/payeeProfilePicture`
 */
export function deletePayeeProfilePicture(options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: 'payee/payeeProfilePicture',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/users/enabled`
 */
export function getAllSelectedAdminsWithEmail(
	dto: Varicent.RESTAPI.v1.DTOs.BPM.BPMGetSelectedPayeesDTO,
	query?: {
		offset?: number;
		limit?: number;
		filter?: string;
		fields?: string;
		unique?: boolean;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'users/enabled',
		apiVersion: 'v1' as const,
		data: dto,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ResultSetDTO[]>(request);
}

/**
 * Get list of admins in a swimlane
 * url: `api/v1/workflowmanager/bpmInquiries/swimlanes/${swimLaneID}/admin`
 */
export function getInquirySwimLaneAdmins(
	swimLaneID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflowmanager/bpmInquiries/swimlanes/${swimLaneID}/admin`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.AdminUserDTO[]>(request);
}
