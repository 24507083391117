/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { createContext, useContext, useMemo } from 'react';
import { injectIntl, InjectedIntl } from 'react-intl';

const intlContext = createContext<CustomIntl | null>(null);

export const CustomIntlProvider = injectIntl(({ intl, children }) => {
	const customIntl = useMemo(() => {
		const conditionalFormatMessage = (...args: [any, any]) => {
			if (!args[0]) {
				return '';
			}
			if (typeof args[0] === 'string') {
				return args[0];
			}
			return intl?.formatMessage(...args);
		};
		return { ...intl, conditionalFormatMessage } as CustomIntl;
		// only doing this because injectIntl doesn't maintain referencial integrity properly.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<intlContext.Provider value={customIntl}>{children}</intlContext.Provider>
	);
});

export interface CustomIntl extends InjectedIntl {
	/**
	 * A special version of format message. If the argument is a string, just return that.
	 * If it's an object, pass it through localization.
	 */
	conditionalFormatMessage:
		| ((msg: string) => string)
		| InjectedIntl['formatMessage'];
}

export const useIntl = () => {
	const intl = useContext(intlContext);

	if (!intl) {
		throw new Error(
			'Intl has not been configured properly. Make sure CustomIntlProvider is included in the component tree above.'
		);
	}
	return intl;
};

export default intlContext;
