/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as RedirectActionTypes from '../constants/actionTypes';

const handlers = {};

export function redirect(state = {}, action) {
	const { type } = action;
	if (!handlers[type]) {
		return state;
	}
	return handlers[type](state, action);
}

handlers[RedirectActionTypes.BLOCKING_REDIRECT] = handlers[
	RedirectActionTypes.REDIRECT
] = (state, { payload: { url, model } }) => {
	const nextState = { ...state };
	nextState.redirectUrl = url;
	if (model) {
		nextState.model = model;
	}
	return nextState;
};

handlers[RedirectActionTypes.CLEAR_REDIRECT] = (state) => {
	const nextState = { ...state };
	nextState.redirectUrl = false;
	nextState.model = false;
	return nextState;
};
