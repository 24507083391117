/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useState } from 'react';
import qs from 'qs';
import styled from 'react-emotion';
import { Close16 } from '@carbon/icons-react';

import { IconOnlyButton } from 'components/common/iconOnlyButton';

/**
 * @method getCurrentQueryString
 * @description returns everything after the ? in a url
 *  ie: www.website.com/path?foo=bar&age=30 => foo=bar&age=30
 * @param {string} url
 * @returns {string}
 */
export const getCurrentQueryString = (url: string) =>
	!!url && url.includes('?') ? url.substr(url.indexOf('?') + 1) : '';

const queryStringParams: string = getCurrentQueryString(window.location.href);
const { debug = 'false' } = qs.parse(queryStringParams);

// to use the logger, append the url param `?debug=true` to the end
const logger = {
	debugEnabled: debug === 'true',
	emit(method, ...logArguments) {
		if (this.debugEnabled) {
			/* eslint-disable no-console */
			!!console[method] && console[method](...logArguments);
			/* eslint-enable no-console */
		}
	},
	log(...args) {
		this.emit('log', ...args);
	},
	info(...args) {
		this.emit('info', ...args);
	},
	warn(...args) {
		this.emit('warn', ...args);
	},
	error(...args) {
		this.emit('error', ...args);
	},
	table(...args) {
		this.emit('table', ...args);
	},
	trace(...args) {
		this.emit('trace', ...args);
	},
	group(...args) {
		this.emit('group', ...args);
	},
	groupCollapsed(...args) {
		this.emit('groupCollapsed', ...args);
	},
	groupEnd(...args) {
		this.emit('groupEnd', ...args);
	},
};

type CodeProps = {
	collapseable?: boolean;
	children: any;
};

export const Code = ({ collapseable = false, children }: CodeProps) => {
	const [open, toggleOpen] = useState<boolean>(false);
	const [isClosed, setIsClosed] = useState<boolean>(false);

	const onClick = () => {
		if (collapseable) {
			toggleOpen(!open);
		}
	};

	return (
		<>
			{!isClosed && (
				<DebugContainer>
					{collapseable && (
						<DebugButton onClick={onClick}>
							{open ? 'close output' : 'open output'}
						</DebugButton>
					)}
					{open && <CodeContainer>{children}</CodeContainer>}
					<IconOnlyButton
						text="dimiss debugger"
						minimal
						icon={Close16}
						style={{
							position: 'absolute',
							top: '0.75rem',
							right: '0.75rem',
						}}
						onClick={() => {
							setIsClosed(true);
						}}
					/>
				</DebugContainer>
			)}
		</>
	);
};

const DebugContainer = styled.div`
	display: flex;
	position: relative;
	padding: 0.5rem;
	flex-direction: column;
	height: auto;
	border-radius: 0;
	border: none;
	overflow: visible;
`;

const DebugButton = styled.button`
	display: flex;
	width: 100%;
	appearance: none;
	border: none;
	padding: 0.5rem;
	font-size: 1rem;
	border: 1px solid #ccc;
	background-color: #f5f5f5;
	font-weight: 700;
	color: red;
	text-transform: uppercase;
	justify-content: center;
`;

const CodeContainer = styled.pre`
	display: flex;
	min-height: auto;
	height: auto;
	overflow: visible;
	background-color: #f5f5f5;
	margin: 0;
	border-radius: 0;
	padding: 10px;
	font-size: 12px;
	border-style: none solid solid solid;
	border-width: none 1px 2px 3px;
	border-color: none #ccc #ccc #ccc;
`;

export default logger;
