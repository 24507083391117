/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import VIcon from 'components/common/icon';
import VButton from 'components/common/buttonComponent';
import VModal from 'components/common/modalComponent';
import VTooltip from 'components/common/tooltipComponent';
import VBaseComponent from 'components/common/baseComponent';
import { Link } from 'react-router';

export default class ConfirmModal extends VBaseComponent {
	static propTypes = {
		/*
		 * call back to a store action that handles user's decision, if it's true
		 * the call back should dispatch the saved action object stored in the state.
		 */
		onFinishHandler: PropTypes.func.isRequired,
		title: PropTypes.node.isRequired,
		message: PropTypes.node.isRequired,
		primaryButtonText: PropTypes.node.isRequired,
		secondaryButtonText: PropTypes.node.isRequired,
		transitionPath: PropTypes.string,
		usePrimaryButton: PropTypes.bool,
		noSecondaryButton: PropTypes.bool,
		modalType: PropTypes.string,
		successModal: PropTypes.bool,
		linkAndAction: PropTypes.bool,
		showModalCloseButton: PropTypes.bool,
		onRejectHandler: PropTypes.func,
		rejectButtonText: PropTypes.node,
		isNestedModal: PropTypes.bool,
		enforceFocus: PropTypes.bool,
	};

	static defaultProps = {
		modalType: 'warning',
		showModalCloseButton: true,
		isNestedModal: true,
	};

	state = {
		showDetails: false,
	};

	onClickCancel() {
		this.props.onFinishHandler(false);
	}

	onClickConfirm() {
		this.props.onFinishHandler(true);
	}

	onClickReject() {
		const { onRejectHandler } = this.props;
		if (onRejectHandler) {
			onRejectHandler();
		} else {
			this.onClickCancel();
		}
	}

	renderActionPrimaryButton(primaryButtonText, type) {
		return (
			<VTooltip text={primaryButtonText} placement="top">
				<div>
					<VButton
						label={primaryButtonText}
						action={this.onClickConfirm.bind(this)}
						type={type}
						focus
						data-test="confirm-modal-action-primary-button"
					/>
				</div>
			</VTooltip>
		);
	}

	renderLinkPrimaryButton(primaryButtonText, transitionPath, type) {
		return (
			<Link to={transitionPath}>
				<VButton
					label={primaryButtonText}
					type={type}
					focus
					data-test="confirm-modal-link-primary-button"
				/>
			</Link>
		);
	}

	renderLinkActionPrimaryButton(primaryButtonText, transitionPath, type) {
		return (
			<Link to={transitionPath}>
				<VButton
					label={primaryButtonText}
					type={type}
					action={this.onClickConfirm.bind(this)}
					focus
					data-test="confirm-modal-link-action-primary-button"
				/>
			</Link>
		);
	}

	renderDetails() {
		const { details } = this.props;
		const { showDetails } = this.state;
		if (!details) {
			return null;
		}

		return (
			<div className="v-confirm-modal-details">
				<span
					className="v-confirm-modal-details-toggle"
					onClick={() => this.setState({ showDetails: !showDetails })}
				>
					{this.formatMessage(
						showDetails ? 'MODAL_HIDE_DETAILS' : 'MODAL_SHOW_DETAILS'
					)}
				</span>
				<div
					className={`v-confirm-modal-details-message${
						this.state.showDetails ? '' : ' v-hidden'
					}`}
				>
					{details}
				</div>
			</div>
		);
	}

	renderIcon() {
		const { successModal, modalType } = this.props;
		let iconClass = '';
		if (successModal) {
			iconClass = 'icon-signature';
		} else if (modalType) {
			switch (modalType) {
				case 'success':
					iconClass = 'icon-signature';
					break;
				case 'warning':
					iconClass = 'icon-warning';
					break;
				case 'question':
				case 'information':
				case 'iconless':
				default:
					iconClass = '';
					break;
			}
		}
		return <VIcon iconClass={iconClass} type="static" />;
	}

	render() {
		const {
			title,
			message,
			primaryButtonText,
			secondaryButtonText,
			transitionPath,
			noSecondaryButton,
			usePrimaryButton,
			linkAndAction,
			showModalCloseButton,
			rejectButtonText,
			modalType,
			isNestedModal,
			enforceFocus,
		} = this.props;
		const confirmCloseId = 'confirm-close-modal';
		let buttonType = 'destructive';
		if (usePrimaryButton) {
			buttonType = 'primary';
		}
		return (
			<VModal
				aria-labelledby={confirmCloseId}
				show
				size="medium"
				noHideOnClickOutside
				onHide={this.onClickCancel.bind(this)}
				isNestedModal={isNestedModal}
				enforceFocus={enforceFocus}
			>
				<VModal.Header
					id={confirmCloseId}
					className="v-confirm-modal-header"
					title={title}
					closeButton={showModalCloseButton}
				/>
				<VModal.Body>
					<div
						className={`v-confirm-modal-body${
							modalType === 'iconless' ? ' iconless' : ''
						}`}
					>
						{this.renderIcon()}
						<div
							className="v-confirm-modal-body-message"
							data-test="confirm-modal-body-message"
						>
							{message}
						</div>
					</div>
					{this.renderDetails()}
				</VModal.Body>
				<VModal.Footer className="v-confirm-modal-footer">
					{!noSecondaryButton ? (
						<VButton
							label={secondaryButtonText}
							action={this.onClickCancel.bind(this)}
							type="secondary"
							data-test="confirm-modal-secondary-button"
						/>
					) : null}
					{transitionPath && linkAndAction
						? this.renderLinkActionPrimaryButton(
								primaryButtonText,
								transitionPath,
								buttonType
						  )
						: null}
					{transitionPath && !linkAndAction
						? this.renderLinkPrimaryButton(
								primaryButtonText,
								transitionPath,
								buttonType
						  )
						: null}
					{!transitionPath
						? this.renderActionPrimaryButton(primaryButtonText, buttonType)
						: null}
					{rejectButtonText ? (
						<VButton
							label={rejectButtonText}
							action={this.onClickReject.bind(this)}
							type="secondary"
							data-test="confirm-modal-reject-button"
						/>
					) : null}
				</VModal.Footer>
			</VModal>
		);
	}
}
