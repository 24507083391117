/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import RootFilter from './filterOperator/rootFilter';
import ColumnFilter from './columnFilter';
import R from 'ramda';

/*
 * Used to manipulate filter queries. See the Varicent readme for the syntax.
 * Example: "a=4;b=1,2,3||b<>5" becomes
 * [EqualFilter(a,4), OrFilter(EqualFilter(b,[1,2,3]), NotEqualFilter(b,5))]
 */

class ApiFilterStackElement {
	constructor(
		apiFilter,
		columnName = '',
		filter = new RootFilter(),
		uiFilter = new RootFilter()
	) {
		this.apiFilter = apiFilter;
		this.columnChanged = columnName;
		this.filter = filter;
		this.uiFilter = uiFilter;
	}

	copy(apiFilter = this.apiFilter) {
		return new ApiFilterStackElement(
			apiFilter,
			this.columnChanged,
			this.filter.copy(),
			this.uiFilter.copy()
		);
	}

	equals(apiFilterStackElement) {
		return (
			this.filter.toString() === apiFilterStackElement.filter.toString() &&
			this.uiFilter.toString() === apiFilterStackElement.uiFilter.toString()
		);
	}

	// prints out only the api filter
	toString() {
		return this.filter.toString();
	}

	filterRow(row, columns) {
		return (
			this.uiFilter.filter(row, columns) && this.filter.filter(row, columns)
		);
	}

	isFilterOnColumn(columnName) {
		return (
			this.uiFilter.isFilterOnColumn(columnName) ||
			this.filter.isFilterOnColumn(columnName)
		);
	}

	mapColumns(columnMap) {
		this.uiFilter.mapColumns(columnMap);
		this.filter.mapColumns(columnMap);
	}

	addFilter(columnName, filter) {
		if (this.apiFilter.allUI || this.apiFilter.uiColumns[columnName]) {
			this.uiFilter.filters.push(filter);
		} else {
			this.filter.filters.push(filter);
		}
	}
}

export default class ApiFilter {
	constructor(allUI = false, copy = false) {
		this.initialize(null, copy, allUI);
	}

	initialize(rowViewerConfig = null, copy = false, allUI = false) {
		/*
		 * FilterStack is the list of filters used for determining which rows to show. We need the history
		 * for determining which filter options to show (see getFilterForOptions function below)
		 */
		this.filterStack = [];
		// An area to store a filter while it is being built. This is moved to filterStack with the function applyPendingFilter.
		this.nextFilter = null;
		// If true then execute all filters on the UI.
		this.allUI = allUI;
		// These columns are filtered on the front end. Only relevant if allUI is false.
		this.uiColumns = {};
		// Some columns have fixed filter options like Audit. Store those here.
		this.columnOptionsMap = {};

		if (!copy) {
			// this will push intentionally an empty filter whose columnName is [ null ]
			this.pushNewFilter();
		}

		if (rowViewerConfig) {
			const keys = Object.keys(rowViewerConfig.customColumns);

			for (let i = 0; i < keys.length; i++) {
				const col = rowViewerConfig.customColumns[keys[i]];
				if (col.options) {
					this.columnOptionsMap[col.key] = col.options.slice(0);
				}
				if (col.customFilter) {
					this.uiColumns[col.key] = true;
				}
			}
		}
	}

	pushNewFilter(filters = [], columnName = null) {
		const current = this.currentFilterElement();
		if (this.allUI || this.uiColumns[columnName]) {
			this.filterStack.push(
				new ApiFilterStackElement(
					this,
					columnName,
					current ? current.filter.copy() : new RootFilter(),
					new RootFilter(filters)
				)
			);
		} else {
			this.filterStack.push(
				new ApiFilterStackElement(
					this,
					columnName,
					new RootFilter(filters),
					current ? current.uiFilter.copy() : new RootFilter()
				)
			);
		}
	}

	currentFilterElement() {
		if (this.filterStack.length === 0) return null;
		return this.filterStack[this.filterStack.length - 1];
	}

	currentFilter(columnName) {
		const current = this.currentFilterElement();
		if (!current) return null;

		if (this.allUI || this.uiColumns[columnName]) {
			return current.uiFilter;
		}
		return current.filter;
	}

	pendingFilterElement() {
		return this.nextFilter;
	}

	pendingFilter(columnName) {
		if (this.allUI || this.uiColumns[columnName]) {
			return this.nextFilter.uiFilter;
		}
		return this.nextFilter.filter;
	}

	applyPendingFilter(columnFilter) {
		if (!this.pendingFilterElement().equals(this.currentFilterElement())) {
			this.pushNewFilter(
				this.pendingFilter(columnFilter.columnName).filters,
				columnFilter.columnName
			);
		}

		this.nextFilter = this.currentFilterElement().copy();
		this.pendingFilter(columnFilter.columnName).populateColumnFilter(
			columnFilter
		);
	}

	getColumnFilter(columnName, hasRange = true) {
		let options = [];
		if (this.columnOptionsMap[columnName]) {
			options = this.columnOptionsMap[columnName];
		}

		const columnFilter = new ColumnFilter(columnName, options, hasRange, this);
		this.nextFilter = this.currentFilterElement().copy();
		return this.pendingFilter(columnName).populateColumnFilter(columnFilter);
	}

	toString() {
		return this.currentFilterElement().toString();
	}

	// Using the given row, this function returns true if it satisfies the current filter.
	filter(row, columns) {
		return this.currentFilterElement().filterRow(row, columns);
	}

	isFilterOnColumn(columnName) {
		return this.currentFilterElement().isFilterOnColumn(columnName);
	}

	mapColumns(columnMap) {
		this.currentFilterElement().mapColumns(columnMap);
	}

	copy() {
		const newFilter = new ApiFilter(this.allUI, true);
		newFilter.nextFilter = this.nextFilter;

		for (let i = 0; i < this.filterStack.length; i++) {
			newFilter.filterStack.push(this.filterStack[i].copy(newFilter));
		}

		newFilter.uiColumns = { ...this.uiColumns };
		newFilter.columnOptionsMap = { ...this.columnOptionsMap };

		return newFilter;
	}

	filterOnColumns(columns) {
		const newFilter = this.copy();
		newFilter.filterStack.forEach((element) => {
			const filters = element.filter.filters;
			if (filters.length) {
				element.filter.filters = filters.filter(function (filter) {
					if (typeof filter.left === 'string') {
						return columns.includes(filter.left);
					} else {
						return (
							columns.includes(filter.left.left) ||
							columns.includes(filter.right.left)
						);
					}
				});
			}
		});
		return newFilter;
	}

	getFilterForOptions(columnDef) {
		return this.getFilterForOptionsInternal(
			columnDef ? columnDef.key : columnDef,
			columnDef && columnDef.filter && columnDef.filter.type === 'Date'
		);
	}

	/*
	 * When getting a filter to fetch the options for a column
	 * ignore filters applied to that column until we hit a filter
	 * for a different column starting from the last one and going
	 * backwards. In the case of date columns, we stop when we hit
	 * a filter for that column in order to also filter the options
	 * of the filter box.
	 */
	getFilterForOptionsInternal(columnName, isDateColumn) {
		// return null if the filter stack is empty
		if (this.filterStack.length === 0) return null;

		// start from the last filter in the array
		const topFilterIndex = this.filterStack.length - 1;
		let i = topFilterIndex;

		/*
		 * as long as the column name was specified and the current
		 * filter refers to that column, keep going
		 */
		while (columnName && this.filterStack[i].columnChanged === columnName) {
			if (i === 0) {
				// we've reached the bottom of the stack, so we forcibly have to break the loop
				break;
			}

			/*
			 * date columns are treated differently since the ability to select a range is also
			 * provided and, when it's defined, only those entries selected by the range are
			 * displayed as filter options. For all other columns, the top 100  unique options
			 * are always displayed regardless of user selection for that column.
			 */
			if (isDateColumn && i === topFilterIndex) {
				/*
				 * this is the active filter applied to the given column which contains Date values.
				 * If the date filter includes a date range selection use it to filter the options.
				 * Otherwise keep going and proceed normally.
				 * this.filterStack[i]
				 * -> .filter : RootFilter
				 *    -> filters : Array
				 *       -> [m] : FilterOperator
				 *          -> .type : 'Range'
				 */
				const rangeFilter = R.find(R.propEq('type', 'Range'))(
					this.filterStack[i].filter.filters
				);
				if (rangeFilter) {
					break;
				}
			}

			/*
			 * decrement the index in order to test the previous
			 * filter from the stack
			 */
			i--;
		}

		// ignore filters applied to the column we want to fetch options for
		const newFilterStackElem = this.copy().filterStack[i];
		newFilterStackElem.filter.filters =
			newFilterStackElem.filter.filters.filter((f) => f.left !== columnName);
		return newFilterStackElem;
	}
}
