/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LocaleHelper from '../helpers/localeHelper';
import VBaseComponent from 'components/common/baseComponent';
import VIcon from 'components/common/icon';
import VIconGroup from 'components/common/iconGroup';
import { bannerTypes } from '../services/maintenanceService';
import { clearBannerMessage } from 'actions/bannerMessageActions';
import { getCalendarDateTime } from 'helpers/dateHelper';
import {
	AdminWebBannerNotifiedTypes,
	MAX_CUSTOM_BANNER_ALLOWED,
} from 'constants/banner/adminWebBannerOptions';

import '../styles/components/messageBanner.scss';
import { Close16 } from '@carbon/icons-react';
import { Button } from '@blueprintjs/core';
import {
	colorGray2,
	colorDarkGray3,
	colorGold3,
	Icon,
} from '@varicent/components';
import styled, { css } from 'react-emotion';

const MaintenanceTextWrapper = styled.div`
	font-size: 0.875rem;
	line-height: 1.4286;
	text-align: center;
	flex-grow: 2;
	padding: 0.625rem 0;
`;

@connect(
	(state) => ({
		bannerMessage: state.bannerMessage,
		locale: state.locale,
	}),
	(dispatch) => ({
		dispatch,
		clearBannerMessage: bindActionCreators(clearBannerMessage, dispatch),
	})
)
export default class MessageBannerContainer extends VBaseComponent {
	static propTypes = {
		banners: PropTypes.object,
		message: PropTypes.string,
		type: PropTypes.string,
		actionLinkLabel: PropTypes.string,
		action: PropTypes.object,
		messagesNeedTranslation: PropTypes.bool,
	};

	onClickLink = () => {
		if (this.props.bannerMessage.action) {
			this.props.dispatch(this.props.bannerMessage.action);
		}
	};

	onClickClose = (banner) => {
		this.props.clearBannerMessage(banner);
	};

	renderConfirmationMessage = () => {
		const message = this.translateIfNeeded(this.props.bannerMessage.message);
		const actionLinkLabel = this.translateIfNeeded(
			this.props.bannerMessage.actionLinkLabel
		);
		let timer = setTimeout(this.onClickClose, 5000);

		if (message) {
			return (
				<div
					className="v-banner-box v-banner-box-confirmation"
					onMouseOver={() => {
						clearTimeout(timer);
					}}
					onMouseOut={() => {
						timer = setTimeout(this.onClickClose, 5000);
					}}
				>
					<VIconGroup marginOn="right">
						<VIcon
							iconClass="icon-checkmark-16px"
							size="xsmall"
							type="static"
						/>
					</VIconGroup>
					{message}
					{actionLinkLabel ? (
						<a onClick={this.onClickLink} href="#">
							{actionLinkLabel}
						</a>
					) : null}
					<VIconGroup className="v-banner-close-icon">
						<VIcon
							iconClass="icon-closemodal"
							size="xsmall"
							action={() => this.onClickClose()}
						/>
					</VIconGroup>
				</div>
			);
		}

		return null;
	};

	renderConfirmationMessageWithLink = () => {
		const message = this.translateIfNeeded(this.props.bannerMessage.message);
		const actionLinkLabel = this.translateIfNeeded(
			this.props.bannerMessage.actionLinkLabel
		);
		const actionLink = this.translateIfNeeded(this.props.bannerMessage.action);
		let timer = setTimeout(this.onClickClose, 5000);

		if (message) {
			return (
				<div
					className="v-banner-box v-banner-box-confirmation"
					onMouseOver={() => {
						clearTimeout(timer);
					}}
					onMouseOut={() => {
						timer = setTimeout(this.onClickClose, 5000);
					}}
				>
					<VIconGroup marginOn="right">
						<VIcon
							iconClass="icon-checkmark-16px"
							size="xsmall"
							type="static"
						/>
					</VIconGroup>
					{message}
					{actionLinkLabel ? (
						<a href={actionLink} target="_blank">
							{actionLinkLabel}
						</a>
					) : null}
					<VIconGroup className="v-banner-close-icon">
						<VIcon
							iconClass="icon-closemodal"
							size="xsmall"
							action={() => this.onClickClose()}
						/>
					</VIconGroup>
				</div>
			);
		}

		return null;
	};

	translateIfNeeded(message) {
		if (typeof message === 'object') {
			const values = _.mapValues(message.values, (value) => {
				const fmtValue = this.translateTableOrColumnName(value);
				return this.formatMessage(fmtValue.toUpperCase());
			});

			if (message.id) {
				const msg = this.formatMessage(message.id, values);
				if (this.props.bannerMessage.messagesNeedTranslation) {
					return this.translateMessage(msg);
				}
				return msg;
			}
			if (this.props.bannerMessage.messagesNeedTranslation) {
				const msg = this.formatMessage(message);
				return this.translateMessage(msg.Message);
			}
		} else if (typeof message === 'string') {
			if (this.props.bannerMessage.messagesNeedTranslation) {
				const msg = this.formatMessage(message);
				return this.translateMessage(msg);
			}
		}
		return message;
	}

	renderActionLink = ({ banner, actionLinkLabel }) => {
		if (!!actionLinkLabel && banner.type === bannerTypes.releaseNotes) {
			return (
				<a href={banner.link} target="_blank">
					{actionLinkLabel}
				</a>
			);
		} else if (!!actionLinkLabel && banner.type === bannerTypes.maintenance) {
			return (
				<a onClick={this.onClickLink} href="#">
					{actionLinkLabel}
				</a>
			);
		} else {
			return null;
		}
	};

	parseBanner = (bannerText) => {
		let parsedText = [];

		try {
			let text = bannerText;

			let i = 0;
			while (text) {
				if (i++ > 100) {
					throw new Error('Loop exceeded.');
				}

				const nextSquareBracket = text.indexOf('[');
				const nextSeperator = text.indexOf('](');
				const lastBracket = text.indexOf(')');

				if (
					nextSquareBracket === -1 ||
					nextSeperator === -1 ||
					lastBracket === -1 ||
					nextSquareBracket > nextSeperator ||
					nextSeperator > lastBracket
				) {
					const nextToken =
						Math.min(
							...[nextSquareBracket, nextSeperator, lastBracket].filter(
								(n) => n !== -1
							)
						) + 1;
					parsedText.push(text.substring(0, nextToken));
					text = text.substring(nextToken);
				} else {
					parsedText.push(text.substring(0, nextSquareBracket));

					const linkText = text.substring(nextSquareBracket + 1, nextSeperator);
					const linkUrl = text.substring(nextSeperator + 2, lastBracket);

					parsedText.push(
						<a target="_blank" href={linkUrl} style={{ margin: 0 }}>
							{linkText}
						</a>
					);
					text = text.substring(lastBracket + 1);
				}
			}
		} catch (e) {
			parsedText = [bannerText];
		}

		return parsedText;
	};

	renderBanner = ({ banner, actionLinkLabel, index = null }) => {
		const {
			message = null,
			font_color = `rgb(${colorDarkGray3})`,
			background_color = `rgb(${colorGold3})`,
		} = banner;

		const actionLink = this.renderActionLink({
			banner,
			actionLinkLabel,
		});
		const actionLinkRendering = actionLink ? (
			<>
				&nbsp;
				{actionLink}
			</>
		) : null;

		if (!!banner && !!message) {
			let displayBanner = { ...message };
			if (message.time) {
				displayBanner = {
					...message,
					time: getCalendarDateTime(moment.utc(message.time)),
				};
			}
			const displayMessage = message.needTranslation
				? LocaleHelper.formatMessage(
						window.store.getState(),
						message.key,
						displayBanner
				  )
				: message.key;

			const [parsedBannerMessage] = this.parseBanner(displayMessage);
			const shortenedBannerMessage = parsedBannerMessage
				.substr(0, 5)
				.toLowerCase()
				.replace(/\s+/g, '-');
			const uIndex =
				typeof index === 'number' ? index : Math.round(Math.random() * 1000);

			return (
				<div
					className="v-banner-box"
					key={`banner-${shortenedBannerMessage}-${uIndex}`}
					style={{ backgroundColor: background_color }}
				>
					<MaintenanceTextWrapper style={{ color: font_color }}>
						{parsedBannerMessage}
						{!!actionLinkRendering && actionLinkRendering}
					</MaintenanceTextWrapper>
					<Button
						className={css`
							margin: auto !important;
							&:hover {
								color: rgb(${colorGray2}) !important;
								background-color: transparent !important;
							}
						`}
						minimal
						onClick={() => this.onClickClose(banner)}
						icon={
							<Icon style={{ color: font_color }}>
								<Close16 />
							</Icon>
						}
					/>
				</div>
			);
		}
	};

	render = () => {
		const {
			locale: { status },
			bannerMessage: { type, banners },
		} = this.props;
		const actionLinkLabel = this.translateIfNeeded(
			this.props.bannerMessage.actionLinkLabel
		);

		if (type === 'confirm_message') {
			return this.renderConfirmationMessage();
		}

		if (type === 'confirm_message_link') {
			return this.renderConfirmationMessageWithLink();
		}

		const bannersLocalStorage =
			localStorage.getItem(AdminWebBannerNotifiedTypes.Custom) || '[]';

		if (status && banners && banners.length) {
			/*
			 * rather than filtering, mapping and slicing the array, I decided it will be more efficient
			 * to manipulate the array in a single reduce method
			 */
			return (
				<>
					{banners.reduce((acc, banner, index) => {
						const foundDismissedBanner = bannersLocalStorage.includes(
							JSON.stringify(banner)
						);
						const hasReachedMaxLength = acc.length >= MAX_CUSTOM_BANNER_ALLOWED;
						const willRenderBanner =
							!foundDismissedBanner && !hasReachedMaxLength;

						return [
							...acc,
							/*
							 * IF the current {banner} does not match with a localstorage value
							 * AND IF the current array {acc} is less than 3
							 * THEN render the banner
							 */
							willRenderBanner &&
								this.renderBanner({ banner, actionLinkLabel, index }),
						];
					}, [])}
				</>
			);
		} else {
			return null;
		}
	};
}
