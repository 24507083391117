/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as CustomColorsAPIs from 'icm-rest-client/lib/controllers/customColors';

export default class CustomColorService {
	static readonly MAX_COLORS_SIZE = 7;

	static getColors() {
		return CustomColorsAPIs.get();
	}

	static updateColors(colorsArr) {
		return CustomColorsAPIs.update({ colors: colorsArr });
	}
}
