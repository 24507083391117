/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import '../../styles/components/spinner.scss';
import VReactComponent from 'components/common/reactComponent';

export default class VSpinner extends VReactComponent {
	static propTypes = {
		size: PropTypes.number,
		color: PropTypes.string,
		thickness: PropTypes.number,
		backgroundColor: PropTypes.string,
		centerColor: PropTypes.oneOf(['transparent', 'background']),
		animationDuration: PropTypes.number,
		className: PropTypes.string,
	};

	static defaultProps = {
		size: 16,
		color: 'green',
		thickness: 3,
		backgroundColor: 'white',
		centerColor: 'transparent',
		animationDuration: 2,
	};

	appendPx(num) {
		return `${num}px`;
	}

	appendS(num) {
		return `${num}s`;
	}

	render() {
		const {
			size,
			color,
			thickness,
			backgroundColor,
			centerColor,
			animationDuration,
		} = this.props;

		const roundedSize = Math.round(size);

		const delay = animationDuration * 0.75;
		const border = Math.round(thickness);
		const innerRadius = roundedSize / 2 - border;

		const overlayBorder = border + 2;
		const overlayInnerRadius = innerRadius - 1;
		const overlayOuterRadius = overlayInnerRadius + overlayBorder;

		const realCenterColor =
			centerColor === 'transparent' ? 'transparent' : backgroundColor;

		const style = {
			width: this.appendPx(innerRadius * 2),
			height: this.appendPx(innerRadius * 2),
			backgroundColor: realCenterColor,
			border: `${this.appendPx(border)} solid ${color}`,
		};

		const overlayStyle = {
			width: this.appendPx(overlayInnerRadius),
			height: this.appendPx(overlayInnerRadius * 2),
			backgroundColor: realCenterColor,
			top: this.appendPx(-1 - border),
			border: `${this.appendPx(overlayBorder)} solid ${backgroundColor}`,
		};

		const overlayOuterRadiusPx = this.appendPx(overlayOuterRadius);
		const animation = `v-progress-spinner1-keyframes ${this.appendS(
			animationDuration
		)} infinite ease`;

		const overlayLeftStyle = {
			...overlayStyle,
			left: this.appendPx(-1 - border),
			transformOrigin: `${overlayOuterRadiusPx} ${overlayOuterRadiusPx}`,
			borderRadius: `${overlayOuterRadiusPx} 0 0 ${overlayOuterRadiusPx}`,
			borderRightWidth: 0,
			animation: `${animation} ${this.appendS(delay)}`,
		};

		const overlayRightStyle = {
			...overlayStyle,
			left: this.appendPx(innerRadius),
			transformOrigin: `0 ${overlayOuterRadiusPx}`,
			borderRadius: `0 ${overlayOuterRadiusPx} ${overlayOuterRadiusPx} 0`,
			borderLeftWidth: 0,
			animation,
		};

		const classNames = this.props.className
			? `${this.props.className} v-progress-spinner1`
			: 'v-progress-spinner1';
		return (
			<div className={classNames} style={{ ...this.props.style, ...style }}>
				<div
					className="v-progress-spinner1-overlay-left"
					style={overlayLeftStyle}
				/>
				<div
					className="v-progress-spinner1-overlay-right"
					style={overlayRightStyle}
				/>
			</div>
		);
	}
}
