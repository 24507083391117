/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	ERROR,
	CLEAR_MESSAGE,
	SHOW_MESSAGE,
	CONFIRM_MESSAGE,
	CONFIRM_MESSAGE_LINK,
	INFORMATION,
	MESSAGE_UPLOAD,
	MESSAGE_DOWNLOAD,
	LOGIN,
	LOGOUT,
} from '../constants/actionTypes';

export function message(
	state = {
		message: '',
		type: '',
		requestId: '',
	},
	action
) {
	const { type, payload } = action;

	switch (type) {
		case MESSAGE_UPLOAD:
			return {
				...state,
				type: 'upload',
				message: payload.message,
				actionLinkLabel: '',
				action: null,
				progress: payload.progress,
				messagesNeedTranslation: false,
			};
		case MESSAGE_DOWNLOAD:
			return {
				...state,
				type: 'download',
				message: payload.message,
				actionLinkLabel: '',
				action: null,
				progress: payload.progress,
				messagesNeedTranslation: false,
			};
		case INFORMATION:
			return {
				...state,
				type: 'information',
				message: payload.message,
				actionLinkLabel: payload.actionLinkLabel,
				action: payload.action,
				messagesNeedTranslation: payload.messagesNeedTranslation,
				msgParams: payload.msgParams,
			};
		case ERROR:
			return {
				...state,
				type: 'error',
				message: payload.message,
				actionLinkLabel: payload.actionLinkLabel,
				action: payload.action,
				messagesNeedTranslation: payload.messagesNeedTranslation,
				requestId: payload.requestId ? payload.requestId : '',
			};
		case SHOW_MESSAGE:
			return {
				...state,
				type: 'show_message',
				message: payload.message,
				actionLinkLabel: '',
				action: null,
			};
		case CONFIRM_MESSAGE:
			return {
				...state,
				type: 'confirm_message',
				message: payload.message,
				actionLinkLabel: payload.actionLinkLabel,
				action: payload.action,
				messagesNeedTranslation: payload.messagesNeedTranslation,
			};
		case CONFIRM_MESSAGE_LINK:
			return {
				...state,
				type: 'confirm_message_link',
				message: payload.message,
				actionLinkLabel: payload.actionLinkLabel,
				action: payload.actionLink,
				messagesNeedTranslation: payload.messagesNeedTranslation,
			};
		case CLEAR_MESSAGE:
		case LOGIN:
		case LOGOUT:
			return {
				...state,
				type: 'clear_message',
				message: '',
				actionLinkLabel: '',
				action: null,
				requestId: '',
			};
		default:
			return state;
	}
}
