/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	LOGIN,
	LOGOUT,
	CLEAR_JWT,
	RENEW_JWT,
	ROUTER_NAVIGATE,
	CHANGE_MODEL,
	STATUS_PAGE,
	OPEN_AUTH_MODAL,
	CLOSE_AUTH_MODAL,
	CLOSE_POPUP,
	SET_INTERCOM_HASH,
	SET_INTERCOM_ACTIVE,
} from 'constants/actionTypes';
import {
	LOGIN_LINK,
	RETRIEVE_CREDENTIALS_LINK,
	OPTION_LINK,
	ADMIN_LINK,
	TENANT_LINK,
	STATUS_LINK,
} from 'constants/routerPaths';
import {
	clearModelIndependentState,
	setCurrentAuthState,
	getCurrentAuthState,
	setLastModel,
	updateNextTimeOfTokenRenewal,
	setCurrentJWTFromTokenMap,
} from 'helpers/authHelper';
import { resetTimeout } from 'helpers/timeoutHelper';
import {
	sendLoginToAnalytics,
	initializeAnalytics,
	resetIdentityForAnalytics,
} from 'helpers/analyticsHelper';
import FeatureFlags from 'constants/featureFlags';
import {
	selectFeaturesEnabledUsingToken,
	isFeatureEnabled,
} from 'utils/featureUtils';
import { getJWTPayloadObject } from 'helpers/jwtHelper';

export function authentication(
	state = {
		showAuthModal: false,
		token: '',
		user: '',
		userName: '',
		database: '',
		adminPage: false,
		loginPage: true,
		isLocal: false,
		models: [],
		jwtRenewMinutesInterval: null,
		adminUserAuditLogEnabled: false,
		tenantID: null,
		optionPage: false,
		retrieveCredentialsPage: false,
		tenantPage: false,
		statusPage: false,
		intercomHash: '',
		intercomActive: false,
		featureFlags: {},
	},
	action
) {
	const { type, payload } = action;

	switch (type) {
		case LOGIN: {
			const {
				token,
				user,
				userName,
				database,
				isLocal,
				models,
				tenantID = null,
				servers,
				tokenMap = {},
				jwtRenewMinutesInterval,
				adminUserAuditLogEnabled,
			} = payload;

			setCurrentAuthState({
				token,
				user,
				userName,
				isLocal,
				models,
				servers,
				tokenMap,
				tenantID,
				jwtRenewMinutesInterval,
			});
			setLastModel(userName, database);
			resetTimeout(userName);

			const isHeapDisabled =
				!process.env.ENABLE_HEAP_OVERRIDE &&
				isFeatureEnabled({
					enabledFeatures: selectFeaturesEnabledUsingToken(token, database),
					feature: FeatureFlags.disableHeap,
				});

			if (!isHeapDisabled) {
				initializeAnalytics();
				sendLoginToAnalytics({ userName, tenantId: tenantID });
			}

			return {
				...state,
				token,
				user,
				userName,
				database,
				isLocal,
				models,
				tenantID,
				jwtRenewMinutesInterval,
				adminUserAuditLogEnabled,
				showAuthModal: false,
			};
		}
		case LOGOUT:
			clearModelIndependentState();
			resetIdentityForAnalytics();
			return {
				...state,
				token: '',
				user: '',
				userName: '',
				database: '',
				models: [],
				tenantID: null,
				loginPage: true,
				featureFlags: {},
				intercomHash: '',
				intercomActive: false,
			};
		case CLEAR_JWT:
			clearModelIndependentState();
			return {
				...state,
				token: '',
			};
		case RENEW_JWT:
			const userState = getCurrentAuthState();

			const tokenMap = userState.tokenMap ?? {};
			if (payload.model && tokenMap[payload.model]) {
				const jsonToken = getJWTPayloadObject(payload.token);
				if (jsonToken.model === payload.model)
					tokenMap[payload.model] = payload.token;
			}

			if (payload.token !== userState.token) {
				updateNextTimeOfTokenRenewal(payload.jwtRefreshInterval);
			}
			setCurrentAuthState({ ...userState, token: payload.token, tokenMap });
			return {
				...state,
				token: payload.token,
			};
		case ROUTER_NAVIGATE:
			return {
				...state,
				adminPage: payload.pathname.startsWith(ADMIN_LINK),
				loginPage: payload.pathname.startsWith(LOGIN_LINK),
				optionPage: payload.pathname.startsWith(OPTION_LINK),
				retrieveCredentialsPage: payload.pathname.startsWith(
					RETRIEVE_CREDENTIALS_LINK
				),
				tenantPage: payload.pathname.startsWith(TENANT_LINK),
				statusPage: payload.pathname.startsWith(STATUS_LINK),
			};
		case CHANGE_MODEL:
			setLastModel(state.userName, payload.label);
			return {
				...state,
				database: payload.label,
				intercomHash: '',
				intercomActive: false,
			};
		case STATUS_PAGE:
			return {
				...state,
				statusPage: payload,
			};
		case OPEN_AUTH_MODAL:
			return {
				...state,
				showAuthModal: true,
			};
		case CLOSE_AUTH_MODAL:
			return {
				...state,
				showAuthModal: false,
			};
		case CLOSE_POPUP:
			window.close();
			return {
				...state,
			};
		case SET_INTERCOM_HASH:
			return {
				...state,
				intercomHash: payload,
				intercomActive: true,
			};
		case SET_INTERCOM_ACTIVE:
			return {
				...state,
				intercomActive: payload,
			};

		default:
			return state;
	}
}
