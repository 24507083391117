/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const CALC_PARTIAL_PREVIEW_RESULT_LIMIT = 100;
export const CALC_FULL_PREVIEW_PAGE_LIMIT = 300;
export const ACTIVITY_STATUS = 'status';
export const ACTIVITY_COMPLETED = 'Completed';
export const ACTIVITY_FAILED = 'Failed';
export const COMPLETEDACTIVITIES_URL = 'completedactivities';
export const LIVEACTIVITIES_URL = 'liveactivities';
export const CALC_FULL_PREVIEW_DATA_URL = 'calculationpreviews';
export const CALC_FULL_PREVIEW_INTERVAL_LENGTH = 5000;

export const PREVIEW_TABLE_DIMENSIONS_DEFAULT_HEIGHT = 500;
export const PREVIEW_TABLE_DIMENSIONS_DEFAULT_WIDTH = 898;
export const PREVIEW_TABLE_DIMENSIONS_MODAL_HEIGHT_DIFF = 68;

export const QUERY_TOOL_PREVIEW_PAGE_LIMIT = 300;
export const QUERYTOOL_INTERVAL_LENGTH = 5000;
export const DATASTORE_PREVIEW_LIMIT = 300;
