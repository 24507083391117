/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import VBaseComponent from 'components/common/baseComponent';
import VModal from 'components/common/modalComponent';
import { ABOUT_ICM_MODAL } from '../../constants/modalNames';
import '../../styles/components/about.css';

export default class AboutICMModal extends VBaseComponent {
	static propTypes = {
		modalName: PropTypes.string,
	};

	onCloseAboutICMModal() {
		store.actions.modalActions.closeModal(ABOUT_ICM_MODAL);
	}

	render() {
		const { modalName } = this.props;
		/*
		 * Created new string 'LICENSE_2021_03' so that it will appear as a new
		 * key to be translated when we give strings to translation team. Until
		 * the translation is received, fall back to old LICENSE for other languages
		 */
		const license = this.hasMessageKey('LICENSE_2021_03')
			? this.formatMessage('LICENSE_2021_03')
			: this.formatMessage('LICENSE');

		return (
			<VModal
				show={modalName === ABOUT_ICM_MODAL}
				onHide={this.onCloseAboutICMModal}
				size="large"
				noHideOnClickOutside
			>
				<VModal.Header
					title={this.formatMessage('ABOUT_PRODUCT')}
					closeButton
				/>
				<VModal.Body>
					<div className="v-about-icm">{license}</div>
				</VModal.Body>
			</VModal>
		);
	}
}
