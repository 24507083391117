/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { createAction } from 'redux-act';

import { ROUTER_NAVIGATE } from '../constants/actionTypes';

export const navigate = createAction(
	ROUTER_NAVIGATE,
	(pathname: string, params) => ({
		pathname,
		params,
	})
);
