/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/*
 * SPM-50074:
 * Firefox throws when accessing any property of DataTransfer.
 * react-dnd accesses the `types` property, so we can't trivially avoid it.
 */
export default function () {
	if (typeof DragEvent === 'undefined') {
		return;
	}

	class MockDataTransfer {
		get dropEffect() {
			return 'none';
		}
		set dropEffect(x) {
			/* empty */
		}
		get effectAllowed() {
			return 'none';
		}
		set effectAllowed(x) {
			/* empty */
		}
		get files() {
			return [];
		}
		get items() {
			return [];
		}
		get types() {
			return [];
		}
		clearData() {}
		getData() {
			return '';
		}
		setData() {}
		setDragImage() {}
	}

	if (typeof DataTransfer !== 'undefined') {
		/*
		 * Conventional inheritance can't be used as the DataTransfer constructor throws,
		 * but we need this for potential instanceof checks.
		 */
		Object.setPrototypeOf(MockDataTransfer.prototype, DataTransfer.prototype);
	}

	const oldDescriptor = Object.getOwnPropertyDescriptor(
		DragEvent.prototype,
		'dataTransfer'
	);
	const { get } = oldDescriptor;
	Object.defineProperty(DragEvent.prototype, 'dataTransfer', {
		...oldDescriptor,
		get() {
			const dataTransfer = get.call(this);
			try {
				/* eslint-disable @typescript-eslint/no-unused-expressions */
				// accessing any property will throw an error
				dataTransfer.x;
				/* eslint-enable @typescript-eslint/no-unused-expressions */
				return dataTransfer;
			} catch (e) {
				return new MockDataTransfer();
			}
		},
	});
}
