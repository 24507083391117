/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import '../../styles/components/iconGroup.scss';
import classNames from 'classnames';
import VReactComponent from 'components/common/reactComponent';

export default class VIconGroup extends VReactComponent {
	static propTypes = {
		children: PropTypes.node,
		marginOn: PropTypes.oneOf(['left', 'right', 'both', 'none']),
		spacing: PropTypes.oneOf([
			'xxsmall',
			'xsmall',
			'small',
			'medium',
			'large',
			'xlarge',
		]),
		float: PropTypes.oneOf(['left', 'right', 'none']),
		className: PropTypes.string,
	};

	static defaultProps = {
		marginOn: 'none',
		spacing: 'xxsmall',
		float: 'none',
		className: '',
	};

	render() {
		const { marginOn, spacing, float, children, className } = this.props;
		const classes = classNames(
			'v-icon-group',
			`v-${spacing}-spacing`,
			className,
			{
				'v-left-margin': marginOn === 'left' || marginOn === 'both',
				'v-right-margin': marginOn === 'right' || marginOn === 'both',
				'v-float-left': float === 'left',
				'v-float-right': float === 'right',
			}
		);

		return <span className={classes}>{children}</span>;
	}
}
