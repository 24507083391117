/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import sagaMiddleware from 'middlewares/sagaMiddleware';
import localeMiddleware from 'icm-core/lib/middlewares/localeMiddleware';
import createCustomSentryMiddleware from 'icm-core/lib/middlewares/customSentryMiddleware';

import {
	applyMiddleware,
	combineReducers,
	compose,
	createStore as reduxCreateStore,
	Reducer,
	Store,
} from 'redux';
import createLogger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import R from 'ramda';
import sessionMiddleware from './middlewares/sessionMiddleware';
import reducers, { IGlobalStateShape } from './reducers';
import { ROUTER_NAVIGATE } from 'constants/actionTypes';
import actionLogMiddleware from './middlewares/actionLogMiddleware';
import { createEnhancer } from 'utils/reduxSagaHooks';
import initialSagas from './sagas';

export type { IGlobalStateShape } from './reducers';

const reducersApp = combineReducers<IGlobalStateShape>({
	...(reducers as any),
});

interface IAsyncReducers {
	[key: string]: Reducer<IGlobalStateShape>;
}
export interface ICustomStore extends Store<IGlobalStateShape> {
	injectAsyncReducers: (newReducers: IAsyncReducers) => void;
	asyncReducers: IAsyncReducers;
	actions: any;
	lastNavigationAction: { type: string; payload: any };
}

const trackLastNavigationActionEnhancer =
	(createStore) => (reducers, preloadedState) => {
		const store = createStore(
			reducers,
			preloadedState,
			applyMiddleware(() => (next) => (action) => {
				if (action && action.type === ROUTER_NAVIGATE) {
					store.setLastNavigationAction(action);
				}
				return next(action);
			})
		);
		store.setLastNavigationAction = (action) =>
			(store.LastNavigationAction = action);
		store.getLastNavigationAction = () => store.LastNavigationAction;
		return store;
	};

export const createStore = (initialState?: IGlobalStateShape) => {
	/*
	 * Middleware order:
	 * 1. thunkMiddleware goes first to transform all thunks and to allow login actions to always reach the server.
	 * 2. sessionMiddleware goes after thunk so it can potentially stop actions that modify redux state.
	 * 3. localeMiddleware to associate actions with messages from locale.
	 * 3. sagaMiddleware to see any actions and trigger associated actions.
	 * 4. actionLogMiddleware goes before logger and in prod to send action logs with error reports
	 * 5. ravenMiddleware tracks errors and actions.
	 */
	const middlewares = [
		thunkMiddleware,
		sessionMiddleware,
		localeMiddleware,
		sagaMiddleware,
		actionLogMiddleware,
	];

	const { ON_PREM, SENTRY_URL } = ICM_CONFIG;

	if (
		!ON_PREM &&
		SENTRY_URL &&
		SENTRY_URL.startsWith('http') &&
		process.env.ENABLE_ERROR_REPORT
	) {
		middlewares.push(
			createCustomSentryMiddleware(SENTRY_URL, {
				middlewareOptions: {
					stateTransformer: (state: IGlobalStateShape) => ({
						modelName: R.pathOr(
							'UNKNOWN',
							['authentication', 'database'],
							state
						),
						localCode: R.pathOr('UNKNOWN', ['locale', 'localeCode'], state),
						tenantID: R.pathOr(
							'UNKNOWN',
							['authentication', 'tenantID'],
							state
						),
						browserSize: {
							innerWidth: window && window.innerWidth,
							innerHeight: window && window.innerHeight,
						},
					}),
				},
			})
		);
	}

	if (ICM_CONFIG.ENABLE_LOGGING) {
		middlewares.push(
			createLogger({
				collapsed: true,
			})
		);
	}

	const enhancer = compose(
		applyMiddleware(...middlewares),
		trackLastNavigationActionEnhancer,
		createEnhancer({ sagaMiddleware })
	) as any;
	const innerStore: any = reduxCreateStore(
		reducersApp as any,
		initialState,
		enhancer
	);
	innerStore.injectAsyncReducers = (asyncReducers) => {
		innerStore.asyncReducers = {
			...innerStore.asyncReducers,
			...asyncReducers,
		};
		const newReducer = combineReducers<IGlobalStateShape>({
			...reducers,
			...innerStore.asyncReducers,
		});
		innerStore.replaceReducer(newReducer);
	};
	sagaMiddleware.run(initialSagas);
	window.store = innerStore;
	return innerStore as ICustomStore;
};

export const store = createStore();
