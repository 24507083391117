/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { Varicent } from 'icm-rest-client';

import axios from 'axios';
import { authURL, alert404RESTAPIErrorHandler } from './httpService';
import * as UsersAPIs from 'icm-rest-client/lib/controllers/users';
import * as ModelAPIs from 'icm-rest-client/lib/controllers/model';

export default class ModelOptionsService {
	static getRolesFromTS(tenantID: Varicent.ID) {
		return axios
			.get(`tenants/${tenantID}/security/roles`, { baseURL: authURL })
			.then(({ data }) => data);
	}

	static getUsers(tenantID: Varicent.ID) {
		return axios
			.get(`tenants/${tenantID}/users`, { baseURL: authURL })
			.then(({ data }) => data);
	}

	static getCurrentUser(tenantID: Varicent.ID, email: string) {
		return axios
			.post(`tenants/${tenantID}/user`, { email }, { baseURL: authURL })
			.then(({ data }) => data);
	}

	static createUser(tenantID: Varicent.ID, user) {
		return axios
			.post(`tenants/${tenantID}/users`, user, { baseURL: authURL })
			.then(({ data }) => data);
	}

	static addUserToModel(tenantID: Varicent.ID, email, modelName: string) {
		return axios
			.patch(
				`tenants/${tenantID}/users/model`,
				{ email, model: modelName },
				{ baseURL: authURL }
			)
			.then(({ data }) => data);
	}

	static updateUser(tenantID: Varicent.ID, user) {
		return axios
			.patch(`tenants/${tenantID}/users`, user, { baseURL: authURL })
			.then(({ data }) => data);
	}

	static updateUserEmail(tenantID: Varicent.ID, email, newEmail) {
		return axios
			.patch(
				`tenants/${tenantID}/users/email`,
				{ email, notification_email: newEmail.notification_email },
				{ baseURL: authURL }
			)
			.then(({ data }) => data);
	}

	static updateUserSSOId(tenantID: Varicent.ID, email, ssoId) {
		return axios
			.patch(
				`tenants/${tenantID}/users/sso_nameid`,
				{ email, sso_nameid: ssoId.sso_nameid },
				{ baseURL: authURL }
			)
			.then(({ data }) => data);
	}

	static updateUserUseNativeLogin(
		tenantID: Varicent.ID,
		email,
		useNativeLogin
	) {
		return axios
			.patch(
				`tenants/${tenantID}/users/use_native_login`,
				{ email, use_native_login: useNativeLogin.use_native_login },
				{ baseURL: authURL }
			)
			.then(({ data }) => data);
	}

	static updateUserNames(tenantID: Varicent.ID, email, names) {
		return axios
			.patch(
				`tenants/${tenantID}/users/names`,
				{ email, ...names },
				{ baseURL: authURL }
			)
			.then(({ data }) => data);
	}

	static changePassword(user) {
		return axios
			.patch('users/password', user, { baseURL: authURL })
			.then(({ data }) => data);
	}

	static generateAPIKey(user) {
		return axios
			.post('users/apikey', user, { baseURL: authURL })
			.then(({ data }) => data);
	}

	static rotateAPIKey(user) {
		return axios
			.post('users/apikey?force=true', user, { baseURL: authURL })
			.then(({ data }) => data);
	}

	static deleteAPIKey(user) {
		return axios
			.delete('users/apikey', { baseURL: authURL, data: user })
			.then(({ data }) => data)
			.catch((err) => {
				return alert404RESTAPIErrorHandler(err);
			});
	}

	/*
	 * adminID and role come from the user object. When coming from the rest api 'adminId' `is` user.adminId (from the Admin table).
	 * When coming from tenant services 'adminId' is user.email
	 */
	static updateUserRole(adminId: Varicent.ID, role, modelName, isNew = false) {
		return UsersAPIs.patchUser_Role(
			adminId,
			role,
			{ isNew },
			{
				headers: { Model: modelName },
			}
		);
	}

	static logAdminAddtoModel(adminId: Varicent.ID, role, model) {
		return UsersAPIs.logAdminAdd({ id: adminId, role } as any, {
			headers: { Model: model.model_name },
		});
	}

	static logAdminDeleteFromModel(formattedUsername, model) {
		return UsersAPIs.logAdminDelete({ id: formattedUsername } as any, {
			headers: { Model: model.model_name },
		});
	}

	static updateUserTSRole(tenantID: Varicent.ID, email, roleId) {
		return axios
			.patch(
				`tenants/${tenantID}/users/role/${roleId}`,
				{ email },
				{ baseURL: authURL }
			)
			.then(({ data }) => data);
	}

	static deleteUser(tenantID: Varicent.ID, user) {
		return axios
			.delete(`tenants/${tenantID}/users`, {
				data: user,
				baseURL: authURL,
			})
			.then(({ data }) => data);
	}

	static deleteUserFromModel(tenantID, email: string, modelName: string) {
		return axios
			.delete(`tenants/${tenantID}/users/model/${modelName}`, {
				data: { email },
				baseURL: authURL,
			})
			.then(({ data }) => data);
	}

	static lockUser(tenantID: Varicent.ID, email: string) {
		return axios
			.patch(
				`/tenants/${tenantID}/users/lock`,
				{ email },
				{
					baseURL: authURL,
				}
			)
			.then(({ data }) => data);
	}

	static unlockUser(tenantID: Varicent.ID, email: string) {
		return axios
			.patch(
				`/tenants/${tenantID}/users/unlock`,
				{ email },
				{
					baseURL: authURL,
				}
			)
			.then(({ data }) => data);
	}

	static getModels(tenantID: Varicent.ID) {
		return axios
			.get(`tenants/${tenantID}/models`, { baseURL: authURL })
			.then(({ data }) => data);
	}

	static getAdminOptions(modelName: string) {
		return ModelAPIs.getModelOptions({
			headers: { Model: modelName },
			preventDefaultRESTAPIError: true,
		});
	}

	static patchAdminOptions(
		modelName: string,
		description: Varicent.RESTAPI.v1.DTOs.ConfigValueDTO
	) {
		return ModelAPIs.patchModelOptions([description], {
			headers: { Model: modelName },
		});
	}

	static getModelUsers(modelName: string) {
		return UsersAPIs.getAll({
			headers: { Model: modelName },
			preventDefaultRESTAPIError: true,
		}).then((result) => {
			return result.map((user) => ({
				...user,
				name: user.name ? user.name : user.id,
			}));
		});
	}

	static getRoles(model: { model_name: string }) {
		return UsersAPIs.getAllRoles({ headers: { Model: model.model_name } });
	}

	static getRoleFromaxios(tenantID, email) {
		return axios
			.post(`tenants/${tenantID}/users/role`, { email }, { baseURL: authURL })
			.then(({ data }) => data);
	}

	static getTenantLog(tenantID, query) {
		return axios
			.get(`auditlogsnew/${tenantID}`, {
				params: query,
				baseURL: authURL,
			})
			.then(({ data }) => data);
	}

	static requestContactEmail(
		tenantID: Varicent.ID,
		email: string,
		contact_email: string,
		email_subject: string,
		email_message: string
	) {
		return axios
			.post(
				`tenants/${tenantID}/users/request_contact_email`,
				{ email, contact_email, email_subject, email_message },
				{ baseURL: authURL }
			)
			.then(({ data }) => data)
			.catch(() => {
				return {};
			});
	}

	static verifyContactEmail(
		tenantID: Varicent.ID,
		email: string,
		contact_email: string,
		passcode: string
	) {
		return axios
			.post(
				`tenants/${tenantID}/users/verify_contact_email`,
				{ email, contact_email, passcode },
				{ baseURL: authURL }
			)
			.then(({ data }) => data);
	}

	static deleteContactEmail(tenantID: Varicent.ID, email: string) {
		return axios
			.delete(`tenants/${tenantID}/users/contact_email`, {
				data: { email },
				baseURL: authURL,
			})
			.then(({ data }) => data)
			.catch(() => {
				return {};
			});
	}
}
