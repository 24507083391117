/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/*
 * This middleware gives a chance for the user to re-authenticate after the session has timed out instead of
 * kicking the user to the login page immediately.
 *
 * Why do we need this?
 * See SPM-47161. After the auth token expires, every request to the REST API returns with response code 401.
 * Upon receiving a 401, the user is redirected to the login page, losing all unsaved data.
 * The improved process gives the user a change to re-authenticate on every action dispatched. The goal is to
 * have the user be able to refresh the session without affecting unsaved data.
 *
 * Caveats:
 * Changes to the state without dispatching actions will not be intercepted.
 * The following cases are not handled well:
 *
 * 1. Using callbacks to directly clear or change React component state.
 * Effect: The change will go through.
 * Fix: save working data in redux state and modify only with actions and reducers.
 *
 * 2. Using service classes to make REST API calls directly.
 * Effect: the API call will hit the endpoint and return 401 due to the invalid jwt token. But no redirection will happen.
 * Fix: consider using redux-api-middleware so API calls will be represented by a regular action object.
 *
 * 3. Route change (navigating to another module for example).
 * Effect: The route will change but all actions that load data for that module will be blocked. The page does not load
 * properly. We currently do not have access to react-router events.
 * Fix: consider using react-router-redux so we will have access to react-router events as actions.
 */

import { isJWTNotExpired } from '../helpers/jwtHelper';
import { getOpenAuthModalAction } from '../helpers/authHelper';
import {
	ALLOWABLE_ACTIONS,
	SQUELCHED_ACTIONS,
} from '../constants/middlewareActionTypes';

function isReduxRouterAction(action) {
	return action.type.indexOf('@@reduxReactRouter') === 0;
}

function isAllowableAction(action) {
	return ALLOWABLE_ACTIONS.includes(action.type) || isReduxRouterAction(action);
}

function isSquelchedAction(action) {
	return SQUELCHED_ACTIONS.includes(action.type);
}

const sessionMiddleware = () => (next) => (action) => {
	if (isJWTNotExpired()) {
		// if JWT has not expired, allow the action to pass to the next middleware
		return next(action);
	} else if (isAllowableAction(action)) {
		// if the action must be allowed to reach the reducer under all circumstances, pass it to the next middleware
		return next(action);
	} else if (isSquelchedAction(action)) {
		/*
		 * if we do not want the action to reach the reducer but also do not want to trigger a login modal,
		 * do nothing
		 */
	} else {
		// else discard the original action and pass on an action that will trigger the login modal
		const openAuthModalAction = getOpenAuthModalAction();
		next(openAuthModalAction);
	}
};

export default sessionMiddleware;
export { isReduxRouterAction, isAllowableAction, isSquelchedAction }; // export for testing only
