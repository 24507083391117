/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as Actions from '../constants/actionTypes';

function getInitialState() {
	return {
		actionListeners: {},
	};
}

const initialState = getInitialState();

const handlers = {};

export function general(state = initialState, action) {
	const { type, payload } = action;

	const { actionListeners } = state;

	if (actionListeners[type]) {
		actionListeners[type]();
		delete actionListeners[type];
	}

	if (!handlers[type]) return state;
	return handlers[type](state, payload);
}

// For testing
handlers[Actions.SET_ACTION_LISTENER] = (state, payload) => {
	const { actionListeners } = state;
	const { event, action } = payload;
	actionListeners[event] = action;

	return {
		...state,
		actionListeners,
	};
};
