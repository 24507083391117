/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import moment from 'moment';

export const defaultDataValues = {
	STRING: '',
	DATE: () => {
		const localDate = moment();
		/*
		 * month is parsed as a 1-based number, but it's obtained as a 0-based number so
		 * we add 1 to the current month number.
		 */
		return moment.utc(
			`${localDate.year()}-${1 + localDate.month()}-${localDate.date()}`,
			'YYYY-MM-DD'
		);
	},
	DROPDOWN: '',
	NUMERIC: 0.0,

	String: '',
	LongString: '',
	MaxString: '',

	Date: () => {
		const localDate = moment();
		/*
		 * month is parsed as a 1-based number, but it's obtained as a 0-based number so
		 * we add 1 to the current month number.
		 */
		return moment.utc(
			`${localDate.year()}-${1 + localDate.month()}-${localDate.date()}`,
			'YYYY-MM-DD'
		);
	},
	DateTime: () => new Date(),

	Decimal: 0.0,
	Int: 0.0,
	Long: 0.0,
	Float: 0.0,

	/*
	 * TODO: What should these defaults be?
	 * 'BLOB',
	 * 'Identity',
	 * 'CLOB',
	 * 'RowVersion',
	 * 'UID'
	 */
};

export const SQL_MAX_DATE = '9998-12-31T00:00:00.000Z';
