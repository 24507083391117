import { useEffect } from 'react';
import { useRouter } from 'utils/routeUtils';

export default function ExitLinkUpdater() {
	const { location } = useRouter();
	const adminRouteRegex = /^\/admin/;
	const loginRouteRegex = /^\/login/;
	const tenantRouteRegex = /^\/tenant/;

	useEffect(() => {
		const fullPath = location.pathname + (location.search ?? '');
		if (
			!adminRouteRegex.test(fullPath) &&
			!loginRouteRegex.test(fullPath) &&
			!tenantRouteRegex.test(fullPath)
		) {
			sessionStorage.setItem('ADMIN_SETTINGS_EXIT_LINK', fullPath);
		}
	}, [location]);
	return null;
}
