/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import { connect } from 'react-redux';
import BaseComponent from 'components/common/baseComponent';
import { IGlobalStateShape } from 'reducers';
import { Button, Classes, Dialog } from '@blueprintjs/core';
import { Icon } from '@varicent/components';
import { Reset16 } from '@carbon/icons-react';
import { css } from 'react-emotion';

const mapStateToProps = (state: IGlobalStateShape) => ({
	showAssetError: state.global.showAssetError,
});

const triggerReload = () => location.reload();

const enhance = connect(mapStateToProps);

class AssetReloadDialog extends BaseComponent<
	ReturnType<typeof mapStateToProps>
> {
	render() {
		const { showAssetError } = this.props;
		return (
			<Dialog
				title={this.formatMessage('ASSET_RELOAD_TITLE')}
				isOpen={showAssetError}
				canEscapeKeyClose={false}
				canOutsideClickClose={false}
				isCloseButtonShown={false}
				portalClassName={css`
					z-index: 2100000001; // super high z-index value to ensure above everything including spinner (max is 2147483647)
				`}
			>
				<div className={Classes.DIALOG_BODY}>
					<p>{this.formatMessage('ASSET_RELOAD_REQUIRED')}</p>
				</div>

				<footer className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							large
							intent="primary"
							onClick={triggerReload}
							icon={
								<Icon>
									<Reset16 />
								</Icon>
							}
						>
							{this.formatMessage('ASSET_RELOAD_CONFIRMATION')}
						</Button>
					</div>
				</footer>
			</Dialog>
		);
	}
}

export default enhance(AssetReloadDialog);
