/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { Router, Route, Redirect, IndexRoute } from 'react-router';
import { useDispatch } from 'react-redux';
import React from 'react';

import { history } from './routerHistory';
import { selectFeatureFlags, isFeatureEnabled } from 'utils/featureUtils';
import * as Paths from 'constants/routerPaths';
import dynamicLoad from 'utils/dynamicLoad';
import ErrorContainer from 'containers/errors/errorContainer';
import FeatureFlags from 'constants/featureFlags';
import LoginContainer from 'containers/loginContainer';
import Main from './main';
import MaintenanceContainer from 'containers/errors/maintenanceContainer';
import PageNotFoundContainer from 'containers/errors/pageNotFoundContainer';
import RequiredAuthContainer from 'containers/requiredAuthContainer';
import RetrieveCredentialsContainer from 'containers/retrieveCredentialsContainer';
import SSOAuthRedirectContainer from 'containers/SSOAuthRedirectContainer';
import {
	resetGlobalActionStatusPollCount,
	getGlobalActionStatus,
} from 'actions/globalActionActions';
import FeatureFlagCheck from 'components/common/permissions/featureFlagCheck';
// This is necessary because `Paths` exports start with slashes, even those that should be relative:
//
// <Route path="home">
//   <Route path="dashboard"> // <- this is a relative path, `/home/dashboard`
//   <Route path="/error"> // <- this is a root-relative path, `/error`
// </Route>
const relative = (path) => path.slice('/'.length);

const ChartContainer = dynamicLoad(() => import('containers/chartContainer'));
const PerformanceContainer = dynamicLoad(() =>
	import('containers/performanceContainer')
);
const PerformanceContainerV2 = dynamicLoad(() =>
	import('containers/performanceContainerV2')
);
const HealthDashboardContainer = dynamicLoad(() =>
	import('containers/healthDashboardContainer')
);
const MobileOptionsContainer = dynamicLoad(() =>
	import('containers/mobileOptionsContainer')
);
const OptionContainer = dynamicLoad(() => import('containers/optionContainer'));
const StatusContainer = dynamicLoad(() => import('containers/statusContainer'));
const HierarchyContainer = dynamicLoad(() =>
	import('containers/hierarchyContainer')
);
const PulsePlanDetailContainer = dynamicLoad(() =>
	import('containers/pulsePlanDetailContainer')
);
const PulseContainer = dynamicLoad(() => import('containers/pulseContainer'));
const CalcRowViewerContainer = dynamicLoad(() =>
	import('containers/calcRowViewerContainer')
);
const RowViewerContainer = dynamicLoad(() =>
	import('containers/rowViewerContainer')
);
const RowViewerContainerV2 = dynamicLoad(() =>
	import('containers/rowViewerContainerV2/builder')
);
const SignatureControlContainer = dynamicLoad(() =>
	import('containers/signatureControlContainer')
);
const SignatureControlContainerV2 = dynamicLoad(() =>
	import('containers/signatureControlContainerV2')
);
const ExternalToolsContainer = dynamicLoad(() =>
	import('containers/externalToolsContainerV2')
);
const StarSchemaContainer = dynamicLoad(() =>
	import('containers/toolsContainerV2/starSchema/starSchemaContainer')
);
const StarSchemaPreviewContainer = dynamicLoad(() =>
	import('containers/toolsContainerV2/starSchema/previewedComponent')
);
const PayeeGroupsContainer = dynamicLoad(() =>
	import('containers/toolsContainerV2/payeeGroup/payeeGroupsContainer')
);
const OptimizeModelContainer = dynamicLoad(() =>
	import('containers/toolsContainerV2/optimizeModelContainer')
);
const ComposerContainer = dynamicLoad(() =>
	import('containers/composerContainer')
);
const WorkflowContainer = dynamicLoad(() =>
	import('containers/workflowContainer')
);
const PortalAccessContainer = dynamicLoad(() =>
	import('containers/portalAccessContainer')
);
const SchedulerContainerLegacy = dynamicLoad(() =>
	import('containers/schedulerContainer')
);
const SchedulerContainer = dynamicLoad(() =>
	import('containers/scheduler/index')
);
const ScenariosContainer = dynamicLoad(() =>
	import('containers/scenariosContainer')
);
const TaskManagerContainer = dynamicLoad(() =>
	import('containers/taskManagerContainer')
);
const PayeeDocumentsManagerContainer = dynamicLoad(() =>
	import('containers/payeeDocumentsManagerContainer')
);
const ActivityContainer = dynamicLoad(() =>
	import('containers/activityContainer')
);
const ModelOptionsContainer = dynamicLoad(() =>
	import('containers/modelOptionsContainer')
);
const TenantLogContainer = dynamicLoad(() =>
	import('containers/tenantLogContainer')
);
const ProcessListContainer = dynamicLoad(() =>
	import('containers/processListContainer')
);
const WebReportContainer = dynamicLoad(() =>
	import('containers/webReportContainer')
);
const WebReportPreviewContainer = dynamicLoad(() =>
	import('containers/webReportPreviewContainer')
);
const WebFormContainer = dynamicLoad(() =>
	import('containers/webFormContainer')
);
const DownloadsContainer = dynamicLoad(() =>
	import('containers/userDownloadsContainer')
);
const AdminContainer = dynamicLoad(() => import('containers/adminContainer'));
const AuditContainer = dynamicLoad(() => import('containers/auditContainer'));
const AuditContainerV2 = dynamicLoad(() =>
	import('containers/auditContainerV2')
);

const ExportErrorLogsContainer = dynamicLoad(() =>
	import('containers/logsContainerV2/exportErrorLogsContainerV2')
);
const ImportErrorLogsContainer = dynamicLoad(() =>
	import('containers/logsContainerV2/importErrorLogsContainerV2')
);
const ImportErrorLogDetailContainer = dynamicLoad(() =>
	import('containers/importErrorLogDetailContainer')
);
const ExportErrorLogDetailContainer = dynamicLoad(() =>
	import('containers/exportErrorLogDetailContainer')
);
const SchedulerErrorLogsContainer = dynamicLoad(() =>
	import('containers/logsContainerV2/schedulerErrorLogsContainerV2')
);
const TaskErrorLogsContainer = dynamicLoad(() =>
	import('containers/logsContainerV2/taskLogsContainerV2')
);
const ExtToolLogsContainer = dynamicLoad(() =>
	import('containers/logsContainerV2/extToolLogsContainerV2')
);
const ExtToolLogsDetailContainer = dynamicLoad(() =>
	import('containers/extToolLogsDetailContainer')
);
const PpoLogsContainer = dynamicLoad(() =>
	import('containers/logsContainerV2/ppoLogsContainerV2')
);
const ManageUsersContainer = dynamicLoad(() =>
	import('containers/manageUsersContainer')
);
const ManageUsersContainerV2 = dynamicLoad(() =>
	import('containers/manageUsersContainerV2')
);
const ManageRolesContainer = dynamicLoad(() =>
	import('containers/manageRolesContainer')
);
const AdminOptionsContainer = dynamicLoad(() =>
	import('containers/adminMoreOptionsContainerV2')
);
const AdminIntegrationsContainer = dynamicLoad(() =>
	import('containers/adminIntegrationsContainerV2')
);
const QueryToolContainer = dynamicLoad(() =>
	import('containers/queryToolContainer')
);
const SavedQueryToolContainer = dynamicLoad(() =>
	import('containers/savedQueryToolContainer')
);
const ModelSummaryContainer = dynamicLoad(() =>
	import('containers/modelSummaryContainerV2')
);
const WebMessagesContainer = dynamicLoad(() =>
	import('containers/webMessagesContainer')
);
const WebMessagesContainerV2 = dynamicLoad(() =>
	import('containers/webMessagesContainerV2')
);
const SavedImportsContainer = dynamicLoad(() =>
	import('containers/savedImportsContainerV2')
);
const PublisherContainer = dynamicLoad(() =>
	import('containers/publisherContainerV2')
);
const SavedTransformationsContainer = dynamicLoad(() =>
	import('containers/savedTransformationsContainerV2')
);
const DocumentsContainer = dynamicLoad(() =>
	import('containers/documentsContainerV2')
);
const HistoryTrackingContainer = dynamicLoad(() =>
	import('containers/historyTrackingContainerV2')
);
const DataGridTemplateContainer = dynamicLoad(() =>
	import('containers/dataGridTemplateContainerV2')
);
const MigrationContainer = dynamicLoad(() =>
	import('containers/migrationContainer')
);
const CalendarsContainer = dynamicLoad(() =>
	import('containers/calendarsContainerV2')
);
const HomeContainer = dynamicLoad(() => import('containers/homeContainer'));

// PLANS
const PlansList = dynamicLoad(() =>
	import('containers/plansContainer/PlansWizard/plansList')
);
const PlanDetails = dynamicLoad(() =>
	import('containers/plansContainer/PlansWizard/plansDetails')
);
const PlanEdit = dynamicLoad(() =>
	import('containers/plansContainer/PlansWizard/plansEdit')
);

const PulseAnalyticsContainer = dynamicLoad(() =>
	import('containers/pulseAnalyticsContainer')
);
const DataPortsContainer = dynamicLoad(() =>
	import('containers/dataPortsContainer')
);
const ManagePayeeTypesContainer = dynamicLoad(() =>
	import('containers/managePayeeTypesContainer')
);
const RapidReportConfigContainer = dynamicLoad(() =>
	import('containers/rapidReportsConfigContainer')
);
const DataDiscoveryContainer = dynamicLoad(() =>
	import('containers/dataDiscoveryContainer')
);
const HierarchiesContainer = dynamicLoad(() =>
	import('containers/hierarchiesContainer')
);
const SupportDocumentationContainer = dynamicLoad(() =>
	import('containers/supportDocumentationContainer')
);

const PresenterFlexible = dynamicLoad(() =>
	import('containers/presenterFlexibleContainer')
);
const PresenterAdaptivePreviewContainer = dynamicLoad(() =>
	import('containers/presenterAdaptivePreviewContainer')
);

const ReportingCenter = dynamicLoad(() =>
	import('containers/reportingCenterContainer')
);

const DataModule = dynamicLoad(() => import('containers/dataModuleContainer'));

const WorkflowEntry = dynamicLoad(() =>
	import(
		/* webpackChunkName: "WorkflowExperience" */ 'containers/workflowEntryContainer'
	)
);

const WorkflowEntryV2 = dynamicLoad(() =>
	import(
		/* webpackChunkName: "WorkflowExperience" */ 'containers/workflowEntryContainerV2'
	)
);

const Inquiry = dynamicLoad(() =>
	import(
		/* webpackChunkName: "InquiryExperience" */ 'containers/inquiryContainer'
	)
);

const InquiryView = dynamicLoad(() =>
	import(
		/* webpackChunkName: "InquiryView" */ 'containers/inquiryContainer/inquiryViewContainer'
	)
);

const AdaptiveFormsBuilder = dynamicLoad(() =>
	import('containers/adaptiveFormsContainer/builder')
);

const AdaptiveFormsViewer = dynamicLoad(() =>
	import('containers/adaptiveFormsContainer/viewer')
);

const HomePageContentComponentV2 = dynamicLoad(() =>
	import('components/home/homePageContentComponentV2')
);

const PeopleModuleContainer = dynamicLoad(() =>
	import('containers/peopleContainer/peopleContainer')
);

const featureFlagOnEnterCheck =
	(featureFlag, options = {}) =>
	(nextState, replace) => {
		const { errorWhenFeatureEnabled = false } = options;
		const featureFlags = selectFeatureFlags(window.store.getState());
		const isEnabled = isFeatureEnabled({
			enabledFeatures: featureFlags,
			feature: featureFlag,
		});

		if (
			(!isEnabled && !errorWhenFeatureEnabled) ||
			(isEnabled && errorWhenFeatureEnabled)
		) {
			replace({
				pathname: Paths.ERROR,
				nextPathname: nextState.location.pathname,
			});
		}
	};

const routes = React.memo(() => {
	const dispatch = useDispatch();
	React.useEffect(() => {
		HomeContainer.preload();
	}, []);
	const updateGlobalActionStatus = React.useCallback(() => {
		// Reset the poll counter for global action status
		dispatch(resetGlobalActionStatusPollCount());
		dispatch(getGlobalActionStatus());
	}, [dispatch]);

	return (
		<Router history={history}>
			<Route component={Main}>
				<Route
					component={RequiredAuthContainer}
					onEnter={RequiredAuthContainer.onEnter(window.store)}
					onChange={updateGlobalActionStatus}
				>
					<Route
						path={Paths.HOME_LINK}
						component={() => (
							<FeatureFlagCheck
								feature={FeatureFlags.classicHomePage}
								alternative={<HomePageContentComponentV2 />}
							>
								<HomeContainer />
							</FeatureFlagCheck>
						)}
					/>

					<Route
						path={Paths.PEOPLE_LINK}
						component={() => (
							<FeatureFlagCheck feature={FeatureFlags.peopleModule}>
								<PeopleModuleContainer />
							</FeatureFlagCheck>
						)}
					/>
					<Route
						path={Paths.PLANS_LINK}
						component={PlansList}
						onEnter={featureFlagOnEnterCheck(FeatureFlags.plansEnabled)}
					/>
					<Route
						path={`${Paths.PLANS_LINK}/:planId`}
						component={PlanDetails}
						onEnter={featureFlagOnEnterCheck(FeatureFlags.plansEnabled)}
					/>
					<Route
						path={`${Paths.PLANS_LINK}/:planId/compose`}
						component={PlanEdit}
						onEnter={featureFlagOnEnterCheck(FeatureFlags.plansEnabled)}
					/>

					<Route
						path={`${Paths.PLANS_LINK}/:planId/:planComponentId`}
						component={PlanEdit}
						onEnter={featureFlagOnEnterCheck(FeatureFlags.plansEnabled)}
					/>

					<Route
						path={Paths.COMPOSER_LINK_WITH_COMPONENT}
						component={ComposerContainer}
						onEnter={featureFlagOnEnterCheck(FeatureFlags.disableComposer, {
							errorWhenFeatureEnabled: true,
						})}
					/>
					<Route
						path={Paths.COMPOSER_LINK}
						component={ComposerContainer}
						onEnter={featureFlagOnEnterCheck(FeatureFlags.disableComposer, {
							errorWhenFeatureEnabled: true,
						})}
					/>
					<Route
						path={Paths.WORKFLOWS_LINK}
						component={() => (
							<FeatureFlagCheck
								feature={FeatureFlags.classicWorkflow}
								alternative={<WorkflowEntryV2 />}
							>
								<WorkflowEntry />
							</FeatureFlagCheck>
						)}
					/>

					{/* Forwarding /workflow to /workflows */}
					<Redirect from="/workflow" to={Paths.WORKFLOWS_LINK} />
					<Route
						path={`${Paths.WORKFLOWS_LINK}/:id`}
						component={WorkflowContainer}
					/>
					{/* Forwarding /workflow/:id to /workflows/:id */}
					<Redirect from="/workflow/:id" to={`${Paths.WORKFLOWS_LINK}/:id`} />
					<Route
						path={Paths.PORTAL_ACCESS_LINK}
						component={PortalAccessContainer}
					>
						<Route path=":category" component={PortalAccessContainer} />
					</Route>

					<Route
						path={`${Paths.SCHEDULER_LINK}/legacy`}
						component={SchedulerContainerLegacy}
					/>
					<Route
						path={`${Paths.SCHEDULER_LINK}/beta`}
						component={SchedulerContainer}
					/>
					<Route
						path={Paths.SCHEDULER_LINK}
						component={(props) => (
							<FeatureFlagCheck
								feature={FeatureFlags.newScheduler}
								alternative={<SchedulerContainerLegacy {...props} />}
							>
								<SchedulerContainer {...props} />
							</FeatureFlagCheck>
						)}
					/>

					<Route path={Paths.SCENARIOS_LINK} component={ScenariosContainer}>
						<Route path=":selectedWorkspaceId" component={ScenariosContainer} />
					</Route>
					<Route
						path={Paths.ANALYTICS_MEASURES_LINK}
						component={DataPortsContainer}
					/>
					<Route path={Paths.TASKS_LINK} component={TaskManagerContainer}>
						<Route path=":category" component={TaskManagerContainer} />
					</Route>
					{/* Forwarding /taskmanager to /tasks */}
					<Redirect from="/taskmanager" to={Paths.TASKS_LINK} />
					{/* Forwarding /taskmanager/:category to /tasks/:category */}
					<Redirect
						from="/taskmanager/:category"
						to={`${Paths.TASKS_LINK}/:category`}
					/>
					<Route path={Paths.ACTIVITY_LINK} component={ActivityContainer} />
					<Route path={Paths.TENANT_LINK} component={ModelOptionsContainer} />
					<Route path={Paths.TENANT_LOG_LINK} component={TenantLogContainer} />
					<Route
						path={Paths.PROCESS_LISTS_LINK}
						component={ProcessListContainer}
					>
						<Route path=":id" component={ProcessListContainer} />
					</Route>
					<Route
						path={Paths.WEBREPORT_LINK}
						component={WebReportContainer}
						onEnter={featureFlagOnEnterCheck(FeatureFlags.disableComposer, {
							errorWhenFeatureEnabled: true,
						})}
					/>
					<Route
						path={Paths.WEBREPORT_PREVIEW_LINK}
						component={WebReportPreviewContainer}
					/>
					<Route
						path={`${Paths.WEBREPORT_PREVIEW_LINK}/:inquiryId`}
						component={WebReportPreviewContainer}
					/>
					<Route path={Paths.WEBFORM_LINK} component={WebFormContainer} />
					<Route path={Paths.DOWNLOADS_LINK} component={DownloadsContainer}>
						<Route path=":category" component={DownloadsContainer} />
					</Route>
					<Route path={Paths.ADMIN_LINK} component={AdminContainer}>
						<Route
							path={relative(Paths.AUDIT_LINK)}
							component={(props) => (
								<FeatureFlagCheck
									feature={FeatureFlags.classicAudit}
									alternative={<AuditContainerV2 {...props} />}
								>
									<AuditContainer {...props} />
								</FeatureFlagCheck>
							)}
						>
							<Route path=":changeLogId" component={AuditContainer}>
								<Route path=":id" component={AuditContainer} />
							</Route>
						</Route>
						<Route
							path={relative(Paths.PERFORMANCE_LINK)}
							component={(props) => (
								<FeatureFlagCheck
									feature={FeatureFlags.classicPerformance}
									alternative={<PerformanceContainerV2 {...props} />}
								>
									<PerformanceContainer {...props} />
								</FeatureFlagCheck>
							)}
						>
							<Route path="batch" component={PerformanceContainer}>
								<Route path=":id" component={PerformanceContainer} />
							</Route>
							<Route path="batchcompare" component={PerformanceContainerV2}>
								<Route path=":id" component={PerformanceContainerV2} />
							</Route>
							<Route path="calc" component={PerformanceContainer}>
								<Route path=":id" component={PerformanceContainer} />
							</Route>
						</Route>
						<Route
							path={relative(Paths.HEALTH_DASHBOARD_LINK)}
							component={HealthDashboardContainer}
						/>
						<Route
							path={`${relative(Paths.COMPUTATION_CHART)}/:id`}
							component={ChartContainer}
						/>
						<Route
							path={relative(Paths.ERROR_LOGS_LINK)}
							component={ExportErrorLogsContainer}
						/>
						<Route
							path={relative(Paths.IMPORT_ERROR_LOGS_LINK)}
							component={ImportErrorLogsContainer}
						/>
						<Route
							path={`${relative(Paths.IMPORT_ERROR_LOGS_LINK)}/:id`}
							component={ImportErrorLogDetailContainer}
						/>
						<Route
							path={relative(Paths.EXPORT_ERROR_LOGS_LINK)}
							component={ExportErrorLogsContainer}
						/>
						<Route
							path={`${relative(Paths.EXPORT_ERROR_LOGS_LINK)}/:id`}
							component={ExportErrorLogDetailContainer}
						/>
						<Route
							path={relative(Paths.SCHEDULER_ERROR_LOGS_LINK)}
							component={SchedulerErrorLogsContainer}
						/>
						<Route
							path={relative(Paths.TASK_ERROR_LOGS_LINK)}
							component={TaskErrorLogsContainer}
						/>
						<Route
							path={Paths.EXTERNAL_TOOLS_LOGS_LINK}
							component={ExtToolLogsContainer}
						/>
						<Route
							path={`${Paths.EXTERNAL_TOOLS_LOGS_LINK}/:runID`}
							component={ExtToolLogsDetailContainer}
						/>
						{/* Forwarding from old ppo logs to new calc endinge logs. */}
						<Redirect from="ppologs" to={Paths.CALC_ENGINE_LOGS_LINK} />
						<Route
							onEnter={featureFlagOnEnterCheck(FeatureFlags.ppoEnabled)}
							path={relative(Paths.CALC_ENGINE_LOGS)}
							component={PpoLogsContainer}
						/>
						<Route
							path={relative(Paths.MY_ACCOUNT_LINK)}
							component={SchedulerContainer}
						/>
						<Route
							path={relative(Paths.UPDATE_PROFILE_LINK)}
							component={SchedulerContainer}
						/>
						<Route
							path={relative(Paths.MANAGE_USERS_LINK)}
							component={ManageUsersContainerV2}
						/>
						<Route
							path={relative(Paths.MANAGE_ROLES_LINK)}
							component={ManageRolesContainer}
						/>
						<Route
							path={relative(Paths.MANAGE_PAYEE_TYPES_LINK)}
							component={ManagePayeeTypesContainer}
						/>
						<Route
							path={relative(Paths.MORE_OPTIONS_LINK)}
							component={AdminOptionsContainer}
						>
							<Route path=":tab" component={AdminOptionsContainer} />
						</Route>
						<Route
							path={relative(Paths.MOBILE_OPTIONS_LINK)}
							component={MobileOptionsContainer}
						/>
						<Route
							path={relative(Paths.INTEGRATIONS_LINK)}
							component={AdminIntegrationsContainer}
						/>
						<Route
							path={relative(Paths.MODEL_SUMMARY_LINK)}
							component={ModelSummaryContainer}
						/>
						<Route
							path={relative(Paths.MANAGE_LINK)}
							component={SchedulerContainer}
						/>
						<Route
							path={relative(Paths.WEB_MESSAGES_LINK)}
							component={WebMessagesContainerV2}
						/>
						<Route
							path={relative(Paths.SAVE_IMPORTS_LINK)}
							component={SavedImportsContainer}
						/>
						<Route
							path={relative(Paths.SAVED_PUBLICATIONS_LINK)}
							component={PublisherContainer}
						/>
						<Route
							path={relative(Paths.SAVED_TRANSFORMATIONS_LINK)}
							component={SavedTransformationsContainer}
						/>
						<Route
							path={relative(Paths.MANAGE_DOCUMENTS_LINK)}
							component={DocumentsContainer}
						/>
						<Route
							path={relative(Paths.MANAGE_PAYEE_DOCUMENTS_LINK)}
							component={PayeeDocumentsManagerContainer}
						/>
						<Route
							path={relative(Paths.HISTORY_TRACKING_LINK)}
							component={HistoryTrackingContainer}
						/>
						<Route
							path={relative(Paths.DATA_GRID_TEMPLATES_LINK)}
							component={DataGridTemplateContainer}
						/>
						<Route
							path={relative(Paths.MIGRATION_LINK)}
							component={MigrationContainer}
						/>
						<Route
							path={relative(Paths.TOOLS_LINK)}
							component={SchedulerContainer}
						/>
						<Route
							path={relative(Paths.CALENDARS_LINK)}
							component={CalendarsContainer}
						/>
						<Route
							path={relative(Paths.OPTIMIZE_MODEL_LINK)}
							component={OptimizeModelContainer}
						/>
						<Route
							path={relative(Paths.PAYEE_GROUPS_LINK)}
							component={PayeeGroupsContainer}
						>
							<Route path=":id" component={PayeeGroupsContainer} />
						</Route>
						<Route
							path={relative(Paths.STAR_SCHEMA_LINK)}
							component={StarSchemaContainer}
						/>
						<Route
							path={`${relative(Paths.STAR_SCHEMA_LINK)}/:id`}
							component={StarSchemaPreviewContainer}
						/>
						<Route
							onEnter={featureFlagOnEnterCheck(FeatureFlags.onCloud)}
							path={relative(Paths.EXTERNAL_TOOLS_LINK)}
							component={ExternalToolsContainer}
						/>
						<Route
							onEnter={featureFlagOnEnterCheck(FeatureFlags.queryTool)}
							path={relative(Paths.QUERY_TOOL_LINK)}
							component={QueryToolContainer}
						>
							<Route path=":type/:id" component={QueryToolContainer} />
						</Route>
						<Route
							onEnter={featureFlagOnEnterCheck(FeatureFlags.queryTool)}
							path={relative(Paths.SAVED_QUERYTOOL_LINK)}
							component={SavedQueryToolContainer}
						/>
						<Route
							onEnter={featureFlagOnEnterCheck(FeatureFlags.hierarchies)}
							path={relative(Paths.HIERARCHIES_LINK)}
							component={HierarchiesContainer}
						/>
					</Route>
					<Route
						path={Paths.SIGNATURE_LINK}
						component={SignatureControlContainerV2}
					/>
					<Route path={Paths.ROW_VIEWER_LINK} component={RowViewerContainer}>
						<Route path=":baseInputFormId" component={RowViewerContainer}>
							<Route
								path=":filterColumn/:filterValue"
								component={RowViewerContainer}
							/>

							<Route
								path=":basedOnColumn/:referencedTable/:id/:filterColumn"
								component={RowViewerContainer}
							>
								<Route path=":filterValue" component={RowViewerContainer} />
							</Route>
						</Route>
					</Route>
					<Route
						path={Paths.CALC_ROW_VIEWER_LINK}
						component={CalcRowViewerContainer}
					>
						<Route path="preview" component={CalcRowViewerContainer} />
					</Route>
					<Route
						path={Paths.PORT_ROW_VIEWER_LINK}
						component={RowViewerContainer}
					/>
					<Route
						path={Paths.PULSE_ANALYTICS_LINK}
						component={PulseAnalyticsContainer}
					/>
					<Route
						onEnter={featureFlagOnEnterCheck(FeatureFlags.rapidReports)}
						path={`${Paths.RAPID_REPORTS_LINK}/:reportId`}
						component={RapidReportConfigContainer}
					/>
					<Route path={Paths.REPORTS} component={ReportingCenter} />
					{/* Forwarding /reporting to /reports */}
					<Redirect from="/reporting" to={Paths.REPORTS} />
					<Route path={Paths.DATA_MODULE} component={DataModule} />
					<Route
						onEnter={featureFlagOnEnterCheck(FeatureFlags.dataModule)}
						path={Paths.DATA_MODULE}
						component={DataModule}
					/>
					<Route
						onEnter={featureFlagOnEnterCheck(FeatureFlags.dataModule)}
						path={`${Paths.DATA_MODULE}${Paths.ROW_VIEWER_LINK}`}
						component={RowViewerContainerV2}
					>
						<Route path=":baseInputFormId" component={RowViewerContainerV2}>
							<Route
								path=":filterColumn/:filterValue"
								component={RowViewerContainerV2}
							/>

							<Route
								path=":basedOnColumn/:referencedTable/:id/:filterColumn"
								component={RowViewerContainerV2}
							>
								<Route path=":filterValue" component={RowViewerContainerV2} />
							</Route>
						</Route>
					</Route>
					<Route
						onEnter={featureFlagOnEnterCheck(FeatureFlags.dataDiscovery)}
						path={`${Paths.DATA_DISCOVERY_DASHBOARD_LINK}/:dashboardId`}
						component={DataDiscoveryContainer}
					/>
					<Route path={Paths.PULSE_LINK} component={PulseContainer}>
						<Route path=":id/settings" component={PulseContainer} />
					</Route>
					<Route
						path={`${Paths.PULSE_LINK}/:id`}
						component={PulsePlanDetailContainer}
					/>
					<Route path={Paths.HIERARCHY_LINK} component={HierarchyContainer} />
					<Route path={Paths.STATUS_LINK} component={StatusContainer} />
					<Redirect
						from={Paths.DATA_DISCOVERY_DASHBOARD_LINK}
						to={Paths.REPORTS}
					/>
					<Redirect from={Paths.RAPID_REPORTS_LINK} to={Paths.REPORTS} />
					<Redirect from={Paths.PRESENTER_FLEXIBLE} to={Paths.REPORTS} />
					<Route
						path={`${Paths.PRESENTER_FLEXIBLE}/:reportId`}
						component={PresenterFlexible}
					/>
					<Route path={Paths.INQUIRY_MANAGEMENT_LINK} component={Inquiry} />
					<Route
						path={`${Paths.INQUIRY_MANAGEMENT_LINK}/:inquiryId`}
						component={InquiryView}
					/>
					<Route
						path={`${Paths.PRESENTER_FLEXIBLE}/:reportId/preview`}
						component={PresenterAdaptivePreviewContainer}
					/>
					<Route
						path={`${Paths.PRESENTER_FLEXIBLE}/:reportId/preview/:inquiryId`}
						component={PresenterAdaptivePreviewContainer}
					/>
					<Route path={Paths.ADAPTIVE_FORMS}>
						<IndexRoute component={AdaptiveFormsBuilder} />
						<Route path="builder/:id" component={AdaptiveFormsBuilder} />
						<Route path=":id" component={AdaptiveFormsViewer} />
					</Route>
				</Route>
				<Route path={Paths.LOGIN_LINK} component={LoginContainer} />
				<Route
					path={Paths.SSO_AUTH_REDIRECT_LINK}
					component={SSOAuthRedirectContainer}
				/>
				<Route path={Paths.OPTION_LINK} component={OptionContainer} />
				<Route
					path={Paths.RETRIEVE_CREDENTIALS_LINK}
					component={RetrieveCredentialsContainer}
				/>
			</Route>
			<Route
				path={Paths.SUPPORT_DOCUMENTATION_LINK}
				component={SupportDocumentationContainer}
			/>
			<Route path={Paths.ERROR} component={ErrorContainer} />
			<Route path={Paths.MAINTENANCE} component={MaintenanceContainer} />
			<Route path="*" component={PageNotFoundContainer} />
		</Router>
	);
});

export default routes;
