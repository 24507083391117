/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import axios from 'axios';
import { authURL } from './httpService';

export enum bannerTypes {
	maintenance = 'maintenance',
	releaseNotes = 'release-notes',
	custom = 'custom',
}

export default class MaintenanceService {
	// will be de Deprecate
	static getUpcomingAndOngoingMaintenances() {
		return axios
			.get('maintenance', { baseURL: authURL })
			.then(({ data }) => data);
	}

	static getOngoingBanners() {
		return axios.get('banners', { baseURL: authURL }).then(({ data }) => data);
	}
}
