/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/*
 * This module contains functions that operate on JWTs.
 * E.g. decoding, checking expiration, etc.
 */
import { getJWT } from 'helpers/authHelper';

export const getCurrentTimeInSeconds = () => Math.floor(Date.now() / 1000);

export function b64DecodeUnicode(str) {
	return decodeURIComponent(
		Array.prototype.map
			.call(
				atob(str),
				(c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
			)
			.join('')
	);
}

// Return the decoded JWT payload as a JS object
export function getJWTPayloadObject(encodedJWT) {
	const encodedPayload = encodedJWT.split('.')[1];
	const decodedPayload = b64DecodeUnicode(encodedPayload);
	const payloadObject = JSON.parse(decodedPayload);
	return payloadObject;
}

// Return true if JWT has not expired
export function isJWTNotExpired() {
	const jwt = getJWT();
	if (!jwt) {
		return false;
	}

	const payload = getJWTPayloadObject(jwt);
	const expireTimeInSeconds = payload.exp; // expiration time is already in UTC (in seconds)

	return getCurrentTimeInSeconds() < expireTimeInSeconds;
}
