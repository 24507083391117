/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { store } from './store';
import { Provider } from 'react-redux';
import AppContentContainer from 'containers/appContentContainer';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useTakeEvery } from 'utils/reduxSagaHooks';
import { LOGIN, LOGOUT, CHANGE_MODEL } from 'constants/actionTypes';
import { delay } from 'redux-saga/effects';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 60000,
			retry: false,
		},
	},
});

const App = () => (
	<>
		<div>
			<QueryClientProvider client={queryClient}>
				<Provider store={store}>
					<ResetQueriesOnLogin />
					<AppContentContainer />
				</Provider>
			</QueryClientProvider>
		</div>
		<div
			id="ant-portal-container"
			className="ant-portal-container"
			style={{
				position: 'absolute',
				left: 0,
				top: 0,
				height: '100vh',
				width: '100vw',
				pointerEvents: 'none',
				background: 'transparent',
			}}
		/>
	</>
);

const ResetQueriesOnLogin = () => {
	useTakeEvery(
		[LOGIN, LOGOUT, CHANGE_MODEL],
		function* (action) {
			if (action.type === CHANGE_MODEL || action.type === LOGOUT) {
				queryClient.clear();
			}
			yield delay(1);
			if (action.type === LOGIN) {
				queryClient.refetchQueries({ active: true });
			}
		},
		[]
	);
	return null;
};

ReactDOM.render(<App />, document.getElementById('app'));
