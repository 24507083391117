/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { AllFilterOperators } from '../../constants/filterOperators';
import AndFilter from '../../models/filter/filterOperator/andFilter';
import OrFilter from '../../models/filter/filterOperator/orFilter';
import GreaterFilter from '../../models/filter/filterOperator/greaterFilter';
import GreaterOrEqualFilter from '../../models/filter/filterOperator/greaterOrEqualFilter';
import LessFilter from '../../models/filter/filterOperator/lessFilter';
import LessOrEqualFilter from '../../models/filter/filterOperator/lessOrEqualFilter';
import EqualFilter from '../../models/filter/filterOperator/equalFilter';
import NotEqualFilter from '../../models/filter/filterOperator/notEqualFilter';
import StartsWithFilter from '../../models/filter/filterOperator/startsWithFilter';
import EndsWithFilter from '../../models/filter/filterOperator/endsWithFilter';
import RangeFilter from '../../models/filter/filterOperator/rangeFilter';
import ContainsFilter from '../../models/filter/filterOperator/containsFilter';
import LikeFilter from 'models/filter/filterOperator/likeFilter';
import RootFilter from 'models/filter/filterOperator/rootFilter';

export const filterOptionsLimit = 101;
export const filterOpenBracket = '((';
export const filterCloseBracket = '))';

export function createClause(operator, left, right, parent) {
	let filter = null;
	switch (operator) {
		case AllFilterOperators.And:
			filter = new AndFilter(null, null, parent);
			break;
		case AllFilterOperators.Or:
			filter = new OrFilter(null, null, parent);
			break;
		default:
			throw new Error(`${operator} is not a clause`);
	}

	filter.left = left;
	filter.right = right;

	return filter;
}

export function createFilter(operator, column, right, parent) {
	if (operator === AllFilterOperators.Range) {
		const range = right instanceof Array ? right : [right];
		return new RangeFilter(column, range, parent);
	} else if (operator === AllFilterOperators.Equal) {
		const range = right instanceof Array ? right : [right];
		return new EqualFilter(column, range, parent);
	}

	const value = right;

	switch (operator) {
		case AllFilterOperators.NotEqual:
			return new NotEqualFilter(column, value, parent);
		case AllFilterOperators.StartsWith:
			return new StartsWithFilter(column, value, parent);
		case AllFilterOperators.EndsWith:
			return new EndsWithFilter(column, value, parent);
		case AllFilterOperators.Less:
			return new LessFilter(column, value, parent);
		case AllFilterOperators.LessOrEqual:
			return new LessOrEqualFilter(column, value, parent);
		case AllFilterOperators.Greater:
			return new GreaterFilter(column, value, parent);
		case AllFilterOperators.GreaterOrEqual:
			return new GreaterOrEqualFilter(column, value, parent);
		case AllFilterOperators.ContainedIn:
			return new ContainsFilter(column, value, parent);
		case AllFilterOperators.Like:
			return new LikeFilter(column, value, parent);
		default:
			throw new Error(`${operator} is not a filter`);
	}
}

/**
 * Applies one extra filter to an existing one and returns the resulting filterString.
 * Does not modify the existing apiFilter.
 * @export
 * @param {ApiFilter} apiFilter
 * @param {any} operator from AllFilterOperators
 * @param {string} column to restrict on
 * @param {string} right : a value to restrict on
 * @returns A filterString usable by the API.
 */
export function createAdHocFilterString(apiFilter, operator, column, right) {
	const apiString = apiFilter.toString();
	const adHocString = createFilter(
		operator,
		column,
		right,
		apiFilter
	).toString();

	if (apiString === '') {
		return adHocString;
	} else {
		return `${apiString};${adHocString}`;
	}
}

export function getFilterOptionsPayload(
	columnKey,
	filterString = null,
	additionalData = {},
	limit = filterOptionsLimit
) {
	const query = {
		fields: columnKey,
		limit,
		unique: true,
		...additionalData,
	};

	if (filterString) query.filter = filterString;

	return query;
}

/**
 * @typedef {Object} Range - type to describe a range
 * @property {number} offset
 * @property {number} count
 */

/**
 * @typedef {Object} FilterPayload - type to describe filter payload
 * @property {number} offset
 * @property {number} limit
 * @property {boolean} unique
 * @property {string} [filter] - an optional filter string
 */

/**
 * @function getFilterPayload
 * @param  {Range} range - Range of filter
 * @param  {string} [filterString=null] - optional filter string
 * @param  {boolean} unique
 * @param  {string} columnKey
 * @return {FilterPayload}
 */
export function getFilterPayload(
	range,
	filterString = null,
	unique,
	columnKey
) {
	const query = {
		offset: range.offset,
		limit: range.count,
		unique,
	};

	if (filterString) query.filter = filterString;
	if (columnKey) query.fields = columnKey;

	return query;
}

export function searchWithSimpleLikeFilter(items, searchValue, columnName) {
	const filter = new RootFilter();
	filter.push(
		createFilter(AllFilterOperators.Like, columnName, searchValue, filter)
	);
	return filter.filter([items], [columnName], true);
}
