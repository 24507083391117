/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import R from 'ramda';
import { IGlobalStateShape } from 'reducers';
import { getJWTPayloadObject } from 'helpers/jwtHelper';
import { createSelector } from 'reselect';
import { connect, useSelector } from 'react-redux';
import FeatureFlags from 'constants/featureFlags';

/*
 * A selector that parses the token object and returns a keyed
 * object to represent enabled flags.
 */
export const selectFeatureFlags = createSelector<
	IGlobalStateShape,
	string,
	string,
	{
		[key: string]: boolean;
	}
>(
	(state) => state.authentication.token,
	(state) => state.authentication.database,
	selectFeaturesEnabledUsingToken
);

export const isFeatureEnabled = ({
	enabledFeatures,
	feature,
}: {
	enabledFeatures: {
		[key: string]: boolean;
	};
	feature?: FeatureFlags;
}) => {
	if (process.env.ENABLE_ALL_FEATURES) {
		// some special exceptions for development features
		if (
			feature === FeatureFlags.symonEmbeddedImportsDisabled ||
			feature === FeatureFlags.disableComposer ||
			feature === FeatureFlags.onPrem
		) {
			return false;
		}
		return !(feature ?? '').startsWith('classic');
	}
	if (R.isNil(feature)) {
		return true;
	}
	return enabledFeatures[feature];
};

export const isFeatureEnabledSelector =
	(feature: FeatureFlags) => (state: IGlobalStateShape) =>
		isFeatureEnabled({
			enabledFeatures: selectFeatureFlags(state),
			feature,
		});

export const withEnabledFeatures = connect((state: IGlobalStateShape) => ({
	enabledFeatures: selectFeatureFlags(state),
}));

/** Try not to use this one. It depends on a global store. Better to use one of the above if possible */
export const isFeatureEnabledWithGlobalStore = (feature: FeatureFlags) => {
	return isFeatureEnabledSelector(feature)(window.store.getState());
};

export function selectFeaturesEnabledUsingToken(token, currentModel) {
	let featuresList = {};
	const cloudProvider = window.ICM_CONFIG.CLOUD_PROVIDER;

	if (token && currentModel) {
		const tokenObject = getJWTPayloadObject(token);

		if (tokenObject.feature_flags) {
			// try to get feature flags with old jwt first in order to be backward compatible
			if (tokenObject.feature_flags[currentModel] !== undefined) {
				if (tokenObject.feature_flags[currentModel]) {
					featuresList = tokenObject.feature_flags[currentModel]
						.split(',')
						.reduce((acc, feature) => {
							acc[feature.trim()] = true;
							return acc;
						}, {});
				}
			} else {
				featuresList = tokenObject.feature_flags
					.split(',')
					.reduce((acc, feature) => {
						acc[feature.trim()] = true;
						return acc;
					}, {});
			}
		}

		if (cloudProvider !== 'AWS' && featuresList[FeatureFlags.watsonAssistant]) {
			featuresList[FeatureFlags.watsonAssistant] = true;
		}

		featuresList[FeatureFlags.ppoEnabled] =
			tokenObject.is_ppo ||
			(tokenObject.ppo_models &&
				R.contains(currentModel, tokenObject.ppo_models));

		featuresList[FeatureFlags.isPayeeWebV2Enabled] =
			tokenObject.is_v2 ||
			(tokenObject.models_v2 &&
				tokenObject.models_v2.find((model) => currentModel === model));

		featuresList[FeatureFlags.dataDiscovery] =
			featuresList[FeatureFlags.isPayeeWebV2Enabled] &&
			(cloudProvider === 'AWS'
				? featuresList[FeatureFlags.awsDataDiscoveryEnabled]
				: !featuresList[FeatureFlags.dataDiscoveryDisabled]);

		featuresList[FeatureFlags.rapidReports] =
			featuresList[FeatureFlags.isPayeeWebV2Enabled] &&
			!featuresList[FeatureFlags.rapidReportsDisabled];
	}

	if ((window as any).ICM_CONFIG.ON_PREM) {
		featuresList[FeatureFlags.onPrem] = true;
	} else {
		featuresList[FeatureFlags.onCloud] = true;
	}

	return featuresList;
}

export const useIsFeatureEnabled = (feature?: FeatureFlags) => {
	const allFeatureFlags = useSelector(selectFeatureFlags);
	return isFeatureEnabled({
		enabledFeatures: allFeatureFlags,
		feature,
	});
};
