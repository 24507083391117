/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const eLearningActionTypes = {
	ELEARNING_GET_COURSES: 'ELEARNING_GET_COURSES',
	ELEARNING_GET_COURSE_UNITS: 'ELEARNING_GET_COURSE_UNITS',
	ELEARNING_GET_COURSE_LINK: 'ELEARNING_GET_COURSE_LINK',
	ELEARNING_CLEAR_COURSE_LINK: 'ELEARNING_CLEAR_COURSE_LINK',
	ELEARNING_USER_LOGIN: 'ELEARNING_USER_LOGIN',
	ELEARNING_USER_VALID: 'ELEARNING_USER_VALID',
	ELEARNING_SETTINGS_VALID: 'ELEARNING_SETTINGS_VALID',
};
