import React from 'react';
import { AnchorButton, Button } from '@blueprintjs/core';
import { Icon, Tooltip } from '@varicent/components';
import type { CarbonIconType } from '@carbon/icons-react';

export const IconOnlyButton = ({
	icon: IconIncoming,
	inline,
	text,
	tooltipProps,
	anchorButton,
	...props
}: {
	icon: CarbonIconType;
	inline?: boolean;
	anchorButton?: boolean;
	text: string;
	tooltipProps?: Partial<React.ComponentPropsWithoutRef<typeof Tooltip>>;
} & Omit<React.ComponentPropsWithoutRef<typeof Button>, 'icon' | 'text'>) => {
	const combinedProps = {
		...props,
		icon: (
			<Icon>
				<IconIncoming />
			</Icon>
		),
		'aria-label': text,
	};

	return (
		<Tooltip
			content={text}
			boundary="viewport"
			wrapperTagName={!inline ? 'div' : 'span'}
			targetTagName={!inline ? 'div' : 'span'}
			{...tooltipProps}
		>
			{props.disabled || anchorButton ? (
				<AnchorButton {...(combinedProps as any)} />
			) : (
				<Button {...combinedProps} />
			)}
		</Tooltip>
	);
};
