/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as ActionTypes from '../constants/actionTypes';

const fontsState = {
	loaded: false,
	fonts: [
		/*
		 * Fonts that have good coverage across browsers and os (Windows & Mac OS)
		 * http://www.ampsoft.net/webdesign-l/WindowsMacFonts.html
		 * https://www.w3schools.com/cssref/css_websafe_fonts.asp
		 */
		'Arial',
		'Times New Roman',
		'Courier New',
		'Verdana',
		'Georgia',
		'Comic Sans MS',
		'Trebuchet MS',
		'Arial Black',
		'Impact',
		'Tahoma',
		// Extra fonts requested by customers
		'Calibri',
		'Roboto',
		'IBM Plex Mono',
		'IBM Plex Sans',
		'IBM Plex Sans Condensed',
		'IBM Plex Serif',
		'Didact Gothic',
		'Montserrat',
		'Open Sans',
		'Sora',
	],
};

function getInitialState() {
	return fontsState;
}

const initialState = getInitialState();
const handlers = {};

// Reducer
export function fonts(state = initialState, action) {
	const { type, payload } = action;

	if (!handlers[type]) return state;
	return handlers[type](state, payload);
}

// Action Handlers
handlers[ActionTypes.FONTS_GET_REQUEST_FINISHED] = (state, { result }) => ({
	...state,
	fonts: result,
	loaded: true,
});
