/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import classnames from 'classnames';
import VBaseComponent from 'components/common/baseComponent';
import VTooltip from 'components/common/tooltipComponent';
import '../../styles/components/label.scss';

export default class VLabel extends VBaseComponent {
	static propTypes = {
		labelFor: PropTypes.string,
		label: PropTypes.node,
		className: PropTypes.string,
		noline: PropTypes.bool,
		toolTip: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
		wrapperClassName: PropTypes.string,
		button: PropTypes.object,
		helpIcon: PropTypes.bool,
	};

	static defaultProps = {
		noline: false,
		className: '',
	};

	renderLabelWithTooltip() {
		const { id, label, className, noline, toolTip, helpIcon } = this.props;
		const labelElem = label ? <label htmlFor={id}>{label}</label> : null;
		const mainClass = classnames(className, {
			'v-label': noline,
			'v-label-underline': !noline,
			'v-text-ellipsis': toolTip,
		});
		const tooltipText = typeof toolTip === 'boolean' ? label : toolTip;
		return (
			<VTooltip text={tooltipText} placement="bottom">
				<div className={`${mainClass} v-label-helpIcon`}>
					{labelElem}
					{this.renderHelpIcon(helpIcon)}
				</div>
			</VTooltip>
		);
	}

	renderHelpIcon(helpIcon) {
		if (helpIcon) {
			return <i className="icon-help2 v-label-helpIcon" />;
		}
		return null;
	}

	render() {
		const {
			labelFor,
			label,
			className,
			noline,
			toolTip,
			wrapperClassName,
			button,
			helpIcon,
		} = this.props;
		const labelElem = label ? <label htmlFor={labelFor}>{label}</label> : null;
		const mainClass = classnames(className, {
			'v-label': noline,
			'v-label-underline': !noline,
			'v-text-ellipsis': toolTip,
		});

		return (
			<div className={wrapperClassName}>
				{toolTip ? this.renderLabelWithTooltip() : null}
				{!toolTip ? (
					<div className={mainClass}>
						{labelElem}
						{button}
						{this.renderHelpIcon(helpIcon)}
					</div>
				) : null}
			</div>
		);
	}
}
