/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { Component } from 'react';
import LocaleHelper from '../../helpers/localeHelper';
import * as SystemTranslations from '../../localizations/systemTables/systemTranslations';
import { ICustomStore } from 'store';

/*
 * Currently defaulting to allow {any} props and state. This allows our javascript
 * code to pass validation but should be removed once we get all our typings in order.
 */
export default abstract class VBaseComponent<
	PropsShape = any,
	StateShape = any
> extends Component<PropsShape, StateShape> {
	// Store shouldn't be stored here but allow it for now.
	protected store: ICustomStore;

	constructor(props, context) {
		super(props, context);

		this.store = window.store;
	}

	protected replaceText(key, replaceMap) {
		return LocaleHelper.replaceText(this.store.getState(), key, replaceMap);
	}

	protected hasMessageKey(key) {
		return key in this.store.getState().locale?.messages;
	}

	protected formatMessage(...args) {
		return LocaleHelper.formatMessage(this.store.getState(), ...args);
	}

	protected translateTableName(id) {
		return SystemTranslations.translateTableName(id);
	}

	protected translateColumnName(id, removeSpaces = true) {
		return SystemTranslations.translateColumnName(id, removeSpaces);
	}

	protected translateTableOrColumnName(id) {
		return SystemTranslations.translateTableOrColumnName(id);
	}

	protected translatePath(path, containsTable = true) {
		return SystemTranslations.translatePath(path, containsTable);
	}

	protected translateMessage(message) {
		return SystemTranslations.translateMessage(message);
	}

	protected reverseTranslateTableName(id) {
		return SystemTranslations.reverseTranslateTableName(id);
	}

	protected reverseTranslateColumnName(id) {
		return SystemTranslations.reverseTranslateColumnName(id);
	}

	protected reverseTranslateTableOrColumnName(id) {
		return SystemTranslations.reverseTranslateTableOrColumnName(id);
	}

	protected reverseTranslatePath(path) {
		return SystemTranslations.reverseTranslatePath(path);
	}

	protected isSystemReservedTableName(name) {
		return SystemTranslations.isSystemReservedTableName(name);
	}

	protected translateCalendarName(id) {
		return SystemTranslations.translateCalendarName(id);
	}

	protected crossLanguageReverseTranslateCalendarName(id) {
		return SystemTranslations.crossLanguageReverseTranslateCalendarName(id);
	}

	protected reverseTranslateCalendarName(id) {
		return SystemTranslations.reverseTranslateCalendarName(id);
	}
}
