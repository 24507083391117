/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import * as Sentry from '@sentry/browser';

interface IProps {
	reportErrors?: boolean;
	onHandleError?: (error: any, errorInfo: any) => void;
}

export default class ErrorCatcher extends React.Component<IProps> {
	componentDidMount() {
		window.addEventListener('error', this.handleGlobalError);
	}

	handleGlobalError = (event: ErrorEvent) => {
		// SPM-63990: stop redirecting if error does not have content or if error level is warning
		if (event?.error) {
			if (event.error.level === 'Warning') {
				console.warn('Caught global warning:', event.error);
			} else {
				console.error('Caught global error:', event.error);
				if (this.props.onHandleError) {
					this.props.onHandleError(event.error, null);
				}
			}
		}
	};

	componentDidCatch(error, errorInfo) {
		console.error('Caught global React error:', error, errorInfo);
		try {
			if (this.props.reportErrors) {
				Sentry.withScope((scope) => {
					if (errorInfo) {
						scope.setExtras(errorInfo);
					}

					Sentry.captureException(error);

					if (this.props.onHandleError) {
						this.props.onHandleError(error, errorInfo);
					}
				});
			}
		} catch (e) {
			// ignore all errors at this point.
		}
	}

	componentWillUnmount() {
		window.removeEventListener('error', this.handleGlobalError);
	}

	render() {
		return <>{this.props.children}</>;
	}
}
