/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import _ from 'lodash';
import R from 'ramda';
/**
 * Triple buffering
 */

export default class Buffer {
	constructor(
		range = { offset: 0, count: 0 },
		data = [[], [], []],
		pending = null
	) {
		this.range = { ...range };
		this.data = [...data];

		if (pending) {
			this.pending = { ...pending };
		} else {
			this.pending = this.getDefaultPendingRange();
		}
	}

	static createEmptyBuffer(range) {
		return new Buffer(range, undefined, {
			range: {
				offset: range.offset,
				count: 3 * range.count,
			},
			allPages: true,
		});
	}

	static createBuffer(dataRows) {
		return new Buffer({ offset: 0, count: dataRows.length }, [
			[...dataRows],
			[],
			[],
		]);
	}

	static createFullBuffer(dataRows, pageSize) {
		const page1 = [];
		const page2 = [];
		const page3 = [];
		dataRows.forEach((row, index) => {
			if (index < pageSize) {
				page1.push(row);
			} else if (pageSize <= index && index < pageSize * 2) {
				page2.push(row);
			} else {
				page3.push(row);
			}
		});
		return new Buffer({ offset: 0, count: pageSize }, [page1, page2, page3]);
	}

	getDefaultPendingRange() {
		return {
			range: {
				offset: this.range.offset,
				count: 3 * this.range.count,
			},
			allPages: true,
		};
	}

	isIndexInRange(index) {
		return (
			index >= this.range.offset &&
			index < 3 * this.range.count + this.range.offset
		);
	}

	findPageOfIndexInRange(index, range) {
		return Math.trunc(index / range.count);
	}

	getRange() {
		return { ...this.range };
	}

	getPendingRange() {
		let range = null;
		if (this.pending) {
			range = this.pending.range;
		}
		return range;
	}

	getDataLength() {
		let dataLength = 0;
		for (let i = 0; i < this.data.length; i++) {
			dataLength += this.data[i].length;
		}
		return dataLength;
	}

	getTotalBufferLength() {
		return this.range.count * this.data.length;
	}

	computeRangeForIndex(range, index) {
		let page = this.findPageOfIndexInRange(index, range);
		if (page > 0) {
			page -= 1;
		}
		return {
			offset: page * range.count,
			count: range.count,
		};
	}

	getDataAtIndex(index) {
		if (this.isIndexInRange(index)) {
			// relative index
			const relativeIndex = index - this.range.offset;
			const page = this.findPageOfIndexInRange(relativeIndex, this.range);
			const data = this.data[page];
			// might be undefined if data is empty
			return data[relativeIndex - page * this.range.count];
		}
		return null;
	}

	insertManyAtIndex(data, index) {
		data.forEach((datum, datumIndex) => {
			this.insertDataAtIndex(datum, index + datumIndex);
		});
	}

	insertDataAtIndex(data, index, replace) {
		let nextBuffer = this;

		if (this.isIndexInRange(index)) {
			// relative index
			const relativeIndex = index - this.range.offset;
			const page = this.findPageOfIndexInRange(relativeIndex, this.range);

			nextBuffer = new Buffer(this.range, this.data);

			nextBuffer.data[page].splice(
				relativeIndex - page * this.range.count,
				replace ? 1 : 0,
				data
			);
		}

		return nextBuffer;
	}

	replaceDataAtIndex(data, index) {
		return this.insertDataAtIndex(data, index, 1);
	}

	replaceDataAtRange(rowsData, rows) {
		const nextBuffer = new Buffer(this.range, this.data);

		const replaceEntries = rows.map((item, index) => rowsData[index]);

		rows.forEach((item, index) => {
			if (!replaceEntries[index]) {
				return;
			}

			const relativeIndex = item - this.range.offset;
			const page = this.findPageOfIndexInRange(relativeIndex, this.range);
			const pageData = nextBuffer.data[page];

			if (pageData) {
				pageData.splice(
					relativeIndex - page * this.range.count,
					1,
					replaceEntries[index]
				);
			}
		});

		return nextBuffer;
	}

	performActionToBuffer(action) {
		const nextBuffer = new Buffer(this.range, this.data);

		nextBuffer.data.forEach((group) => {
			group.forEach((data) => {
				action(data);
			});
		});
		return nextBuffer;
	}

	/*
	 * returns a collection of elements that satisfy the condition as specified by the given
	 * predicate function
	 */
	selectFromBuffer(predicate) {
		const ret = [];
		this.data.forEach((group) => {
			group.forEach((item) => {
				if (predicate(item)) {
					ret.push(item);
				}
			});
		});
		return ret;
		/*
		 * OR ---
		 * return R.pipe(
		 * 	R.unnest,
		 * 	R.unnest,
		 * 	R.filter(predicate)
		 * )(this.data);
		 */
	}

	selectFirstFromBuffer(predicate) {
		return R.pipe(R.unnest, R.unnest, R.find(predicate))(this.data);
	}

	deleteDataAtIndex(index) {
		let nextBuffer = this;

		if (this.isIndexInRange(index)) {
			// relative index
			const relativeIndex = index - this.range.offset;
			const page = this.findPageOfIndexInRange(relativeIndex, this.range);

			nextBuffer = new Buffer(this.range, this.data.slice(0));

			nextBuffer.data[page] = nextBuffer.data[page].slice(0);
			nextBuffer.data[page].splice(relativeIndex - page * this.range.count, 1);
		}

		return nextBuffer;
	}

	deleteDataAtIndexList(indexList) {
		let nextBuffer = this;

		if (indexList && indexList.length) {
			// sort in descending order
			const sortedIndexList = indexList.slice(0).sort((a, b) => b - a);

			sortedIndexList.forEach((index) => {
				nextBuffer = nextBuffer.deleteDataAtIndex(index);
			});
		}

		return nextBuffer;
	}

	moveBackwardToIndex(index) {
		let nextBuffer = this;
		// where is index?
		const { offset, count } = this.range;
		if (index < offset) {
			// replace all data
			nextBuffer = Buffer.createEmptyBuffer(
				this.computeRangeForIndex(this.range, index)
			);
		} else {
			// find page
			const page = this.findPageOfIndexInRange(index - offset, this.range);
			if (page > 2) {
				// SPM-44688 scrolling down and up really fast causes the index to be less then offset when it shouldn't. We should return the buffer in this case.
				return nextBuffer;
			}
			if (page === 0 && offset > 0) {
				// shift buffer backwards, expecting arrival of data
				nextBuffer = new Buffer(
					{
						offset: offset - count,
						count,
					},
					[[], this.data[0], this.data[1]],
					{
						range: {
							offset: offset - count,
							count,
						},
						relativePage: 0,
					}
				);
			}
		}
		return nextBuffer;
	}

	moveForwardToIndex(index) {
		let nextBuffer = this;
		// where is index?
		const { offset, count } = this.range;
		if (index < offset) {
			// SPM-44688 scrolling down and up really fast causes the index to be less then offset when it shouldn't. We should return the buffer in this case.
			return nextBuffer;
		}
		if (index >= offset + 3 * count) {
			// replace all data
			nextBuffer = Buffer.createEmptyBuffer(
				this.computeRangeForIndex(this.range, index)
			);
		} else {
			// find page
			const page = this.findPageOfIndexInRange(index - offset, this.range);
			if (page > 2) {
				// SPM-44688 scrolling down and up really fast causes the index to be less then offset when it shouldn't. We should return the buffer in this case.
				return nextBuffer;
			}
			if (page === 2) {
				// shift buffer forward, expecting arrival of data
				nextBuffer = new Buffer(
					{
						offset: offset + count,
						count,
					},
					[this.data[1], this.data[2], []],
					{
						range: {
							offset: offset + 3 * count,
							count,
						},
						relativePage: 2,
					}
				);
			}
		}
		return nextBuffer;
	}

	addDataAtRange(data, range) {
		const {
			range: { count },
		} = this;
		const pendingRange = this.getPendingRange();
		let nextBuffer = this;

		if (!range) {
			return nextBuffer;
		}

		if (
			pendingRange &&
			pendingRange.offset === range.offset &&
			pendingRange.count === range.count
		) {
			if (this.pending.allPages) {
				nextBuffer = new Buffer(this.range, [
					data.slice(0, count),
					data.slice(count, 2 * count),
					data.slice(2 * count, 3 * count),
				]);
			} else {
				const relativePage = this.pending.relativePage;
				if (relativePage === 0) {
					nextBuffer = new Buffer(this.range, [
						data,
						this.data[1],
						this.data[2],
					]);
				} else if (relativePage === 2) {
					nextBuffer = new Buffer(this.range, [
						this.data[0],
						this.data[1],
						data,
					]);
				} else {
					throw new Error(
						`Buffer without relativePage for data: ${data} range: ${range}!`
					);
				}
			}
		} else {
			/*
			 * In this case the buffer has recieved data that is not in the pendingRange
			 * if the pendingRange has been overwritten then the data may still be valid
			 */
			if (
				range.count === this.range.count ||
				range.count === this.getTotalBufferLength()
			) {
				const newPages = [];
				for (
					let bufferPiece = 0;
					bufferPiece < range.count;
					bufferPiece += this.range.count
				) {
					const newBufferPage = {};
					newBufferPage.data = data.slice(
						bufferPiece,
						bufferPiece + this.range.count
					);
					newBufferPage.range = {};
					newBufferPage.range.count = this.range.count;
					newBufferPage.range.offset = range.offset + bufferPiece;
					newPages.push(newBufferPage);
				}
				for (let newPageNum = 0; newPageNum < newPages.length; newPageNum++) {
					const newPage = newPages[newPageNum];
					if (
						newPage.range.offset + newPage.range.count <=
						this.range.offset + this.getTotalBufferLength()
					) {
						if (newPage.range.offset >= this.range.offset) {
							const newDataIndex =
								(newPage.range.offset - this.range.offset) / this.range.count;
							if (_.isEmpty(this.data[newDataIndex])) {
								this.data[newDataIndex] = data;
								nextBuffer = this;
							}
						}
					}
				}
			}
		}
		return nextBuffer;
	}
}
