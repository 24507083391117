/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useEffect } from 'react';
import ErrorPage from 'icm-core/lib/components/errorPageComponent';
import { injectIntl, InjectedIntlProps } from 'react-intl';

export default injectIntl(({ intl }: InjectedIntlProps) => {
	useEffect(() => {
		document.title = intl.formatMessage({ id: 'MAINTENANCE_TITLE' });
	}, [intl]);

	return (
		<ErrorPage
			errorId="MAINTENANCE_DOWN"
			errorDescriptionId="MAINTENANCE_COME_BACK_LATER"
			isMaintenance
		/>
	);
});
