/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const localeDates = {
	de: 'ddd, D. MMM YYYY',
	es: 'ddd, D [de] MMM [de] YYYY',
	fr: 'ddd D MMM YYYY',
	'fr-CA': 'ddd D MMM YYYY',
	gl: 'ddd D MMM YYYY',
	it: 'ddd, D MMM YYYY',
	ja: 'YYYY年M月D日 ddd',
	ko: 'YYYY년 MMM D일 ddd',
	nl: 'ddd D MMM YYYY',
	pl: 'ddd, D MMM YYYY',
	pt: 'ddd, D [de] MMM [de] YYYY',
	'pt-BR': 'ddd, D [de] MMM [de] YYYY [às]',
	sv: 'ddd D MMM YYYY',
	tr: 'ddd, D MMM YYYY',
	'zh-CN': 'YYYY年MMMD日ddd',
	zh: 'YYYY年MMMD日ddd',
	'zh=TW': 'YYYY年MMMD日ddd',
	en: 'ddd MMM D YYYY',
	hu: 'YYYY. MM. DD.',
	sk: 'D. M. YYYY',
};
