/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import R from 'ramda';
import { OPEN_AUTH_MODAL, CLEAR_JWT } from '../constants/actionTypes';
import { isJWTNotExpired, getCurrentTimeInSeconds } from './jwtHelper';
import LocaleHelper from './localeHelper';
import { host } from 'helpers/storageHelper';
import { parse } from 'qs';

// returns 0 if localStorage item does not exist
export const getNextTimeOfTokenRenewal = () =>
	Number(localStorage.getItem(`${host}__nextTokenRenewal`));
export const shouldGetNewToken = () =>
	getCurrentTimeInSeconds() > getNextTimeOfTokenRenewal();

/*
 * About userToken and JWT:
 *
 * userToken refers to the key-value pair stored in HTML localStorage whose
 * key is <host>__userToken, and
 * value is a stringified JSON containing various connection information such as user's name, list of models,
 * tenantID, and authorization JWT.
 *
 * JWT refers to the 'token' property in userToken (described above). It is a Javascript Web Token used to access
 * protected REST API routes. The JWT is included in the Authorization header in HTTP requests using the Bearer schema.
 */

/*
 * We use both sessionStorage and localStorage to hold the last model to ensure that model selection is intuitive.
 * This is what is stored in each storage:
 * - sessionStorage: if a model is selected in the current tab, that model
 *                   if this tab was opened from another tab, except for new tabs* ({right,middle,ctrl} click), the model from that tab
 *                   otherwise nothing
 * - localStorage: the model selected in the last tab the user (right) clicked on
 * This is what we load in the following situations:
 * - Load new tab via user interaction (bookmark, type manually): localStorage; last model selected in any tab
 * - Refresh current tab: sessionStorage; current model
 * - Open new tab via the app: maybe sessionStorage; the model selected in the opener tab
 *                             otherwise localStorage; the model selected in the last tab interacted with (which is the opener tab**)
 * *: see the following :(
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=818389#c9
 *    https://bugs.chromium.org/p/chromium/issues/detail?id=165452
 * **: except for a race condition where you open a new tab from one model, then quickly switch to a different model
 *     and open another new tab
 */
export function getLastModel(userName) {
	const key = `${host}__${userName}__lastModel`;

	const queryObject = parse(location.search);
	const values = queryObject.nextQuery;
	const selectedModel = values?.split('=')[1] ?? '';

	return (
		selectedModel || sessionStorage.getItem(key) || localStorage.getItem(key)
	);
}

function _setLastModel(userName, model) {
	const key = `${host}__${userName}__lastModel`;
	sessionStorage.setItem(key, model);
	localStorage.setItem(key, model);
}

const updateModelListener = (() => {
	let user;
	let model;

	const addListener = R.once(() => {
		window.addEventListener(
			'mousedown',
			() => _setLastModel(user, model),
			true /* capture */
		);
	});

	return (userName, modelName) => {
		user = userName;
		model = modelName;
		addListener();
	};
})();

export function setLastModel(userName, model) {
	_setLastModel(userName, model);
	updateModelListener(userName, model);
}

/**
 * Current auth state is stored in localStorage,
 * token is stored as the JWT token for current model
 */
export function getCurrentAuthState() {
	const stored = localStorage.getItem(`${host}__userToken`);
	return stored ? JSON.parse(stored) : null;
}

export function setCurrentAuthState({
	token,
	user,
	userName,
	isLocal,
	models,
	servers,
	tokenMap,
	tenantID,
	jwtRenewMinutesInterval,
}) {
	localStorage.setItem(
		`${host}__userToken`,
		JSON.stringify({
			token,
			user,
			userName,
			isLocal,
			models,
			servers,
			tokenMap,
			tenantID,
			jwtRenewMinutesInterval,
		})
	);
}

export function setCurrentJWTFromTokenMap(model) {
	const state = getCurrentAuthState();
	state.token = state.tokenMap[model] ?? state.token;
	setCurrentAuthState(state);
	return state.token;
}

export function getTokenMap() {
	const state = getCurrentAuthState();
	return state?.tokenMap ?? {};
}

export function getJWTRenewMinutesInterval() {
	const state = getCurrentAuthState();
	return state?.jwtRenewMinutesInterval;
}

export function getModels() {
	const state = getCurrentAuthState();
	if (state && state.models) return state.models;
	return [];
}

export function getModelServers() {
	const state = getCurrentAuthState();
	if (state && state.servers) return state.servers;
	return {};
}

export function clearModelIndependentState() {
	localStorage.removeItem(`${host}__userToken`);
}

// Return encoded JWT token from localStorage
export function getJWT() {
	const state = getCurrentAuthState();
	if (window.store) {
		const auth = window.store.getState().authentication;
		if (auth?.database && state?.tokenMap?.[auth.database])
			return state.tokenMap[auth.database];
	}
	if (state) return state.token;
}

// return action object to open the auth modal
export function getOpenAuthModalAction() {
	return { type: OPEN_AUTH_MODAL };
}

export function getClearJWTAction() {
	return { type: CLEAR_JWT };
}

export function checkLoggedInThenCloseModal() {
	// If popup was generated and then closed
	if (window.ssoOpenedPopup && window.ssoOpenedPopup.closed) {
		// Return to previous screen and delete refrence
		store.actions.authActions.setWaitForPopup(false);
		window.removeEventListener('focus', checkLoggedInThenCloseModal, false);
		delete window.ssoOpenedPopup;
		// If we are logged in close the session expired modal and clear error messages
		if (isJWTNotExpired()) {
			store.actions.messageActions.clearMessage();
			store.actions.authActions.closeAuthModal();
		}
	}
}

export function manualCheckLoggedInThenCloseModal() {
	// Most likely popups not enabled, but any case where the new window wasn't properly generated
	if (!window.ssoOpenedPopup) {
		// return to previous screen and print error message
		store.actions.authActions.setWaitForPopup(false);
		window.removeEventListener('focus', checkLoggedInThenCloseModal, false);
		store.actions.messageActions.showMessage(
			LocaleHelper.formatMessage(
				window.store.getState(),
				'ATTEMPT_CONTINUE_WITHOUT_COMPLETING_SSO'
			)
		);
		// If the popup is still open
	} else if (window.ssoOpenedPopup && !window.ssoOpenedPopup.closed) {
		store.actions.messageActions.showMessage(
			LocaleHelper.formatMessage(
				window.store.getState(),
				'ATTEMPT_CONTINUE_WITHOUT_COMPLETING_SSO'
			)
		);
	} else {
		checkLoggedInThenCloseModal();
	}
}

export function updateNextTimeOfTokenRenewal(jwtRenewMinutesInterval) {
	// default to 5 minutes
	const nextRefresh =
		(R.isNil(jwtRenewMinutesInterval) ? 5 : jwtRenewMinutesInterval) * 60;
	localStorage.setItem(
		`${host}__nextTokenRenewal`,
		String(getCurrentTimeInSeconds() + nextRefresh)
	);
}
