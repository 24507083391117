/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const MIN_DATE = '0001-01-01T00:00:00';
export const MAX_DATE = '9999-12-31T23:59:59';
export function GetSystemType(dbType) {
	switch (dbType) {
		case 'String':
		case 'LongString':
		case 'MaxString':
		case 'UID':
		case 'List':
		case 'Picklist':
		case 'Text':
			return 'String';
		case 'Date':
		case 'DateTime':
			return 'DateTime';
		case 'Numeric':
		case 'Decimal':
			return 'Decimal';
		case 'Identity':
		case 'Int':
			return 'Int';
		case 'Float':
			return 'Float';
		default:
			break;
	}
	return null;
}

const DateFormatOption = {
	MM_DD_YY: 'MM_DD_YY',
	MM_DD_YYYY: 'MM_DD_YYYY',
	DD_MM_YY: 'DD_MM_YY',
	DD_MM_YYYY: 'DD_MM_YYYY',
	YY_MM_DD: 'YY_MM_DD',
	YYYY_MM_DD: 'YYYY_MM_DD',
	MMMM_D_YYYY: 'MMMM_D_YYYY',
	D_MMMM_YYYY: 'D_MMMM_YYYY',
	D_MMM_YYYY: 'D_MMM_YYYY',
	MMM_D_YYYY: 'MMM_D_YYYY',
};

export const dateFormats = [
	{
		id: 'PRESENTER_FLEXIBLE_STYLE_DATE_OPTION_FORMAT_MM_DD_YY',
		value: DateFormatOption.MM_DD_YY,
	},
	{
		id: 'PRESENTER_FLEXIBLE_STYLE_DATE_OPTION_FORMAT_MM_DD_YYYY',
		value: DateFormatOption.MM_DD_YYYY,
	},
	{
		id: 'PRESENTER_FLEXIBLE_STYLE_DATE_OPTION_FORMAT_DD_MM_YY',
		value: DateFormatOption.DD_MM_YY,
	},
	{
		id: 'PRESENTER_FLEXIBLE_STYLE_DATE_OPTION_FORMAT_DD_MM_YYYY',
		value: DateFormatOption.DD_MM_YYYY,
	},
	{
		id: 'PRESENTER_FLEXIBLE_STYLE_DATE_OPTION_FORMAT_YY_MM_DD',
		value: DateFormatOption.YY_MM_DD,
	},
	{
		id: 'PRESENTER_FLEXIBLE_STYLE_DATE_OPTION_FORMAT_YYYY_MM_DD',
		value: DateFormatOption.YYYY_MM_DD,
	},
	{
		id: 'PRESENTER_FLEXIBLE_STYLE_DATE_OPTION_FORMAT_MMMM_D_YYYY',
		value: DateFormatOption.MMMM_D_YYYY,
	},
	{
		id: 'PRESENTER_FLEXIBLE_STYLE_DATE_OPTION_FORMAT_D_MMMM_YYYY',
		value: DateFormatOption.D_MMMM_YYYY,
	},
	{
		id: 'PRESENTER_FLEXIBLE_STYLE_DATE_OPTION_FORMAT_D_MMM_YYYY',
		value: DateFormatOption.D_MMM_YYYY,
	},
	{
		id: 'PRESENTER_FLEXIBLE_STYLE_DATE_OPTION_FORMAT_MMM_D_YYYY',
		value: DateFormatOption.MMM_D_YYYY,
	},
];
