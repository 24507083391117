/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { useIsFeatureEnabled } from 'utils/featureUtils';
import FeatureFlags from 'constants/featureFlags';
import React from 'react';

/**
 * When feature exists, children will render.
 * If it doesn't exists, it will fallback to render the `alternative` or just nothing, if
 * no alternative is provided.
 *
 * `invertFeatureFlag` inverts feature functionality
 */
const FeatureFlagCheck: React.FC<{
	feature?: FeatureFlags;
	invertFeatureFlag?: boolean;
	alternative?: React.ReactNode;
}> = (props) => {
	const { children, feature, invertFeatureFlag = false, alternative } = props;
	let isFeatureEnabled = useIsFeatureEnabled(feature);
	isFeatureEnabled = invertFeatureFlag ? !isFeatureEnabled : isFeatureEnabled;

	if (isFeatureEnabled || alternative) {
		return <>{isFeatureEnabled ? children : alternative}</>;
	}
	return null;
};

export default FeatureFlagCheck;
