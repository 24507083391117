/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { AllFilterOperators } from '../../../constants/filterOperators';
import { toFilterString } from '../../../helpers/filter/filterToString';
import FilterOperator from './filterOperator';

export default class RangeFilter extends FilterOperator {
	constructor(left, right, parent) {
		super(AllFilterOperators.Range, left, right, parent);
	}

	toString() {
		return toFilterString(this.type, this.left.toString(), this.right);
	}

	interpretAsType(val) {
		const converted = [];
		for (let i = 0; i < this.right.length; i++) {
			converted.push(super.interpretAsType(val, this.right[i]));
		}
		return converted;
	}

	filter(row, columns) {
		const item = this.getRowValueForColumn(row, columns);
		return (
			!item.found ||
			(item.filterValue[0] <= item.rowValue &&
				item.filterValue[1] >= item.rowValue)
		);
	}

	copy(parent) {
		return new RangeFilter(this.left, this.right.slice(0), parent);
	}
}
