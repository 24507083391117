/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export default {
	listItemPadding: '.5em 1em',
	color: '#666',
	hoverIconColorDefault: '#bbb',
	hoverIconColorOnHover: '#4e98f8',
	xxxSmall: '4px',
	xxSmall: '8px',
	xSmall: '16px',
	small: '20px',
	medium: '24px',
	large: '32px',
	xLarge: '48px',
	Grey10: '#e0e0e0',
	Grey20: '#c7c7c7',
	Grey30: '#aeaeae',
	Grey40: '#959595',
	Grey50: '#777677',
	Grey60: '#5a5a5a',
	Grey70: '#464646',
	Grey80: '#323232',
	Grey90: '#121212',
	Grey100: '#000',
	Gray10: '#e0e0e0',
	Gray20: '#c7c7c7',
	Gray30: '#aeaeae',
	Gray40: '#959595',
	Gray50: '#777677',
	Gray60: '#5a5a5a',
	Gray70: '#464646',
	Gray80: '#323232',
	Gray90: '#121212',
	Gray100: '#000',
	White: '#fff',
	Neutral1: '#fdfdfd',
	Neutral2: '#f9f9f9',
	Neutral3: '#f4f4f4',
	Neutral4: '#ececec',
	Green0: '#f1fbe4',
	'Green2-5': '#f1fbe4',
	Green30: '#8cd211',
	Green40: '#5aa700',
	Green60: '#33a726',
	Blue0: '#f2faff',
	'Blue2-5': '#f2faff',
	Blue5: '#e0f2fe',
	Blue20: '#d6f0ff',
	Blue30: '#5aaafa',
	Blue40: '#5596e6',
	Blue50: '#4178be',
	Blue60: '#325c80',
	Blue70: '#264a60',
	Blue80: '#2277b8',
	Teal5: '#d4fcf2',
	Teal40: '#00b4a0',
	Teal50: '#008571',
	Orange5: '#fee9d1',
	Orange30: '#ff7832',
	Orange50: '#d74108',
	Yellow5: '#fdf3be',
	Yellow5Tinted: '#ffffe0',
	Yellow20: '#fdd600',
	Yellow30: '#efc100',
	Purple5: '#f6e9fe',
	Purple10: '#ffe2ff',
	Purple20: '#f7e9ff',
	Purple40: '#9855d4',
	Purple50: '#d884ce',
	Purple60: '#734098',
	Purple90: '#d884ce',
	Maroon: '#83231e',
	'Red2-5': '#fff4f6',
	Red40: '#ff5050',
	Red50: '#c7c7c7',
	IconDisabled: '#c7c7c7',
	IconNormal: '#959595',
	IconHover: '#4178be',
	IconActive: '#4178be',
	IconStatic: '#5a5a5a',
	ControlHeight: '36px',
	ToolbarMiniControlHeight: '24px',
	ToolbarMiniControlFontSize: '11px',
	HeaderBarHeight: '3rem',
	SidebarMaxWidth: '300px',
	Magenta: '#de2984',
	SuccessGreen: '#82bc3f',
	ErrorRed: '#df673a',
	PresenterNode: '#fbbc3d',
	borderColor: '#e0e0e0',
};
