/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

// Admin actions
export const GET_ADMIN_OPTIONS_COMPLETED = 'GET_ADMIN_OPTIONS_COMPLETED';

// Admin sidebar action
export const EXPAND_ADMIN_SIDEBAR = 'EXPAND_ADMIN_SIDEBAR';
