/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	HIDE_FORGOT_PASSWORD_REQUEST,
	HIDE_FORGOT_PASSWORD_SUBMIT,
	SHOW_FORGOT_PASSWORD_REQUEST,
	SHOW_FORGOT_PASSWORD_SUBMIT,
} from 'constants/actionTypes';
import { createAction } from 'redux-act';
import authService from 'services/authService';

export const showForgotPasswordRequest = createAction(
	SHOW_FORGOT_PASSWORD_REQUEST
);
export const hideForgotPasswordRequest = createAction(
	HIDE_FORGOT_PASSWORD_REQUEST
);
// Default payload? Should be empty
export const showForgotPasswordSubmit = createAction<string>(
	SHOW_FORGOT_PASSWORD_SUBMIT
);
export const hideForgotPasswordSubmit = createAction(
	HIDE_FORGOT_PASSWORD_SUBMIT
);

export const requestPasscode =
	(username: string): ThunkAction<Promise<{ preview: string }>> =>
	() => {
		return authService.requestPasscode(username);
	};

export const submitPasscode =
	(
		username: string,
		password: string,
		newPassword: string
	): ThunkAction<Promise<any>> =>
	() => {
		return authService.submitPasscode(username, password, newPassword);
	};
