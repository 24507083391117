/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import createSentryMiddleware from 'redux-sentry-middleware';
import * as Sentry from '@sentry/browser';

export interface IOpts {
	sentryOptions?: Sentry.BrowserOptions;
	middlewareOptions?: any;
}

export const defaultSentryConfig = {
	beforeBreadcrumb(breadcrumb) {
		// trying to filter out redux logging.
		if (
			breadcrumb.category === 'console' &&
			breadcrumb.level === 'log' &&
			(breadcrumb.message.startsWith('%c prev state') ||
				breadcrumb.message.startsWith('%c next state') ||
				breadcrumb.message.startsWith('%c action'))
		) {
			return null;
		}
		return breadcrumb;
	},
};

export const defaultMiddlewareOptions = {
	breadcrumbDataFromAction: (action) => ({ type: action.type }), // Take only the action type
	actionTransformer: (action) => ({ type: action.type }), // Take only the action type
};
export default function createCustomSentryMiddleware(
	sentryUrl: string,
	options: IOpts
) {
	const { DEPLOY_ENV } =
		(window as any).ICM_CONFIG || (window as any).PAYEE_WEB_CONFIG;
	Sentry.init({
		dsn: sentryUrl,
		environment: DEPLOY_ENV,
		...defaultSentryConfig,
		...options.sentryOptions,
	});
	return createSentryMiddleware(Sentry, {
		...defaultMiddlewareOptions,
		...options.middlewareOptions,
	});
}
