// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 * Optimize the current model
 * url: `api/v1/rpc/optimize`
 */
export function optimize(options?: RequestOptions) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/optimize',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Get current model summary information
 * url: `api/v1/modelsummary`
 */
export function getModelSummary(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'modelsummary',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ModelStatsDTO[]>(request);
}

/**
 * Get current model calc time information
 * url: `api/v1/modelsummary/calcinfo`
 */
export function getModelLastCalcInfo(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'modelsummary/calcinfo',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.LastCalculationInfoDTO>(
		request
	);
}

/**
 * Set the model administrator options
 * url: `api/v1/adminoptions`
 */
export function patchModelOptions(
	configDTOs: Varicent.RESTAPI.v1.DTOs.ConfigValueDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PATCH,
		url: 'adminoptions',
		apiVersion: 'v1' as const,
		data: configDTOs,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ConfigValueDTO[]>(
		request
	);
}

/**
 * Get the model administrator options
 * url: `api/v1/adminoptions`
 */
export function getModelOptions(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'adminoptions',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ConfigValueDTO[]>(
		request
	);
}

/**
 * Gets the model theme
 * url: `api/v1/modeltheme`
 */
export function getModelTheme(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'modeltheme',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ConfigValueDTO>(request);
}

/**
 * Sets the model themes
 * url: `api/v1/modeltheme`
 */
export function putModelTheme(
	modelThemeMap: { [key: string]: Varicent.RESTAPI.v1.DTOs.ConfigValueDTO },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: 'modeltheme',
		apiVersion: 'v1' as const,
		data: modelThemeMap,
		...options,
	};
	return configurableRequest<{
		[key: string]: Varicent.RESTAPI.v1.DTOs.ConfigValueDTO;
	}>(request);
}

/**
 * Gets the model theme
 * url: `api/v1/modelthememap`
 */
export function getModelThemeMap(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'modelthememap',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<{
		[key: string]: Varicent.RESTAPI.v1.DTOs.ConfigValueDTO;
	}>(request);
}

/**
 * Get the current data tier optimization config value
 * url: `api/v1/getdto`
 */
export function getDataTierOptimization(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'getdto',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<boolean>(request);
}

/**
 * Data Tier Optimization enabled.
 * url: `api/v1/enabledto`
 */
export function enableDataTierOptimization(options?: RequestOptions) {
	const request = {
		method: HttpVerb.POST,
		url: 'enabledto',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Data Tier Optimization disabled.
 * url: `api/v1/disabledto`
 */
export function disableDataTierOptimization(options?: RequestOptions) {
	const request = {
		method: HttpVerb.POST,
		url: 'disabledto',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Patch Watson Assistant Config.
 * url: `api/v1/watsonassistantconfig`
 */
export function patchWatsonAssistantConfig(
	query: { dto: Varicent.RESTAPI.v1.DTOs.WatsonAssistantConfigDTO },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PATCH,
		url: 'watsonassistantconfig',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Get Watson Assistant Config.
 * url: `api/v1/watsonassistantconfig`
 */
export function getWatsonAssistantConfig(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'watsonassistantconfig',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Gets the suppress warning messages setting for the current user.
 * url: `api/v1/suppresswarningmessages`
 */
export function getIsSuppressWarningMessages(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'suppresswarningmessages',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<boolean>(request);
}

/**
 * Toggles the suppress warning messages setting for the current user.
 * url: `api/v1/suppresswarningmessages/${messageType}`
 */
export function putSuppressWarningMessages(
	messageType: Varicent.Core.Messaging.SuppressableMessages.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `suppresswarningmessages/${messageType}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<boolean>(request);
}

/**
 * User-specified model name
 * url: `api/v1/model/name`
 */
export function getModelName(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'model/name',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<string>(request);
}

/**
 * Gets the value of a specified key
 * url: `api/v1/config/${key}`
 */
export function getConfigValue(key: string, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `config/${key}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<string>(request);
}

/**
 * Checks whether Message Center is enabled for the model
 * url: `api/v1/payee/messagecenterconfig`
 */
export function checkIfMessageCenterEnable(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/messagecenterconfig',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<string>(request);
}

/**
 * Checks whether Export Block Bad Characters is enabled for the model
 * url: `api/v1/adminoptions/exportblockbadcharactersconfig`
 */
export function checkIfExportBlockBadCharactersEnable(
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'adminoptions/exportblockbadcharactersconfig',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<boolean>(request);
}

/**
 * Documentation of the API
 * url: `api/v1/documentation`
 */
export function getDocumentation(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'documentation',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 * Gets the theme for the current user.
 * url: `api/v1/theme`
 */
export function getTheme(
	query?: { getBackground?: Varicent.Domain.Themes.GetBackground },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'theme',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ThemeDTO>(request);
}

/**
 * Gets the theme for the current user.
 * url: `api/v1/payeemodeltheme`
 */
export function getThemeForPayee(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'payeemodeltheme',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ThemeDTO>(request);
}

/**
 * Delete Specified background image id
 * url: `api/v1/modeltheme/backgroundimage/${id}`
 */
export function deleteBackgroundImage(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: `modeltheme/backgroundimage/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Updates the theme for the current user.
 * url: `api/v1/theme`
 */
export function putTheme(
	theme: Varicent.RESTAPI.v1.DTOs.ThemeDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: 'theme',
		apiVersion: 'v1' as const,
		data: theme,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ThemeDTO>(request);
}

/**
 * Copies all the background images stored in COS to link to another model
 * url: `api/v1/theme/copy`
 */
export function copyBackgroundImages(options?: RequestOptions) {
	const request = {
		method: HttpVerb.POST,
		url: 'theme/copy',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ThemeDTO>(request);
}

/**
 * Gets the id associated with name.
 * url: `api/v1/idlookup/${name}`
 */
export function getIdLookup(
	name: string,
	query?: { type?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `idlookup/${name}`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.IdLookupDTO>(request);
}

/**
 * Clears all salesforce credentials.
 * url: `api/v1/clearsalesforce`
 */
export function clearSalesforceCredentials(options?: RequestOptions) {
	const request = {
		method: HttpVerb.POST,
		url: 'clearsalesforce',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Get all HomePage tabs
 * url: `api/v1/homepagetabs`
 */
export function getHomePageTabs(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'homepagetabs',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.AdminCustomization.HomePageTabDTO[]
	>(request);
}

/**
 * Get all payee ids and names
 * url: `api/v1/model/payees`
 */
export function getPayees(
	query?: { filter?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'model/payees',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PayeeNameDTO[]>(request);
}

/**
 * Set all HomePage tabs
 * url: `api/v1/homepagetabs`
 */
export function setHomePageTabs(
	homePageTabDTOs: Varicent.RESTAPI.v1.DTOs.AdminCustomization.HomePageTabDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'homepagetabs',
		apiVersion: 'v1' as const,
		data: homePageTabDTOs,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.AdminCustomization.HomePageTabDTO[]
	>(request);
}

/**
 * Get All Admin sidebar item customization info for admin
 * url: `api/v1/adminsidebar`
 */
export function getSidebarCustomization(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'adminsidebar',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.AdminCustomization.AdminSidebarItemDTO[]
	>(request);
}

/**
 * Set All Admin Sidebar customization items
 * url: `api/v1/adminsidebar`
 */
export function setSidebarCustomization(
	adminSidebarItemDTOs: Varicent.RESTAPI.v1.DTOs.AdminCustomization.AdminSidebarItemDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'adminsidebar',
		apiVersion: 'v1' as const,
		data: adminSidebarItemDTOs,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.AdminCustomization.AdminSidebarItemDTO[]
	>(request);
}

/**
 * Updates the report for plans.
 * url: `api/v1/selectreport/`
 */
export function setReport(
	query: { reportID: Varicent.ID },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'selectreport/',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<number>(request);
}

/**
 * Get selected report of plans.
 * url: `api/v1/getselectedreport`
 */
export function getSelectedReport(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'getselectedreport',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<number>(request);
}
