/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { createAction } from 'redux-act';

import { MODEL_THEME_RECIEVED } from 'constants/actionTypes';
import {
	getModelTheme,
	putModelTheme,
	getModelThemeMap,
} from 'icm-rest-client/lib/controllers/model';
import { runActionWithSpinner } from 'actions/globalActionActions';
import LocaleHelper from 'helpers/localeHelper';
import { MODEL_THEME_SPINNER } from 'constants/spinnerTypes';
import { Varicent } from 'icm-rest-client';
import * as MessageActions from 'actions/messageActions';

export function getThemeForModel(model: string) {
	return (dispatch, getState) => {
		if (model && !getState().modelThemes[model]) {
			dispatch(
				runActionWithSpinner(
					getModelTheme({ headers: { Model: model } })
						.then((data) => dispatch(modelThemeRecieved({ [model]: data })))
						.catch(() => {
							// selecting default model theme
							dispatch(
								modelThemeRecieved({
									[model]: {
										key: 'ModelTheme',
										value: '',
										version: { rowVersion: 0 },
									},
								})
							);
							dispatch(
								MessageActions.translatedError(
									LocaleHelper.formatMessage(getState(), 'MODEL_THEME_DEFAULT')
								)
							);
						}),
					MODEL_THEME_SPINNER
				)
			);
		}
	};
}

export function updateThemes(modelThemeMap: {
	[key: string]: Varicent.RESTAPI.v1.DTOs.ConfigValueDTO;
}) {
	return (dispatch) => {
		putModelTheme(modelThemeMap).then((data) =>
			dispatch(modelThemeRecieved(data))
		);
	};
}

export function getFullModelThemeMap() {
	return (dispatch) =>
		dispatch(
			runActionWithSpinner(
				getModelThemeMap().then((data) => dispatch(modelThemeRecieved(data))),
				MODEL_THEME_SPINNER
			)
		);
}

export const modelThemeRecieved = createAction<{
	[key: string]: Varicent.RESTAPI.v1.DTOs.ConfigValueDTO;
}>(MODEL_THEME_RECIEVED);
