/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import VReactComponent from 'components/common/reactComponent';
import { LOGIN_LINK } from '../constants/routerPaths';
import { queryStringify } from '../helpers/utility/urlUtilities';

export default class RequiredAuthContainer extends VReactComponent {
	static propTypes = {
		children: PropTypes.node,
	};

	static onEnter(store) {
		return (nextState, replace) => {
			if (nextState.location.pathname === LOGIN_LINK) return;

			const state = store.getState();
			if (!state.authentication.user) {
				let query = { nextPathname: nextState.location.pathname };
				if (nextState.location.query) {
					query = {
						nextPathname: nextState.location.pathname,
						nextQuery: queryStringify(nextState.location.query),
					};
				}
				replace({ pathname: LOGIN_LINK, query });
			}
		};
	}

	render() {
		return this.props.children;
	}
}
