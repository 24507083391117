/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import ReactDOM from 'react-dom';
import styled from 'react-emotion';
import InnerClickListener from 'carbon-components-react/lib/internal/InnerClickListener';
import '../../styles/components/tableFilterBox.scss';
import VBaseComponent from 'components/common/baseComponent';
import VTooltip from 'components/common/tooltipComponent';
import VIcon from 'components/common/icon';
import ApiFilter from '../../models/filter/apiFilter';
import { history } from 'routerHistory';

// Adding this div to get around some madness with IE: https://github.com/philipwalton/flexbugs/issues/216
const IEFixer = styled('div')`
	display: flex;
	flex-direction: row;
`;

export default class TableContextMenuComponent extends VBaseComponent {
	static propTypes = {
		contextMenu: PropTypes.object,
		rowData: PropTypes.object,
		value: PropTypes.string,
		column: PropTypes.object,
		showDescription: PropTypes.bool,
		showContextMenu: PropTypes.bool,
		onToggleDescription: PropTypes.func,
		onToggleContextMenu: PropTypes.func,
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			expandJumps: false,
			selectedJump: -1,
			isToggled: false,
		};
	}

	isOptionSelected(index) {
		return this.state.selectedJump === index;
	}

	onToggleContextMenu(setting) {
		this.props.onToggleContextMenu(setting);
		this.setState((prevState) => ({
			isToggled: !prevState.isToggled,
		}));
	}

	onToggleDescription() {
		this.props.onToggleDescription();
	}

	getJumpUrl(item, rowData, columnIndex) {
		const context = item.context;
		let url = '/rowViewer';

		const tableName = item.context.referencedTable
			? item.context.referencedTable
			: item.tableName;
		const cellValue = rowData.data[columnIndex];
		url += `/${tableName}/${item.context.referencedInputFormId}/${context.referencedColumn}/${cellValue}`;

		const apiFilter = new ApiFilter();
		const colFilter = apiFilter.getColumnFilter(context.referencedColumn);
		colFilter.toggleOption(rowData.data[columnIndex], true);
		colFilter.syncOptionsToFilters();
		colFilter.applyPendingFilter();
		const filterQuery = apiFilter.toString();
		url += `?q=${filterQuery}`;

		return url;
	}

	getLabel(item) {
		if (item.localize) {
			return this.formatMessage(item.label);
		}
		return item.label;
	}

	jump(item, rowData, columnIndex) {
		const url = this.getJumpUrl(item, rowData, columnIndex);
		history.push(url);
	}

	renderContextMenu() {
		const onOpenJumps = () => {
			this.setState({ expandJumps: true });
		};
		const onCloseJumps = () => {
			this.setState({ expandJumps: false });
		};
		const { jumpTos, hasDescription } = this.props.contextMenu;
		const { rowData, column, showContextMenu } = this.props;
		const shortList = jumpTos.sort((a, b) => {
			if (a.label < b.label) return -1;
			if (a.label > b.label) return 1;
			return 0;
		});
		return (
			<div>
				{showContextMenu ? (
					<div className="v-filter-options">
						<span>
							<div className="v-filter-option">
								<span
									className="v-filter-label"
									data-test={`table-context-menu-jumpto-${this.props['data-test']}`}
								>
									{this.formatMessage('TABLE_CONTEXT_MENU_JUMPTO')}
								</span>
								<div className="v-context-icon">
									{shortList.length ? (
										<span>
											{!this.state.expandJumps ? (
												<VIcon
													iconClass="icon-downcheveron"
													size="glyph-medium"
													action={onOpenJumps}
													data-test="jumpto-dropdown"
												/>
											) : null}
											{this.state.expandJumps ? (
												<VIcon
													iconClass="icon-upcheveron"
													size="glyph-medium"
													action={onCloseJumps}
												/>
											) : null}
										</span>
									) : null}
								</div>
							</div>
							{this.state.expandJumps ? (
								<ul>
									{shortList.map((item, index) => (
										<li
											className="v-filter-option"
											onClick={() =>
												this.jump(item, rowData, column.columnIndex)
											}
										>
											<VTooltip
												text={this.translateMessage(
													item.localize
														? this.formatMessage(item.label)
														: item.label
												)}
											>
												<span>
													{this.isOptionSelected(index) ? (
														<i className="icon icon-completed v-filter-option-icon" />
													) : null}
													<span
														className="v-filter-label"
														data-test={`jump-to-${item.label}`}
													>
														{this.translateMessage(
															item.localize
																? this.formatMessage(item.label)
																: item.label
														)}
													</span>
												</span>
											</VTooltip>
										</li>
									))}
								</ul>
							) : null}
						</span>
						{hasDescription ? (
							<ul>
								<li
									className="v-filter-option"
									onClick={() => this.onToggleDescription(true)}
									data-test={`table-context-menu-toggleDescription-${this.props['data-test']}`}
								>
									<span>
										{column.dropDown.showDescription ? (
											<i
												className="icon icon-completed v-filter-option-icon"
												data-test={`showDescription-iconCompleted-${this.props['data-test']}`}
											/>
										) : null}
										<span className="v-filter-label">
											{this.formatMessage('TABLE_CONTEXT_MENU_DESCRIPTION')}
										</span>
									</span>
								</li>
							</ul>
						) : null}
					</div>
				) : null}
			</div>
		);
	}

	componentDidUpdate() {
		if (this.scheduleUpdate) {
			this.scheduleUpdate();
		}
	}

	render() {
		const { column } = this.props;
		const colStyle = { width: column.width };
		let yOffset = 10;

		/*
		 * Default offsets can be used for Chrome, but not for other browsers or else top-positioned popovers
		 * will cover rowviewer values
		 */
		if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
			yOffset = 0;
		}

		return (
			<Manager>
				<Reference>
					{({ ref }) => (
						<span
							style={colStyle}
							className="v-table-header"
							ref={ref}
							onClick={(e) => e.stopPropagation()}
						>
							<span className="v-jump v-jump-icons">
								<VIcon
									iconClass="icon-menuoverflow"
									action={() => this.onToggleContextMenu(true)}
									size="glyph-small"
									data-test={`toggle-contextMenu-${this.props['data-test']}`}
								/>
							</span>
						</span>
					)}
				</Reference>
				{this.state.isToggled &&
					ReactDOM.createPortal(
						<Popper
							modifiers={{
								offset: {
									offset: `0, ${yOffset}`,
								},
							}}
						>
							{({ ref, style, placement, scheduleUpdate }) => {
								this.scheduleUpdate = scheduleUpdate;
								return (
									<InnerClickListener
										refKey="innerRef"
										onClickOutside={() => this.onToggleContextMenu(false)}
									>
										<IEFixer>
											<span
												ref={ref}
												style={{
													...style,
													zIndex: 9999,
												}}
												data-placement={placement}
												className="v-filter-box"
												onClick={(e) => e.stopPropagation()}
											>
												{this.renderContextMenu()}
											</span>
										</IEFixer>
									</InnerClickListener>
								);
							}}
						</Popper>,
						document.querySelector('body')
					)}
			</Manager>
		);
	}
}
