// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
	configurableRequestRaw,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 * Get all Web Forms, may be filtered by name prefix
 * url: `api/v1/webforms`
 */
export function getAll(query?: { filter?: string }, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'webforms',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.WebFormNameDTO[]>(
		request
	);
}

/**
 * Get Web Form by id
 * url: `api/v1/webforms/${id}`
 */
export function get(
	id: Varicent.ID,
	query?: { expand?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `webforms/${id}`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.WebFormDefinitionDTO>(
		request
	);
}

/**
 * dependencies for the given web form
 * url: `api/v1/webforms/${id}/dependents`
 */
export function getDependencies(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `webforms/${id}/dependents`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.DependencyInfoDTO>(
		request
	);
}

/**
 * Get web form filters
 * url: `api/v1/webformsfilters`
 */
export function getAllFilters(
	query?: {
		offset?: number;
		limit?: number;
		filter?: string;
		orderBy?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'webformsfilters',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<Varicent.RESTAPI.v1.DTOs.WebFormFilterDTO[]>(
		request
	);
}

/**
 * Get web form filters for rowviewer
 * url: `api/v1/webformsfilters/rowviewer`
 */
export function getAllFiltersRowViewer(
	query?: {
		offset?: number;
		limit?: number;
		filter?: string;
		orderBy?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'webformsfilters/rowviewer',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<Varicent.RESTAPI.v1.DTOs.ResultSetDTO[]>(
		request
	);
}

/**
 * Get filters for a specific webform
 * url: `api/v1/webforms/${id}/filters`
 */
export function getWebFormFilters(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `webforms/${id}/filters`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.WebFormFilterDTO[]>(
		request
	);
}

/**
 * Create a new web form filter
 * url: `api/v1/webformsfilters`
 */
export function postFilter(
	filterDTO: Varicent.RESTAPI.v1.DTOs.WebFormFilterDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'webformsfilters',
		apiVersion: 'v1' as const,
		data: filterDTO,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.WebFormFilterDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/webformsfilters/${id}`
 */
export function deleteFilter(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `webformsfilters/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Update Web Form Filter
 * url: `api/v1/webformsfilters/${id}`
 */
export function putFilter(
	id: Varicent.ID,
	webFormFilterDTO: Varicent.RESTAPI.v1.DTOs.WebFormFilterDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `webformsfilters/${id}`,
		apiVersion: 'v1' as const,
		data: webFormFilterDTO,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.WebFormFilterDTO>(
		request
	);
}

/**
 * Create a new Web Form
 * url: `api/v1/webforms`
 */
export function post(
	webFormDTO: Varicent.RESTAPI.v1.DTOs.Composer.ComposerWebFormElementDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'webforms',
		apiVersion: 'v1' as const,
		data: webFormDTO,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Composer.ComposerWebFormElementDTO>(
		request
	);
}

/**
 * Delete Web Form by ID
 * url: `api/v1/webforms/${id}`
 */
export function doDelete(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `webforms/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<number>(request);
}

/**
 * Update Web Form
 * url: `api/v1/webforms/${id}`
 */
export function put(
	id: Varicent.ID,
	webFormDTO: Varicent.RESTAPI.v1.DTOs.WebFormDefinitionDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `webforms/${id}`,
		apiVersion: 'v1' as const,
		data: webFormDTO,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.WebFormDefinitionDTO>(
		request
	);
}

/**
 * Get web form filter sources
 * url: `api/v1/webformsfilters/sources`
 */
export function getAllFilterSources(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'webformsfilters/sources',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<{
		[key: string]: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO;
	}>(request);
}

/**
 * Get web form filter values
 * url: `api/v1/webformsfilters/${id}/values`
 */
export function getFilterValues(
	id: Varicent.ID,
	query: { portalAccessGroupId: Varicent.ID },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `webformsfilters/${id}/values`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<{ [key: string]: string }>(request);
}

/**
 * Get web form filter sources
 * url: `api/v1/webformsconfig`
 */
export function getConfig(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'webformsconfig',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ConfigValueDTO>(request);
}

/**
 *
 * url: `api/v1/webforms/${id}/preview`
 */
export function getPreview(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `webforms/${id}/preview`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/webform/deleteJWTCookie`
 */
export function deleteJWTCookie(
	query: { cookiePath: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: 'payee/webform/deleteJWTCookie',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<void>(request);
}
