/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import { Modal } from 'react-bootstrap';
import VBaseComponent from 'components/common/baseComponent';
import VSpinner from 'components/common/spinnerComponent';
import '../../styles/components/spinnerModal.scss';
import { connect } from 'react-redux';
import { css } from 'react-emotion';

@connect((state) => ({
	globalAction: state.globalAction,
}))
export default class VSpinnerModal extends VBaseComponent {
	static propTypes = {
		globalAction: PropTypes.object,
	};

	onHide() {}

	getKey(spinners) {
		let sum = 0;
		const spinnerNames = Object.keys(spinners);
		for (let i = 0; i < spinnerNames.length; i++) {
			sum += spinners[spinnerNames[i]];
		}

		return sum;
	}

	render() {
		const { showSpinner, spinners } = this.props.globalAction;
		return (
			<Modal
				aria-label={this.formatMessage('LOADING')}
				show={showSpinner}
				animation={false}
				backdrop // setting to false doesn't remove the backdrop for some reason
				backdropClassName="v-visibly-hidden" // need to hide due to incorrect z-index
				dialogClassName="v-spinner-modal"
				onHide={this.onHide}
				key={this.getKey(spinners)}
				className={css`
					z-index: 2100000000; // super high z-index value to ensure above everything including sidebarContainer Nav (max is 2147483647)
					// need to emulate component backdrop here to ensure it has the correct z-index
					background-color: rgba(70, 70, 70, 0.4); // color match backdrop
				`}
			>
				<VSpinner
					size={90}
					thickness={10}
					color="#5596e6"
					backgroundColor="rgb(182,182,182)"
				/>
			</Modal>
		);
	}
}
