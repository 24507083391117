/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	UPDATE_ACTIVITY_DATE,
	GET_ALL_ACTIVITIES_COMPLETED,
	REQUEST_ALL_ACTIVITIES_LIVE,
	RECEIVE_ALL_ACTIVITIES_LIVE,
	RESET_ACTIVITY_FILTERS,
	TYPE_FILTER_CHANGED,
	FORCED_TYPE_FILTER_CHANGED,
	STATUS_FILTER_CHANGED,
	SHOW_ALL_SELECTED,
	SHOW_ERROR_MESSAGE,
	SHOW_MORE_LIVE,
	SHOW_LESS_LIVE,
	UPDATE_COMPLETED,
	FINISHED_SCROLLING,
	GO_TO_NEW_COMPLETED,
	SHOW_DELETE_CONFIRMATION,
	SHOW_DELETE_LIVE_CONFIRMATION,
	DELETE_LIVE_ACTIVITY,
	CANCEL_DELETE_CONFIRMATION,
	DELETE_ACTIVITY,
	ACTIVITY_TOGGLE_DROPDOWN,
	ACTIVITY_FOCUS_CHANGE,
	LOGOUT,
	CHANGE_MODEL,
	ROUTER_NAVIGATE,
} from '../constants/actionTypes';

import { ALL_PROGRESS_TYPES } from '../constants/activity/progressTypes';
import {
	ALL_COMPLETED_STATUS_TYPES,
	FAILED,
} from '../constants/activity/activityTexts';
import moment from 'moment';

const activityItem = (item) => ({
	progressId: item.progressId,
	date: moment.utc(item.time),
	status: item.status,
	type: item.type,
	message: item.message,
	messageDetails: item.messageDetails,
});

const liveActivityItem = (item) => ({
	progressId: item.progressId,
	date: moment.utc(item.time),
	userId: item.userId,
	status: item.status,
	type: item.type,
	percent: item.percent,
	description: item.description,
	isCancellable: item.isCancellable,
	isInitialization: item.isInitialization,
	computationId: item.computationId,
});

function initialize() {
	return {
		selectedDate: undefined,
		completed: [],
		liveRequest: {
			live: [],
			requesting: false,
		},
		selectedTypes: ALL_PROGRESS_TYPES,
		selectedStatuses: ALL_COMPLETED_STATUS_TYPES,
		activitiesShowError: [],
		completedDateEnd: moment.utc(),
		completedDateStart: moment.utc(),
		newCompletedCount: 0,
		failedCount: 0,
		moreLiveActivitiesCount: 0,
		showMoreLiveSelected: false,
		scrollToDate: false,
		scrollToNewCompleted: false,
		deleteLiveActivityId: undefined,
		completedToDelete: undefined,
		filterDropDownOpen: false,
		nextActivitiesLink: undefined,
		deleteButtonFocus: true,
	};
}

const initialState = initialize();

const handlers = {};

// the reducer
export function activity(state = initialState, action) {
	const { type } = action;
	if (!handlers[type]) {
		return state;
	}
	return handlers[type](state, action);
}

// action handlers

handlers[UPDATE_ACTIVITY_DATE] = (state, { payload }) => ({
	...state,
	selectedDate: payload.date,
	activitiesShowError: [],
	completedToDelete: undefined,
	scrollToDate: true,
});

handlers[FINISHED_SCROLLING] = (state) => ({
	...state,
	scrollToDate: false,
	scrollToNewCompleted: false,
});

handlers[GO_TO_NEW_COMPLETED] = (state) => ({
	...state,
	activitiesShowError: [],
	completedToDelete: undefined,
	scrollToNewCompleted: true,
});

handlers[SHOW_DELETE_CONFIRMATION] = (state, { payload }) => ({
	...state,
	completedToDelete: payload.progressId,
	deleteButtonFocus: true,
});

handlers[CANCEL_DELETE_CONFIRMATION] = (state) => ({
	...state,
	completedToDelete: undefined,
});

handlers[DELETE_ACTIVITY] = (state, { payload }) => {
	const newCompletedAfterDelete = [];
	let failedCount = state.failedCount;
	for (const item of state.completed) {
		if (item.progressId !== payload.progressId) {
			newCompletedAfterDelete.push(item);
		} else if (item.status === FAILED) {
			failedCount--;
		}
	}
	return {
		...state,
		completedToDelete: undefined,
		failedCount,
		completed: newCompletedAfterDelete,
	};
};

handlers[GET_ALL_ACTIVITIES_COMPLETED] = (state, { payload }) => {
	let completed;
	let failedCount = state.failedCount;
	if (payload.reset) {
		completed = [];
		failedCount = 0;
	} else {
		completed = [...state.completed];
	}

	if (payload.activities.length !== 0) {
		for (const item of payload.activities) {
			completed.push(new activityItem(item));
			if (item.status === FAILED) {
				failedCount++;
			}
		}
	}

	let url;
	if (payload.link) {
		const endIndex = payload.link.indexOf('> ; rel="next"');
		if (endIndex > 0) {
			const linkPrefix = payload.link.substring(0, endIndex);

			url = linkPrefix.substring(linkPrefix.lastIndexOf('<') + 1);
		}
	}

	return {
		...state,
		completed,
		failedCount,
		nextActivitiesLink: url,
		completedDateStart:
			completed.length > 0
				? completed[0].date
				: moment.utc(state.completedDateStart),
		completedDateEnd:
			completed.length > 0
				? completed[completed.length - 1].date
				: moment.utc(state.completedDateEnd),
	};
};

handlers[REQUEST_ALL_ACTIVITIES_LIVE] = (state) => ({
	...state,
	liveRequest: {
		...state.liveRequest,
		requesting: true,
	},
});

handlers[RECEIVE_ALL_ACTIVITIES_LIVE] = (state, { payload }) => {
	const newLive = payload.activities.map((item) => new liveActivityItem(item));

	let moreLiveCount = 0;
	// had more count so we still want to hide some if there are more than 5
	if (!state.showMoreLiveSelected && newLive.length > 5) {
		moreLiveCount = newLive.length - 5;
	}

	return {
		...state,
		liveRequest: {
			live: newLive,
			requesting: false,
		},
		moreLiveActivitiesCount: moreLiveCount,
	};
};

handlers[SHOW_MORE_LIVE] = (state) => ({
	...state,
	showMoreLiveSelected: true,
	moreLiveActivitiesCount: 0,
});

handlers[SHOW_LESS_LIVE] = (state) => {
	let moreLiveCount = 0;
	// had more count so we still want to hide some if there are more than 5
	if (state.liveRequest.live.length > 5) {
		moreLiveCount = state.liveRequest.live.length - 5;
	}

	return {
		...state,
		showMoreLiveSelected: false,
		moreLiveActivitiesCount: moreLiveCount,
	};
};

handlers[UPDATE_COMPLETED] = (state, { payload }) => {
	const newlyCompleted = [];
	let failedCount = state.failedCount;
	for (const item of payload.activities) {
		newlyCompleted.push(new activityItem(item));
		if (item.status === FAILED) {
			failedCount++;
		}
	}

	const allCompleted = newlyCompleted.concat([...state.completed]);
	return {
		...state,
		completed: allCompleted,
		failedCount,
		completedDateStart:
			newlyCompleted.length > 0
				? newlyCompleted[0].date
				: moment.utc(state.completedDateStart),
		newCompletedCount: state.newCompletedCount + payload.activities.length,
	};
};

handlers[RESET_ACTIVITY_FILTERS] = (state) => ({
	...state,
	selectedTypes: ALL_PROGRESS_TYPES,
	selectedStatuses: ALL_COMPLETED_STATUS_TYPES,
});

handlers[TYPE_FILTER_CHANGED] = (state, { payload }) => {
	const selectedTypes = [...state.selectedTypes];
	const index = selectedTypes.indexOf(payload.value);
	if (index < 0) {
		selectedTypes.push(payload.value);
	} else {
		selectedTypes.splice(index, 1);
	}

	return {
		...state,
		selectedTypes,
		filterDropDownOpen: true,
	};
};

handlers[FORCED_TYPE_FILTER_CHANGED] = (state, { payload }) => {
	return {
		...state,
		selectedTypes: payload.selectedTypes,
	};
};

handlers[STATUS_FILTER_CHANGED] = (state, { payload }) => ({
	...state,
	selectedStatuses: payload.selectedStatuses,
});

handlers[SHOW_ALL_SELECTED] = (state) => {
	const selectedTypes = [...state.selectedTypes];
	const selected = [];
	if (selectedTypes.length !== ALL_PROGRESS_TYPES.length) {
		for (const item of ALL_PROGRESS_TYPES) {
			selected.push(item);
		}
	}

	return {
		...state,
		selectedTypes: selected,
		filterDropDownOpen: true,
	};
};

handlers[ACTIVITY_TOGGLE_DROPDOWN] = (state) => ({
	...state,
	filterDropDownOpen: !state.filterDropDownOpen,
});

handlers[SHOW_ERROR_MESSAGE] = (state, { payload }) => {
	const expandedEvents = [...state.activitiesShowError];
	const idIndex = expandedEvents.indexOf(payload.progressId);
	if (idIndex < 0) {
		expandedEvents.push(payload.progressId);
	} else {
		expandedEvents.splice(idIndex, 1);
	}
	return {
		...state,
		activitiesShowError: expandedEvents,
	};
};

handlers[SHOW_DELETE_LIVE_CONFIRMATION] = (state, { payload }) => ({
	...state,
	deleteLiveActivityId: payload.progressId,
});

handlers[DELETE_LIVE_ACTIVITY] = (state) => ({
	...state,
	deleteLiveActivityId: undefined,
});

handlers[ACTIVITY_FOCUS_CHANGE] = (state, { payload }) => {
	const focus = payload.key === 37;
	return {
		...state,
		deleteButtonFocus: focus,
	};
};

// Cleanup
handlers[LOGOUT] =
	handlers[ROUTER_NAVIGATE] =
	handlers[CHANGE_MODEL] =
		() => initialize();
