// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';
import getUrlSafeDateString from '../utils/getUrlSafeDateString';

/* This assumes one class per controller file */

/**
 * Get all Calendars
 * url: `api/v1/calendars`
 */
export function getAll(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'calendars',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO[]>(
		request
	);
}

/**
 * Get the basic info for all calendars.
 * url: `api/v1/calendarscompact`
 */
export function getAllCompact(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'calendarscompact',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.Time.CompactCalendarDTO[]
	>(request);
}

/**
 * Get all Periods for a calendar and level
 * url: `api/v1/calendars/${timeId}/levelperiods/${levelId}`
 */
export function getLevelPeriods(
	timeId: Varicent.ID,
	levelId: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `calendars/${timeId}/levelperiods/${levelId}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.PeriodDTO[]>(
		request
	);
}

/**
 * Get all Periods for a calendar and level
 * url: `api/v1/payee/calendars/${timeId}/levelperiods/${levelId}`
 */
export function getLevelPeriodsForPayee(
	timeId: Varicent.ID,
	levelId: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/calendars/${timeId}/levelperiods/${levelId}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.PeriodDTO[]>(
		request
	);
}

/**
 * Get all Periods for a calendar
 * url: `api/v1/payee/calendars/${timeId}/levelperiods`
 */
export function getAllLevelPeriodsForPayee(
	timeId: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/calendars/${timeId}/levelperiods`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.PeriodDTO[]>(
		request
	);
}

/**
 * Delete calendar by ID
 * url: `api/v1/calendars/${id}`
 */
export function doDelete(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `calendars/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Add calendar by Calendar
 * url: `api/v1/calendars`
 */
export function add(
	calendar: Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'calendars',
		apiVersion: 'v1' as const,
		data: calendar,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO>(
		request
	);
}

/**
 * Get calendar by template
 * url: `api/v1/calendartemplate/${template}/periods/${getUrlSafeDateString(startDate)}`
 */
export function getYear(
	template: Varicent.Domain.Time.Calendar.Template,
	startDate: Date,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `calendartemplate/${template}/periods/${getUrlSafeDateString(
			startDate
		)}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.PeriodDTO>(request);
}

/**
 * Get calendar by template with specified yearname
 * url: `api/v1/calendartemplate/${template}/periods/${getUrlSafeDateString(startDate)}/${yearName}`
 */
export function getYearWithYearName(
	template: Varicent.Domain.Time.Calendar.Template,
	startDate: Date,
	yearName: String,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `calendartemplate/${template}/periods/${getUrlSafeDateString(
			startDate
		)}/${yearName}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.PeriodDTO>(request);
}

/**
 * Get template by levels
 * url: `api/v1/calendartemplate`
 */
export function getTemplates(
	query?: { filter?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'calendartemplate',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.CalendarTemplateDTO>(
		request
	);
}

/**
 * Update calendar by Calendar and ID
 * url: `api/v1/rpc/calendars/${id}/affectedCalcs`
 */
export function numberOfCalcsAffectedByCalendarChange(
	id: Varicent.ID,
	calendar: Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `rpc/calendars/${id}/affectedCalcs`,
		apiVersion: 'v1' as const,
		data: calendar,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO[]>(
		request
	);
}

/**
 * Update calendar by Calendar and ID
 * url: `api/v1/calendars/${id}`
 */
export function putSync(
	id: Varicent.ID,
	calendar: Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `calendars/${id}`,
		apiVersion: 'v1' as const,
		data: calendar,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO[]>(
		request
	);
}

/**
 * Update calendar by Calendar and ID
 * url: `api/v1/calendars/${id}/async`
 */
export function putAsync(
	id: Varicent.ID,
	calendar: Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `calendars/${id}/async`,
		apiVersion: 'v1' as const,
		data: calendar,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO[]>(
		request
	);
}

/**
 * Lock and unlock calendar by period and ID
 * url: `api/v1/rpc/calendars/${id}/calculateandlock`
 */
export function calcAndLock(
	id: Varicent.ID,
	calendar: Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `rpc/calendars/${id}/calculateandlock`,
		apiVersion: 'v1' as const,
		data: calendar,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO[]>(
		request
	);
}

/**
 * Start process to lock and unlock calendar by period and ID
 * url: `api/v1/rpc/calendars/${id}/lock`
 */
export function lock(
	id: Varicent.ID,
	calendar: Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `rpc/calendars/${id}/lock`,
		apiVersion: 'v1' as const,
		data: calendar,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Time.CalendarDTO[]>(
		request
	);
}

/**
 * Get affected calculations
 * url: `api/v1/calendars/${id}/calculations`
 */
export function getAffectedCalculations(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `calendars/${id}/calculations`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<String[]>(request);
}

/**
 * Get tables using calendar
 * url: `api/v1/calendars/${id}/dependenttables`
 */
export function getCalendarTables(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `calendars/${id}/dependenttables`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<String[]>(request);
}

/**
 * Get calcs using calendar
 * url: `api/v1/calendars/${id}/dependentcalculations`
 */
export function getCalendarCalculations(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `calendars/${id}/dependentcalculations`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.Composer.CalculationCompactDTO[]
	>(request);
}
