/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const ADMIN_ROLE = 'Administrator';

export const ACTIVITY_CANCELALL = 'Activity/Cancel All';
export const ACTIVITY_VIEWALL = 'Activity/View All';

export const AUDITLOG_VIEW = 'Audit Log/View';
export const AUDITLOG_EDIT = 'Audit Log/Edit';

export const WORKFLOWMANAGER_VIEW = 'Workflow Manager/View';
export const WORKFLOWMANAGER_EDIT = 'Workflow Manager/Edit';

export const CALCULATE_VIEW = 'Calculate/View';
export const CALCULATE_RUN = 'Calculate/Run';
export const CALCULATE_INDIVIDUALCALC = 'Calculate/Individual Calculation';

export const COMPOSER_VIEW = 'Composer/View';
export const COMPOSER_EDIT = 'Composer/Edit';
export const COMPOSER_TOPCOMPONENTEDIT = 'Composer/TopComponent/Edit';
export const COMPOSER_INPUTFORMSADD = 'Composer/Input Forms/Add';
export const COMPOSER_INPUTFORMSEDIT = 'Composer/Input Forms/Edit';
export const COMPOSER_INPUTFORMSDELETE = 'Composer/Input Forms/Delete';
export const COMPOSER_VIEWS = 'Composer/Add Views';
export const COMPOSER_WEBDATAEDIT = 'Composer/Web Data Edit';

export const DATASTORE_ADD = 'Data Store/Add';
export const DATASTORE_VIEW = 'Data Store/{0}/View';
export const DATASTORE_EDIT = 'Data Store/{0}/Edit';
export const DATASTORE_DELETE = 'Data Store/{0}/Delete';

export const HEALTH_DASHBOARD_VIEW = 'Health Dashboard/View';
export const HEALTH_DASHBOARD_RUN = 'Health Dashboard/Run';
export const HEALTH_DASHBOARD_DELETE = 'Health Dashboard/Delete';

export const HOMEPAGE_ADMINOPTIONSVIEW = 'Home Page/Admin Options/View';
export const HOMEPAGE_ADMINOPTIONSEDIT = 'Home Page/Admin Options/Edit';

export const HOMEPAGE_MANAGEDOCUMENTSVIEW = 'Home Page/Manage Documents/View';
export const HOMEPAGE_MANAGEDOCUMENTSEDIT = 'Home Page/Manage Documents/Edit';
export const HOMEPAGE_MANAGEDOCUMENTSDELETE =
	'Home Page/Manage Documents/Delete';

export const HOMEPAGE_MANAGESAVEDIMPORTSVIEW =
	'Home Page/Manage Saved Imports/View';
export const HOMEPAGE_MANAGESAVEDIMPORTSEDIT =
	'Home Page/Manage Saved Imports/Edit';
export const HOMEPAGE_MANAGESAVEDIMPORTSDELETE =
	'Home Page/Manage Saved Imports/Delete';

export const HOMEPAGE_MANAGESAVEDPUBLICATIONSVIEW =
	'Home Page/Manage Saved Publications/View';
export const HOMEPAGE_MANAGESAVEDPUBLICATIONSEDIT =
	'Home Page/Manage Saved Publications/Edit';
export const HOMEPAGE_MANAGESAVEDPUBLICATIONSDELETE =
	'Home Page/Manage Saved Publications/Delete';

export const HOMEPAGE_MANAGESAVEDTRANSFORMATIONSVIEW =
	'Home Page/Manage Saved Transformations/View';
export const HOMEPAGE_MANAGESAVEDTRANSFORMATIONSEDIT =
	'Home Page/Manage Saved Transformations/Edit';
export const HOMEPAGE_MANAGESAVEDTRANSFORMATIONSDELETE =
	'Home Page/Manage Saved Transformations/Delete';

export const HOMEPAGE_MANAGEHISTORYTRACKINGVIEW =
	'Home Page/Manage History Tracking/View';
export const HOMEPAGE_MANAGEHISTORYTRACKINGEDIT =
	'Home Page/Manage History Tracking/Edit';

export const HOMEPAGE_MIGRATIONSEDIT = 'Home Page/Migrations/Edit';
export const HOMEPAGE_MIGRATIONSEXECUTE = 'Home Page/Migrations/Execute';

export const HOMEPAGE_OPTIMIZEMODEL = 'Home Page/Optimize Model';
export const HOMEPAGE_MANAGETHEME = 'Home Page/Manage Theme';

export const HOMEPAGE_CALENDARSVIEW = 'Home Page/Calendars/View';
export const HOMEPAGE_CALENDARSEDIT = 'Home Page/Calendars/Edit';
export const HOMEPAGE_CALENDARSLOCKPERIOD =
	'Home Page/Calendars/Period Locking';
export const HOMEPAGE_CALENDARSPURGEPERIOD =
	'Home Page/Calendars/Period Purging';

export const HOMEPAGE_PAYEEGROUPSVIEW = 'Home Page/Payee Groups/View';
export const HOMEPAGE_PAYEEGROUPSEDIT = 'Home Page/Payee Groups/Edit';

export const HOMEPAGE_QUERYTOOLDELETE = 'QueryTool/Delete';
export const HOMEPAGE_QUERYTOOLEDIT = 'QueryTool/Edit';
export const HOMEPAGE_QUERYTOOLVIEW = 'QueryTool/View';

export const HOMEPAGE_STARSCHEMAVIEW = 'Home Page/Star Schema/View';
export const HOMEPAGE_STARSCHEMAEDIT = 'Home Page/Star Schema/Edit';

export const HOMEPAGE_PROCESSMAP = 'Home Page/Process List';
export const HOMEPAGE_PROCESSMAPADD = 'Home Page/Process List/Add';
export const HOMEPAGE_PROCESSMAPEDIT = 'Home Page/Process List/{0}/Edit';
export const HOMEPAGE_PROCESSMAPDELETE = 'Home Page/Process List/{0}/Delete';
export const HOMEPAGE_PROCESSMAPVIEW = 'Home Page/Process List/{0}/View';

export const HOMEPAGE_MANAGEDATAGRIDTEMPLATESVIEW =
	'Home Page/Manage Data Grid Templates/View';
export const HOMEPAGE_MANAGEDATAGRIDTEMPLATESADD =
	'Home Page/Manage Data Grid Templates/Add';
export const HOMEPAGE_MANAGEDATAGRIDTEMPLATESEDIT =
	'Home Page/Manage Data Grid Templates/Edit';
export const HOMEPAGE_MANAGEDATAGRIDTEMPLATESDELETE =
	'Home Page/Manage Data Grid Templates/Delete';

export const HOMEPAGE_HIERARCHYVIEW = 'Home Page/Hierarchies/View';
export const HOMEPAGE_HIERARCHYEDIT = 'Home Page/Hierarchies/Edit';
export const HOMEPAGE_HIERARCHYDELETE = 'Home Page/Hierarchies/Delete';

export const HOMEPAGE_MODELSUMMARY = 'Home Page/Model Summary';

export const HOMEPAGE_IMPERSONATEPAYEE = 'Home Page/Impersonate Payee';

export const IMPORT_DATA = 'Import/Data';
export const IMPORT_ADVANCED = 'Import/Advanced';
export const IMPORT_FILE_VIEW = 'Import/Files/View';
export const IMPORT_FILE_EDIT = 'Import/Files/Edit';

export const PULSE_VIEW = 'Pulse/View';
export const PULSE_EDIT = 'Pulse/Edit';

export const DATA_PORTS_VIEW = 'Data Ports/View';
export const DATA_PORTS_EDIT = 'Data Ports/Edit';

export const SCHEDULER_VIEW = 'Scheduler/View';
export const SCHEDULER_EDIT = 'Scheduler/Edit';

export const SCENARIOS_VIEW = 'Scenarios/View';
export const SCENARIOS_PROMOTE = 'Scenarios/Promote';

export const TASKS_VIEW = 'Tasks/View';
export const TASKS_EDIT = 'Tasks/Edit';

export const WEBFORMS_VIEW = 'Web Forms/View';

export const PRESENTER_VIEW = 'Presenter/View';
export const PRESENTER_AUDITLOGVIEW = 'Presenter/Audit Log/View';

export const PORTALACCESS_VIEW = 'Portal Access/View';
export const PORTALACCESS_EDIT = 'Portal Access/Edit';

export const TABLESTRUCTURE_VIEW = 'Composer/Table/{0}/Structure/View';
export const TABLESTRUCTURE_DROP = 'Composer/Table/{0}/Structure/Drop';
export const TABLESTRUCTURE_EDIT = 'Composer/Table/{0}/Structure/Edit';
export const TABLESTRUCTURE_HISTORY = 'Composer/Table/{0}/Structure/History';
export const TABLESTRUCTURE_IMPORT = 'Composer/Table/{0}/Structure/Import';

export const ASSIGNTABLE_DROP = 'Composer/Table/{0}/Drop';

export const TABLEINPUTFORM_VIEW = 'Composer/Table/{0}/Input Forms/{1}/View';
export const TABLEINPUTFORM_DELETE =
	'Composer/Table/{0}/Input Forms/{1}/Delete';
export const TABLEINPUTFORM_BULK_DELETE =
	'Composer/Table/{0}/Input Forms/{1}/Bulk Delete';

export const RAPID_REPORT_VIEW = 'Rapid Reports/View';
export const RAPID_REPORT_EDIT = 'Rapid Reports/Edit';
export const RAPID_REPORT_DELETE = 'Rapid Reports/Delete';

export const DATA_DISCOVERY_DASHBOARD_VIEW = 'Data Discovery/View';
export const DATA_DISCOVERY_DASHBOARD_EDIT = 'Data Discovery/Edit';
export const DATA_DISCOVERY_DASHBOARD_DELETE = 'Data Discovery/Delete';

export const COMPOSER_COMPONENT_VIEW = 'Composer/Component/{0}/View';
export const COMPOSER_COMPONENT_EDIT = 'Composer/Component/{0}/Edit';

export const PRESENTER_FLEX_VIEW = 'Presenter Adaptive/View';
export const PRESENTER_FLEX_EDIT = 'Presenter Adaptive/Edit';
export const PRESENTER_FLEX_DELETE = 'Presenter Adaptive/Delete';

export const INQUIRY_MANAGEMENT_VIEW = 'Inquiry/View';

export const ADAPTIVE_FORM_VIEW = 'Adaptive Forms/View';
export const ADAPTIVE_FORM_EDIT = 'Adaptive Forms/Edit';
export const ADAPTIVE_FORM_DELETE = 'Adaptive Forms/Delete';
export const ADAPTIVE_FORM_SUBMIT_DATA = 'Adaptive Forms/Submit Data';
