/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import ColumnDefinition from './columnDefinition';
import { FormControl } from 'react-bootstrap';
import { Manager, Popper } from 'react-popper';
import '../../styles/models/column/editColumnDefinition.scss';

export default class EditableColumnDefinition extends ColumnDefinition {
	constructor(...props) {
		super(...props);
	}

	update(...props) {
		return new EditableColumnDefinition(this, ...props);
	}

	getMaxInputLength() {
		return this.maxLength;
	}

	finishEdit = (columnData) => {
		columnData.onCellEscape();
	};

	saveAndFinish = (cellValue, rowIndex, cellDataKey, columnData, event) => {
		this.onCellChange(cellValue, rowIndex, cellDataKey, columnData, event);
		this.finishEdit(columnData);
	};

	getEditingCellContent({ value, cellDataKey, rowIndex, columnData }) {
		return (
			<Manager>
				{rowIndex === columnData.editingRow &&
					ReactDOM.createPortal(
						<Popper
							referenceElement={this.getOverlayTarget(rowIndex, cellDataKey)}
							placement="bottom"
						>
							{({ ref, style, placement, scheduleUpdate }) => {
								this.scheduleUpdate = scheduleUpdate;
								return (
									<span
										className="v-editable-column-definition"
										style={{ ...style, width: this.width }}
										ref={ref}
										data-placement={placement}
									>
										<FormControl
											id={`${cellDataKey}${rowIndex}`}
											type="text"
											defaultValue={value}
											autoFocus
											onKeyDown={(e) =>
												this.onKeyDown(
													e,
													e.target.value,
													true,
													rowIndex,
													cellDataKey,
													columnData
												)
											}
											maxLength={
												this.getMaxInputLength()
													? this.getMaxInputLength()
													: 100
											}
											onBlur={(e) =>
												this.saveAndFinish(
													e.target.value,
													rowIndex,
													cellDataKey,
													columnData,
													{ event: 'finishingEdit' }
												)
											}
										/>
									</span>
								);
							}}
						</Popper>,
						document.body
					)}
			</Manager>
		);
	}
	getOverlayTarget(rowIndex, cellDataKey) {
		const elems = document.getElementsByClassName(
			`v-fdt-cell-wrap-${rowIndex}`
		);
		return ReactDOM.findDOMNode(
			elems[cellDataKey] ? elems[cellDataKey] : elems[this.columnIndex]
		);
	}

	getNonEditingCellContent({
		value,
		cellData,
		cellDataKey,
		rowData,
		rowIndex,
		columnData,
		width,
	}) {
		return super.getCellContent(
			value,
			cellData,
			cellDataKey,
			rowData,
			rowIndex,
			columnData,
			width
		);
	}

	getCellContent(
		value,
		cellData,
		cellDataKey,
		rowData,
		rowIndex,
		columnData,
		width
	) {
		const data = {
			value,
			cellData,
			cellDataKey,
			rowData,
			rowIndex,
			columnData,
			width,
		};

		return (
			<div>
				{this.getNonEditingCellContent(data)}
				{this.getEditingCellContent(data)}
			</div>
		);
	}

	getOnClick(cellData, cellDataKey, rowData, rowIndex, columnData) {
		if (!rowData.readOnly && columnData.editingRow !== rowIndex) {
			return () =>
				this.onCellClick(cellData, cellDataKey, rowData, rowIndex, columnData);
		}
	}

	onCellClick(cellData, cellDataKey, rowData, rowIndex, columnData) {
		columnData.onCellClick(rowIndex, columnData.columnDefinition);
	}

	onKeyDown(e, cellValue, external, rowIndex, cellDataKey, columnData) {
		if (e.keyCode === 9) {
			e.preventDefault();
			columnData.onCellTab(rowIndex, cellDataKey, columnData.columnDefinition, {
				event: e.shiftKey ? 'tab_back' : 'tab_fwd',
			});
			this.onCellChange(cellValue, rowIndex, cellDataKey, columnData);
		} else if (e.keyCode === 13) {
			e.preventDefault();
			columnData.onCellEscape();
			this.onCellChange(cellValue, rowIndex, cellDataKey, columnData);
		} else if (e.keyCode === 27) {
			e.preventDefault();
			columnData.onCellEscape();
		}
	}

	onCellChange(cellValue, rowIndex, cellDataKey, columnData, event) {
		columnData.onCellChange(
			cellValue,
			rowIndex,
			cellDataKey,
			columnData.columnDefinition,
			event
		);
	}
}
