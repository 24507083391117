/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import VBaseComponent from 'components/common/baseComponent';
import { completeSSOLogin } from 'actions/authActions';
import { connect } from 'react-redux';

@connect(null, {
	completeSSOLogin,
})
export default class SSOAuthRedirectContainer extends VBaseComponent {
	componentWillMount() {
		// API call for jwt
		this.props.completeSSOLogin({
			model: this.formatMessage('YOU_HAVE_NO_MODELS'),
			unknown: this.formatMessage('UNKNOWN_ERROR'),
			SSO: this.formatMessage('SSO_ERROR'),
		});
	}

	render() {
		return <div className="v-login-container" />;
	}
}
