/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	Activity32,
	ContainerSoftware32,
	DataBase32,
	Devices32,
	Document32,
	EventSchedule32,
	Forum32,
	Group32,
	Home32,
	ListChecked32,
	ModelBuilder32,
	OperationsRecord32,
	ReportData32,
	ScalesTipped32,
	LicenseThirdParty32,
	RequestQuote32,
} from '@carbon/icons-react';

import {
	ACTIVITY_LINK,
	ADAPTIVE_FORMS,
	COMPOSER_LINK,
	DATA_DISCOVERY_DASHBOARD_LINK,
	DATA_MODULE,
	HOME_LINK,
	INQUIRY_MANAGEMENT_LINK,
	PEOPLE_LINK,
	PLANS_LINK,
	PORTAL_ACCESS_LINK,
	PRESENTER_FLEXIBLE,
	PROCESS_LISTS_LINK,
	RAPID_REPORTS_LINK,
	REPORTS,
	ROW_VIEWER_LINK,
	SCENARIOS_LINK,
	SCHEDULER_LINK,
	TASKS_LINK,
	WEBFORM_LINK,
	WEBREPORT_LINK,
	WORKFLOWS_LINK,
} from 'constants/routerPaths';
import {
	ACTIVITY_VIEWALL,
	ADAPTIVE_FORM_VIEW,
	COMPOSER_VIEW,
	DATA_DISCOVERY_DASHBOARD_VIEW,
	HOMEPAGE_PROCESSMAP,
	PORTALACCESS_VIEW,
	PRESENTER_FLEX_VIEW,
	PRESENTER_VIEW,
	RAPID_REPORT_VIEW,
	SCENARIOS_VIEW,
	SCHEDULER_VIEW,
	TASKS_VIEW,
	WORKFLOWMANAGER_VIEW,
} from 'constants/permissions';

import FeatureFlags from 'constants/featureFlags';

// They key name is critical, as it is referenced in the backend for configuration.
const commonLinks = [
	{
		text: 'HOME',
		key: 'HOME',
		link: HOME_LINK,
		Icon: Home32,
		permission: null,
		tooltipDescription: 'NAV_HOME_TOOLTIP',
	},
	{
		text: 'PROCESS_LISTS',
		key: 'PROCESS_LISTS',
		link: PROCESS_LISTS_LINK,
		Icon: ListChecked32,
		permission: HOMEPAGE_PROCESSMAP,
		tooltipDescription: 'NAV_PROCESS_LISTS_TOOLTIP',
		linkToHelp:
			'https://support.varicent.com/s/#en/user-guide/process-lists.html',
	},
	{
		text: 'COMPOSER',
		key: 'COMPOSER',
		link: COMPOSER_LINK,
		Icon: ContainerSoftware32,
		childLinks: [ROW_VIEWER_LINK, WEBREPORT_LINK, WEBFORM_LINK],
		permission: COMPOSER_VIEW,
		tooltipDescription: 'NAV_COMPOSER_TOOLTIP',
		linkToHelp: 'https://support.varicent.com/s/#en/user-guide/composer.html',
		feature: FeatureFlags.disableComposer,
		hideWhenFeatureEnabled: true,
	},
	{
		text: 'WORKFLOWS',
		key: 'WORKFLOW_MANAGER',
		link: WORKFLOWS_LINK,
		Icon: ModelBuilder32,
		permission: WORKFLOWMANAGER_VIEW,
		tooltipDescription: 'NAV_WORKFLOWS_TOOLTIP',
		linkToHelp:
			'https://support.varicent.com/s/#en/user-guide/workflow-manager.html',
	},
	{
		text: 'PORTAL_ACCESS',
		key: 'PORTAL_ACCESS',
		link: PORTAL_ACCESS_LINK,
		Icon: Devices32,
		permission: PORTALACCESS_VIEW,
		tooltipDescription: 'NAV_PORTAL_ACCESS_TOOLTIP',
		linkToHelp:
			'https://support.varicent.com/s/#en/user-guide/portal-access.html',
	},
	{
		text: 'SCHEDULER',
		key: 'SCHEDULER',
		link: SCHEDULER_LINK,
		Icon: EventSchedule32,
		permission: SCHEDULER_VIEW,
		tooltipDescription: 'NAV_SCHEDULER_TOOLTIP',
		linkToHelp: 'https://support.varicent.com/s/#en/user-guide/scheduler.html',
	},
	{
		text: 'TASKS',
		key: 'TASK_MANAGER',
		link: TASKS_LINK,
		Icon: OperationsRecord32,
		permission: TASKS_VIEW,
		tooltipDescription: 'NAV_TASKS_TOOLTIP',
		linkToHelp:
			'https://support.varicent.com/s/#en/user-guide/task-manager.html',
	},
	{
		text: 'ACTIVITY',
		key: 'ACTIVITY',
		link: ACTIVITY_LINK,
		Icon: Activity32,
		permission: ACTIVITY_VIEWALL,
		tooltipDescription: 'NAV_ACTIVITY_TOOLTIP',
		linkToHelp:
			'https://support.varicent.com/s/#en/user-guide/activity-tab.html',
	},
	{
		text: 'SCENARIOS',
		key: 'SCENARIOS',
		link: SCENARIOS_LINK,
		Icon: ScalesTipped32,
		permission: SCENARIOS_VIEW,
		tooltipDescription: 'NAV_SCENARIOS_TOOLTIP',
		linkToHelp: 'https://support.varicent.com/s/#en/user-guide/scenarios.html',
	},
	{
		text: 'REPORTS',
		key: 'REPORTING_CENTER',
		link: REPORTS,
		Icon: ReportData32,
		childLinks: [
			PRESENTER_FLEXIBLE,
			DATA_DISCOVERY_DASHBOARD_LINK,
			RAPID_REPORTS_LINK,
		],
		permission: [
			PRESENTER_FLEX_VIEW,
			DATA_DISCOVERY_DASHBOARD_VIEW,
			RAPID_REPORT_VIEW,
			PRESENTER_VIEW,
		],
		feature: FeatureFlags.isPayeeWebV2Enabled,
		tooltipDescription: 'NAV_REPORS_TOOLTIP',
		linkToHelp: 'https://support.varicent.com/s/#en/user-guide/reporting.html',
	},
	{
		text: 'INQUIRY_MANAGEMENT',
		key: 'INQUIRY_MANAGEMENT',
		link: INQUIRY_MANAGEMENT_LINK,
		Icon: Forum32,
		permission: WORKFLOWMANAGER_VIEW,
		feature: FeatureFlags.modernInquirySubmissions,
		tooltipDescription: 'NAV_INQUIRY_MANAGEMENT_TOOLTIP',
	},
	{
		text: 'ADAPTIVE_FORMS',
		key: 'ADAPTIVE_FORMS',
		link: ADAPTIVE_FORMS,
		Icon: RequestQuote32,
		permission: ADAPTIVE_FORM_VIEW,
		tooltipDescription: 'NAV_ADAPTIVE_FORMS_TOOLTIP',
	},
	{
		text: 'DATA_MODULE',
		key: 'DATA_MODULE',
		link: DATA_MODULE,
		Icon: DataBase32,
		feature: FeatureFlags.dataModule,
		tooltipDescription: 'NAV_DATA_MODULE_TOOLTIP',
	},
	{
		text: 'PLANS',
		key: 'PLANS',
		link: PLANS_LINK,
		Icon: LicenseThirdParty32,
		feature: FeatureFlags.plansEnabled,
		permission: null,
		tooltipDescription: 'NAV_PLANS_TOOLTIP',
	},
	{
		text: 'PEOPLE',
		key: 'PEOPLE',
		link: PEOPLE_LINK,
		Icon: Group32,
		permission: null,
		tooltipDescription: 'NAV_PEOPLE_TOOLTIP',
		feature: FeatureFlags.peopleModule,
	},
];

const linksWithNewPulse = commonLinks;
const linksWithOldPulse = commonLinks;

export function sidebar(
	state = {
		linksWithNewPulse,
		linksWithOldPulse,
	}
) {
	return state;
}
