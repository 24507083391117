/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import FilterOperator from './filterOperator';

export default class ClauseFilter extends FilterOperator {
	constructor(type, left, right, parent) {
		super(type, left, right, parent);
	}

	isColumn(columnName) {
		return this.left.isColumn(columnName) && this.right.isColumn(columnName);
	}

	interpretAsType() {
		throw new Error('Not Supported');
	}

	mapColumns(columnMap) {
		this.left.mapColumns(columnMap);
		this.right.mapColumns(columnMap);
	}

	filter(/* rows, columns */) {
		throw new Error('Implement Me!');
	}
}
