/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

// This file is for any generic url utilities

/**
 * Take key value pairs and convert into query string for urls
 *
 * @param query object containing query parameters
 * @return The get parameter string
 */
export function queryStringify(query) {
	let queryString = '';
	for (const key in query) {
		if (query[key] !== undefined) {
			queryString = `${queryString + key}=${query[key]}&`;
		}
	}
	// remove final & and return
	return queryString.substring(0, queryString.length - 1);
}
