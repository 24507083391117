/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { getData } from 'services/modelStatusService';
import { createAction } from 'redux-act';
import { MODEL_STATUS_DATA_RECIEVED } from 'constants/actionTypes';
import { Varicent } from 'icm-rest-client';

export const dataRecieved =
	createAction<Varicent.RESTAPI.v1.DTOs.LastCalculationInfoDTO>(
		MODEL_STATUS_DATA_RECIEVED
	);

export const fetchData = (): ThunkAction<void> => {
	return (dispatch) => {
		getData().then((response) => {
			dispatch(dataRecieved(response));
		});
	};
};
