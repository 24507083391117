/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const MIGRATION_SELECT_SOURCE_MODEL = 'MIGRATION_SELECT_SOURCE_MODEL';
export const MIGRATION_BUILD_DISPLAY_TREE = 'MIGRATION_BUILD_DISPLAY_TREE';
export const MIGRATION_DISPLAY_TOGGLE_FOLDER =
	'MIGRATION_DISPLAY_TOGGLE_FOLDER';
export const MIGRATION_DISPLAY_TOGGLE_DEPENDENCY =
	'MIGRATION_DISPLAY_TOGGLE_DEPENDENCY';
export const MIGRATION_CLEAR_SELECTED_DEPENDENCIES =
	'MIGRATION_CLEAR_SELECTED_DEPENDENCIES';
export const MIGRATION_RECEIVE_COMPOSER_COMPONENTS =
	'MIGRATION_RECEIVE_COMPOSER_COMPONENTS';
export const MIGRATION_RECEIVE_HIERARCHIES = 'MIGRATION_RECEIVE_HIERARCHIES';
export const MIGRATION_RECEIVE_IMPORTS = 'MIGRATION_RECEIVE_IMPORTS';
export const MIGRATION_RECEIVE_PORTAL_ACCESS_TREES =
	'MIGRATION_RECEIVE_PORTAL_ACCESS_TREES';
export const MIGRATION_RECEIVE_ROLES = 'MIGRATION_RECEIVE_ROLES';
export const MIGRATION_RECEIVE_USERS = 'MIGRATION_RECEIVE_USERS';
export const MIGRATION_RECEIVE_WEBTABS = 'MIGRATION_RECEIVE_WEBTABS';
export const MIGRATION_RECIEVE_PRESENTER_FLEX_REPORTS =
	'MIGRATION_RECIEVE_PRESENTER_FLEX_REPORTS';
export const MIGRATION_RECEIVE_ADAPTIVE_FORMS =
	'MIGRATION_RECEIVE_ADAPTIVE_FORMS';
export const MIGRATION_RECEIVE_WEBFORMS_FILTERS =
	'MIGRATION_RECEIVE_WEBFORMS_FILTERS';
export const MIGRATION_RECEIVE_CALENDARS = 'MIGRATION_RECEIVE_CALENDARS';
export const MIGRATION_RECEIVE_PROCESSLISTS = 'MIGRATION_RECEIVE_PROCESSLISTS';
export const MIGRATION_RECEIVE_BPMWORKFLOWS = 'MIGRATION_RECEIVE_BPMWORKFLOWS';
export const MIGRATION_RECEIVE_PULSE_PLANS = 'MIGRATION_RECEIVE_PULSE_PLANS';
export const MIGRATION_RECEIVE_DEPENDENCIES = 'MIGRATION_RECEIVE_DEPENDENCIES';
export const MIGRATION_RECEIVE_AND_SELECT_DEPENDENCIES =
	'MIGRATION_RECEIVE_AND_SELECT_DEPENDENCIES';
export const MIGRATION_RECEIVE_DESTINATION_CALENDARS =
	'MIGRATION_RECEIVE_DESTINATION_CALENDARS';
export const MIGRATION_SELECT_SOURCE_CALENDAR =
	'MIGRATION_SELECT_SOURCE_CALENDAR';
export const MIGRATION_SELECT_LOCAL_CALENDAR =
	'MIGRATION_SELECT_LOCAL_CALENDAR';
export const MIGRATION_CLEAR_ALL_DATA = 'MIGRATION_CLEAR_ALL_DATA';
export const MIGRATION_MAP_CALENDAR_LEVEL = 'MIGRATION_MAP_CALENDAR_LEVEL';
export const MIGRATION_SET_CALENDARS_MAPPED = 'MIGRATION_SET_CALENDARS_MAPPED';
export const MIGRATION_SET_OVERWRITE = 'MIGRATION_SET_OVERWRITE';
export const MIGRATION_RECEIVE_COMPARISON_DATA =
	'MIGRATION_RECEIVE_COMPARISON_DATA';
export const MIGRATION_COMPLETE = 'MIGRATION_COMPLETE';
export const MIGRATION_SELECT_STATUS_CATEGORY =
	'MIGRATION_SELECT_STATUS_CATEGORY';
export const MIGRATION_SELECT_PROCESS_MAP = 'MIGRATION_SELECT_PROCESS_MAP';
export const MIGRATION_REMAP_PROCESS_TASK = 'MIGRATION_REMAP_PROCESS_TASK';
export const MIGRATION_SELECT_PROCESS_TASK_MAPPING_ELEMENT =
	'MIGRATION_SELECT_PROCESS_TASK_MAPPING_ELEMENT';
export const MIGRATION_SELECT_PROCESS_TASK_MAPPING_ACTION =
	'MIGRATION_SELECT_PROCESS_TASK_MAPPING_ACTION';
export const MIGRATION_SET_PROCESS_TASK_MAPPING_ARGS =
	'MIGRATION_SET_PROCESS_TASK_MAPPING_ARGS';
export const MIGRATION_RECEIVE_PROCESS_TASK_MAPPING_ELEMENTS =
	'MIGRATION_RECEIVE_PROCESS_TASK_MAPPING_ELEMENTS';
export const MIGRATION_SHOW_STATUS_ERROR_PANEL =
	'MIGRATION_TOGGLE_STATUS_ERROR_PANEL';
export const MIGRATION_HIDE_STATUS_ERROR_PANEL =
	'MIGRATION_HIDE_STATUS_ERROR_PANEL';
export const MIGRATION_RECEIVE_DESTINATION_PAYEE_GROUPS =
	'MIGRATION_RECEIVE_DESTINATION_PAYEE_GROUPS';
export const MIGRATION_MAP_PAYEE_GROUP = 'MIGRATION_MAP_PAYEE_GROUP';
export const MIGRATION_REQUEST_MIGRATION_DATA =
	'MIGRATION_REQUEST_MIGRATION_DATA';
export const MIGRATION_RECEIVE_MIGRATION_DATA =
	'MIGRATION_RECEIVE_MIGRATION_DATA';
export const MIGRATION_REQUEST_DEPENDENCIES = 'MIGRATION_REQUEST_DEPENDENCIES';
export const MIGRATION_REQUEST_REQUIRED_CALENDARS =
	'MIGRATION_REQUEST_REQUIRED_CALENDARS';
export const MIGRATION_REQUEST_COMPARISON_DATA =
	'MIGRATION_REQUEST_COMPARISON_DATA';
export const MIGRATION_RECEIVE_REQUIRED_SOURCE_TABLE_AND_CALENDAR_MAPPINGS =
	'MIGRATION_RECEIVE_REQUIRED_SOURCE_TABLE_AND_CALENDAR_MAPPINGS';
export const MIGRATION_MAP_SOURCE_TABLE = 'MIGRATION_MAP_SOURCE_TABLE';
export const MIGRATION_REQUEST_REQUIRED_SOURCE_TABLE_MAPPINGS =
	'MIGRATION_REQUEST_REQUIRED_SOURCE_TABLE_MAPPINGS';
export const MIGRATION_REQUEST_MAPPINGS_COMPARISON_DATA =
	'MIGRATION_REQUEST_MAPPINGS_COMPARISON_DATA';
export const MIGRATION_PREPARE = 'MIGRATION_PREPARE';
export const MIGRATION_PREPARE_FINISH = 'MIGRATION_PREPARE_FINISH';
export const MIGRATION_ADD_TABLE_NO_MATCH_ERROR =
	'MIGRATION_ADD_TABLE_NO_MATCH_ERROR';
export const MIGRATION_RESIZE_STATUS_TABLE = 'MIGRATION_RESIZE_STATUS_TABLE';
export const MIGRATION_COLUMN = 'MIGRATION_COLUMN';
export const MIGRATION_RESIZE_STATUS_COLUMNS =
	'MIGRATION_RESIZE_STATUS_COLUMNS';
export const MIGRATION_STARTED = 'MIGRATION_STARTED';
export const MIGRATION_REPORT_PROGRESS = 'MIGRATION_REPORT_PROGRESS';
export const MIGRATION_TOGGLE_FILTER = 'MIGRATION_TOGGLE_FILTER';
export const MIGRATION_SEARCH_TEXT_CHANGED = 'MIGRATION_SEARCH_TEXT_CHANGED';
export const MIGRATION_FILTER_COLUMN = 'MIGRATION_FILTER_COLUMN';
export const MIGRATION_UPDATE_CALENDAR_MAPPING =
	'MIGRATION_UPDATE_CALENDAR_MAPPING';
