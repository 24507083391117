/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import VBaseComponent from 'components/common/baseComponent';
import VIcon from 'components/common/icon';
import VProgressBar from 'components/common/progressBarComponent';
import '../styles/components/messageBox.scss';
import { clearMessage } from 'actions/messageActions';

@connect((state) => state.message, {
	clearMessage,
})
export default class MessageContainer extends VBaseComponent {
	static propTypes = {
		type: PropTypes.string,
		message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		actionLinkLabel: PropTypes.string,
		action: PropTypes.string,
		messagesNeedTranslation: PropTypes.bool,
		actionLink: PropTypes.string,
		progress: PropTypes.number,
		msgParams: PropTypes.object,
	};

	constructor(props, context) {
		super(props, context);
		this.timer = undefined;
		this.clearTimer = undefined;
		this.state = {
			timeElapsed: 0,
		};
		this.buttonRef = React.createRef();
	}

	onClickLink(val) {
		if (this.props.action) this.props.action(val);
	}

	onClickClose() {
		this.props.clearMessage();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.message !== nextProps.message) {
			if (
				nextProps.type === 'confirm_message' ||
				nextProps.type === 'confirm_message_link' ||
				nextProps.type === 'information'
			) {
				this.clearTimer = setTimeout(this.props.clearMessage, 10000);
			}
		}
		if (nextProps.progress >= 0) {
			if (nextProps.progress === 0) {
				this.setState({ timeElapsed: 0 });
				this.timer = setInterval(() => {
					this.setState({ timeElapsed: this.state.timeElapsed + 1 });
				}, 1000);
			} else if (nextProps.progress === 100) {
				this.state.timeElapsed = 0;
				this.timer = undefined;
			}
		} else {
			clearInterval(this.timer);
		}
	}

	componentWillUnmount() {
		clearInterval(this.timer);
		if (this.clearTimer) {
			clearTimeout(this.clearTimer);
		}
	}

	getHoursMinutesSecondsString() {
		const hours = Math.floor(this.state.timeElapsed / 3600);
		const minutes = Math.floor((this.state.timeElapsed - hours * 3600) / 60);
		const seconds = this.state.timeElapsed - hours * 3600 - minutes * 60;
		let hoursString = hours.toString();
		if (hoursString.length < 2) {
			hoursString = `0${hoursString}`;
		}
		let minutesString = minutes.toString();
		if (minutesString.length < 2) {
			minutesString = `0${minutesString}`;
		}
		let secondsString = seconds.toString();
		if (secondsString.length < 2) {
			secondsString = `0${secondsString}`;
		}
		return `${hoursString}:${minutesString}:${secondsString}`;
	}

	renderProgressMessage(message, progress, type) {
		const iconclass = type === 'upload' ? 'icon-upload-32' : 'icon-download-32';
		return (
			<div className="v-message-box">
				<div className="v-message-row v-row-1">
					<div className="v-message-elem v-message-icon">
						<VIcon type="static" size="xsmall" iconClass={iconclass} />
					</div>
					<div className="v-message-elem v-message-title">{message}</div>
					<div className="v-message-elem v-message-close">
						<VIcon
							type="normal"
							size="xsmall"
							iconClass="icon-closemodal"
							action={() => this.onClickClose()}
							buttonRef={this.buttonRef}
							ariaLabel={this.formatMessage('MODAL_CLOSE')}
						/>
					</div>
				</div>
				<div className="v-message-row v-row-2">
					<div className="v-message-elem v-message-progress">
						<VProgressBar percent={progress} />
					</div>
					<div className="v-message-elem v-message-percentage">
						{this.replaceText('MESSAGE_PERCENT', {
							num: progress.toString(),
						})}
					</div>
				</div>
				<div className="v-message-row v-row-3">
					<div className="v-message-elem v-message-time">
						{this.formatMessage('MESSAGE_TIME_ELAPSED')}
					</div>
					<div className="v-message-elem v-message-time v-message-elapsed">
						{this.getHoursMinutesSecondsString()}
					</div>
				</div>
			</div>
		);
	}

	isTypeError(type) {
		if (
			type === 'confirm_message' ||
			type === 'confirm_message_link' ||
			type === 'information'
		) {
			return false;
		}
		return true;
	}

	onMessageMouseOver(type) {
		if (!this.isTypeError(type)) {
			clearTimeout(this.clearTimer);
		}
	}

	onMessageMouseOut(type) {
		if (!this.isTypeError(type)) {
			this.clearTimer = setTimeout(this.props.clearMessage, 10000);
		}
	}

	renderMessage(message, link, linkLabel, type, requestId) {
		if (message) {
			let typeClass = 'v-message-error';
			let iconClass = 'icon-critical';
			let supportClasses = ['v-orange-50'];
			if (type === 'confirm_message' || type === 'confirm_message_link') {
				typeClass = 'v-message-completed';
				iconClass = 'icon-completed';
				supportClasses = ['v-green-40'];
			} else if (type === 'information') {
				typeClass = 'v-message-info';
				iconClass = 'icon-information';
				supportClasses = ['v-blue-40'];
			}

			let secondRow;

			if (link && linkLabel) {
				secondRow = (
					<div className="v-message-elem v-message-title">
						<a href={link} target="_blank">
							{linkLabel}
						</a>
					</div>
				);
			}

			if (type === 'error' && requestId && requestId !== '') {
				secondRow =
					({ secondRow },
					(
						<div className="v-message-elem v-message-requestid">
							{this.formatMessage('MESSAGE_FOOTER_REQUEST_ID', {
								id: requestId,
							})}
						</div>
					));
			}

			if (secondRow) {
				secondRow = <div className="v-message-row v-row-2">{secondRow}</div>;
			}

			return (
				<div
					className={`v-message-box v-message-no-percent ${typeClass}`}
					onMouseOver={() => {
						this.onMessageMouseOver(type);
					}}
					onMouseOut={() => {
						this.onMessageMouseOut(type);
					}}
				>
					<div className="v-message-row v-row-1">
						<div className="v-message-elem v-message-icon">
							<VIcon
								type="static"
								size="xsmall"
								iconClass={iconClass}
								supportingClasses={supportClasses}
							/>
						</div>
						<div
							className="v-message-elem v-message-content"
							data-test={`message-content-${message}`}
						>
							{message}
						</div>
						<div className="v-message-elem v-message-close">
							<VIcon
								type="normal"
								size="xsmall"
								iconClass="icon-closemodal"
								action={() => this.onClickClose()}
								data-test="message-modal-icon"
								buttonRef={this.buttonRef}
								ariaLabel={this.formatMessage('MODAL_CLOSE')}
							/>
						</div>
					</div>
					{secondRow}
					<div className="v-message-row v-row-3" />
				</div>
			);
		}
		return null;
	}

	translateIfNeeded(message, params = null) {
		if (Array.isArray(message)) {
			return message.map((error) => <div>{error}</div>);
		} else if (typeof message === 'object') {
			const values = _.mapValues(message.values, (value) => {
				const fmtValue = this.translateTableOrColumnName(value);
				return this.formatMessage(fmtValue.toUpperCase());
			});

			if (message.id) {
				const msg = this.formatMessage(message.id, values);
				if (this.props.messagesNeedTranslation) {
					return this.translateMessage(msg);
				}
				return msg;
			}
			if (this.props.messagesNeedTranslation) {
				const msg = this.formatMessage(message);
				return this.translateMessage(msg.Message);
			}
		} else if (typeof message === 'string' && message !== '') {
			if (this.props.messagesNeedTranslation) {
				const msg = this.formatMessage(message, params);
				return this.translateMessage(msg);
			}
		}
		return message;
	}

	render() {
		const { type, action, message, requestId } = this.props;
		if (!message) {
			this.state.timeElapsed = 0;
			clearInterval(this.timer);
			this.timer = undefined;
		}
		const translatedMessage = this.translateIfNeeded(
			message,
			this.props.msgParams
		);
		const actionLinkLabel = this.translateIfNeeded(this.props.actionLinkLabel);
		if (type === 'upload' || type === 'download') {
			return this.renderProgressMessage(
				translatedMessage,
				this.props.progress || 0,
				type
			);
		}

		return this.renderMessage(
			translatedMessage,
			action,
			actionLinkLabel,
			type,
			requestId
		);
	}
}
