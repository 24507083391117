/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import IntlProvider from './intlProvider';
import defaultMessages from './localizations/messages.properties';
import { Provider } from '@varicent/components';
import { useIntl } from './contexts/intlContext';

const NestedProvider: React.FC = ({ children }) => {
	const intl = useIntl();
	return <Provider formatMessage={intl.formatMessage}>{children}</Provider>;
};

export default ({ children }) => {
	return (
		<IntlProvider
			loadLocale={(code) =>
				import(
					`./localizations/messages${
						code === 'en' ? '' : `_${code}`
					}.properties`
				)
			}
			defaultMessages={defaultMessages}
		>
			<NestedProvider>{children}</NestedProvider>
		</IntlProvider>
	);
};
