/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import axios from 'axios';
import { authURL, defaultRESTAPIErrorHandler, ssoURL } from './httpService';
import { getJWTPayloadObject } from 'helpers/jwtHelper';
import { getJWT, getTokenMap } from 'helpers/authHelper';

interface ISSOpayload {
	idpLoginUrl: string;
}

interface ISLOpayload {
	idpLogoutUrl: string;
}

export interface ILoginData {
	models: string[];
	tenant_id: number;
	jwt_renew_minutes_interval: number;
	token?: string;
	loggedIn: boolean;
	first_name: string;
	last_name: string;
	accountNumber?: Nullable<string>;
	token_map: object;
}

interface ISSOLoginResponse extends ILoginData {
	username: string;
	nextPathname: string;
	modal?: boolean;
}

export default class AuthService {
	static login(username: string, password: string, database?: string) {
		const requestConfig = {
			baseURL: authURL,
			preventDefaultRESTAPIError: true,
		};
		if (database) {
			return axios
				.post<ILoginData>(
					'v2/login',
					{ email: username, password, database },
					requestConfig
				)
				.then(({ data }) => data);
		}
		return axios
			.post<ILoginData>(
				'v2/login',
				{ email: username, password },
				requestConfig
			)
			.then(({ data }) => data);
	}

	static remoteLogin(
		username: string,
		password: string,
		url: string,
		host: string
	) {
		const requestConfig = {
			baseURL: url || `${host}/services`,
			preventDefaultRESTAPIError: true,
		};

		return axios
			.post<ILoginData>('login', { email: username, password }, requestConfig)
			.then(({ data }) => data);
	}

	static getSSOLink(username: string, nextPathname: string, modal?: boolean) {
		return axios
			.post<ISSOpayload>(
				`v2/saml/sso/adminweb`,
				{ email: username },
				{ params: { nextPathname, modal }, baseURL: authURL }
			)
			.then(({ data }) => data);
	}

	static getSSOLinkForHost(
		hostname: string,
		nextPathname: string,
		modal?: boolean
	) {
		return axios
			.post<ISSOpayload>(
				'v2/saml/sso/adminweb/tenant',
				{ hostname },
				{ params: { nextPathname, modal }, baseURL: authURL }
			)
			.then(({ data }) => data);
	}

	static getSLOLink(username: string) {
		return axios
			.post<ISLOpayload>(
				`saml/slo/adminweb`,
				{ email: username },
				{ baseURL: authURL }
			)
			.then(({ data }) => data);
	}

	static completeSSOLogin() {
		return axios
			.get<ISSOLoginResponse>('saml/login', {
				baseURL: ssoURL,
				preventDefaultRESTAPIError: true,
				withCredentials: true,
			})
			.then(({ data }) => data)
			.catch((err) => {
				if (
					err?.response?.status === 400 &&
					err?.response?.data?.message === 'No relay state found in redis.'
				) {
					/*
					 * SPM-63973: mute this particular error for now, as it doesn't impact client's ability to login,
					 * until we find how completeSSOLogin can be called twice
					 */
				} else {
					defaultRESTAPIErrorHandler(err);
				}
				throw err;
			});
	}

	static logout() {
		const tokenMap = getTokenMap();
		return axios
			.post<void>('logout', { token_map: tokenMap }, { baseURL: authURL })
			.then(({ data }) => data);
	}

	static updatePassword(
		username: string,
		password: string,
		newPassword: string
	) {
		return axios
			.patch<any>(
				'users/password',
				{ email: username, password, new_password: newPassword },
				{ baseURL: authURL }
			)
			.then(({ data }) => data);
	}

	static requestPasscode(username: string) {
		return axios
			.post<{ preview: string }>(
				'users/forgotpassword',
				{ email: username },
				{ baseURL: authURL }
			)
			.then(({ data }) => data);
	}

	static submitPasscode(
		username: string,
		passcode: string,
		newPassword: string
	) {
		const payload = {
			email: username,
			passcode,
			password: newPassword,
		};
		return axios
			.post<any>('users/resetpassword', payload, { baseURL: authURL })
			.then(({ data }) => data);
	}

	static getNewToken() {
		const jwt = getJWT();
		return AuthService.getNewTokenFromOld(jwt);
	}

	static getNewTokenFromOld(jwt) {
		// renew old JWT token
		if (getJWTPayloadObject(jwt).dbserver !== undefined) {
			return axios
				.post<any>('renewtoken', null, {
					baseURL: authURL,
					preventDefaultRESTAPIError: true,
				})
				.then(({ data }) => data);
		}
		return axios
			.post<any>('v2/renewtoken', null, {
				baseURL: authURL,
				preventDefaultRESTAPIError: true,
			})
			.then(({ data }) => data);
	}
}
