// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 * Get all process lists
 * url: `api/v1/processlists`
 */
export function getAll(
	query?: { filter?: string; orderBy?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'processlists',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ProcessListDTO[]>(
		request
	);
}

/**
 * Get process list by id
 * url: `api/v1/processlists/${id}`
 */
export function get(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `processlists/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ProcessListDTO>(request);
}

/**
 * Create a new process list
 * url: `api/v1/processlists`
 */
export function post(
	processlist: Varicent.RESTAPI.v1.DTOs.ProcessListDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'processlists',
		apiVersion: 'v1' as const,
		data: processlist,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ProcessListDTO>(request);
}

/**
 * Update an existing process list by ID. ID must match ID of body
 * url: `api/v1/processlists/${id}`
 */
export function put(
	id: Varicent.ID,
	processlist: Varicent.RESTAPI.v1.DTOs.ProcessListDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `processlists/${id}`,
		apiVersion: 'v1' as const,
		data: processlist,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ProcessListDTO>(request);
}

/**
 * Update all process lists
 * url: `api/v1/processlists`
 */
export function putAll(
	processLists: Varicent.RESTAPI.v1.DTOs.ProcessListDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: 'processlists',
		apiVersion: 'v1' as const,
		data: processLists,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ProcessListDTO[]>(
		request
	);
}

/**
 * Delete process list by ID
 * url: `api/v1/processlists/${id}`
 */
export function doDelete(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `processlists/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<string>(request);
}
