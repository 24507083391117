/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	LOGOUT,
	CHANGE_MODEL,
	PERMISSIONS_RECIEVE_CURRENT_PERMISSIONS,
	ADD_PROCESSLIST_PERMISSION,
} from 'constants/actionTypes';
import { HOMEPAGE_PROCESSMAP } from 'constants/permissions';
import {
	getPermissionToEditCurrentProcessList,
	getPermissionToDeleteCurrentProcessList,
	getPersmissionToViewCurrentProcessList,
} from '../helpers/permissionHelper';

const handlers = {};

function getInitialState() {
	return {
		grantedPermissions: {},
		permissionsLoaded: false,
	};
}

export function permissions(state = getInitialState(), action) {
	const { type } = action;
	if (!handlers[type]) {
		return state;
	}
	return handlers[type](state, action);
}

handlers[PERMISSIONS_RECIEVE_CURRENT_PERMISSIONS] = (state, { payload }) => {
	const newPermissions = {};
	let hasProcessMapPermission = false;
	for (let i = 0; i < payload.length; i++) {
		if (!hasProcessMapPermission && payload[i].includes(HOMEPAGE_PROCESSMAP)) {
			newPermissions[HOMEPAGE_PROCESSMAP] = true;
			hasProcessMapPermission = true;
		}
		newPermissions[payload[i]] = true;
	}
	return {
		...state,
		grantedPermissions: newPermissions,
		permissionsLoaded: true,
	};
};

handlers[ADD_PROCESSLIST_PERMISSION] = (state, { payload }) => {
	const id = payload.id;
	const newPermissions = {
		[getPermissionToEditCurrentProcessList(id)]: true,
		[getPermissionToDeleteCurrentProcessList(id)]: true,
		[getPersmissionToViewCurrentProcessList(id)]: true,
	};
	return {
		...state,
		grantedPermissions: {
			...state.grantedPermissions,
			...newPermissions,
		},
	};
};

handlers[LOGOUT] = getInitialState;
handlers[CHANGE_MODEL] = getInitialState;
