export const remoteMigrationActionTypes = {
	REMOTE_MIGRATION_GET: 'REMOTE_MIGRATION_GET', // this should be used
	REMOTE_MIGRATION_DELETE: 'REMOTE_MIGRATION_DELETE',
	REMOTE_MIGRATION_CREATE: 'REMOTE_MIGRATION_CREATE',
	REMOTE_MIGRATION_CONNECT: 'REMOTE_MIGRATION_CONNECT',
	REMOTE_MIGRATION_CLEAR: 'REMOTE_MIGRATION_CLEAR',
	REMOTE_MIGRATION_LOGIN: 'REMOTE_MIGRATION_LOGIN',
	REMOTE_MIGRATION_SET: 'REMOTE_MIGRATION_SET',
	REMOTE_MIGRATION_GET_TS_URL: 'REMOTE_MIGRATION_GET_TS_URL',
};
