/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const MODEL_OPTIONS_REQUEST_MODELS = 'MODEL_OPTIONS_REQUEST_MODELS';
export const MODEL_OPTIONS_RECEIVE_MODELS = 'MODEL_OPTIONS_RECEIVE_MODELS';
export const MODEL_OPTIONS_RECEIVE_OPTIONS = 'MODEL_OPTIONS_RECEIVE_OPTIONS';
export const MODEL_OPTIONS_UPDATE_SELECTED_MODEL =
	'MODEL_OPTIONS_UPDATE_SELECTED_MODEL';
export const MODEL_OPTIONS_RECEIVE_MODEL_USERS =
	'MODEL_OPTIONS_RECEIVE_MODEL_USERS';
export const MODEL_OPTIONS_NO_ROLES = 'MODEL_OPTIONS_NO_ROLES';
export const MODEL_OPTIONS_REQUEST_ROLES_FROM_TS =
	'MODEL_OPTIONS_REQUEST_ROLES_FROM_TS';
export const MODEL_OPTIONS_RECEIVE_ROLES_FROM_TS =
	'MODEL_OPTIONS_RECEIVE_ROLES_FROM_TS';
export const MODEL_OPTIONS_REQUEST_USERS = 'MODEL_OPTIONS_REQUEST_USERS';
export const MODEL_OPTIONS_RECEIVE_USERS = 'MODEL_OPTIONS_RECEIVE_USERS';
export const MODEL_OPTIONS_RECEIVE_USER_ROLES =
	'MODEL_OPTIONS_RECEIVE_USER_ROLES';
export const MODEL_OPTIONS_UPDATE_USER_FIELD =
	'MODEL_OPTIONS_UPDATE_USER_FIELD';
export const MODEL_OPTIONS_SELECT_USER = 'MODEL_OPTIONS_SELECT_USER';
export const MODEL_OPTIONS_CREATE_USER_SUCCESS =
	'MODEL_OPTIONS_CREATE_USER_SUCCESS';
export const MODEL_OPTIONS_ADD_USER_TO_MODEL_SUCCESS =
	'MODEL_OPTIONS_ADD_USER_TO_MODEL_SUCCESS';
export const MODEL_OPTIONS_UPDATE_USER_ROLE_SUCCESS =
	'MODEL_OPTIONS_UPDATE_USER_ROLE_SUCCESS';
export const MODEL_OPTIONS_DELETE_USER_SUCCESS =
	'MODEL_OPTIONS_DELETE_USER_SUCCESS';
export const MODEL_OPTIONS_DELETE_INACTIVE_USER_SUCCESS =
	'MODEL_OPTIONS_DELETE_INACTIVE_USER_SUCCESS';
export const MODEL_OPTIONS_DELETE_USER_FROM_MODEL_SUCCESS =
	'MODEL_OPTIONS_DELETE_USER_FROM_MODEL_SUCCESS';
export const MODEL_OPTIONS_TOGGLE_LOCK_USER_SUCCESS =
	'MODEL_OPTIONS_TOGGLE_LOCK_USER_SUCCESS';
export const MODEL_OPTIONS_CLEAR_USERS = 'MODEL_OPTIONS_CLEAR_USERS';
export const SET_SELECTED_USER_ROLE = 'SET_SELECTED_USER_ROLE';
export const MODEL_OPTIONS_RECEIVE_CURRENT_ROLE =
	'MODEL_OPTIONS_RECEIVE_CURRENT_ROLE';
