/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

// Admin Option Keys

export const HistoryDefaultEnabled = 'HistoryDefaultEnabled';
export const ModelDescription = 'ModelDescription';

// general
export const ModelName = 'ModelName';
export const ImportBlockBadCharacters = 'ImportBlockBadCharacters';
export const ImportEscapeCharacter = 'ImportEscapeCharacter';
export const ExportBlockBadCharacters = 'ExportBlockBadCharacters';
export const TagLine = 'TagLine';
export const DefaultEditDate = 'DefaultEditDate';
export const ModelLanguage = 'ModelLanguage';
export const DisableEmailsInScheduler = 'DisableEmailsInScheduler';
export const DisableEmailsInTaskManagerWhenEnablingPayees =
	'DisableEmailsInTaskManager';
export const PDFPublishUseUnicode = 'PDFPublishUseUnicode';
export const BOMCharacterInTextPublish = 'BOMCharacterInTextPublish';
export const ArchivedInquiries = 'ArchivedInquiries';

// user
export const FailedLogOnAttemptsAllowed = 'FailedLogOnAttemptsAllowed';
export const PasswordExpireDays = 'PasswordExpireDays';
export const PasswordExpirePromptDaysBefore = 'PasswordExpirePromptDaysBefore';
export const PasswordExpirePromptFrequency = 'PasswordExpirePromptFrequency';
export const PasswordFinalWarningFrequency = 'PasswordFinalWarningFrequency';
export const RequireStrongUserPasswords = 'RequireStrongUserPasswords';
export const AdminPasswordResetEnabled = 'AdminPasswordResetEnabled';
export const AdminPasswordResetExpireSeconds =
	'AdminPasswordResetExpireSeconds';
export const CalculationPreviewWarning = 'CalculationPreviewWarning';
export const AdminPasswordHistory = 'AdminPasswordHistory';
export const ChangeAdminPasswordOnAdminSet = 'ChangeAdminPasswordOnAdminSet';

// web
export const WebEnabled = 'WebEnabled';
export const WebPasswordResetEnabled = 'WebPasswordResetEnabled';
export const WebPasswordResetExpireSeconds = 'WebPasswordResetExpireSeconds';
export const WebLockoutEnabled = 'WebLockoutEnabled';
export const FailedWebLogOnAttempts = 'FailedWebLogOnAttempts';
export const WebHelpEmail = 'WebHelpEmail';
export const WebPasswordLifespan = 'WebPasswordLifespan';
export const WebPasswordExpirePromptDaysBefore =
	'WebPasswordExpirePromptDaysBefore';
export const WebPasswordExpirePromptFrequency =
	'WebPasswordExpirePromptFrequency';
export const ChangeWebPasswordOnFirstLogin = 'ChangeWebPasswordOnFirstLogin';
export const WebBaseURL = 'WebBaseURL';
export const WebVersion = 'WebVersion';
export const WebPasswordHistory = 'WebPasswordHistory';
export const MessageCentreEnabled = 'MessageCentreEnabled';

// audit log
export const WebUserLogging = 'WebUserLogging';
export const AuditLogReverseSort = 'AuditLogReverseSort';

// payee ledger
export const WebLedgerHeaderInterval = 'WebLedgerHeaderInterval';
export const WebLedgerManualSubmit = 'WebLedgerManualSubmit';

// portal access
export const EmailUsersOnSignOff = 'EmailUsersOnSignOff';
export const EmailUsersOnInquiry = 'EmailUsersOnInquiry';
export const PortalAccessMailAddress = 'PortalAccessMailAddress';

// publisher
export const PublishMailAddress = 'PublishMailAddress';

// tsapi
export const PayeeDocumentsAPI = 'PayeeDocumentsAPI';
export const PayeeDocumentsTokenTable = 'PayeeDocumentsTokenTable';

// calculation
export const IncrementalCalculation = 'IncrementalCalculation';
export const GenerateComputationLog = 'GenerateComputationLog';
export const RestrictUnaggregatedVariables = 'RestrictUnaggregatedVariables';
export const AutoJoin = 'AutoJoin';
export const PPOIncrementalOptimizations = 'PPOIncrementalOptimizations';

// Watson Assistant
export const WatsonAssistantEnabled = 'WatsonAssistantEnabled';
export const WatsonAssistantCustomName = 'WatsonAssistantCustomName';
export const WatsonAssistantAssistantID = 'WatsonAssistantAssistantID';

// Symon
export const SymonDomain = 'SymonDomain';
export const SymonUsername = 'SymonUsername';
export const SymonPassword = 'SymonPassword';
export const SymonAPIKey = 'SymonAPIKey';

// Cloud Object Storage
export const COSAccessKey = 'COSAccessKey';
export const COSSecretKey = 'COSSecretKey';
export const COSBucket = 'COSBucket';
export const COSEndpoint = 'COSEndpoint';

export const KeysForIntegrations = [
	SymonDomain,
	SymonUsername,
	SymonPassword,
	SymonAPIKey,
	WatsonAssistantEnabled,
	WatsonAssistantCustomName,
	WatsonAssistantAssistantID,
];
