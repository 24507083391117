/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import VBaseComponent from 'components/common/baseComponent';
import VModal from 'components/common/modalComponent';
import LoginContainer from 'containers/loginContainer';

export default class AuthenticationModal extends VBaseComponent {
	static propTypes = {
		location: PropTypes.shape({
			pathname: PropTypes.string,
		}),
		remote: PropTypes.bool,
		closeAuthModal: PropTypes.func,
	};

	render() {
		if (this.props.remote) {
			return (
				<VModal onHide={this.props.closeAuthModal} show size="large">
					<VModal.Header
						closeButton
						title={this.formatMessage('AUTH_REMOTE_MODAL_TITLE')}
					/>
					<VModal.Body>
						<LoginContainer
							closeAuthModal={this.props.closeAuthModal}
							remote
							location={this.props.location}
							modal
						/>
					</VModal.Body>
				</VModal>
			);
		}

		return (
			<VModal show size="large" noHideOnClickOutside>
				<VModal.Header title={this.formatMessage('AUTH_MODAL_TITLE')} />
				<VModal.Body
					style={{
						paddingBottom: '2rem',
					}}
				>
					<LoginContainer location={this.props.location} modal />
				</VModal.Body>
			</VModal>
		);
	}
}
