/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import dynamicLoad from 'utils/dynamicLoad';
import VLoginHeader from 'components/common/loginHeaderComponent';
import VSpinnerModal from 'components/common/spinnerModalComponent';
import AboutICMModal from 'components/common/AboutICMModalComponent';
import ConfirmModal from 'components/common/confirmModalComponent';
import {
	ABOUT_ICM_MODAL,
	MAINTENANCE_MODAL,
	EDIT_PROFILE_MODAL,
	MODEL_THEMES_MODAL,
} from 'constants/modalNames';
import {
	LOGIN_LINK,
	SSO_AUTH_REDIRECT_LINK,
	HOME_LINK,
} from 'constants/routerPaths';
import 'styles/main.scss';
import VReactComponent from 'components/common/reactComponent';
import AuthenticationModal from 'components/authentication/authModalComponent';
import LoginContainer from 'containers/loginContainer';
import MessageContainer from 'containers/messageContainer';
import MessageBannerContainer from 'containers/messageBannerContainer';
import SidebarContainer from 'containers/sidebarContainer';
import RetrieveCredentialsContainer from 'containers/retrieveCredentialsContainer';
import SSOAuthRedirectContainer from 'containers/SSOAuthRedirectContainer';
import { navigate } from 'actions/routerActions';
import { clearRedirect } from 'actions/redirectActions';
import { changeModelAndRenewJWT } from 'actions/authActions';
import AssetReloadDialog from 'components/common/dialogs/assetReloadDialog';
import {
	reportErrorDetails,
	isReportErrorDetailEnabled,
} from 'helpers/errorHelper';
import { history } from 'routerHistory';
import ErrorCatcher from 'icm-core/lib/components/errorCatcher';
import { FocusStyleManager } from '@blueprintjs/core';
import { Intercom } from 'customHooks';
import FeatureFlags from 'constants/featureFlags';
import { RouterProvider, useRouteBlockingDialog } from 'utils/routeUtils';
import { useIntl } from 'icm-core/lib/contexts/intlContext';
import { selectFeatureFlags, isFeatureEnabled } from 'utils/featureUtils';
import { appcuesTrack } from 'helpers/analyticsHelper';
import cx from 'classnames';
import { RelativePortalMarker } from 'components/layouts';
import ExitLinkUpdater from 'components/historyTracking/exitLinkUpdater';

FocusStyleManager.onlyShowFocusOnTabs();

const VHeader = dynamicLoad(() => import('components/common/headerComponent'));

const MaintenanceModal = dynamicLoad(() =>
	import('components/maintenance/maintenanceModalComponent')
);

const AdminSettingsSidebarContainer = dynamicLoad(() =>
	import('containers/adminSettingsSidebarContainer')
);

const EditProfile = dynamicLoad(() =>
	import('components/modelOptions/userManagement/classicEditProfile')
);
const StatusContainer = dynamicLoad(() => import('containers/statusContainer'));
const ModelThemeModalComponent = dynamicLoad(() =>
	import('components/modelOptions/modelThemeModalComponent')
);

const performNavigate = (props) => {
	const normalizedPathname = props.location.pathname.startsWith('/')
		? props.location.pathname
		: `/${props.location.pathname}`;
	if (normalizedPathname === LOGIN_LINK && props.auth.tenantID !== null) {
		/*
		 * We have a tenant, and we aren't showing the authmodal so redirect to
		 * home page.
		 */
		history.push(HOME_LINK);
		return;
	}

	props.navigate(normalizedPathname, props.params);
};
@connect(
	(state) => ({
		auth: state.authentication,
		header: state.globalAction.header,
		modal: state.modal,
		login: state.login,
		modelOptions: state.modelOptions,
		redirect: state.redirect,
		model: state.authentication.database,
		global: state.global,
		locale: state.locale,
		modelThemes: state.modelThemes,
		featureFlags: selectFeatureFlags(state),
	}),
	{
		navigate,
		clearRedirect,
		changeModelAndRenewJWT,
	}
)
export default class Main extends VReactComponent {
	static propTypes = {
		children: PropTypes.object,
		auth: PropTypes.object,
		location: PropTypes.shape({
			// injected by react-router
			pathname: PropTypes.string,
		}),
		params: PropTypes.object, // injected by react-router
		modelOptions: PropTypes.object,
		header: PropTypes.string,
		modal: PropTypes.shape({
			modalName: PropTypes.string,
			modalContextProps: PropTypes.object,
		}),
		login: PropTypes.shape({
			showPasswordChange: PropTypes.bool,
		}),
		redirect: PropTypes.object,
		model: PropTypes.string,
	};

	componentWillMount() {
		performNavigate(this.props);
	}

	componentWillReceiveProps(nextProps) {
		if (!this.props.auth.user && nextProps.auth.user) {
			// login success
			const pathName =
				(this.props.location.query && this.props.location.query.nextPathname) ||
				'/';
			if (this.props.location.query.nextQuery) {
				history.push(`${pathName}?${this.props.location.query.nextQuery}`);
			} else {
				history.push(pathName);
			}
		} else if (this.props.auth.user && !nextProps.auth.user) {
			// logout
			history.push(LOGIN_LINK);
		}

		if (this.props.location.pathname !== nextProps.location.pathname) {
			performNavigate(nextProps);
			appcuesTrack();
		}
	}

	componentWillUpdate(nextProps) {
		if (nextProps.redirect.redirectUrl) {
			history.push(nextProps.redirect.redirectUrl);
			/*
			 *If the redirect actually occurred(wasn't cancelled by save confirmations)
			 *We split on '?' to remove query parameters.
			 */
			if (
				nextProps.redirect.redirectUrl.split('?')[0] ===
				window.location.pathname
			) {
				// If the redirect is because we're changing the model
				if (nextProps.redirect.model) {
					this.props.changeModelAndRenewJWT(nextProps.redirect.model);
				}
				this.props.clearRedirect();
			}
		}
	}

	globalOnClick(e) {
		if (this.props.global.globalOnClick) {
			this.props.global.globalOnClick(e);
		}
	}

	renderAboutICMModal(modalName) {
		return <AboutICMModal modalName={modalName} />;
	}

	renderMaintenanceModal(modalName) {
		return <MaintenanceModal modalName={modalName} />;
	}

	renderAuthModal() {
		return <AuthenticationModal location={this.props.location} />;
	}

	renderAdminContent() {
		const {
			header,
			auth: { user, models, database },
		} = this.props;

		return (
			<div className="v-main" key={database}>
				<VHeader
					user={user}
					selectedModel={database}
					models={models}
					adminPage
					headerTitle={header}
				/>
				<div className="v-container">
					<AdminSettingsSidebarContainer />
					<section
						id="main-content"
						role="main"
						className="v-content"
						tabIndex="-1"
					>
						<MessageBannerContainer />
						<MessageContainer />
						<div className="flex-fit">{this.props.children}</div>
					</section>
					<RelativePortalMarker category="main" />
				</div>
				<VSpinnerModal />
			</div>
		);
	}

	renderTenantContent() {
		const {
			header,
			location,
			auth: { user, models, database },
		} = this.props;

		return (
			<div className="v-main">
				<VHeader
					tenantHeader={
						~location.pathname.indexOf('log') ? 'TENANT_LOG' : 'MODEL_OPTIONS'
					}
					user={user}
					selectedModel={database}
					models={models}
					headerTitle={header}
				/>
				<div className="v-container">
					<section className="v-content">
						<MessageBannerContainer />
						<MessageContainer />
						<div className="flex-fit">{this.props.children}</div>
					</section>
					<RelativePortalMarker category="main" />
				</div>
				<VSpinnerModal />
			</div>
		);
	}

	renderSSOContent() {
		return (
			<div className="v-main">
				<VLoginHeader />
				<div className="v-container v-login">
					<section className="v-content">
						<MessageBannerContainer />
						<MessageContainer />
						<div className="flex-fit">
							<SSOAuthRedirectContainer />
						</div>
					</section>
					<RelativePortalMarker category="main" />
				</div>
			</div>
		);
	}

	renderLoginContent(className) {
		return (
			<div className="v-main">
				<VLoginHeader />
				<div className={cx(className, 'flex-fit')}>
					<section className="v-content flex-fit">
						<MessageBannerContainer />
						<MessageContainer />
						<div className="flex-fit">
							<LoginContainer location={this.props.location} modal={false} />
						</div>
					</section>
					<RelativePortalMarker category="main" />
				</div>
			</div>
		);
	}

	renderRetrieveCredentialsContent() {
		return (
			<div className="v-main">
				<VLoginHeader />
				<div className="v-container v-login">
					<section className="v-content">
						<MessageBannerContainer />
						<MessageContainer />
						<div className="flex-fit">
							<RetrieveCredentialsContainer />
						</div>
					</section>
					<RelativePortalMarker category="main" />
				</div>
			</div>
		);
	}

	renderOtherContent() {
		const {
			header,
			auth: { user, models, database },
		} = this.props;
		// key forces remount if model changes
		return (
			<div key={this.props.model} className="v-main">
				<VHeader
					user={user}
					selectedModel={database}
					models={models}
					headerTitle={header}
				/>
				<div className="v-container">
					<SidebarContainer />
					<section
						id="main-content"
						role="main"
						className="v-content"
						tabIndex="-1"
					>
						<MessageBannerContainer />
						<MessageContainer />
						<div className="flex-fit">{this.props.children}</div>
					</section>
					<RelativePortalMarker category="main" />
				</div>
				<VSpinnerModal />
			</div>
		);
	}

	renderStatusContent() {
		return (
			<div className="v-main flex-fit">
				<StatusContainer pathname={this.props.location.pathname} />
			</div>
		);
	}

	renderContent() {
		const {
			loginPage,
			adminPage,
			optionPage,
			retrieveCredentialsPage,
			tenantPage,
			statusPage,
			database,
		} = this.props.auth;
		const { showPasswordChange } = this.props.login;

		if (showPasswordChange) {
			return this.renderLoginContent('v-change-password-container');
		}

		if (statusPage) {
			return this.renderStatusContent();
		}

		if (!loginPage && !optionPage) {
			if (!this.props.modelThemes[database]) {
				return <VSpinnerModal />;
			}

			if (adminPage && !retrieveCredentialsPage && !tenantPage) {
				return this.renderAdminContent();
			}

			if (!adminPage && !retrieveCredentialsPage && !tenantPage) {
				return this.renderOtherContent();
			}

			if (!adminPage && !retrieveCredentialsPage && tenantPage) {
				return this.renderTenantContent();
			}

			if (!adminPage && retrieveCredentialsPage && !tenantPage) {
				return this.renderRetrieveCredentialsContent();
			}
		}

		if (this.props.location.pathname === SSO_AUTH_REDIRECT_LINK) {
			return this.renderSSOContent();
		}
		return this.renderLoginContent('v-container v-login');
	}

	onHandleError = (error) => {
		if (error.message === 'ResizeObserver loop limit exceeded') {
			return;
		}
		if (
			error?.name === 'TypeError' &&
			error?.message.includes("Cannot read property 'querySelectorAll' of null")
		) {
			return;
		}
		localStorage.setItem('lastException', error);
		localStorage.setItem(
			'lastExceptionMessage',
			error ? error.stack : 'There is no stack...'
		);
		reportErrorDetails(error, error.message, () => {
			if (process.env.ENABLE_ERROR_REDIRECT) {
				window.onbeforeunload = null; // don't prompt on error
				history.push('/error');
			}
		});
	};

	render() {
		const {
			modal: { modalName },
		} = this.props;

		const isSupportLiveChatDisabled = isFeatureEnabled({
			enabledFeatures: this.props.featureFlags,
			feature: FeatureFlags.disableSupportLiveChat,
		});

		let modal = null;

		if (modalName === ABOUT_ICM_MODAL) {
			modal = this.renderAboutICMModal(modalName);
		} else if (modalName === EDIT_PROFILE_MODAL) {
			modal = <EditProfile />;
		} else if (modalName === MODEL_THEMES_MODAL) {
			modal = <ModelThemeModalComponent />;
		} else if (modalName === MAINTENANCE_MODAL) {
			modal = this.renderMaintenanceModal(modalName);
		} else if (this.props.auth.showAuthModal && !this.props.auth.loginPage) {
			modal = this.renderAuthModal();
		}

		return (
			<RouterProvider>
				<ExitLinkUpdater />
				<ErrorCatcher
					reportErrors={isReportErrorDetailEnabled()}
					onHandleError={this.onHandleError}
				>
					<div
						onClick={(e) => {
							this.globalOnClick(e);
						}}
						key={this.props.locale.localeCode}
					>
						{!isSupportLiveChatDisabled && <Intercom />}
						{this.renderContent()}
						{modal}
						<AssetReloadDialog />
						<RouteLeaveDialog />
					</div>
				</ErrorCatcher>
			</RouterProvider>
		);
	}
}

const RouteLeaveDialog = () => {
	const { confirmationDialog, resumeRouteChange, cancelRouteChange } =
		useRouteBlockingDialog();
	const intl = useIntl();
	return confirmationDialog ? (
		<ConfirmModal
			title={intl.formatMessage({ id: 'CONFIRM' })}
			message={confirmationDialog.message}
			primaryButtonText={intl.formatMessage({ id: 'CONFIRM' })}
			secondaryButtonText={intl.formatMessage({ id: 'CANCEL' })}
			onFinishHandler={(confirmed) => {
				if (confirmed) {
					resumeRouteChange();
				} else {
					cancelRouteChange();
				}
			}}
		/>
	) : null;
};
