/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import defaultMessages from '../localizations/messages.properties';

// table names
import table_en from '../localizations/systemTables/tableNames.properties';
import table_de from '../localizations/systemTables/tableNames_de.properties';
import table_es from '../localizations/systemTables/tableNames_es.properties';
import table_fr from '../localizations/systemTables/tableNames_fr.properties';
import table_hu from '../localizations/systemTables/tableNames_hu.properties';
import table_it from '../localizations/systemTables/tableNames_it.properties';
import table_ja from '../localizations/systemTables/tableNames_ja.properties';
import table_ko from '../localizations/systemTables/tableNames_ko.properties';
import table_nl from '../localizations/systemTables/tableNames_nl.properties';
import table_pt from '../localizations/systemTables/tableNames_pt.properties';
import table_sv from '../localizations/systemTables/tableNames_sv.properties';
import table_tr from '../localizations/systemTables/tableNames_tr.properties';
import table_zh from '../localizations/systemTables/tableNames_zh-cn.properties';
import table_zh_TW from '../localizations/systemTables/tableNames_zh-tw.properties';
import table_pl from '../localizations/systemTables/tableNames_pl.properties';
import table_sk from '../localizations/systemTables/tableNames_sk.properties';

// column names
import column_en from '../localizations/systemTables/columnNames.properties';
import column_de from '../localizations/systemTables/columnNames_de.properties';
import column_es from '../localizations/systemTables/columnNames_es.properties';
import column_fr from '../localizations/systemTables/columnNames_fr.properties';
import column_hu from '../localizations/systemTables/columnNames_hu.properties';
import column_it from '../localizations/systemTables/columnNames_it.properties';
import column_ja from '../localizations/systemTables/columnNames_ja.properties';
import column_ko from '../localizations/systemTables/columnNames_ko.properties';
import column_nl from '../localizations/systemTables/columnNames_nl.properties';
import column_pt from '../localizations/systemTables/columnNames_pt.properties';
import column_sv from '../localizations/systemTables/columnNames_sv.properties';
import column_tr from '../localizations/systemTables/columnNames_tr.properties';
import column_zh from '../localizations/systemTables/columnNames_zh-cn.properties';
import column_zh_TW from '../localizations/systemTables/columnNames_zh-tw.properties';
import column_pl from '../localizations/systemTables/columnNames_pl.properties';
import column_sk from '../localizations/systemTables/columnNames_sk.properties';

// calendar names
import calendar_en from '../localizations/systemTables/calendarNames.properties';
import calendar_de from '../localizations/systemTables/calendarNames_de.properties';
import calendar_es from '../localizations/systemTables/calendarNames_es.properties';
import calendar_fr from '../localizations/systemTables/calendarNames_fr.properties';
import calendar_hu from '../localizations/systemTables/calendarNames_hu.properties';
import calendar_it from '../localizations/systemTables/calendarNames_it.properties';
import calendar_ja from '../localizations/systemTables/calendarNames_ja.properties';
import calendar_ko from '../localizations/systemTables/calendarNames_ko.properties';
import calendar_nl from '../localizations/systemTables/calendarNames_nl.properties';
import calendar_pt from '../localizations/systemTables/calendarNames_pt.properties';
import calendar_sv from '../localizations/systemTables/calendarNames_sv.properties';
import calendar_tr from '../localizations/systemTables/calendarNames_tr.properties';
import calendar_zh from '../localizations/systemTables/calendarNames_zh-cn.properties';
import calendar_zh_TW from '../localizations/systemTables/calendarNames_zh-tw.properties';
import calendar_pl from '../localizations/systemTables/calendarNames_pl.properties';
import calendar_sk from '../localizations/systemTables/calendarNames_sk.properties';

export const SYSTEM_TABLE_DICTIONARIES = {
	en: table_en,
	de: table_de,
	es: table_es,
	fr: table_fr,
	hu: table_hu,
	it: table_it,
	ja: table_ja,
	ko: table_ko,
	nl: table_nl,
	pt: table_pt,
	sv: table_sv,
	tr: table_tr,
	zh: table_zh,
	zh_TW: table_zh_TW,
	pl: table_pl,
	sk: table_sk,
};
export const SYSTEM_COLUMN_DICTIONARIES = {
	en: column_en,
	de: column_de,
	es: column_es,
	fr: column_fr,
	hu: column_hu,
	it: column_it,
	ja: column_ja,
	ko: column_ko,
	nl: column_nl,
	pt: column_pt,
	sv: column_sv,
	tr: column_tr,
	zh: column_zh,
	zh_TW: column_zh_TW,
	pl: column_pl,
	sk: column_sk,
};
export const SYSTEM_CALENDAR_DICTIONARIES = {
	en: calendar_en,
	de: calendar_de,
	es: calendar_es,
	fr: calendar_fr,
	hu: calendar_hu,
	it: calendar_it,
	ja: calendar_ja,
	ko: calendar_ko,
	nl: calendar_nl,
	pt: calendar_pt,
	sv: calendar_sv,
	tr: calendar_tr,
	zh: calendar_zh,
	zh_TW: calendar_zh_TW,
	pl: calendar_pl,
	sk: calendar_sk,
};

export const REVERSED_SYSTEM_COLUMN_DICTIONARIES = {
	en: reverse(SYSTEM_COLUMN_DICTIONARIES.en),
	de: reverse(SYSTEM_COLUMN_DICTIONARIES.de),
	es: reverse(SYSTEM_COLUMN_DICTIONARIES.es),
	fr: reverse(SYSTEM_COLUMN_DICTIONARIES.fr),
	it: reverse(SYSTEM_COLUMN_DICTIONARIES.it),
	ja: reverse(SYSTEM_COLUMN_DICTIONARIES.ja),
	ko: reverse(SYSTEM_COLUMN_DICTIONARIES.ko),
	nl: reverse(SYSTEM_COLUMN_DICTIONARIES.nl),
	pt: reverse(SYSTEM_COLUMN_DICTIONARIES.pt),
	sv: reverse(SYSTEM_COLUMN_DICTIONARIES.sv),
	tr: reverse(SYSTEM_COLUMN_DICTIONARIES.tr),
	zh: reverse(SYSTEM_COLUMN_DICTIONARIES.zh),
	zh_TW: reverse(SYSTEM_COLUMN_DICTIONARIES.zh_TW),
	pl: reverse(SYSTEM_COLUMN_DICTIONARIES.pl),
	sk: reverse(SYSTEM_COLUMN_DICTIONARIES.sk),
};

function reverse(dictionary) {
	const reversed = {};
	for (const prop in dictionary) {
		if (dictionary.hasOwnProperty(prop)) {
			reversed[dictionary[prop]] = prop;
		}
	}
	return reversed;
}

function translations() {
	const conversion = {};
	for (const ln in SYSTEM_CALENDAR_DICTIONARIES) {
		if (SYSTEM_CALENDAR_DICTIONARIES.hasOwnProperty(ln)) {
			const values = SYSTEM_CALENDAR_DICTIONARIES[ln];
			for (const key in values) {
				if (values.hasOwnProperty(key)) {
					conversion[values[key]] = key;
				}
			}
		}
	}
	return conversion;
}

export function BuildSystemTranslations(code) {
	return {
		tableNames: SYSTEM_TABLE_DICTIONARIES[code],
		columnNames: SYSTEM_COLUMN_DICTIONARIES[code],
		calendarNames: SYSTEM_CALENDAR_DICTIONARIES[code],
		reversedTableNames: reverse(SYSTEM_TABLE_DICTIONARIES[code]),
		reversedColumnNames: reverse(SYSTEM_COLUMN_DICTIONARIES[code]),
		reversedCalendarNames: reverse(SYSTEM_CALENDAR_DICTIONARIES[code]),
		calendarNamesTranslations: translations(),
	};
}

export const LOCALE_DEFAULT_CODE = 'en';
export const LOCALE_DEFAULT_MESSAGES = defaultMessages;
export const LOCALE_DEFAULT_SYSTEM_TRANSLATIONS =
	BuildSystemTranslations(LOCALE_DEFAULT_CODE);
