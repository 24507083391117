/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { BuildSystemTranslations } from '../constants/localeDefaults';

import * as CultureAPIs from 'icm-rest-client/lib/controllers/culture';
import { addLocaleData } from 'react-intl';
import moment from 'moment';

export default class LocaleService {
	static getLocaleCode() {
		return CultureAPIs.get();
	}

	static setLocaleInfo(resolve, localeData, messages, localeCode, shortCode) {
		addLocaleData(localeData);
		moment.locale(localeCode);
		resolve({
			messages,
			systemTranslations: BuildSystemTranslations(shortCode),
		});
	}

	static getMessages(localeCode) {
		return new Promise((resolve) => {
			const shortCode = localeCode.split('-')[0];
			switch (shortCode) {
				case 'de': // German
					require.ensure([], () => {
						const messages = require('../localizations/messages_de.properties');
						const localeData = require('react-intl/locale-data/de');
						require('moment/locale/de');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'pl': // Polish
					require.ensure([], () => {
						const messages = require('../localizations/messages_pl.properties');
						const localeData = require('react-intl/locale-data/pl');
						require('moment/locale/pl');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'es': // Spanish
					require.ensure([], () => {
						const messages = require('../localizations/messages_es.properties');
						const localeData = require('react-intl/locale-data/es');
						require('moment/locale/es');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'fr': // French
					if (localeCode === 'fr-CA') {
						require.ensure([], () => {
							const messages = require('../localizations/messages_fr.properties');
							const localeData = require('react-intl/locale-data/fr');
							require('moment/locale/fr-ca');
							this.setLocaleInfo(
								resolve,
								localeData,
								messages,
								localeCode,
								shortCode
							);
						});
					} else {
						require.ensure([], () => {
							const messages = require('../localizations/messages_fr.properties');
							const localeData = require('react-intl/locale-data/fr');
							require('moment/locale/fr');
							this.setLocaleInfo(
								resolve,
								localeData,
								messages,
								localeCode,
								shortCode
							);
						});
					}
					break;
				case 'it': // Italian
					require.ensure([], () => {
						const messages = require('../localizations/messages_it.properties');
						const localeData = require('react-intl/locale-data/it');
						require('moment/locale/it');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'ja': // Japanese
					require.ensure([], () => {
						const messages = require('../localizations/messages_ja.properties');
						const localeData = require('react-intl/locale-data/ja');
						require('moment/locale/ja');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'ko': // Korean
					require.ensure([], () => {
						const messages = require('../localizations/messages_ko.properties');
						const localeData = require('react-intl/locale-data/ko');
						require('moment/locale/ko');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'nl': // Netherlands
					require.ensure([], () => {
						const messages = require('../localizations/messages_nl.properties');
						const localeData = require('react-intl/locale-data/nl');
						require('moment/locale/nl');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'pt': // Portuguese
					require.ensure([], () => {
						const messages = require('../localizations/messages_pt.properties');
						const localeData = require('react-intl/locale-data/pt');
						require('moment/locale/pt');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'sv': // Swedish
					require.ensure([], () => {
						const messages = require('../localizations/messages_sv.properties');
						const localeData = require('react-intl/locale-data/sv');
						require('moment/locale/sv');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'tr': // Turkish
					require.ensure([], () => {
						const messages = require('../localizations/messages_tr.properties');
						const localeData = require('react-intl/locale-data/tr');
						require('moment/locale/tr');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'zh': // Chinese - Mandarin
					if (localeCode.toLowerCase().includes('zh-hant')) {
						require.ensure([], () => {
							const messages = require('../localizations/messages_zh_TW.properties');
							const localeData = require('react-intl/locale-data/zh');
							require('moment/locale/zh-tw');
							this.setLocaleInfo(
								resolve,
								localeData,
								messages,
								localeCode,
								'zh_TW'
							);
						});
					} else {
						require.ensure([], () => {
							const messages = require('../localizations/messages_zh.properties');
							const localeData = require('react-intl/locale-data/zh');
							require('moment/locale/zh-cn');
							this.setLocaleInfo(
								resolve,
								localeData,
								messages,
								localeCode,
								shortCode
							);
						});
					}
					break;
				case 'gl': // RPX
					require.ensure([], () => {
						const messages = require('../localizations/messages_gl.properties');
						const localeData = require('react-intl/locale-data/en');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'hu': // Hungarian
					require.ensure([], () => {
						const messages = require('../localizations/messages_hu.properties');
						const localeData = require('react-intl/locale-data/hu');
						require('moment/locale/hu');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'sk': // Slovak
					require.ensure([], () => {
						const messages = require('../localizations/messages_sk.properties');
						const localeData = require('react-intl/locale-data/sk');
						require('moment/locale/sk');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
				case 'en': // English
					if (localeCode === 'en-GB') {
						require.ensure([], () => {
							const messages = require('../localizations/messages.properties');
							const localeData = require('react-intl/locale-data/en');
							require('moment/locale/en-gb');
							this.setLocaleInfo(
								resolve,
								localeData,
								messages,
								localeCode,
								'en'
							);
						});
					} else {
						require.ensure([], () => {
							const messages = require('../localizations/messages.properties');
							const localeData = require('react-intl/locale-data/en');
							this.setLocaleInfo(
								resolve,
								localeData,
								messages,
								localeCode,
								shortCode
							);
						});
					}
					break;
				default:
					require.ensure([], () => {
						const messages = require('../localizations/messages.properties');
						const localeData = require('react-intl/locale-data/en');
						this.setLocaleInfo(
							resolve,
							localeData,
							messages,
							localeCode,
							shortCode
						);
					});
					break;
			}
		});
	}
}
