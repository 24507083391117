/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import moment from 'moment';
import { SQL_MAX_DATE } from 'constants/rowViewer/defaultDataValues';
import * as DateConstants from 'constants/dateConstants';

export const MinDateTime = moment.utc(DateConstants.MIN_DATE);
export const MaxDateTime = moment.utc(DateConstants.MAX_DATE);

export function isMinTime(time) {
	const momentTime = moment.utc(time);
	return momentTime !== null && !momentTime.isAfter(MinDateTime);
}

export function isMaxTime(time) {
	const momentTime = moment.utc(time);
	return momentTime !== null && !momentTime.isBefore(MaxDateTime);
}

/**
 * @export
 * @param {moment} time - a utc moment object that needs to be converted to other time zone
 * @returns a moment object.
 */
export function changeTimeZoneToLocal(time) {
	const offset = new Date(time.toDate()).getTimezoneOffset();
	return time.add(offset, 'minute');
}

/**
 * @export
 * @param {moment} time - a local moment object that needs to be converted to UTC time zone
 * @returns a moment object.
 */
export function changeTimeZoneToUTC(time) {
	const offset = new Date(time.toDate()).getTimezoneOffset();
	return time.add(-offset, 'minute');
}
/**
 * @param {*} levelId - Calculation Level of calendar
 * @param {*} calendar - Locked calendar
 * @return a moment object
 */
export function getFirstOpenDate(levelId, calendar) {
	const index = calendar.definition.levels.indexOf(levelId);
	const levelLockedDate = calendar.levelLockedDates[index];
	return levelLockedDate === DateConstants.MIN_DATE
		? moment.utc(calendar.calendarStart)
		: moment.utc(levelLockedDate).add(1, 'd');
}

/*
 * Given a date value, return a string formatted in the specified format
 * return an empty string if the provided dateValue is undefined/null/empty
 */
export function formatDateTime(dateValue, formatString, isUTC) {
	if (!dateValue) {
		return '';
	}

	if (isUTC) {
		return moment.utc(dateValue).format(formatString);
	}

	return moment(dateValue).format(formatString);
}

// Given a date, return as localized format similar to YYYY-MM-DD HH:MM:SS AM/PM
export function formatLocaleDateTimeForCSV(date) {
	const formatString = moment.localeData().longDateFormat('LLL');
	const formatted = moment.utc(date).local().format(formatString);
	return `"${formatted}"`;
}

/*
 * Given a date value, return a string formatted relative to today, Eg. "Yesterday at 10:45 AM"
 * If dateValue is earlier than a week, it will return a string in the format: "[long date] [time]"
 * Return an empty string if the provided dateValue is undefined/null/empty
 */
export function getCalendarDateTime(dateValue) {
	if (!dateValue) {
		return '';
	}

	// fix incorrect times - avoid converting dates to local time more than once
	dateValue = new Date(dateValue);

	/*
	 * workaround for the default fallback format, which is 'L', but we want 'LLL'
	 * Notice we return a string that refers to the local time
	 */
	const calendarValue = moment.utc(dateValue).local().format('LLL');
	const fallbackFormatString = moment.localeData().calendar('sameElse');
	const fallbackFormattedDate = moment
		.utc(dateValue)
		.local()
		.format(fallbackFormatString);

	return calendarValue === fallbackFormattedDate
		? moment.utc(dateValue).local().format('LLL')
		: calendarValue;
}

export function addZero(number, milliseconds) {
	if (milliseconds === true) {
		let newNum = number;
		if (number < 100) {
			newNum = `0${newNum}`;
		}
		if (number < 10) {
			newNum = `0${newNum}`;
		}
		return newNum;
	}
	if (number < 10) {
		return `0${number}`;
	} else {
		return number;
	}
}

/*
 * Handles two formats of input: 1. An integer representing a duration in MS; 2. a string of the format hh:mm:ss.ss.
 * return a string in a specified format, or a default format('HH:mm:ss.SS') if none provided
 */
export function formatDuration(duration, hideMilliseconds) {
	if (isNaN(duration)) {
		const parts = duration.split(':');
		if (parts.length === 3) {
			let hrs = 0;
			const partsHrs = parts[0].split('.');
			if (partsHrs.length === 2) {
				const days = parseInt(partsHrs[0], 10);
				hrs = days * 24 + parseInt(partsHrs[1], 10);
			} else {
				hrs = addZero(parseInt(parts[0], 10));
			}
			const mins = addZero(parseInt(parts[1], 10));
			const secs = addZero(parseFloat(parts[2], 10).toFixed(2));
			if (!(isNaN(hrs) || isNaN(mins) || isNaN(secs))) {
				return `${hrs}:${mins}:${secs}`;
			}
		}
	}
	const dur = moment.duration(duration);
	const days = dur.days();
	let hrs = dur.hours();
	const mins = addZero(dur.minutes());
	let secs = `${dur.seconds()}.${addZero(dur.milliseconds(), true)}`;
	secs = parseFloat(secs, 10).toFixed(2);
	if (hideMilliseconds === true) {
		secs = addZero(secs.split('.')[0]);
	} else {
		secs = addZero(secs);
	}
	if (days > 0) {
		hrs = days * 24 + hrs;
	} else {
		hrs = addZero(hrs);
	}
	return `${hrs}:${mins}:${secs}`;
}

// Determine if target is inbetween start and end. All params are strings
export function inclusiveInbetween(target, start, end) {
	const t = moment.utc(target);
	const s = moment.utc(start);
	const e = moment.utc(end);
	return t >= s && t <= e;
}

// Given a date string, extract the date part only and return a UTC string for the provided date string with 00:00:00 as time
export function getUTCDate(dateString) {
	const localDate = moment.utc(dateString);
	return moment
		.utc([localDate.year(), localDate.month(), localDate.date()])
		.toISOString();
}

// Given a date string, extract the date part only and return a UTC string for the provided date with 00:00:00 as time
export function stripTime(dateTime) {
	const localDate = moment.utc(dateTime);
	return moment
		.utc([localDate.year(), localDate.month(), localDate.date()])
		.toDate();
}

export function formatDisplayDate(data, format, isUTC) {
	if (data === SQL_MAX_DATE) {
		return formatDateTime(data, format || 'LL', true);
	}
	return formatDateTime(data, format || 'LL', isUTC);
}

/*
 * Determines if a string matches any of the ISO-8601 string formats or at least if it
 * can be parsed by Date.parse()
 */
export function isDateString(dateString) {
	return moment.utc(dateString).isValid();
}

export function momentToUTCDate(momentDate) {
	/*
	 * momentDate is an instance of Moment. When we get a native JavaScript date
	 * object from Moment, it returns the date in local date/time. But we need the UTC
	 * date/time.
	 */
	const localTime = momentDate.toDate();
	// so create a new date instance using the UTC date
	const utcDate = new Date(
		Date.UTC(
			localTime.getUTCFullYear(),
			localTime.getUTCMonth(),
			localTime.getUTCDate(),
			localTime.getUTCHours(),
			localTime.getUTCMinutes(),
			localTime.getUTCSeconds()
		)
	);
	return utcDate;
}

export function formatDateLabel(item) {
	return {
		...item,
		label:
			item.key === 'NULL'
				? item.label
				: formatDisplayDate(item.label, 'L', true),
	};
}

export function momentToSqlDate(momentDate) {
	return momentDate.format('YYYY-MM-DD');
}
