/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

export default class RequiredTextValueRule {
	static propShape = {
		errorMessage: PropTypes.string,
		errorKey: PropTypes.string,
	};

	constructor(errorMessage, errorKey) {
		Object.assign(this, { errorMessage });
		this.errorKey = errorKey;
	}

	validate(val) {
		const invalid = !val || !val.trim();

		return {
			invalid,
			error: invalid ? this.errorMessage : null,
			errorKey: this.errorMessage
				? null
				: this.errorKey || 'VALIDATION_REQUIRED_GENERIC',
		};
	}
}
