/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as colorActionTypes from '../constants/actionTypes';
import CustomColorService from 'services/customColorService';

const handlers = {};

function getInitialState() {
	return {
		customColors: [
			'#FFFFFF',
			'#FFFFFF',
			'#FFFFFF',
			'#FFFFFF',
			'#FFFFFF',
			'#FFFFFF',
			'#FFFFFF',
		],
	};
}

const initialState = getInitialState();

export function colors(state = initialState, action) {
	const { type } = action;
	if (!handlers[type]) {
		return state;
	}
	return handlers[type](state, action);
}

handlers[colorActionTypes.COLORS_UPDATE_CUSTOM] = (
	state,
	{ payload: { customColors } }
) => ({
	...state,
	customColors,
});
handlers[colorActionTypes.COLORS_GET_CUSTOM] = (
	state,
	{ payload: { customColorDTO } }
) => {
	const customColors = customColorDTO.colors;
	// If the API doesn't have a full set of colors stored, just use the defaults set in "getInitialState"
	if (customColors.length === CustomColorService.MAX_COLORS_SIZE) {
		return {
			...state,
			customColors,
		};
	} else {
		return state;
	}
};

handlers[colorActionTypes.LOGOUT] = (state) => state;
