/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import LocaleHelper from 'helpers/localeHelper';
import { restApiError, alert404RestApiError } from 'actions/messageActions';
import R from 'ramda';

export const authURL = `${ICM_CONFIG.AUTH_URL}/services`;

/*
 * DEV_SSO_URL should only be set for local development when CORS is
 * set up properly on tenant services
 */
export const ssoURL = process.env.DEV_SSO_URL
	? `${process.env.DEV_SSO_URL}/services`
	: '/services';

export function getApiRootUrl() {
	return ICM_CONFIG.API_URL;
}

// TODO-psachs: Import this instead, once shifted to axios entirely.
const errorMessageFormatter = (response) => {
	if (response.data && (response.data.Message || response.data.message)) {
		return response.data.Message || response.data.message;
	} else if (typeof response.data === 'string') {
		return response.data;
	} else if (response.data && response.data[0]) {
		const errItems = response.data[0].errorItems;
		return R.isNil(errItems) ? response.data : errItems[0].errorMessage;
	} else if (
		!response.data &&
		response.error &&
		(response.error.message || response.error.Message)
	) {
		return response.error.message || response.error.Message;
	} else if (
		response.data &&
		response.data.errorItems &&
		response.data.errorItems.length > 0
	) {
		return response.data.errorItems[0].errorMessage;
	}
	return LocaleHelper.formatMessage(window.store.getState(), 'UNKNOWN_ERROR');
};

export function defaultRESTAPIErrorHandler(error) {
	let newError = error;

	if (newError.config.responseType === 'arraybuffer') {
		if (newError.response) {
			newError = {
				...newError,
				response: {
					...newError.response,
					data: JSON.parse(new TextDecoder().decode(newError.response.data)),
				},
			};
		}
	}

	const err =
		newError?.error ??
		newError.response?.data?.error ??
		newError.response?.data?.Message;
	const requestId =
		newError.response && newError.response.headers
			? newError.response.headers['x-request-id']
			: undefined;
	if (newError.response) {
		window.store.dispatch(
			restApiError(
				err,
				newError.response.status,
				'',
				null,
				errorMessageFormatter(newError.response),
				undefined,
				requestId
			)
		);
	}
	return Promise.reject(newError);
}

export function alert404RESTAPIErrorHandler(error) {
	if (error.response) {
		window.store.dispatch(
			alert404RestApiError(
				error.error,
				error.response.status,
				'',
				null,
				errorMessageFormatter(error.response)
			)
		);
	}
	return Promise.reject(error);
}

export function buildFilterString(filterMap?: Map<string, string>) {
	let query = '';
	if (filterMap) {
		let firstParam = true;
		for (const key of filterMap.keys()) {
			const val = filterMap.get(key);
			if (val) {
				query = firstParam ? `${query}${key}=${val}` : `${query};${key}=${val}`;
				firstParam = false;
			}
		}
	}
	return query === '' ? undefined : query;
}
