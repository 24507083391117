// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

import { AxiosInstance } from 'axios';

export let axiosInstance: AxiosInstance | null = null;

export default function ({ axios }: { axios: AxiosInstance }) {
	axiosInstance = axios;
}
