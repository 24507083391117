/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { GET_DOCUMENTATION_BUCKET_URL } from '../constants/actionTypes';
import { createReducer } from 'redux-act';

export type StateShape = {
	documentationBucketURL: string;
};

const defaultState: StateShape = {
	documentationBucketURL: '',
};

const reducer = createReducer<StateShape>({}, defaultState);

reducer.on(
	GET_DOCUMENTATION_BUCKET_URL,
	(state, { documentationBucketURL }) => ({
		...state,
		documentationBucketURL,
	})
);

export const documentation = reducer;
