// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 * Get all trees
 * url: `api/v1/portalaccesstrees`
 */
export function getAllTrees(
	query?: { filter?: string; orderBy?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'portalaccesstrees',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.Workflow.WorkflowTreeDTO[]
	>(request);
}

/**
 * Get all trees
 * url: `api/v1/portalaccesstrees/${id}`
 */
export function getTree(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `portalaccesstrees/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.Workflow.WorkflowTreeDTO[]
	>(request);
}

/**
 * Create a new tree
 * url: `api/v1/portalaccesstrees`
 */
export function addTree(
	tree: Varicent.RESTAPI.v1.DTOs.Workflow.WorkflowTreeDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'portalaccesstrees',
		apiVersion: 'v1' as const,
		data: tree,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Workflow.WorkflowTreeDTO>(
		request
	);
}

/**
 * Delete a tree
 * url: `api/v1/portalaccesstrees/${id}`
 */
export function deleteTree(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `portalaccesstrees/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Update a tree
 * url: `api/v1/portalaccesstrees/${id}`
 */
export function putTree(
	id: Varicent.ID,
	tree: Varicent.RESTAPI.v1.DTOs.Workflow.WorkflowTreeDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `portalaccesstrees/${id}`,
		apiVersion: 'v1' as const,
		data: tree,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Workflow.WorkflowTreeDTO>(
		request
	);
}

/**
 * Run tree builder asynchronously
 * url: `api/v1/rpc/treebuilder`
 */
export function generateTree(
	dto: Varicent.RESTAPI.v1.DTOs.PortalAccess.TreeBuilderDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/treebuilder',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<{ [key: string]: string }>(request);
}
