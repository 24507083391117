/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_OPEN_DELETE_CONFIRMATION = 'MODAL_OPEN_DELETE_CONFIRMATION';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MODAL_TOGGLE_EXPAND = 'MODAL_TOGGLE_EXPAND';
