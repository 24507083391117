/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	SHOW_BANNER_MESSAGE,
	CLEAR_BANNER_MESSAGE,
	LOGOUT,
} from '../constants/actionTypes';

export function bannerMessage(state = {}, action) {
	const { type, payload } = action;

	switch (type) {
		case SHOW_BANNER_MESSAGE:
			return {
				...state,
				type: 'show_message',
				banners: payload.banners,
				message: payload.message,
				actionLinkLabel: payload.actionLinklabel,
				messagesNeedTranslation: payload.messagesNeedTranslation,
				action: payload.action,
			};
		case CLEAR_BANNER_MESSAGE:
			return {
				...state,
				type: 'clear_message',
				banners: payload
					? state.banners.filter((b) => b !== payload.banner)
					: [],
				message: undefined,
			};
		case LOGOUT:
			return {
				...state,
				type: 'clear_message',
				banners: [],
				message: undefined,
				actionLinkLabel: '',
				action: null,
			};
		default:
			return state;
	}
}
