/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import { connect } from 'react-redux';
import Routes from '../routes';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { setLocale } from 'actions/localeActions';
import { IGlobalStateShape } from 'reducers';
import { selectFeatureFlags } from 'utils/featureUtils';
import { Modal } from 'react-bootstrap';
import VSpinner from 'components/common/spinnerComponent';
import { Helmet } from 'react-helmet';
import { IntlProvider, injectIntl } from 'react-intl';
import { getThemeForModel } from 'actions/modelThemeActions';
import CoreProvider from 'icm-core/lib/provider';
import { FeatureFlagProvider } from 'presenter-flexible-components/lib/utils/featureFlagContext';
import { ToastProvider } from '@varicent/components';
import { css } from 'emotion';
import styleVars from 'styles/config/default';
import { RelativePortalProvider } from 'components/layouts';

const mapStateToProps = (state: IGlobalStateShape) => ({
	locale: state.locale,
	authentication: state.authentication,
	featureFlags: selectFeatureFlags(state),
});

const mapDispatchToProps = {
	setLocale,
	getThemeForModel,
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

class AppContentContainer extends React.PureComponent<
	ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
> {
	componentDidMount() {
		this.props.setLocale();
		this.getModelThemeIfRequired();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.authentication.database !== this.props.authentication.database
		) {
			this.getModelThemeIfRequired();
		}
	}

	getModelThemeIfRequired = () => {
		this.props.getThemeForModel(this.props.authentication.database);
	};

	render() {
		const {
			locale: { localeCode, messages },
		} = this.props;

		if (!localeCode) {
			return (
				<Modal
					show
					animation={false}
					backdrop
					dialogClassName="v-spinner-modal"
				>
					<VSpinner
						size={90}
						thickness={10}
						color="#5596e6"
						backgroundColor="rgb(182,182,182)"
					/>
				</Modal>
			);
		}

		return (
			<DndProvider backend={HTML5Backend}>
				<FeatureFlagProvider>
					<IntlProvider locale={localeCode} messages={messages}>
						<CoreProvider>
							<RelativePortalProvider>
								<ToastProvider
									className={css`
										margin-top: ${styleVars.HeaderBarHeight};
										white-space: pre-wrap;
										z-index: 1060;

										// customize overflow so long error messages can scroll if necessary
										.bp3-toast {
											overflow: hidden;

											.bp3-toast-message {
												overflow: auto;
												height: 100%;
											}
										}
									`}
								>
									<HelmetInt />
									<Routes />
								</ToastProvider>
							</RelativePortalProvider>
						</CoreProvider>
					</IntlProvider>
				</FeatureFlagProvider>
			</DndProvider>
		);
	}
}

const HelmetInt = injectIntl(({ intl }) => {
	return (
		<Helmet>
			<html lang={intl.locale} />
		</Helmet>
	);
});

export default enhance(AppContentContainer as any);
