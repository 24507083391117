/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';

const ToolsSvg = (props) => (
	<svg viewBox="0 0 48 48" {...props}>
		<path
			d="M25.732 18.889l9.311-9.311a2.386 2.386 0 013.375 0h0a2.386 2.386 0 010 3.375l-9.311 9.311m-1.688-1.688l9.282-9.282M13.069 34.926l7.731-7.731M9.115 37.137l1.743 1.743 3.228-2.936-2.035-2.035-2.936 3.228zm18.38-6.106a6.002 6.002 0 008.213 7.506h0l-4.001-4.001a2.005 2.005 0 010-2.828h0a2.005 2.005 0 012.828 0l4.001 4.001h0a6.004 6.004 0 00-7.506-8.213L20.505 16.971a6.004 6.004 0 00-8.213-7.507h0l4.001 4.001a2.005 2.005 0 010 2.828h0a2.005 2.005 0 01-2.828 0l-4.001-4.001h0a6.004 6.004 0 007.504 8.214l10.527 10.525z"
			fill="none"
			stroke="#000"
			strokeWidth={0.72}
			strokeLinejoin="round"
			strokeMiterlimit={10}
		/>
	</svg>
);

export default ToolsSvg;
