/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from 'react-redux';
import { useEffect } from 'react';
import { SagaMiddleware, Saga } from 'redux-saga';
import { takeEvery, ActionPattern } from 'redux-saga/effects';
import { Action, StoreEnhancer } from 'redux';

export const createEnhancer: ({
	sagaMiddleware: SagaMiddleware,
}) => StoreEnhancer<any> =
	({ sagaMiddleware }) =>
	(createStore) =>
	(...args) => {
		const store = createStore(...args);
		(store as any).sagaMiddleware = sagaMiddleware;
		return store;
	};

export function useSaga<Args extends any[]>(
	f: Saga<Args>,
	sagaArgs: Args = [] as any,
	deps: any[] = []
) {
	const store = useStore();
	useEffect(() => {
		const middleware = (store as any).sagaMiddleware as
			| SagaMiddleware
			| undefined;
		if (middleware) {
			const saga = middleware.run(f as any, ...sagaArgs);
			return () => saga.cancel();
		}
		return () => {};
	}, [store, ...deps]);
}

export function useTakeEvery(
	pattern: ActionPattern,
	handler: (action: Action) => void,
	deps: any[]
) {
	useSaga(
		function* useSagaFunc() {
			yield takeEvery(pattern, handler);
		},
		[],
		deps
	);
}
