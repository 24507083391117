/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as MiddlewareBypass from './middlewareBypassActionTypes';

export const LOCALE_REQUESTED = 'LOCALE_REQUESTED';
export const LOCALE_RECEIVED = MiddlewareBypass.LOCALE_RECEIVED;
export const LOCALE_ERROR = 'LOCALE_ERROR';
