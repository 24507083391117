/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/**
 * This is a middleware to associate the current locale (messages) with each action.
 * This allows the reducers to access localization messages without calling store.getState().
 * Try not to use these.
 */

export default (store) => (next) => (action) => {
	const messages = store.getState().locale && store.getState().locale.messages;

	const newAction = {
		...action,
		meta: {
			...action.meta,
			localeMessagesState: {
				locale: {
					messages,
				},
			},
		},
	};
	return next(newAction);
};
