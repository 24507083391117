// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
	configurableRequestRaw,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 *
 * url: `api/v1/liveactivities`
 */
export function getAll(
	query?: {
		filter?: string;
		orderBy?: string;
		limit?: number;
		offset?: number;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'liveactivities',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<Varicent.RESTAPI.v1.DTOs.LiveActivityDTO[]>(
		request
	);
}

/**
 *
 * url: `api/v1/payee/liveactivities`
 */
export function getAllForPayee(
	query?: {
		filter?: string;
		orderBy?: string;
		limit?: number;
		offset?: number;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/liveactivities',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<Varicent.RESTAPI.v1.DTOs.LiveActivityDTO[]>(
		request
	);
}

/**
 *
 * url: `api/v1/liveactivities/${id}`
 */
export function get(
	id: Varicent.ID,
	query?: { payeeID?: Varicent.ID },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `liveactivities/${id}`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.LiveActivityDTO>(request);
}

/**
 *
 * url: `api/v1/payee/liveactivities/${id}`
 */
export function getForPayee(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/liveactivities/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.LiveActivityDTO>(request);
}

/**
 *
 * url: `api/v1/liveactivities/${id}`
 */
export function putCancel(
	id: Varicent.ID,
	liveActivityDTO: Varicent.RESTAPI.v1.DTOs.LiveActivityDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `liveactivities/${id}`,
		apiVersion: 'v1' as const,
		data: liveActivityDTO,
		...options,
	};
	return configurableRequest<void>(request);
}
