/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import VReactComponent from 'components/common/reactComponent';
import { Manager, Reference, Popper } from 'react-popper';
import ReactDOM from 'react-dom';

export default class VTooltip extends VReactComponent {
	static propTypes = {
		id: PropTypes.string,
		children: PropTypes.node,
		text: PropTypes.node,
		placement: PropTypes.oneOf(['left', 'top', 'right', 'bottom']),
		breakType: PropTypes.oneOf(['break-all', 'break-word']),
		isDragging: PropTypes.bool,
		boundariesElement: PropTypes.string,
	};

	static defaultProps = {
		placement: 'left',
		breakType: 'break-word',
		boundariesElement: 'viewport',
	};

	state = { isOpen: false };

	setOpen = () => this.setState({ isOpen: true });

	setNotOpen = () => this.setState({ isOpen: false });

	componentDidUpdate() {
		if (this.scheduleUpdate) {
			this.scheduleUpdate();
		}
	}

	renderChildren = (ref) => {
		const child = React.Children.only(this.props.children);
		return React.cloneElement(child, {
			ref: (node) => {
				ref(node);
				if (typeof child.ref === 'function') {
					child.ref(node);
				}
			},
			onMouseEnter: this.setOpen,
			onMouseLeave: this.setNotOpen,
		});
	};

	render() {
		const {
			text,
			id,
			breakType,
			isDragging,
			boundariesElement,
			placement: propPlacement,
		} = this.props;
		const { isOpen } = this.state;

		return (
			<Manager>
				<Reference>{({ ref }) => this.renderChildren(ref)}</Reference>
				{isOpen &&
					text &&
					!isDragging &&
					ReactDOM.createPortal(
						<Popper
							placement={propPlacement}
							modifiers={{
								preventOverflow: { boundariesElement },
							}}
						>
							{({ ref, style, placement, scheduleUpdate, arrowProps }) => {
								this.scheduleUpdate = scheduleUpdate;
								return (
									<div
										ref={ref}
										style={style}
										data-placement={placement}
										className={`fade in tooltip ${placement}`}
										onMouseEnter={this.setOpen}
										onMouseLeave={this.setNotOpen}
										data-test="fade-in-tooltip"
									>
										<div
											style={{ wordBreak: breakType }}
											id={id}
											className="tooltip-inner"
										>
											{text}
										</div>
										<div
											ref={arrowProps.ref}
											style={arrowProps.style}
											className="tooltip-arrow"
										/>
									</div>
								);
							}}
						</Popper>,
						document.querySelector('body')
					)}
			</Manager>
		);
	}
}
