/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

export default class EmailTextRule {
	static propShape = {
		errorMessage: PropTypes.string,
	};

	constructor(errorMessage) {
		Object.assign(this, { errorMessage });
	}

	validate(val) {
		const trimmedVal = val && val.trim();

		if (trimmedVal) {
			const invalid = !trimmedVal.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*$/);

			return {
				invalid,
				error: invalid ? this.errorMessage : undefined,
				errorKey: this.errorMessage ? undefined : 'VALIDATION_EMAIL_GENERIC',
			};
		}

		return {
			invalid: true,
			error: this.errorMessage,
			errorKey: this.errorMessage ? undefined : 'VALIDATION_EMAIL_GENERIC',
		};
	}
}
