// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
	configurableRequestRaw,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 * Get all Pulse plans
 * url: `api/v1/pulseplans`
 */
export function getAll(
	query?: { filter?: string; orderBy?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'pulseplans',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Pulse.PulsePlanDTO[]>(
		request
	);
}

/**
 * Get a Pulse plan
 * url: `api/v1/pulseplans/${id}`
 */
export function get(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `pulseplans/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Pulse.PulsePlanDTO>(
		request
	);
}

/**
 * Get all Tagged
 * url: `api/v1/pulsetagged`
 */
export function getAllTags(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'pulsetagged',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Pulse.TaggedDTO[]>(
		request
	);
}

/**
 * Add a Pulse plan.
 * url: `api/v1/pulseplans`
 */
export function post(
	planDto: Varicent.RESTAPI.v1.DTOs.Pulse.PulsePlanDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'pulseplans',
		apiVersion: 'v1' as const,
		data: planDto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Pulse.PulsePlanDTO>(
		request
	);
}

/**
 * Update a Pulse plan.
 * url: `api/v1/pulseplans`
 */
export function put(
	planDto: Varicent.RESTAPI.v1.DTOs.Pulse.PulsePlanDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: 'pulseplans',
		apiVersion: 'v1' as const,
		data: planDto,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Remove the specified Pulse plan
 * url: `api/v1/pulseplans/${id}`
 */
export function doDelete(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `pulseplans/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Get all taggable items in the current model
 * url: `api/v1/pulsetaggables`
 */
export function getTaggables(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'pulsetaggables',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Pulse.TaggableDTO[]>(
		request
	);
}

/**
 *
 * url: `api/v1/pulsetaggablesources`
 */
export function getAllTaggableSources(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'pulsetaggablesources',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.Composer.DataSourceSchemaDTO[]
	>(request);
}

/**
 * Add/Update a Tagged item. If the id is valid, it will be updated, if id <= 0 then it will be added.
 * url: `api/v1/pulsetagged`
 */
export function putTagged(
	taggedDTO: Varicent.RESTAPI.v1.DTOs.Pulse.TaggedDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: 'pulsetagged',
		apiVersion: 'v1' as const,
		data: taggedDTO,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Pulse.TaggedDTO>(request);
}

/**
 * Remove a Tagged item.
 * url: `api/v1/pulsetagged/${id}`
 */
export function deleteTagged(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `pulsetagged/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Returned Pulse report structures
 * url: `api/v1/pulsereports`
 */
export function getReports(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'pulsereports',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.Pulse.CompPlanReportTypeDTO[]
	>(request);
}

/**
 * Return payee viewable pulse reports
 * url: `api/v1/payee/pulsereports/payeeViewableReports`
 */
export function getViewablePulseReportsForPayee(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/pulsereports/payeeViewableReports',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Returned Pulse report structures
 * url: `api/v1/pulsereports/${id}`
 */
export function putReport(
	id: Varicent.ID,
	filter: Varicent.RESTAPI.v1.DTOs.QuerySourceDefinitionDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `pulsereports/${id}`,
		apiVersion: 'v1' as const,
		data: filter,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.Pulse.CompPlanReportTypeDTO[]
	>(request);
}

/**
 * Return pulse tag mapping preview
 * url: `api/v1/pulsetagmappingpreview`
 */
export function postTagMappingPreview(
	state: Varicent.RESTAPI.v1.DTOs.Preview.PulseTagPreviewStateDTO,
	query?: {
		offset?: number;
		limit?: number;
		orderBy?: string;
		filter?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'pulsetagmappingpreview',
		apiVersion: 'v1' as const,
		data: state,
		query,
		...options,
	};
	return configurableRequestRaw<Varicent.RESTAPI.v1.DTOs.ResultSetDTO>(request);
}

/**
 *
 * url: `api/v1/payee/pulsereports/${id}/data`
 */
export function getReportData(
	id: Varicent.Domain.CompPlanManager.CompPlanReportType,
	query?: { offset?: number; limit?: number },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/pulsereports/${id}/data`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/pulsereports/${id}/datacount`
 */
export function getReportDataCount(
	id: Varicent.Domain.CompPlanManager.CompPlanReportType,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/pulsereports/${id}/datacount`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/pulsereports/${id}/benchmarks`
 */
export function getBenchmarkTypes(
	id: Varicent.Domain.CompPlanManager.CompPlanReportType,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `pulsereports/${id}/benchmarks`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/pulsereports/${id}/benchmarks`
 */
export function getBenchmarkTypesForPayee(
	id: Varicent.Domain.CompPlanManager.CompPlanReportType,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/pulsereports/${id}/benchmarks`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/pulsereports/${id}/benchmarks/${source}`
 */
export function getBenchmarkData(
	id: Varicent.Domain.CompPlanManager.CompPlanReportType,
	source: string,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `pulsereports/${id}/benchmarks/${source}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/pulsereports/${id}/benchmarks/${source}`
 */
export function getBenchmarkDataForPayee(
	id: Varicent.Domain.CompPlanManager.CompPlanReportType,
	source: string,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/pulsereports/${id}/benchmarks/${source}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/pulsereports/${reportType}/calendar`
 */
export function getCalendarPeriods(
	reportType: Varicent.Domain.CompPlanManager.CompPlanReportType,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/pulsereports/${reportType}/calendar`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/pulsereports/${reportType}/isMapped`
 */
export function isReportFullyTagged(
	reportType: Varicent.Domain.CompPlanManager.CompPlanReportType,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/pulsereports/${reportType}/isMapped`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<boolean>(request);
}
