/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { AllFilterOperators } from '../../constants/filterOperators';
import R from 'ramda';
import moment from 'moment';
import { momentToSqlDate } from 'helpers/dateHelper';

/*
 * This is used to build simple filter strings. I.E.:
 * - (Equals, A, [1,2,3]) => A=1\,2\,3
 * - (GreaterThan, A, 1) => A=(1\,]
 */

export function escapeSpecialChar(filterString) {
	const regex = /([/;=\\,<>|&^$⊇])/g;
	return typeof filterString === 'string'
		? filterString.replace(regex, '/$1')
		: filterString;
}

export function toFilterString(type, left, right) {
	/* eslint no-template-curly-in-string: "off" */
	const FilterOperatorStringRepresentation = {
		NotEqual: '{L}<>{...R}',
		StartsWith: '{L}^{R}',
		EndsWith: '{L}${R}',
		Less: '{L}=[\\,{R})',
		LessOrEqual: '{L}=[\\,{R}]',
		Greater: '{L}=({R}\\,]',
		GreaterOrEqual: '{L}=[{R}\\,]',
		And: '{L}&&{R}',
		Or: '{L}||{R}',
		Range: '{L}=[{...R}]',
		Equal: '{L}={...R}',
		ContainedIn: '{L}⊇{R}',
	};

	let stringRep = FilterOperatorStringRepresentation[type];
	if (stringRep === null) {
		throw new Error(`${type} not found in string representations.`);
	}

	stringRep = stringRep.replace('{L}', left);

	if (
		type === AllFilterOperators.Range ||
		type === AllFilterOperators.Equal ||
		type === AllFilterOperators.NotEqual
	) {
		if (!(right instanceof Array)) {
			throw new Error('Right side value must be an array for list operators.');
		}

		stringRep = stringRep.replace(
			'{...R}',
			R.map(
				(v) => (moment.isMoment(v) ? momentToSqlDate(v) : escapeSpecialChar(v)),
				right
			).join('\\,')
		);
	} else {
		if (type === 'And' || type === 'Or') {
			stringRep = stringRep.replace(
				'{R}',
				moment.isMoment(right) ? momentToSqlDate(right) : right
			);
		} else {
			stringRep = stringRep.replace(
				'{R}',
				moment.isMoment(right)
					? momentToSqlDate(right)
					: escapeSpecialChar(right)
			);
		}
	}

	return stringRep;
}
