/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const Enter = 'Enter';
export const ArrowUp = 'ArrowUp';
export const ArrowDown = 'ArrowDown';
export const ArrowLeft = 'ArrowLeft';
export const ArrowRight = 'ArrowRight';
export const Space = ' ';
export const Tab = 'Tab';
export const Esc = 'Escape';
