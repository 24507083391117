/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import R from 'ramda';
import { connect } from 'react-redux';
import { compose, branch, renderNothing } from 'recompose';

export const withPermissionCheck = compose(
	connect((state) => ({
		grantedPermissions: state.permissions.grantedPermissions,
	})),
	branch(
		({ grantedPermissions, permission, allRequired }) => {
			if (R.isNil(permission)) {
				return true;
			} else if (Array.isArray(permission)) {
				if (allRequired) {
					return R.all((p) => grantedPermissions[p], permission);
				} else {
					return R.any((p) => grantedPermissions[p], permission);
				}
			} else {
				return grantedPermissions[permission];
			}
		},
		R.identity,
		renderNothing
	)
);

export default withPermissionCheck(({ children }) => <>{children}</>);
