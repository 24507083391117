/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import APIPollingHelper from 'helpers/APIPollingHelper';
import { defaultRESTAPIErrorHandler } from 'services/httpService';

import * as GlobalActionStatusAPIs from 'icm-rest-client/lib/controllers/globalActionStatus';

export default class GlobalActionService {
	static getGlobalActionStatus() {
		return GlobalActionStatusAPIs.get({
			preventDefaultRESTAPIError: true,
		}).then(
			(data) => data,
			(error) => {
				if (error.response && error.respons.status === 401) {
					APIPollingHelper.disableAndUnregisterAPIPollTimerByName(
						APIPollingHelper.headerComponentGlobalActionPollName
					);
					return Promise.resolve();
				} else {
					return defaultRESTAPIErrorHandler(error);
				}
			}
		);
	}
}
