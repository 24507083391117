/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/**
 * This middleware simply pushes the types of all actions to the ErrorLogService.
 * Actions are passed through unfiltered and unchanged.
 */

import ErrorLogService from '../services/errorLogService';

export default () => (next) => (action) => {
	if (action) {
		ErrorLogService.logAction(action.type);
	}

	next(action);
};
