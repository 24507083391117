/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { activity } from './activityReducers';
import adminsidebar from './adminSidebarReducers';
import { authentication } from './authReducers';
import { bannerMessage } from './bannerMessageReducers';
import { colors } from './colorReducers';
import { fonts } from './fontsReducer';
import { general } from './generalReducer';
import { globalAction } from './globalActionReducers';
import globalReducer from './globalReducer';
import { locale } from './localeReducer';
import loginReducer from './loginReducer';
import { maintenance } from './maintenanceReducers';
import { message } from './messageReducers';
import { modal } from './modalReducers';
import { modelOptions } from './modelOptionsReducers';
import { modelSummary } from './modelSummaryReducers';
import { modelStatus } from './modelStatusReducer';
import { redirect } from './redirectReducer';
import { sidebar } from './sidebarReducers';
import { permissions } from './permissionsReducer';
import modelThemes from './modelThemeReducers';
import { eLearning } from './eLearningReducer';
import { dropState } from './dropStateReducer';
import { remoteMigrations } from './remoteMigrationReducer';
import { documentation } from './documentationReducer';

import { IDelayedStateShape } from 'reducers/delayedReducers';

const reducers = {
	authentication,
	globalAction,
	modal,
	login: loginReducer,
	modelOptions,
	redirect,
	message,
	bannerMessage,
	sidebar,
	adminsidebar,
	locale,
	colors,
	fonts,
	general,
	maintenance,
	global: globalReducer,
	activity,
	modelSummary,
	modelStatus,
	permissions,
	eLearning,
	dropState,
	remoteMigrations,
	modelThemes,
	documentation,
};

export type IGlobalStateShape = IDelayedStateShape &
	InferStateFromReducerObject<typeof reducers>;

export default reducers;
