/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import { Link } from 'react-router';
import VText from 'components/input/textComponent';
import VButton from 'components/common/buttonComponent';
import VBaseComponent from '../components/common/baseComponent';
import EmailTextRule from '../models/validation/emailTextRule';
import { LOGIN_LINK } from '../constants/routerPaths';
// import { store } from '../store';
import '../styles/components/loginContainer.scss';

export default class RetrieveCredentialsContainer extends VBaseComponent {
	constructor(props, context) {
		super(props, context);

		this.state = {
			email: '',
		};
	}

	onSubmit(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.isValidData()) {
			/*
			 * TODO: send e-mail
			 * const { email } = this.state;
			 * store.actions.authActions.retrieveCredentials(email);
			 */
		}
	}

	isValidData() {
		return this.state.email && this.refs.emailRemote.state.valid;
	}

	render() {
		return (
			<div>
				<form className="v-lg-content-width" onSubmit={(e) => this.onSubmit(e)}>
					<div className="v-lg-options v-lg-padding">
						<Link to={LOGIN_LINK}>
							<div className="icon-back-left-previous icon-lg-options" />
						</Link>
						<div className="v-lg-options-hd">
							{this.formatMessage('RETRIEVE_CREDENTIALS')}
						</div>
					</div>
					<p className="v-lg-padding">
						{this.formatMessage('RETREIVE_CREDENTIALS_MESSAGE')}
					</p>
					<div className="v-lg-email-padding">
						<VText
							className="v-lg-content-width"
							value={this.state.email}
							placeholder={this.formatMessage('EMAIL_ADDRESS')}
							id="email"
							ref="emailRemote"
							validationRules={[new EmailTextRule()]}
							onChange={(val) => this.setState({ email: val })}
							onEnter={() => this.onSubmit()}
							autofocus
						/>
						<div className="v-lg-btn-options-secondary">
							<VButton
								className="v-btn-lg-padding"
								label={this.formatMessage('SEND_EMAIL')}
								type="primary"
								action={(e) => this.onSubmit(e)}
							/>
						</div>
					</div>
				</form>
			</div>
		);
	}
}
