// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex`
 */
export function getAll(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'presenterflex',
		apiVersion: 'internal' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportInfoDTO[]
	>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/${id}`
 */
export function get(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `presenterflex/${id}`,
		apiVersion: 'internal' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportWithSourceSchemasDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/payee/presenterflex/${id}`
 */
export function getForPayee(
	id: Varicent.ID,
	context: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportContextDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/presenterflex/${id}`,
		apiVersion: 'internal' as const,
		data: context,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportWithSourceSchemasDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/${id}`
 */
export function getByPayee(
	id: Varicent.ID,
	context: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportContextDTO,
	query: { payeeId: Varicent.ID },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `presenterflex/${id}`,
		apiVersion: 'internal' as const,
		data: context,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportWithSourceSchemasDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/background/${id}`
 */
export function getBackground(
	id: Varicent.ID,
	query?: { getDBImage?: boolean },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `presenterflex/background/${id}`,
		apiVersion: 'internal' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexBackgroundDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/payee/presenterflex/background/${id}`
 */
export function getBackgroundForPayee(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/presenterflex/background/${id}`,
		apiVersion: 'internal' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexBackgroundDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/elementparent`
 */
export function getSourceComposerIds(
	query?: {
		tableName?: string;
		calculationId?: Varicent.ID;
		dataStoreId?: Varicent.ID;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'presenterflex/elementparent',
		apiVersion: 'internal' as const,
		query,
		...options,
	};
	return configurableRequest<number[]>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex`
 */
export function post(
	newReport: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'presenterflex',
		apiVersion: 'internal' as const,
		data: newReport,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportInfoDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/${id}`
 */
export function put(
	id: Varicent.ID,
	newReport: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `presenterflex/${id}`,
		apiVersion: 'internal' as const,
		data: newReport,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportInfoDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/background/${id}`
 */
export function updateBackground(
	id: Varicent.ID,
	newBackground: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexBackgroundDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `presenterflex/background/${id}`,
		apiVersion: 'internal' as const,
		data: newBackground,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexBackgroundDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/${id}`
 */
export function doDelete(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `presenterflex/${id}`,
		apiVersion: 'internal' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/${id}/sourcedata/${sourceId}`
 */
export function getSourceRows(
	id: Varicent.ID,
	sourceId: Varicent.ID,
	context: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportContextDTO,
	query?: {
		limit?: number;
		offset?: number;
		orderBy?: string;
		filterBy?: string;
		suggestedValuesColumn?: string;
		selectedBy?: string;
		orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
		payeeId?: Varicent.ID;
		expensiveIncludeTotalRows?: boolean;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `presenterflex/${id}/sourcedata/${sourceId}`,
		apiVersion: 'internal' as const,
		data: context,
		query,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/${id}/picklistsourcedata/${sourceId}`
 */
export function getPickListSourceRows(
	id: Varicent.ID,
	sourceId: Varicent.ID,
	context: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportContextDTO,
	query?: {
		column1?: string;
		column2?: string;
		limit?: number;
		offset?: number;
		orderBy?: string;
		filterBy?: string;
		selectedBy?: string;
		orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
		payeeId?: Varicent.ID;
		expensiveIncludeTotalRows?: boolean;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `presenterflex/${id}/picklistsourcedata/${sourceId}`,
		apiVersion: 'internal' as const,
		data: context,
		query,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/${id}/sourcedata/preview/${sourceId}`
 */
export function getSourceRowsForPreview(
	id: Varicent.ID,
	sourceId: Varicent.ID,
	sourcePreviewDTO: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourcePreviewInfoDTO,
	query?: {
		limit?: number;
		offset?: number;
		orderBy?: string;
		filterBy?: string;
		selectedBy?: string;
		orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
		payeeId?: Varicent.ID;
		expensiveIncludeTotalRows?: boolean;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `presenterflex/${id}/sourcedata/preview/${sourceId}`,
		apiVersion: 'internal' as const,
		data: sourcePreviewDTO,
		query,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/${id}/preview`
 */
export function getEvaluatedValuesForPreview(
	id: Varicent.ID,
	sourcePreviewDTO: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourcePreviewInfoDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `presenterflex/${id}/preview`,
		apiVersion: 'internal' as const,
		data: sourcePreviewDTO,
		...options,
	};
	return configurableRequest<{ [key: number]: any }>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/payee/presenterflex/${id}/sourcedata/${sourceId}`
 */
export function getSourceRowsForPayee(
	id: Varicent.ID,
	sourceId: Varicent.ID,
	context: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportContextDTO,
	query?: {
		limit?: number;
		offset?: number;
		orderBy?: string;
		filterBy?: string;
		suggestedValuesColumn?: string;
		selectedBy?: string;
		orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
		expensiveIncludeTotalRows?: boolean;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/presenterflex/${id}/sourcedata/${sourceId}`,
		apiVersion: 'internal' as const,
		data: context,
		query,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/payee/presenterflex/${id}/picklistsource/${sourceId}`
 */
export function getPickListSourceRowsForPayee(
	id: Varicent.ID,
	sourceId: Varicent.ID,
	context: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportContextDTO,
	query?: {
		column1?: string;
		column2?: string;
		limit?: number;
		offset?: number;
		orderBy?: string;
		filterBy?: string;
		selectedBy?: string;
		orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
		expensiveIncludeTotalRows?: boolean;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/presenterflex/${id}/picklistsource/${sourceId}`,
		apiVersion: 'internal' as const,
		data: context,
		query,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/${id}/picklistsourcedata/preview/${sourceId}`
 */
export function getPickListSourceRowsForPreview(
	id: Varicent.ID,
	sourceId: Varicent.ID,
	sourcePreviewDTO: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourcePreviewInfoDTO,
	query?: {
		column1?: string;
		column2?: string;
		limit?: number;
		offset?: number;
		orderBy?: string;
		filterBy?: string;
		selectedBy?: string;
		orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
		payeeId?: Varicent.ID;
		expensiveIncludeTotalRows?: boolean;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `presenterflex/${id}/picklistsourcedata/preview/${sourceId}`,
		apiVersion: 'internal' as const,
		data: sourcePreviewDTO,
		query,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/payee/presenterflex/${id}/component/${componentId}/export`
 */
export function getExportForPayee(
	id: Varicent.ID,
	componentId: Varicent.ID,
	context: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportContextDTO,
	query?: { filterBy?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/presenterflex/${id}/component/${componentId}/export`,
		apiVersion: 'internal' as const,
		data: context,
		query,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/${id}/component/${componentId}/export`
 */
export function getExportForAdmin(
	id: Varicent.ID,
	componentId: Varicent.ID,
	context: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportContextDTO,
	query: { payeeId: Varicent.ID; filterBy?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `presenterflex/${id}/component/${componentId}/export`,
		apiVersion: 'internal' as const,
		data: context,
		query,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/payee/presenterflex/${id}/sourcedata/${sourceId}/insert`
 */
export function insertDataForPayee(
	id: Varicent.ID,
	sourceId: Varicent.ID,
	dataInsertParams: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexDataInsertParamsDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/presenterflex/${id}/sourcedata/${sourceId}/insert`,
		apiVersion: 'internal' as const,
		data: dataInsertParams,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/${id}/sourcedata/${sourceId}/insert`
 */
export function insertDataForAdmin(
	id: Varicent.ID,
	sourceId: Varicent.ID,
	dataInsertParams: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexDataInsertParamsDTO,
	query: { payeeId: Varicent.ID },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `presenterflex/${id}/sourcedata/${sourceId}/insert`,
		apiVersion: 'internal' as const,
		data: dataInsertParams,
		query,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/payee/presenterflex/${id}/sourcedata/${sourceId}`
 */
export function updateDataForPayee(
	id: Varicent.ID,
	sourceId: Varicent.ID,
	dataUpdateParams: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexDataUpdateParamsDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `payee/presenterflex/${id}/sourcedata/${sourceId}`,
		apiVersion: 'internal' as const,
		data: dataUpdateParams,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/presenterflex/${id}/sourcedata/${sourceId}/update`
 */
export function updateDataForAdmin(
	id: Varicent.ID,
	sourceId: Varicent.ID,
	dataUpdateParams: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexDataUpdateParamsDTO,
	query: { payeeId: Varicent.ID },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `presenterflex/${id}/sourcedata/${sourceId}/update`,
		apiVersion: 'internal' as const,
		data: dataUpdateParams,
		query,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/palette`
 */
export function getAllPalettes(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'palette',
		apiVersion: 'internal' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexPaletteDTO[]
	>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/payee/palette`
 */
export function getAllPalettesForPayee(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/palette',
		apiVersion: 'internal' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexPaletteDTO[]
	>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/paletteusedby/${id}`
 */
export function getPaletteUsedBy(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `paletteusedby/${id}`,
		apiVersion: 'internal' as const,
		...options,
	};
	return configurableRequest<{ [key: number]: string }>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/palette`
 */
export function insertOrUpdatePalette(
	palette: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexPaletteDTO,
	query?: { propagate?: boolean },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'palette',
		apiVersion: 'internal' as const,
		data: palette,
		query,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/palette/${id}`
 */
export function deletePalette(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `palette/${id}`,
		apiVersion: 'internal' as const,
		...options,
	};
	return configurableRequest<void>(request);
}
