/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { AllFilterOperators } from '../../constants/filterOperators';
import * as FilterUtil from '../../helpers/filter/filterUtil';
import FilterOption from '../filterOption';
import _ from 'lodash';

/*
 * This class provides a way for the user to manipulate a filter string. Since each ColumnFilter can
 * only manipulate a single column there are some filters that it cannot create or modify. See ApiFilter
 * for more.
 */

export default class ColumnFilter {
	constructor(columnName, options, hasRange, apiFilter) {
		this.columnName = columnName;
		this.hasRange = hasRange;
		this.apiFilter = apiFilter;
		this.listFilter = null;
		this.exclude = false;
		this.listOptions = [];
		for (let i = 0; i < options.length; i++) {
			this.listOptions.push(
				FilterOption.buildFilterOption(
					options[i].key,
					options[i].label,
					options[i].localize,
					options[i].isSelected
				)
			);
		}
		this.clauseType = AllFilterOperators.And;
		this.freeFormFilter1 = null;
		this.freeFormFilter2 = null;
		this.rangeFilter = null;
		this.loadingOptions = true;
	}

	copy() {
		const newFilter = this.apiFilter
			.copy()
			.getColumnFilter(this.columnName, this.rangeFilter !== null);
		newFilter.loadingOptions = this.loadingOptions;
		newFilter.addNewListOptions(
			this.listOptions.map((l) =>
				FilterOption.buildFilterOption(l.key, l.label, l.localize, l.isSelected)
			)
		);
		return newFilter;
	}

	isFilterOn() {
		return (
			this.freeFormFilter1 !== null ||
			this.freeFormFilter2 !== null ||
			this.rangeFilter !== null ||
			this.listFilter !== null
		);
	}

	applyPendingFilter() {
		this.apiFilter.applyPendingFilter(this);
	}

	// Merges the internal list of filter options with the provided list.
	addNewListOptions(filterOptions) {
		this.listOptions = _.filter(this.listOptions, (o) => o.isSelected);

		for (let i = 0; i < filterOptions.length; i++) {
			let found = false;
			for (let j = 0; j < this.listOptions.length; j++) {
				if (this.listOptions[j].key === filterOptions[i].key) {
					found = true;
					break;
				}
			}

			if (!found) {
				this.listOptions.push(filterOptions[i]);
			}
		}
	}

	getFreeform1Type() {
		if (this.freeFormFilter1 === null) return null;
		return this.freeFormFilter1.type;
	}

	getFreeform2Type() {
		if (this.freeFormFilter2 === null) return null;
		return this.freeFormFilter2.type;
	}

	getFreeform1Value() {
		if (this.freeFormFilter1 === null) return '';
		return this.freeFormFilter1.right instanceof Array
			? this.freeFormFilter1.right[0]
			: this.freeFormFilter1.right;
	}

	getFreeform2Value() {
		if (this.freeFormFilter2 === null) return '';
		return this.freeFormFilter2.right instanceof Array
			? this.freeFormFilter2.right[0]
			: this.freeFormFilter2.right;
	}

	getRangeValue() {
		if (!this.hasRange || !this.rangeFilter) return { from: null, to: null };

		if (this.rangeFilter.type === AllFilterOperators.Range) {
			return {
				from: this.rangeFilter.right[0],
				to: this.rangeFilter.right[1],
			};
		} else if (this.rangeFilter.type === AllFilterOperators.GreaterOrEqual) {
			return { from: this.rangeFilter.right, to: null };
		} else if (this.rangeFilter.type === AllFilterOperators.LessOrEqual) {
			return { from: null, to: this.rangeFilter.right };
		}

		throw new Error('Cannot fetch range filter.');
	}

	// Using existing filters, this function determines which options are selected.
	syncFiltersToOptions() {
		/*
		 * Sometimes there are items in the apiFilter that are not in the listOptions (due to the fact that only the top 100
		 * options are fetched from the api by default). If those options are not present then add them in.
		 */
		if (this.listFilter !== null) {
			for (let i = 0; i < this.listFilter.right.length; i++) {
				if (
					_.findIndex(
						this.listOptions,
						(o) => o.key === this.listFilter.right[i]
					) === -1
				) {
					this.listOptions.push(
						FilterOption.buildFilterOption(this.listFilter.right[i])
					);
				}
			}
		}

		for (let i = 0; i < this.listOptions.length; i++) {
			const listOption = this.listOptions[i];
			let found = false;
			if (this.listFilter !== null) {
				for (let k = 0; k < this.listFilter.right.length; k++) {
					if (listOption.key === this.listFilter.right[k]) {
						found = true;
						break;
					}
				}
			}

			listOption.isSelected = found;
		}
	}

	// marks the provided option as selected. If that option is not found it is added.
	toggleOption(name, toggle) {
		for (let i = 0; i < this.listOptions.length; i++) {
			if (this.listOptions[i].key === name) {
				this.listOptions[i].isSelected = toggle;
				return;
			}
		}

		this.listOptions.push(
			FilterOption.buildFilterOption(name, null, false, toggle)
		);
	}

	toggleAll(toggle) {
		for (let i = 0; i < this.listOptions.length; i++) {
			this.listOptions[i].isSelected = toggle;
		}
	}

	// Using the list of options this function builds the filters.
	syncOptionsToFilters(exclude = false) {
		// If existing filters are in the wrong include/exclude mode, remove them.
		if (this.listFilter !== null) {
			if (exclude !== this.exclude) {
				this.apiFilter.pendingFilter(this.columnName).remove(this.listFilter);
				this.listFilter = null;
			}
		}

		for (let i = 0; i < this.listOptions.length; i++) {
			const listOption = this.listOptions[i];
			let found = false;
			if (this.listFilter !== null) {
				for (let k = 0; !found && k < this.listFilter.right.length; k++) {
					if (listOption.key === this.listFilter.right[k]) {
						found = true;

						if (listOption.isSelected === false) {
							this.listFilter.right.splice(k, 1);
							// If the list of selections is empty remove the entire filter.
							if (this.listFilter.right.length === 0) {
								this.apiFilter
									.pendingFilter(this.columnName)
									.remove(this.listFilter);
								this.listFilter = null;
							}
						}
					}
				}
			}

			if (listOption.isSelected === true && found === false) {
				if (this.listFilter === null) {
					let filter;
					if (exclude) {
						filter = FilterUtil.createFilter(
							AllFilterOperators.NotEqual,
							this.columnName,
							[listOption.key],
							this.apiFilter.pendingFilter(this.columnName)
						);
					} else {
						filter = FilterUtil.createFilter(
							AllFilterOperators.Equal,
							this.columnName,
							[listOption.key],
							this.apiFilter.pendingFilter(this.columnName)
						);
					}
					this.apiFilter.pendingFilter(this.columnName).push(filter);
					this.listFilter = filter;
				} else {
					this.listFilter.right.push(listOption.key);
				}
			}
		}
	}

	clear() {
		for (let i = 0; i < this.listOptions.length; i++) {
			this.listOptions[i].isSelected = false;
		}

		let i = 0;
		while (i < this.apiFilter.pendingFilter(this.columnName).filters.length) {
			if (
				this.apiFilter
					.pendingFilter(this.columnName)
					.filters[i].isColumn(this.columnName)
			) {
				this.apiFilter.pendingFilter(this.columnName).filters.splice(i, 1);
			} else {
				i++;
			}
		}

		this.listFilter = null;

		this.clauseType = AllFilterOperators.And;
		this.freeFormFilter1 = null;
		this.freeFormFilter2 = null;
		this.rangeFilter = null;
	}

	updateFreeformFilters(clauseType, operator1, value1, operator2, value2) {
		const _convertToClause = () => {
			const newClause = FilterUtil.createClause(
				this.clauseType,
				this.freeFormFilter1,
				this.freeFormFilter2,
				this.apiFilter.pendingFilter(this.columnName)
			);
			this.freeFormFilter1.parent = newClause;
			this.freeFormFilter2.parent = newClause;
			this.apiFilter.pendingFilter(this.columnName).push(newClause);
		};

		if (this.clauseType !== clauseType) {
			this.clauseType = clauseType;

			if (this.freeFormFilter1 !== null && this.freeFormFilter2 !== null) {
				this.apiFilter
					.pendingFilter(this.columnName)
					.remove(this.freeFormFilter1.parent);
				_convertToClause();
			}
		}

		if (this.freeFormFilter1 === null && value1 !== '') {
			// Case: Adding a filter
			this.freeFormFilter1 = FilterUtil.createFilter(
				operator1,
				this.columnName,
				value1,
				this.apiFilter.pendingFilter(this.columnName)
			);
			this.apiFilter
				.pendingFilter(this.columnName)
				.filters.push(this.freeFormFilter1);
		} else if (this.freeFormFilter1 !== null && value1 === '') {
			// Case: Removing a filter
			if (
				this.freeFormFilter1.parent ===
				this.apiFilter.pendingFilter(this.columnName)
			) {
				// Case: Filter is under the root so just remove it.
				this.apiFilter
					.pendingFilter(this.columnName)
					.remove(this.freeFormFilter1);
			} else {
				// Case: Filter is under a clause. Remove the clause and move the other constraint to the root.
				this.apiFilter
					.pendingFilter(this.columnName)
					.remove(this.freeFormFilter1.parent);
				this.apiFilter
					.pendingFilter(this.columnName)
					.push(this.freeFormFilter2);
				this.freeFormFilter2.parent = this.apiFilter.pendingFilter(
					this.columnName
				);
			}
			this.freeFormFilter1 = null;
		} else if (
			value1 !== '' &&
			this.freeFormFilter1 !== null &&
			this.freeFormFilter1.type !== operator1
		) {
			// Case: Type of a filter has changed.
			const old = this.freeFormFilter1;
			this.freeFormFilter1 = FilterUtil.createFilter(
				operator1,
				this.columnName,
				value1,
				this.freeFormFilter1.parent
			);

			if (old.parent === this.apiFilter.pendingFilter(this.columnName)) {
				this.apiFilter.pendingFilter(this.columnName).remove(old);
				this.apiFilter
					.pendingFilter(this.columnName)
					.filters.push(this.freeFormFilter1);
			} else {
				old.parent.left = this.freeFormFilter1;
			}
		} else if (value1 !== '' && this.freeFormFilter1 !== null) {
			// Case: Value has changed.
			if (
				this.freeFormFilter1.type === AllFilterOperators.Equal ||
				this.freeFormFilter1.type === AllFilterOperators.NotEqual
			) {
				this.freeFormFilter1.right[0] = value1;
			} else {
				this.freeFormFilter1.right = value1;
			}
		}

		if (this.freeFormFilter2 === null && value2 !== '') {
			// Case: Adding a filter
			this.freeFormFilter2 = FilterUtil.createFilter(
				operator2,
				this.columnName,
				value2,
				this.apiFilter.pendingFilter(this.columnName)
			);
			this.apiFilter
				.pendingFilter(this.columnName)
				.filters.push(this.freeFormFilter2);
		} else if (this.freeFormFilter2 !== null && value2 === '') {
			// Case: Removing a filter
			if (
				this.freeFormFilter2.parent ===
				this.apiFilter.pendingFilter(this.columnName)
			) {
				// Case: Filter is under the root so just remove it.
				this.apiFilter
					.pendingFilter(this.columnName)
					.remove(this.freeFormFilter2);
			} else {
				// Case: Filter is under a clause. Remove the clause and move the other constraint to the root.
				this.apiFilter
					.pendingFilter(this.columnName)
					.remove(this.freeFormFilter2.parent);
				this.apiFilter
					.pendingFilter(this.columnName)
					.push(this.freeFormFilter1);
				this.freeFormFilter1.parent = this.apiFilter.pendingFilter(
					this.columnName
				);
			}
			this.freeFormFilter2 = null;
		} else if (
			value2 !== '' &&
			this.freeFormFilter2 !== null &&
			this.freeFormFilter2.type !== operator2
		) {
			// Case: Type of a filter has changed.
			const old = this.freeFormFilter2;
			this.freeFormFilter2 = FilterUtil.createFilter(
				operator2,
				this.columnName,
				value2,
				this.freeFormFilter2.parent
			);

			if (old.parent === this.apiFilter.pendingFilter(this.columnName)) {
				this.apiFilter.pendingFilter(this.columnName).remove(old);
				this.apiFilter
					.pendingFilter(this.columnName)
					.filters.push(this.freeFormFilter2);
			} else {
				old.parent.right = this.freeFormFilter2;
			}
		} else if (value2 !== '' && this.freeFormFilter2 !== null) {
			// Case: Value has changed.
			if (
				this.freeFormFilter2.type === AllFilterOperators.Equal ||
				this.freeFormFilter2.type === AllFilterOperators.NotEqual
			) {
				this.freeFormFilter2.right[0] = value2;
			} else {
				this.freeFormFilter2.right = value2;
			}
		}

		if (
			this.freeFormFilter1 !== null &&
			this.freeFormFilter2 !== null &&
			this.freeFormFilter1.parent ===
				this.apiFilter.pendingFilter(this.columnName)
		) {
			// Case: Two constraints exist under the root so convert them to a clause.
			this.apiFilter
				.pendingFilter(this.columnName)
				.remove(this.freeFormFilter1);
			this.apiFilter
				.pendingFilter(this.columnName)
				.remove(this.freeFormFilter2);
			_convertToClause();
		}
	}

	updateRangeFilter(range) {
		if (this.rangeFilter) {
			this.apiFilter.pendingFilter(this.columnName).remove(this.rangeFilter);
			this.rangeFilter = null;
		}

		if (range.from !== null && range.to !== null) {
			this.rangeFilter = FilterUtil.createFilter(
				AllFilterOperators.Range,
				this.columnName,
				[range.from, range.to],
				this.parent
			);
			this.apiFilter.pendingFilter(this.columnName).push(this.rangeFilter);
		} else if (range.from !== null) {
			this.rangeFilter = FilterUtil.createFilter(
				AllFilterOperators.GreaterOrEqual,
				this.columnName,
				range.from,
				this.parent
			);
			this.apiFilter.pendingFilter(this.columnName).push(this.rangeFilter);
		} else if (range.to !== null) {
			this.rangeFilter = FilterUtil.createFilter(
				AllFilterOperators.LessOrEqual,
				this.columnName,
				range.to,
				this.parent
			);
			this.apiFilter.pendingFilter(this.columnName).push(this.rangeFilter);
		}
	}
}
