/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

// This class is meant to help with the management of timers that poll the API on a consistent basis. Designed with Live activity polling in mind.
import { axiosInstance } from 'icm-rest-client/lib/utils/configure';
import { defaultRESTAPIErrorHandler } from 'services/httpService';
import * as PreviewConstants from 'constants/preview/previewConstants';

export default class APIPollingHelper {
	static apiPollTimers = [];
	static apiPollPending = {};
	// Poll for global action status every 5 minutes when user is inactive
	static globalActionStatusPollInterval = 300000;
	// Poll for global action status a maximum of 12 times when user is inactive
	static globalActionStatusPollLimit = 12;
	static apiPollInterval = 7000; // 7 seconds
	static activityContainerLiveActivityPollName = 'LiveActivityPoll';
	static generateTasksPollName = 'generateTasksPollName';
	static headerComponentGlobalActionPollName = 'GlobalActionPoll';
	static activityStatusComponentLiveActivityPoll =
		'ActivityStatusLiveActivityPoll';
	static calcPreviewLiveActivityPollName = 'CalcPreviewLiveActivityPoll';
	static calcPreviewCompletedActivityPollName =
		'CalcPreviewCompletedActivityPoll';
	static migrationLiveActivityPollName = 'MigrationLiveActivityPoll';
	static extToolLogPollName = 'ExternalToolLogPoll';
	static workflowPoll = 'workflowPoll';

	static createAndRegisterAPIPollTimer(pollFn, interval, name) {
		this.apiPollPending = {
			[name]: false,
			...this.apiPollPending,
		};
		const handler = async (apiPollPending) => {
			try {
				await pollFn();
			} finally {
				apiPollPending[name] = false;
			}
		};
		const timer = setInterval(() => {
			if (this.apiPollPending[name]) {
				return;
			}
			this.apiPollPending[name] = true;
			handler(this.apiPollPending);
		}, interval);
		this.apiPollTimers.push({ timer, name });

		return timer;
	}

	static unregisterAPIPollTimerByReference(timer) {
		this.apiPollTimers.filter((item) => !(item.timer === timer));
	}

	static unregisterAPIPollTimerByName(name) {
		this.apiPollTimers.filter((item) => !(item.name === name));
	}

	static disableAndUnregisterAPIPollTimerByName(name) {
		this.apiPollTimers.forEach((item) => {
			if (item.name === name) {
				clearInterval(item.timer);
				this.apiPollPending[name] = false;
				this.unregisterAPIPollTimerByName(name);
			}
		});
	}

	/*
	 *poll liveactivities until 404
	 *404 means it's moved to completedactivities or never started
	 */
	static pollLiveActivity(pollName, urls) {
		return axiosInstance
			.get(urls[PreviewConstants.LIVEACTIVITIES_URL], {
				timeout: this.apiPollInterval,
				preventDefaultRESTAPIError: true,
				baseURL: ICM_CONFIG.API_URL,
			})
			.catch((error) => {
				if (error.response && error.response.status) {
					this.disableAndUnregisterAPIPollTimerByName(pollName);
					switch (error.response.status) {
						case 404:
							return this.getCompletedActivity(urls);
						default:
							break;
					}
				}
				return defaultRESTAPIErrorHandler(error);
			});
	}

	static getCompletedActivity(urls) {
		return axiosInstance
			.get(urls[PreviewConstants.COMPLETEDACTIVITIES_URL], {
				preventDefaultRESTAPIError: true,
				baseURL: ICM_CONFIG.API_URL,
			})
			.then(
				(response) => {
					if (response.data.status === PreviewConstants.ACTIVITY_COMPLETED) {
						return response;
					}
					window.store.actions.messageActions.error(
						response.data.messageDetails
							? response.data.messageDetails
							: response.data.message
					);
					return response;
				},
				(error) => {
					defaultRESTAPIErrorHandler(error);
				}
			);
	}
}
