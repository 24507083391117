/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { put } from 'redux-saga/effects';
import { isJWTNotExpired, getJWTPayloadObject } from 'helpers/jwtHelper';
import { getCurrentAuthState, getLastModel } from 'helpers/authHelper';
import { login } from 'actions/authActions';
import { showOngoingBanners } from 'actions/maintenanceActions';
import { isTimeoutExpired } from 'helpers/timeoutHelper';

export default function* authenticationInit() {
	const state = getCurrentAuthState();
	if (state && isJWTNotExpired() && !isTimeoutExpired()) {
		const {
			isLocal,
			jwtRenewMinutesInterval,
			models,
			tenantID,
			token,
			tokenMap = {},
			user,
			userName,
		} = state;

		const lastModel = getLastModel(userName);
		const database = models.includes(lastModel) ? lastModel : models[0];

		const tokenComputed = tokenMap[database] ?? token;
		const jsonToken = getJWTPayloadObject(tokenComputed);
		const adminUserAuditLogEnabled = jsonToken.admin_user_auditLog_enabled;

		yield put(
			login({
				token: tokenComputed,
				user,
				userName,
				database,
				isLocal,
				models,
				tokenMap,
				tenantID,
				jwtRenewMinutesInterval,
				adminUserAuditLogEnabled,
			})
		);
		yield put(showOngoingBanners());
	}
}
