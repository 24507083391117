/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { put, throttle, select } from 'redux-saga/effects';
import { isTimeoutExpired, resetTimeout } from 'helpers/timeoutHelper';
import { getOpenAuthModalAction, getClearJWTAction } from 'helpers/authHelper';
import { ALLOWABLE_ACTIONS } from 'constants/middlewareActionTypes';
import { OPEN_AUTH_MODAL } from 'constants/actionTypes';

/**
 * Checks if we should process the action
 * @param {*} action Current Action
 * @return {bool} returns true if we should check timeout on this action
 */
function checkAction(action) {
	if (
		ALLOWABLE_ACTIONS.includes(action.type) ||
		action.type === OPEN_AUTH_MODAL
	) {
		return false;
	}
	return true;
}

/**
 * If timeout is valid, reset it to one hour from now, otherwise open the login modal
 */
function* verifyTimeout() {
	const loginPage = yield select((state) => state.authentication.loginPage);

	if (!loginPage && isTimeoutExpired()) {
		yield put(getClearJWTAction());
		yield put(getOpenAuthModalAction());
	} else {
		resetTimeout();
	}
}

export default function* checkTimeout() {
	yield throttle(60000, checkAction, verifyTimeout);
}
