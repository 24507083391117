/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const SELECT_SHORTCUT_MODAL = 'SELECT_SHORTCUT_MODAL';
export const ADD_PROCESS_LIST_MODAL = 'ADD_PROCESS_LIST_MODAL';
export const ADD_CALENDAR_MODAL = 'ADD_CALENDAR_MODAL';
export const ADD_CUSTOM_CALENDAR_MODAL = 'ADD_CUSTOM_CALENDAR_MODAL';
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const REPORTING_LINK_MODAL = 'REPORTING_LINK_MODAL';
export const WEB_REPORT_ADD_VALUE_MODAL = 'WEB_REPORT_ADD_VALUE_MODAL';
export const WEB_REPORT_ADD_COMPUTED_COLUMN = 'WEB_REPORT_ADD_COMPUTED_COLUMN';
export const WEB_REPORT_TRANSPOSE_MODAL = 'WEB_REPORT_TRANSPOSE_MODAL';
export const WEB_REPORT_TEMPLATE_MODAL = 'WEB_REPORT_TEMPLATE_MODAL';
export const WEB_REPORT_SAVE_AS_MODAL = 'WEB_REPORT_SAVE_AS_MODAL';
export const WEB_REPORT_SET_PARAMETERS_MODAL =
	'WEB_REPORT_SET_PARAMETERS_MODAL';
export const WEB_REPORT_PDF_OPTIONS_MODAL = 'WEB_REPORT_PDF_OPTIONS_MODAL';
export const WEB_REPORT_PREFERENCES_MODAL = 'WEB_REPORT_PREFERENCES_MODAL';
export const DELETE_CALENDAR_CONFIRMATION_MODAL =
	'DELETE_CALENDAR_CONFIRMATION_MODAL';
export const INFORMATION_MODAL = 'INFORMATION_MODAL';
export const WEB_FORM_FILTER_DELETE_CONFIRMATION_MODAL =
	'WEB_FORM_FILTER_DELETE_CONFIRMATION_MODAL';
export const WEB_RESOURCE_MODAL = 'WEB_RESOURCE_MODAL';
export const ADMIN_FORMS_MODAL = 'ADMIN_FORMS_MODAL';
export const WEB_FORM_TEXT_MODAL = 'WEB_FORM_TEXT_MODAL';
export const WEB_FORM_PREFERENCES_MODAL = 'WEB_FORM_PREFERENCES_MODAL';
export const WEB_FORM_OPTIONS_MODAL = 'WEB_FORM_OPTIONS_MODAL';
export const WEB_FORM_JAVASCRIPT_MODAL = 'WEB_FORM_JAVASCRIPT_MODAL';
export const WEB_FORM_LINK_MODAL = 'WEB_FORM_LINK_MODAL';
export const WEB_FORM_CLEAR_LINK_MODAL = 'WEB_FORM_CLEAR_LINK_MODAL';
export const WEB_FORM_SAVE_AS_MODAL = 'WEB_FORM_SAVE_AS_MODAL';
export const WEB_FORM_FORMAT_CELL_MODAL = 'WEB_FORM_FORMAT_CELL_MODAL';
export const ADD_VALIDATION_RULE = 'ADD_VALIDATION_RULE';
export const WORKFLOW_RESOLVE_ACTION_ERROR_MODAL =
	'WORKFLOW_RESOLVE_ACTION_ERROR_MODAL';
export const WORKFLOW_MANAGE_DOCUMENTS_MODAL =
	'WORKFLOW_MANAGE_DOCUMENTS_MODAL';
export const ADD_EDIT_ADMIN_USER_MODAL = 'ADD_EDIT_ADMIN_USER_MODAL';
export const ADD_USER_MODAL = 'ADD_USER_MODAL';
export const EDIT_USER_MODAL = 'EDIT_USER_MODAL';
export const EDIT_PROFILE_MODAL = 'EDIT_PROFILE_MODAL';
export const MODEL_THEMES_MODAL = 'MODEL_THEMES_MODAL';
export const MAINTENANCE_MODAL = 'MAINTENANCE_MODAL';

export const ADD_WEB_MESSAGE_MODAL = 'ADD_WEB_MESSAGE_MODAL';
export const EDIT_WEB_MESSAGE_MODAL = 'EDIT_WEB_MESSAGE_MODAL';
export const DELETE_SAVED_TRANSFORMATIONS_CONFIRMATION_MODAL =
	'DELETE_SAVED_TRANSFORMATIONS_CONFIRMATION_MODAL';
export const DATE_FORMAT_MODAL = 'DATE_FORMAT_MODAL';
export const HOME_WATCH_LIST_MODAL = 'HOME_WATCH_LIST_MODAL';
export const EFFECTIVE_DATES_CHOOSER_MODAL = 'EFFECTIVE_DATES_CHOOSER_MODAL';
export const ADD_NEW_STAR_SCHEMA_MODAL = 'ADD_NEW_STAR_SCHEMA_MODAL';
export const DELETE_DOCUMENT_CONFIRMATION_MODAL =
	'DELETE_DOCUMENT_CONFIRMATION_MODAL';
export const DELETE_PAYEE_DOCUMENT_CONFIRMATION_MODAL =
	'DELETE_PAYEE_DOCUMENT_CONFIRMATION_MODAL';
export const DELETE_STAR_SCHEMA_CONFIRMATION_MODAL =
	'DELETE_STAR_SCHEMA_CONFIRMATION_MODAL';
export const CLEAR_BY_DATE_MODAL = 'CLEAR_BY_DATE_MODAL';
export const SIGNATURE_CONTROL_DELETE_MODAL = 'SIGNATURE_CONTROL_DELETE_MODAL';
export const ABOUT_ICM_MODAL = 'ABOUT_ICM_MODAL';

export const MIGRATION_MODAL = 'MIGRATION_MODAL';

export const PULSE_ANALYTICS_CONFIG_DEFAULT_PARAMETERS =
	'PULSE_ANALYTICS_CONFIG_DEFAULT_PARAMETERS';
export const PULSE_ANALYTICS_CONFIG_REPORT_RESTRICTIONS =
	'PULSE_ANALYTICS_CONFIG_REPORT_RESTRICTIONS';
export const WEB_FORM_FILTER_DELETE_CONFIRMATION =
	'WEB_FORM_FILTER_DELETE_CONFIRMATION';
export const DATE_PICKER_MODAL = 'DATE_PICKER_MODAL';

export const QUERY_TOOL_CANCEL_QUERY_MODAL = 'QUERY_TOOL_CANCEL_QUERY_MODAL';

export const CROP_IMAGE_MODAL = 'CROP_IMAGE_MODAL';
