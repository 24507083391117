/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import GreaterOrEqualFilter from './greaterOrEqualFilter';
import LessOrEqualFilter from './lessOrEqualFilter';
import EqualFilter from './equalFilter';
import RangeFilter from './rangeFilter';
import ClauseFilter from './clauseFilter';
import NotEqualFilter from './notEqualFilter';
import { sortedFindLastIndexByFunction } from '../../../helpers/utility/arrayUtilities';
import {
	orderFilters,
	excludeColumnToString,
} from '../../../helpers/filterHelper';

export default class RootFilter {
	constructor(filters = []) {
		this.filters = filters;
	}

	isEmpty() {
		return this.filters.length === 0;
	}

	toString() {
		let result = '';
		const filters = orderFilters(this.filters);

		let curColumn = '';
		for (let i = 0; i < filters.length; i++) {
			// if first of a new column in the list
			if (filters[i].getColumnName() !== curColumn) {
				// If we have exclude clauses, parse filters on column as excludes&&((allotherfilters))
				if (filters[i] instanceof NotEqualFilter) {
					const j = sortedFindLastIndexByFunction(
						filters,
						(value) => value.getColumnName(),
						filters[i].getColumnName()
					);
					result += excludeColumnToString(filters, i, j);
					i = j;
				} else {
					result += filters[i].toString();
				}
				curColumn = filters[i].getColumnName();
			} else {
				result += filters[i].toString();
			}
			if (i !== filters.length - 1) {
				result += ';';
			}
		}
		return result;
	}

	checkDuplicate(filters, indexA, indexB) {
		let found = false;
		for (let a = 0; a < filters[indexA].right.length; a++) {
			for (let b = 0; b < filters[indexB].right.length; b++) {
				if (filters[indexA].right[a] === filters[indexB].right[b]) {
					found = true;
				}
			}
		}
		return found;
	}

	mergeFilters(filters, firstIndex, curIndex) {
		if (this.checkDuplicate(filters, firstIndex, curIndex)) {
			// find the non-list filter and replace it with the list filter if its a duplicate
			if (filters[firstIndex].right.length === 1) {
				filters[firstIndex] = filters[curIndex];
			}
		} else {
			filters[firstIndex].right = filters[firstIndex].right.concat(
				filters[curIndex].right
			);
		}
		filters.splice(curIndex, 1);
	}

	populateColumnFilter(columnFilter) {
		columnFilter.listFilter = null;
		columnFilter.rangeFilter = null;
		columnFilter.freeFormFilter1 = null;
		columnFilter.freeFormFilter2 = null;

		// combine equal and not equal filters by finding first of their type and merging the array lists to the first filter in the array. Remove the element merged into the first one
		let equalFilterIndex = null;
		let notEqualFilterIndex = null;
		for (let i = 0; i < this.filters.length; i++) {
			if (this.filters[i].isColumn(columnFilter.columnName)) {
				if (this.filters[i] instanceof EqualFilter) {
					if (equalFilterIndex === null) {
						equalFilterIndex = i;
					} else {
						this.mergeFilters(this.filters, equalFilterIndex, i);
						i--; // subtract index to account for removed filter
					}
				} else if (this.filters[i] instanceof NotEqualFilter) {
					if (notEqualFilterIndex === null) {
						notEqualFilterIndex = i;
					} else {
						this.mergeFilters(this.filters, notEqualFilterIndex, i);
						i--; // subtract index to account for removed filter
					}
				}
			}
		}

		/*
		 * Attempts to extract a column specific view from a filter string. Has the following precedence:
		 * List filter selects the first filter of type Equal.
		 * Range filter selects the first filter of type Range/GreaterOrEqual/LessOrEqual if hasRange is true.
		 * Freeform selects the first two unselected filters.
		 */
		for (let i = 0; i < this.filters.length; i++) {
			if (this.filters[i].isColumn(columnFilter.columnName)) {
				if (
					this.filters[i] instanceof EqualFilter &&
					(!columnFilter.listFilter || this.filters[i].right.length > 1)
				) {
					columnFilter.exclude = false;
					columnFilter.listFilter = this.filters[i];
				} else if (
					this.filters[i] instanceof NotEqualFilter &&
					(!columnFilter.listFilter || this.filters[i].right.length > 1)
				) {
					columnFilter.exclude = true;
					columnFilter.listFilter = this.filters[i];
				} else if (
					columnFilter.hasRange &&
					(this.filters[i] instanceof RangeFilter ||
						this.filters[i] instanceof GreaterOrEqualFilter ||
						this.filters[i] instanceof LessOrEqualFilter)
				) {
					columnFilter.rangeFilter = this.filters[i];
				}
			}
		}

		for (let i = 0; i < this.filters.length; i++) {
			if (
				this.filters[i].isColumn(columnFilter.columnName) &&
				this.filters[i] !== columnFilter.listFilter &&
				this.filters[i] !== columnFilter.rangeFilter
			) {
				if (this.filters[i] instanceof ClauseFilter) {
					columnFilter.clauseType = this.filters[i].type;
					columnFilter.freeFormFilter1 = this.filters[i].left;
					columnFilter.freeFormFilter2 = this.filters[i].right;
				} else if (!columnFilter.freeFormFilter1) {
					columnFilter.freeFormFilter1 = this.filters[i];
				} else if (!columnFilter.freeFormFilter2) {
					columnFilter.freeFormFilter2 = this.filters[i];
				}
			}
		}

		columnFilter.syncFiltersToOptions();

		return columnFilter;
	}

	push(filter) {
		this.filters.push(filter);
	}

	remove(filter) {
		for (let i = 0; i < this.filters.length; i++) {
			if (this.filters[i] === filter) {
				this.filters.splice(i, 1);
				return;
			}
		}

		throw new Error(`${filter}: not deleted!`);
	}

	filter(row, columns, isCaseSensitive) {
		return this.filters.every((f) => f.filter(row, columns, isCaseSensitive));
	}

	isFilterOnColumn(columnName) {
		for (let i = 0; i < this.filters.length; i++) {
			if (this.filters[i].isColumn(columnName)) {
				return true;
			}
		}
		return false;
	}

	mapColumns(columnMap) {
		for (let i = 0; i < this.filters.length; i++) {
			this.filters[i].mapColumns(columnMap);
		}
	}

	copy() {
		const newFilters = new RootFilter([]);
		for (let i = 0; i < this.filters.length; i++) {
			newFilters.filters.push(this.filters[i].copy(newFilters));
		}
		return newFilters;
	}
}
