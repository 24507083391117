/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/*
 * This file contains action types that will be allowed to pass through sessionMiddleware regardless of
 * validity of auth token.
 *
 * Adding to this file:
 * If you need certain actions to reach the reducer when there does not exist a valid auth token (JWT) in localStorage
 * (e.g. for login, password retrieval, etc.), follow the two steps below:
 * 1. Add the action type to this file.
 * 2. You may define the action type again in another file for everyday use (e.g. actionTypes, homeActionTypes)
 * but import this module and use the action defined here as the value of the exported action type in the other file.
 *
 * Usage example:
 * middlewareBypassActionTypes (this file)
 * export const LOGIN = 'LOGIN';
 * actionTypes
 * import * as MiddlewareBypass from './middlewareBypassActionTypes';
 * export const LOGIN = MiddlewareBypass.LOGIN;
 *
 * Then in actual use, you may choose to use the LOGIN constant exported from actionTypes in action creators
 * and reducers.
 */

// Error actions
export const ERROR = 'ERROR';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';

export const CLEAR_BANNER_MESSAGE = 'CLEAR_BANNER_MESSAGE';
export const SHOW_BANNER_MESSAGE = 'SHOW_BANNER_MESSAGE';

// Confirm actions
export const CONFIRM_MESSAGE = 'CONFIRM_MESSAGE';
export const CONFIRM_MESSAGE_LINK = 'CONFIRM_MESSAGE_LINK';

// Login actions
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CLEAR_JWT = 'CLEAR_JWT';
export const RENEW_JWT = 'RENEW_JWT';
export const CHANGE_MODEL = 'CHANGE_MODEL';
export const SHOW_PASSWORD_CHANGE = 'SHOW_PASSWORD_CHANGE';
export const HIDE_PASSWORD_CHANGE = 'HIDE_PASSWORD_CHANGE';
export const SSO_CHECKED = 'SSO_CHECKED';
export const COMPLETE_SSO_LOGIN = 'COMPLETE_SSO_LOGIN';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const SET_WAIT_FOR_POPUP = 'SET_WAIT_FOR_POPUP';
export const SHOW_FORGOT_PASSWORD_REQUEST = 'SHOW_FORGOT_PASSWORD_REQUEST';
export const HIDE_FORGOT_PASSWORD_REQUEST = 'HIDE_FORGOT_PASSWORD_REQUEST';
export const SHOW_FORGOT_PASSWORD_SUBMIT = 'SHOW_FORGOT_PASSWORD_SUBMIT';
export const HIDE_FORGOT_PASSWORD_SUBMIT = 'HIDE_FORGOT_PASSWORD_SUBMIT';
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL';

// Other actions
export const LOCALE_RECEIVED = 'LOCALE_RECEIVED';
export const HOME_RESET = 'HOME_RESET';

// Redirect
export const REDIRECT = 'REDIRECT';
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT';

// Migration
export const MIGRATION_COMPLETE = 'MIGRATION_COMPLETE';

// Authorization
export const STATUS_PAGE = 'STATUS_PAGE';
