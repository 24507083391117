/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as ModelOptionsActionTypes from 'constants/modelOptions/modelOptionsActionTypes';
import * as AdminOptionKeys from 'constants/admin/adminOptionKeys';
import { LOGOUT } from 'constants/actionTypes';
import _ from 'lodash';
import R from 'ramda';
import { update } from 'utils';

const handlers = {};

const initialState = {
	models: [],
	users: { userList: {}, allIds: [] },
	tsRoles: [],
	modelUsers: [],
	roles: [],
	selectedModel: null,
	showRoles: false,
	selectedUser: {
		user_id: null,
		role: null,
	},
	model: {},
	permissionToViewUsers: false,
};

// the reducer
export function modelOptions(state = initialState, action) {
	const { type } = action;
	if (!handlers[type]) {
		return state;
	}
	return handlers[type](state, action);
}

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_RECEIVE_CURRENT_ROLE] = (
	state,
	{ payload: { permissionToViewUsers } }
) => ({
	...state,
	permissionToViewUsers,
});

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_REQUEST_MODELS] = (state) =>
	state;

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_RECEIVE_MODELS] = (
	state,
	{ payload: { models, currentModels } }
) => {
	const newState = { ...state };
	newState.models = [];
	// only display model that the user currently belongs to
	_.forEach(models, (model) => {
		if (_.findIndex(currentModels, (m) => m === model.model_name) >= 0) {
			newState.models.push(model);
		}
	});
	return newState;
};

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_RECEIVE_OPTIONS] = (
	state,
	{ payload: { model_name, options } }
) => {
	const newState = { ...state };
	const index = _.findIndex(
		newState.models,
		(m) => m.model_name === model_name
	);
	const description = _.find(
		options,
		(o) => o.key === AdminOptionKeys.ModelDescription
	);
	newState.models[index].description = description;
	return newState;
};

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_UPDATE_SELECTED_MODEL] = (
	state,
	{ payload: { model } }
) => {
	const newState = { ...state };
	newState.selectedModel = model;
	newState.showRoles = !!model;
	return newState;
};

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_RECEIVE_MODEL_USERS] = (
	state,
	{ payload: { users } }
) => {
	const newState = { ...state };
	newState.modelUsers = users;
	newState.showRoles = true;
	return newState;
};

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_NO_ROLES] = (state) => {
	const newState = { ...state };
	newState.showRoles = false;
	return newState;
};

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_REQUEST_USERS] = (state) =>
	state;

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_RECEIVE_USERS] = (
	state,
	{ payload: { users } }
) => {
	const userList = {};
	const allIds = [];
	_.forEach(users, (user) => {
		userList[user.user_id] = user;
		allIds.push(user.user_id);
	});
	return {
		...state,
		users: { userList, allIds },
	};
};

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_CLEAR_USERS] = (state) => {
	const newState = { ...state };
	newState.users = { userList: {}, allIds: [] };
	return newState;
};

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_RECEIVE_ROLES_FROM_TS] = (
	state,
	{ payload: { roles } }
) => ({
	...state,
	tsRoles: roles,
});

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_RECEIVE_USER_ROLES] = (
	state,
	{ payload: { roles } }
) => {
	const newState = { ...state };
	newState.roles = roles;
	return newState;
};

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_UPDATE_USER_FIELD] = (
	state,
	{ payload: { user_id, key, val } }
) => ({
	...state,
	users: {
		...state.users,
		userList: {
			...state.users.userList,
			[user_id]: {
				...state.users.userList[user_id],
				[key]: val,
				password: '',
				confirm_password: '',
			},
		},
	},
});

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_SELECT_USER] = (
	state,
	{ payload: { user_id, role } }
) => {
	const newState = {
		...state,
		selectedUser: { ...state.selectedUser, user_id, role },
	};
	return newState;
};

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_CREATE_USER_SUCCESS] = (
	state,
	{ payload: { user } }
) => ({
	...state,
	users: {
		userList: { ...state.users.userList, [user.user_id]: user },
		allIds: [...state.users.allIds, user.user_id],
	},
});

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_ADD_USER_TO_MODEL_SUCCESS] = (
	state,
	{ payload: { user, role } }
) => ({
	...state,
	modelUsers: [
		...state.modelUsers,
		{
			...user,
			id: user.email,
			email: user.email,
			role: role.name,
			name: `${user.first_name} ${user.last_name}`,
		},
	],
});

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_UPDATE_USER_ROLE_SUCCESS] = (
	state,
	{ payload: { email, role } }
) => {
	const newState = { ...state };
	const index = _.findIndex(newState.modelUsers, (u) => u.id === email);
	newState.modelUsers[index].role = role.name;
	newState.selectedUser.role = role;
	return newState;
};

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_DELETE_USER_SUCCESS] = (
	state,
	{ payload: { user_id } }
) => ({
	...state,
	users: {
		userList: R.omit([user_id], state.users.userList),
		allIds: [state.users.allIds.filter((id) => id !== user_id)],
	},
});

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_DELETE_INACTIVE_USER_SUCCESS] = (
	state,
	{ payload: { user } }
) => {
	const index = state.modelUsers.findIndex((u) => u.id === user.id);
	if (index > 0) {
		return { ...state, modelUsers: R.remove(index, 1, state.modelUsers) };
	}
	return state;
};

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_DELETE_USER_FROM_MODEL_SUCCESS] =
	(state, { payload: { user } }) => {
		const modelIndexToRemove = state.users.userList[
			user.user_id
		].models.findIndex((model) => model === state.selectedModel.model_name);
		return {
			...state,
			modelUsers: state.modelUsers.filter((u) => u.email !== user.email),
			users: {
				...state.users,
				userList: {
					...state.users.userList,
					[user.user_id]: {
						...state.users.userList[user.user_id],
						models: R.remove(
							modelIndexToRemove,
							1,
							state.users.userList[user.user_id].models
						),
					},
				},
			},
		};
	};

handlers[ModelOptionsActionTypes.MODEL_OPTIONS_TOGGLE_LOCK_USER_SUCCESS] = (
	state,
	{ payload: { user } }
) => ({
	...state,
	modelUsers: update(
		(u) => u.email === user.email,
		(existingUser) => ({ ...existingUser, locked: !existingUser.locked }),
		state.modelUsers
	),
	users: {
		...state.users,
		userList: {
			...state.users.userList,
			[user.user_id]: {
				...state.users.userList[user.user_id],
				locked: !state.users.userList[user.user_id].locked,
			},
		},
	},
});

handlers[LOGOUT] = () => initialState;
