/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

enum FeatureFlags {
	rapidReports = 'rapidReports',
	queryTool = 'queryTool',
	ppoEnabled = 'ppoEnabled',
	onPrem = 'onPrem',
	onCloud = 'onCloud',
	dataDiscovery = 'dataDiscovery',
	watsonAssistant = 'watsonAssistant',
	isPayeeWebV2Enabled = 'isPayeeWebV2Enabled',
	rapidReportsDisabled = 'rapidReportsDisabled',
	dataDiscoveryDisabled = 'dataDiscoveryDisabled',
	awsDataDiscoveryEnabled = 'awsDataDiscoveryEnabled',
	enableCOSPublish = 'enableCOSPublish',
	hierarchies = 'hierarchies',
	rapidReportHierarchies = 'rapidReportHierarchies',
	ppoIncrementalOptimizations = 'ppoincrementaloptimizations',
	currentRowRestrictions = 'currentRowRestrictions',
	dataStorePreviewFromComposer = 'dataStorePreviewFromComposer',
	enableSymonAPIKey = 'enableSymonAPIKey',
	inquiries = 'inquiries',
	modernNavBar = 'modernNavBar',
	disableSupportLiveChat = 'disableSupportLiveChat',
	disableELearning = 'disableELearning',
	supportLiveChat = 'supportLiveChat',
	elearning = 'elearning',
	supportDocumentationEnabled = 'supportDocumentationEnabled',
	ppoAllowVCVFilters = 'ppoAllowVCVFilters',
	lazyLoadViewTable = 'lazyLoadViewTable', // Use to do lazy first load for view tables. ( top 900 rows )
	python3_7 = 'python3_7', // TODO: remove this feature flag after the feature is released to everyone
	disableHeap = 'disableHeap',
	lessLines = 'lessLines',
	modernInquirySubmissions = 'modernInquirySubmissions',
	classicHomePage = 'classicHomePage',
	classicManageUserPage = 'classicManageUserPage',
	classicDownloadsPage = 'classicDownloadsPage',
	classicLiveActivities = 'classicLiveActivities',
	classicActivitiesOnHomePage = 'classicActivitiesOnHomePage',
	classicAudit = 'classicAudit',
	classicPerformance = 'classicPerformance',
	classicWorkflow = 'classicWorkflow',
	dataModule = 'dataModule',
	eHelpDemo = 'eHelpDemo',
	forceThreeStepMigrationCheck = 'forceThreeStepMigrationCheck',
	filterPin = 'filterPin',
	notebooks = 'notebooks',
	plansEnabled = 'plansEnabled',
	peopleModule = 'peopleModule',
	disableComposer = 'disableComposer',
	symonEmbeddedImportsDisabled = 'symonEmbeddedImportsDisabled',
	extendedColumns = 'extendedColumns',
	extraFontEnabled = 'extraFontEnabled',
	newScheduler = 'newScheduler',
	disableAlexanderGroupDestination = 'disableAlexanderGroupDestination',
	disableValidationOnCalendarLevel = 'disableValidationOnCalendarLevel',
	schedulerComponentCalcs = 'schedulerComponentCalcs',
}
export default FeatureFlags;
