/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useEffect, useState, useCallback } from 'react';
import ErrorPage from 'icm-core/lib/components/errorPageComponent';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import R from 'ramda';
import PenguinImage from 'styles/images/penguinSprinkles-01.png';

const penguinStyle = {
	backgroundImage: `url(${PenguinImage})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'contain',
};

export default injectIntl(({ intl }: InjectedIntlProps) => {
	const [pressedKeys, setPressedKeys] = useState([] as string[]);
	const [showPenguin, setShowPenguin] = useState(false);

	useEffect(() => {
		document.title = intl.formatMessage({ id: 'ERROR_PAGE' });
	}, [intl]);

	useEffect(() => {
		if (pressedKeys.join('') === 'penguin') {
			setShowPenguin(true);
		}
	}, [pressedKeys]);

	const easterEgg = useCallback(
		(e) => {
			setPressedKeys(
				R.takeLast('penguin'.length, [...pressedKeys, e.key as string])
			);
		},
		[pressedKeys]
	);

	return (
		<div
			tabIndex={0}
			onKeyUp={easterEgg}
			style={showPenguin ? penguinStyle : {}}
		>
			<ErrorPage
				errorId="ERROR_ERROR_LOADING"
				errorDescriptionId="ERROR_GO_BACK"
			/>
		</div>
	);
});
