/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { useIntl } from 'icm-core/lib/contexts/intlContext';
import React, { useEffect, useState } from 'react';
import styled from 'react-emotion';
import {
	ListItem,
	List,
	Box,
	DragHandle,
	Icon,
	Tooltip,
	HTMLLink,
	colorGray2,
	ScrollIndicator,
	colorCobalt3,
} from '@varicent/components';
import { Button, Classes, Position, Switch, Toaster } from '@blueprintjs/core';
import { css } from 'emotion';
import { Information20, Launch20 } from '@carbon/icons-react';
import PermissionCheck from 'components/common/permissions/permissionCheck';
import FeatureFlagCheck from 'components/common/permissions/featureFlagCheck';
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
} from 'react-beautiful-dnd';
import { move } from 'ramda';
import { IGlobalStateShape } from 'store';
import { setSidebarCustomization } from 'icm-rest-client/lib/controllers/model';
import styleVars from 'styles/config/default';
import { assert } from 'icm-core/lib/utils/typeHelpers';

const BoxWithIcon = styled(Box)`
	.bp3-icon {
		margin: auto 0.5rem auto 0;
	}
`;

const TooltipText = styled.p`
	color: rgb(${colorGray2});
	font-size: 0.875rem;
	line-height: 1.25rem;
`;

const LinkTooltipText = styled(HTMLLink)`
	font-size: 0.875rem;
	line-height: 1.25rem;
`;

const RightContainer = styled.div`
	display: flex;
	.bp3-icon {
		margin: auto 0.5rem auto 0;
	}
`;

const TopRightToaster = Toaster.create({
	position: Position.TOP_RIGHT,
	// don't cover navbar
	className: css`
		margin-top: ${styleVars.HeaderBarHeight};
	`,
});

const SecondaryNavConfigs: React.FC<{
	visibleLinks:
		| IGlobalStateShape['sidebar']['linksWithNewPulse']
		| IGlobalStateShape['sidebar']['linksWithOldPulse'];
	setConfigDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setVisibleLinksMapped: React.Dispatch<
		React.SetStateAction<
			| IGlobalStateShape['sidebar']['linksWithNewPulse']
			| IGlobalStateShape['sidebar']['linksWithOldPulse']
			| undefined
		>
	>;
	mapVisibleLinks: (newVisibleLinks: any) => any;
}> = ({
	visibleLinks,
	setConfigDrawerIsOpen,
	setVisibleLinksMapped,
	mapVisibleLinks,
}) => {
	const { formatMessage } = useIntl();
	const [currentFormState, setCurrentFormState] = useState(
		visibleLinks.filter((item) => item.text !== 'HOME')
	);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (currentFormState !== visibleLinks)
			setCurrentFormState(currentFormState);
	}, [visibleLinks]);

	const saveCurrentConfigs = () => {
		const payload = currentFormState.map((tab) => ({
			tabKey: tab.key as string,
			show: tab.isEnabled,
		}));
		const homeTab = visibleLinks.filter((item) => item.text === 'HOME')[0];
		assert(homeTab.key);
		setIsLoading(true);
		setSidebarCustomization([
			{ tabKey: homeTab.key, show: true },
			...payload,
		]).then((res) => {
			setIsLoading(false);
			setVisibleLinksMapped(mapVisibleLinks(res));
			setConfigDrawerIsOpen(false);
			TopRightToaster.show({
				intent: 'success',
				// Need to update the text for next release (using a string that is already translated.)
				message: formatMessage({ id: 'ADMIN_OPTIONS_SAVE_SUCCESS_MESSAGE' }),
			});
		});
	};

	const onChangeItemVisibilityStatus = (itemToUpdate) => {
		const updatedState = currentFormState.map((item) => {
			if (itemToUpdate === item) {
				return {
					...item,
					isEnabled: !item.isEnabled,
				};
			}
			return item;
		});
		setCurrentFormState(updatedState);
	};

	const categoriesDragEnd = (
		dragResults: DropResult,
		currentFormState: IGlobalStateShape['sidebar']['linksWithNewPulse']
	) => {
		if (!dragResults.destination) {
			return;
		}

		const result = move(
			dragResults.source.index,
			dragResults.destination.index,
			currentFormState
		);

		setCurrentFormState(result);
	};

	return (
		<>
			<ScrollIndicator>
				<p
					className={css`
						padding-bottom: 0.5rem;
						padding-top: 0.5rem;
					`}
				>
					{formatMessage({ id: 'CONFIGURE_SECONDARY_NAV_DESCRIPTION' })}
				</p>

				<DragDropContext
					onDragEnd={(dragResults) =>
						categoriesDragEnd(dragResults, currentFormState)
					}
				>
					<Droppable droppableId="nav-droppable">
						{(provided) => (
							<List {...provided.droppableProps} ref={provided.innerRef}>
								{currentFormState.map((item, index) => (
									<PermissionCheck permission={item.permission} key={item.text}>
										<FeatureFlagCheck
											feature={item.feature}
											invertFeatureFlag={item.hideWhenFeatureEnabled}
										>
											<Draggable
												key={item.text as any}
												draggableId={item.text as any}
												index={index}
											>
												{(provided) => (
													<ListItem
														whiteTheme
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className={css`
															margin-bottom: 0.25rem;
															padding-right: 0;
															&:hover,
															&:active {
																border: 1px solid rgb(${colorCobalt3});
																background-color: white;
															}
														`}
													>
														<DragHandle />
														<BoxWithIcon>
															{item.Icon && (
																<Icon>
																	<item.Icon />
																</Icon>
															)}
															{formatMessage({ id: item.text })}
														</BoxWithIcon>
														<RightContainer>
															<Tooltip
																position="top-left"
																invert
																flex
																css={css`
																	margin-left: 0.25rem;
																`}
																content={
																	<div className={Classes.RUNNING_TEXT}>
																		<TooltipText>
																			{formatMessage({
																				id: item.tooltipDescription,
																			})}
																		</TooltipText>
																		{item.linkToHelp && (
																			<LinkTooltipText
																				href={item.linkToHelp}
																				target="_blank"
																				text={
																					<>
																						{formatMessage({
																							id: 'NAV_LEARN_MORE',
																						})}
																						<Icon>
																							<Launch20 />
																						</Icon>
																					</>
																				}
																			/>
																		)}
																	</div>
																}
																interactionKind="hover"
															>
																<Icon style={{ cursor: 'default' }}>
																	<Information20 />
																</Icon>
															</Tooltip>
															<Switch
																checked={item.isEnabled}
																onChange={() =>
																	onChangeItemVisibilityStatus(item)
																}
																disabled={item.text === 'HOME'}
															/>
														</RightContainer>
													</ListItem>
												)}
											</Draggable>
										</FeatureFlagCheck>
									</PermissionCheck>
								))}
								{provided.placeholder}
							</List>
						)}
					</Droppable>
				</DragDropContext>
			</ScrollIndicator>
			<div className={Classes.DRAWER_FOOTER}>
				<Button
					text={formatMessage({ id: 'CANCEL' })}
					minimal
					large
					onClick={() => {
						setConfigDrawerIsOpen(false);
					}}
				/>
				<Button
					text={formatMessage({ id: 'SAVE' })}
					intent="primary"
					large
					disabled={currentFormState === visibleLinks}
					onClick={saveCurrentConfigs}
					loading={isLoading}
				/>
			</div>
		</>
	);
};

export default SecondaryNavConfigs;
