/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

// Accepts an array of messages that need to be formatted & translated.
export default class AggregateError {
	constructor(errors) {
		this.errors = errors;
	}
}
