// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 *
 * url: `api/v1/webtabs`
 */
export function getAll(
	query?: { filter?: string; orderBy?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'webtabs',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PortalAccess.WebTabDTO[]>(
		request
	);
}

/**
 * Get individual WebTab item by ID
 * url: `api/v1/webtabs/${id}`
 */
export function get(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `webtabs/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PortalAccess.WebTabDTO>(
		request
	);
}

/**
 * Create new WebTab
 * url: `api/v1/webtabs`
 */
export function post(
	webTabDTO: Varicent.RESTAPI.v1.DTOs.PortalAccess.WebTabDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'webtabs',
		apiVersion: 'v1' as const,
		data: webTabDTO,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PortalAccess.WebTabDTO>(
		request
	);
}

/**
 * Update individual WebTab item.
 * url: `api/v1/webtabs/${id}`
 */
export function put(
	id: Varicent.ID,
	webTabDTO: Varicent.RESTAPI.v1.DTOs.PortalAccess.WebTabDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `webtabs/${id}`,
		apiVersion: 'v1' as const,
		data: webTabDTO,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PortalAccess.WebTabDTO>(
		request
	);
}

/**
 * Delete Web Tab and update next web tab
 * url: `api/v1/webtabs`
 */
export function patch(
	webTabDTOs: Varicent.RESTAPI.v1.DTOs.PortalAccess.WebTabDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PATCH,
		url: 'webtabs',
		apiVersion: 'v1' as const,
		data: webTabDTOs,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PortalAccess.WebTabDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/payee/viewablewebtabs`
 */
export function getViewableTabsForPayee(
	query?: { getDDEInfographics?: boolean; mobileWebAccess?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/viewablewebtabs',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.PortalAccess.WebTabDTO[]>(
		request
	);
}
