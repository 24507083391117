/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { createAction } from 'redux-act';

import {
	PERMISSIONS_RECIEVE_CURRENT_PERMISSIONS,
	REQUEST_PERMISSIONS_RELOAD,
	ADD_PROCESSLIST_PERMISSION,
} from 'constants/actionTypes';

export const receivePermissions = createAction(
	PERMISSIONS_RECIEVE_CURRENT_PERMISSIONS
);
export const requestPermissionsReload = createAction(
	REQUEST_PERMISSIONS_RELOAD
);

export const addPLPermissions = createAction(
	ADD_PROCESSLIST_PERMISSION,
	(id: string) => ({
		id,
	})
);
