/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { spawn, all } from 'redux-saga/effects';
import authentication from './authentication';
import watchPermissions from './permissions';
import checkTimeout from './timeout';
import checkRenewToken from './renewToken';

export default function* root() {
	yield all([
		spawn(watchPermissions),
		spawn(authentication),
		spawn(checkTimeout),
		spawn(checkRenewToken),
	]);
}
