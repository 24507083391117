/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const HOME_LINK = '/';
export const PROCESS_LISTS_LINK = '/processlist';
export const COMPOSER_LINK = '/composer';
export const COMPOSER_LINK_WITH_COMPONENT = `${COMPOSER_LINK}/:id`;
export const WORKFLOWS_LINK = '/workflows';
export const PORTAL_ACCESS_LINK = '/portal';
export const PORTAL_ACCESS_CATEGORY_WEBTABS = 'webtabs';
export const PORTAL_ACCESS_CATEGORY_GROUPS = 'groups';
export const PORTAL_ACCESS_CATEGORY_TREES = 'trees';
export const PORTAL_ACCESS_CATEGORY_ASSIGNMENT = 'assignment';
export const PORTAL_ACCESS_CATEGORY_SIGN_OFF = 'signoff';
export const PORTAL_ACCESS_CATEGORY_INQUIRIES = 'inquiries';
export const PORTAL_ACCESS_WEBTABS_LINK = `${PORTAL_ACCESS_LINK}/${PORTAL_ACCESS_CATEGORY_WEBTABS}`;
export const PORTAL_ACCESS_GROUPS_LINK = `${PORTAL_ACCESS_LINK}/${PORTAL_ACCESS_CATEGORY_GROUPS}`;
export const PORTAL_ACCESS_TREES_LINK = `${PORTAL_ACCESS_LINK}/${PORTAL_ACCESS_CATEGORY_TREES}`;
export const PORTAL_ACCESS_ASSIGNMENT_LINK = `${PORTAL_ACCESS_LINK}/${PORTAL_ACCESS_CATEGORY_ASSIGNMENT}`;
export const PORTAL_ACCESS_SIGN_OFF_LINK = `${PORTAL_ACCESS_LINK}/${PORTAL_ACCESS_CATEGORY_SIGN_OFF}`;
export const PORTAL_ACCESS_INQUIRIES_LINK = `${PORTAL_ACCESS_LINK}/${PORTAL_ACCESS_CATEGORY_INQUIRIES}`;
export const SCHEDULER_LINK = '/scheduler';
export const TASKS_LINK = '/tasks';
export const TASK_ALERTS_LINK = '/tasksalerts';
export const RULES_LINK = '/rules';
export const GROUPS_LINK = '/groups';
export const DEFERRED_TASKS_LINK = '/deferredtasks';
export const AUDIT_LINK = '/audit';
export const ERROR_LOGS_LINK = '/errorlogs';
export const IMPORT_ERROR_LOGS_LINK = '/importerrorlogs';
export const EXPORT_ERROR_LOGS_LINK = '/exporterrorlogs';
export const SCHEDULER_ERROR_LOGS_LINK = '/schedulererrorlogs';
export const TASK_ERROR_LOGS_LINK = '/taskerrorlogs';
export const EXTERNAL_TOOLS_LOGS = '/exttoollog';
export const CALC_ENGINE_LOGS = '/calcenginelogs';
export const ACTIVITY_LINK = '/activity';
export const CALCULATE_LINK = '/calculate';
export const SCENARIOS_LINK = '/scenarios';
export const ANALYTICS_MEASURES_LINK = '/analyticsmeasures';
export const ROW_VIEWER_ROOT_LINK = '/rowviewer';
export const ROW_VIEWER_LINK = `${ROW_VIEWER_ROOT_LINK}/:table`;
export const CALC_ROW_VIEWER_ROOT_LINK = '/calcrowviewer';
export const CALC_ROW_VIEWER_LINK = `${CALC_ROW_VIEWER_ROOT_LINK}/:id`;
export const PORT_ROW_VIEWER_ROOT_LINK = '/portrowviewer';
export const PORT_ROW_VIEWER_LINK = `${PORT_ROW_VIEWER_ROOT_LINK}/:port`;
export const PUBLISHER_LINK = '/publisher';
export const DOWNLOADS_LINK = '/downloads';
export const DOWNLOADS_CATEGORY_PUBLISH = 'publish';
export const DOWNLOADS_CATEGORY_IMPORT = 'import';
export const DOWNLOADS_PUBLISH_LINK = `${DOWNLOADS_LINK}/${DOWNLOADS_CATEGORY_PUBLISH}`;
export const DOWNLOADS_IMPORT_LINK = `${DOWNLOADS_LINK}/${DOWNLOADS_CATEGORY_IMPORT}`;
export const PEOPLE_LINK = '/people';

export const ADMIN_LINK = '/admin';
export const SIGNATURE_PART = '/signaturelogs';
export const SIGNATURE_LINK = ADMIN_LINK + SIGNATURE_PART;
export const PERFORMANCE_LINK = '/performance';
export const HEALTH_DASHBOARD_LINK = '/healthdashboard';
export const MOBILE_OPTIONS_LINK = '/mobileoptions';
export const MY_ACCOUNT_LINK = '/myaccount';
export const UPDATE_PROFILE_LINK = '/updateprofile';
export const MANAGE_USERS_LINK = '/manageusers';
export const MANAGE_ROLES_LINK = '/manageroles';
export const MANAGE_PAYEE_TYPES_LINK = '/managepayeetypes';
export const MORE_OPTIONS_LINK = '/moreoptions';
export const INTEGRATIONS_LINK = '/integrations';
export const MODEL_SUMMARY_LINK = '/modelsummary';
export const COMPUTATION_CHART = '/computationchart';
export const COMPUTATION_CHART_LINK = `${ADMIN_LINK}${COMPUTATION_CHART}/`;
export const EXTERNAL_TOOLS_LOGS_LINK = `${ADMIN_LINK}${EXTERNAL_TOOLS_LOGS}`;
export const CALC_ENGINE_LOGS_LINK = `${ADMIN_LINK}${CALC_ENGINE_LOGS}`;
export const USER_SETTINGS_LINK = '/usersettings';
export const MANAGE_LINK = '/manage';
export const WEB_MESSAGES_LINK = '/webmessages';
export const SAVE_IMPORTS_LINK = '/saveimports';
export const SAVED_PUBLICATIONS_LINK = '/savedpublications';
export const SAVED_TRANSFORMATIONS_LINK = '/savedtransformations';
export const MANAGE_DOCUMENTS_LINK = '/managedocuments';
export const MANAGE_PAYEE_DOCUMENTS_LINK = '/managepayeedocuments';
export const HISTORY_TRACKING_LINK = '/historytracking';
export const DATA_GRID_TEMPLATES_LINK = '/datagridtemplates';
export const MIGRATION_LINK = '/migrations';
export const WEBREPORT_ROOT = '/presenter';
export const WEBREPORT_LINK = `${WEBREPORT_ROOT}/:id`;
export const WEBREPORT_PREVIEW_LINK = `${WEBREPORT_LINK}/preview`;
export const WEBFORM_ROOT_LINK = '/webform';
export const WEBFORM_LINK = `${WEBFORM_ROOT_LINK}/:id`;
export const TOOLS_LINK = '/tools';
export const CALENDARS_LINK = '/calendars';
export const OPTIMIZE_MODEL_LINK = '/optimizemodel';
export const QUERY_TOOL_LINK = '/querytool';
export const QUERY_TOOL_COMPOSER = `${ADMIN_LINK}${QUERY_TOOL_LINK}/:type/:id`;
export const PAYEE_GROUPS_LINK = '/payeegroups';
export const PULSE_ANALYTICS_LINK = '/pulseAnalytics';
export const PULSE_LINK = '/pulse';
export const STAR_SCHEMA_LINK = '/starschema';
export const EXTERNAL_TOOLS_LINK = '/externaltools';
export const RAPID_REPORTS_LINK = '/rapidreports';
export const DATA_DISCOVERY_DASHBOARD_LINK = '/datadiscovery';
export const SAVED_QUERYTOOL_LINK = '/savedquerytool';
export const HIERARCHIES_LINK = '/hierarchies';
export const SUPPORT_DOCUMENTATION_LINK = '/supportdocumentation';
export const PLANS_LINK = '/plans';

export const LOGIN_LINK = '/login';
export const SSO_AUTH_REDIRECT_LINK = '/sso_redirect';
export const OPTION_LINK = '/option';
export const RETRIEVE_CREDENTIALS_LINK = '/retrievecredentials';

export const TENANT_LINK = '/tenant';
export const TENANT_LOG_LINK = '/tenant/log';
export const HIERARCHY_ROOT_LINK = '/hierarchy';
export const HIERARCHY_LINK = `${HIERARCHY_ROOT_LINK}/:table`;

export const ERROR = '/error';
export const MAINTENANCE = '/maintenance';
export const ERROR_404 = '/pagenotfound';
export const STATUS_LINK = '/status';

export const PRESENTER_FLEXIBLE = '/presenteradaptive';
export const PRESENTER_ADAPTIVE_LINK = `${PRESENTER_FLEXIBLE}/:id`;
export const PRESENTER_ADAPTIVE_PREVIEW_LINK = `${PRESENTER_ADAPTIVE_LINK}/preview`;
export const REPORTS = '/reports';
export const INQUIRY_MANAGEMENT_LINK = '/inquiry';

export const ADAPTIVE_FORMS = '/forms';

export const DATA_MODULE = '/data';
export const PLAN_SETTINGS = `${ADMIN_LINK}${MORE_OPTIONS_LINK}/plan`;
