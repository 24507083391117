/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import EditableColumnDefinition from './editColumnDefinition';

export default class CustomColumnDefinition extends EditableColumnDefinition {
	constructor(...props) {
		super(...props);
	}

	getCellContent(
		value,
		cellData,
		cellDataKey,
		rowData,
		rowIndex,
		columnData,
		width
	) {
		return columnData.columnDefinition.getCell(
			value,
			cellData,
			cellDataKey,
			rowData,
			rowIndex,
			columnData,
			width
		);
	}

	update(...props) {
		return new CustomColumnDefinition(this, ...props);
	}
}
