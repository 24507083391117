/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

// Admin Options actions
export const ADMIN_OPTIONS_REQUEST_DATA = 'ADMIN_OPTIONS_REQUEST_DATA';
export const ADMIN_OPTIONS_RECEIVE_DATA = 'ADMIN_OPTIONS_RECEIVE_DATA';
export const ADMIN_OPTIONS_RECEIVE_DTO = 'ADMIN_OPTIONS_RECEIVE_DTO';
export const ADMIN_OPTIONS_PATCH_SUCCESS = 'ADMIN_OPTIONS_PATCH_SUCCESS';
export const ADMIN_OPTIONS_DELETE_API_KEY_SUCCESS =
	'ADMIN_OPTIONS_DELETE_API_KEY_SUCCESS';
export const ADMIN_OPTIONS_PATCH_ERROR = 'ADMIN_OPTIONS_PATCH_ERROR';
export const ADMIN_OPTIONS_SHOW_ERROR = 'ADMIN_OPTIONS_SHOW_ERROR';
export const ADMIN_OPTIONS_UPDATE_DTO_SUCCESS =
	'ADMIN_OPTIONS_UPDATE_DTO_SUCCESS';
export const ADMIN_OPTIONS_UPDATE_OPTIONS = 'ADMIN_OPTIONS_UPDATE_OPTIONS';
export const ADMIN_OPTIONS_UPDATE_SYMON_OPTIONS =
	'ADMIN_OPTIONS_UPDATE_SYMON_OPTIONS';
export const ADMIN_OPTIONS_UPDATE_DATA_TIER_OPTIMIZATION =
	'ADMIN_OPTIONS_UPDATE_DATA_TIER_OPTIMIZATION';
export const ADMIN_OPTIONS_CLOSE_SAVE_SUCCESS =
	'ADMIN_OPTIONS_CLOSE_SAVE_SUCCESS';
export const ADMIN_OPTIONS_CLOSE_ERROR = 'ADMIN_OPTIONS_CLOSE_ERROR';
export const ADMIN_OPTIONS_RECEIVE_THEME = 'ADMIN_OPTIONS_RECEIVE_THEME';
export const ADMIN_OPTIONS_RECEIVE_REPORTID = 'ADMIN_OPTIONS_RECEIVE_REPORTID';
