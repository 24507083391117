/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import classnames from 'classnames';
import '../../styles/components/button.scss';
import VReactComponent from 'components/common/reactComponent';

const getInitialState = (props) => ({
	focus: props.enabled && props.focus,
});

export default class VButton extends VReactComponent {
	static propTypes = {
		label: PropTypes.string,
		action: PropTypes.func,
		changeFocus: PropTypes.func,
		type: PropTypes.oneOf(['primary', 'secondary', 'destructive']),
		size: PropTypes.oneOf([
			'small',
			'regular',
			'regular-120width',
			'large',
			'long',
			'100',
		]), // long is regular without max-width
		width: PropTypes.oneOf(['auto', 'full']),
		enabled: PropTypes.bool,
		focus: PropTypes.bool,
		className: PropTypes.string,
		isDarkTheme: PropTypes.bool,
		highlight: PropTypes.bool,
		useEnter: PropTypes.bool,
		'data-test': PropTypes.string,
	};

	static defaultProps = {
		enabled: true,
		focus: false,
		type: 'primary',
		size: 'regular',
		width: 'auto',
		className: '',
		useEnter: true,
	};

	state = getInitialState(this.props);

	componentWillReceiveProps(nextProps) {
		this.setState(getInitialState(nextProps));
	}

	onButtonClick(val) {
		const { action, enabled } = this.props;
		if (action && enabled) {
			action(val);
		}
	}

	onKeyPress(val) {
		const { changeFocus, useEnter } = this.props;
		if (val.keyCode !== 9) {
			val.preventDefault();
		}
		if (val.keyCode === 13 && useEnter) {
			this.onButtonClick(val);
		} else if ((val.keyCode === 37 || val.keyCode === 39) && changeFocus) {
			changeFocus(val.keyCode);
		}
	}

	toggleFocus() {
		this.setState({ focus: !this.state.focus });
	}

	render() {
		const {
			label,
			width,
			enabled,
			className,
			isDarkTheme,
			type,
			size,
			highlighted,
			style,
		} = this.props;
		const { focus } = this.state;
		const buttonType = `v-btn-${type}${isDarkTheme ? '-dark' : ''}`;
		const buttonSize = `v-btn-${size}`;
		const buttonClassName = classnames(
			'v-text-ellipsis',
			'v-button',
			className,
			buttonType,
			buttonSize,
			{
				'v-btn-width-full': width === 'full',
				'v-btn-disable': !enabled,
				'v-btn-highlighted': highlighted,
			}
		);

		return (
			<input
				style={style}
				type="button"
				className={buttonClassName}
				value={label}
				onClick={(e) => this.onButtonClick(e)}
				onKeyDown={(e) => this.onKeyPress(e)}
				autoFocus={focus}
				onFocus={this.toggleFocus.bind(this)}
				onBlur={this.toggleFocus.bind(this)}
				data-test={this.props['data-test']}
				aria-label={label}
			/>
		);
	}
}
