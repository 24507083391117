import styled from 'react-emotion';
import { colorCobalt3 } from '@varicent/components';

export const TilesWrapper = styled.div<{
	fixedColumnCount?: number;
	initialColumnCount?: number;
}>(({ fixedColumnCount, initialColumnCount }) => {
	if (fixedColumnCount && initialColumnCount) {
		throw new Error(
			'Either define fixedColumnCount or initialColumnCount, not both'
		);
	}
	let defaultedColumnCount = fixedColumnCount ?? initialColumnCount ?? 4;
	const colAsPercentage = 100 / defaultedColumnCount;
	let sharedCss = `
			display: grid;
			grid-gap: 1rem;
			grid-template-columns: repeat(${defaultedColumnCount}, 1fr);
			grid-auto-rows: max-content;
			padding-top: 0.25rem;
			padding-bottom: 0.75rem;
			overflow: auto;

			> .bp3-card[aria-selected='true'],
			> .bp3-card[aria-checked='true'] {
				box-shadow: 0 0 0 1px rgb(${colorCobalt3}), 0 0 0 rgb(${colorCobalt3}),
					0 0 0 rgb(${colorCobalt3});
			}

			> * {
				overflow-wrap: break-word;
				word-wrap: break-word;
				word-break: break-all;
				word-break: break-word;
			}

			@media all and (-ms-high-contrast: none) {
				margin-bottom: 0;
				display: flex;
				flex-wrap: wrap;
				> * {
					margin: 0.5rem;
					width: calc(${colAsPercentage}% - (0.5rem * ${defaultedColumnCount}));
					min-width: calc(${colAsPercentage}% - (0.5rem * ${defaultedColumnCount}));
				}
			}
		`;
	if (fixedColumnCount === undefined) {
		if (defaultedColumnCount > 2) {
			defaultedColumnCount--;
			sharedCss += `
					@media (max-width: 959px) and (min-width: 600px) {
						grid-template-columns: repeat(${defaultedColumnCount}, 1fr);

						@media all and (-ms-high-contrast: none) {
							width: calc(${Math.floor(
								100 / defaultedColumnCount
							)}% - (0.5rem * ${defaultedColumnCount}));
							min-width: calc(${Math.floor(
								100 / defaultedColumnCount
							)}% - (0.5rem * ${defaultedColumnCount}));
						}
					}
				`;
		}
		if (defaultedColumnCount > 1) {
			defaultedColumnCount--;
			sharedCss += `
					@media (max-width: 599px) {
						grid-template-columns: repeat(${defaultedColumnCount}, 1fr);
						@media all and (-ms-high-contrast: none) {
							width: calc(${Math.floor(
								100 / defaultedColumnCount
							)}% - (0.5rem * ${defaultedColumnCount}));
							min-width: calc(${Math.floor(
								100 / defaultedColumnCount
							)}% - (0.5rem * ${defaultedColumnCount}));
						}
					}
				`;
		}
	}
	return sharedCss;
});
