/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { createAction } from 'redux-act';

import * as ModalActionTypes from '../constants/modal/modalActionTypes';

export const openModal = createAction(
	ModalActionTypes.MODAL_OPEN,
	(modalName, modalContextProps) => ({
		modalName,
		modalContextProps,
	})
);

export const openDeleteConfirmationModal = createAction(
	ModalActionTypes.MODAL_OPEN_DELETE_CONFIRMATION,
	(modalName, name: string, optionName, data, message, action) => ({
		modalName,
		name,
		optionName,
		data,
		message,
		action,
	})
);

export const closeModal = createAction(
	ModalActionTypes.MODAL_CLOSE,
	(modalName) => ({
		modalName,
	})
);

export const toggleExpand = createAction(
	ModalActionTypes.MODAL_TOGGLE_EXPAND,
	(modalName) => ({
		modalName,
	})
);
