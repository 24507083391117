/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import MaintenanceService, {
	bannerTypes,
} from '../services/maintenanceService';

import moment from 'moment';
import { showMessage } from 'actions/bannerMessageActions';
import * as modalActions from './modalActions';
import { MAINTENANCE_MODAL } from '../constants/modalNames';
import { LOAD_UPCOMING_MAINTENANCES } from '../constants/actionTypes';
import LocaleHelper from '../helpers/localeHelper';
import { AdminWebBannerNotifiedTypes } from '../constants/banner/adminWebBannerOptions';

export function loadUpcomingAndOngoingMaintenances(onSuccess?) {
	return (dispatch, getState) => {
		return MaintenanceService.getOngoingBanners().then((banners) => {
			const maintenances = banners
				.filter((banner) => banner.type === bannerTypes.maintenance)
				.map((banner) => banner.banner);
			dispatch({
				type: LOAD_UPCOMING_MAINTENANCES,
				payload: { upcomingMaintenances: maintenances },
			});
			if (onSuccess) {
				onSuccess(maintenances, dispatch, getState);
			}
			return maintenances;
		});
	};
}

export function loadUOngoingBanners(onSuccess?) {
	return (dispatch, getState) => {
		return MaintenanceService.getOngoingBanners().then((banners) => {
			const maintenances = banners
				.filter((banner) => banner.type === bannerTypes.maintenance)
				.map((banner) => banner.banner);
			dispatch({
				type: LOAD_UPCOMING_MAINTENANCES,
				payload: { upcomingMaintenances: maintenances },
			});
			if (onSuccess) {
				onSuccess(banners, dispatch, getState);
			}
			return banners;
		});
	};
}

const isOngoingMaintenance = (banner) =>
	moment.utc(banner.start_time).isBefore() && !banner.completed_time;

export function isUpcomingMaintenance(banner) {
	return moment.utc(banner.start_time).isAfter();
}

/*
 * decide if banner should be display
 * map each banner item accoding to it's type
 * base {type, message, ...param special to banner type}
 * message: { key: string, needTranslation: bool, ...param for formatMessage }
 */
const mapBanner = (type, banner) => {
	// always show ongoing maintenance and custom banner
	if (type === bannerTypes.maintenance) {
		if (isOngoingMaintenance(banner)) {
			const message = {
				key: 'MAINTENANCE_ONGOING_MAINTENANCES',
				needTranslation: true,
				time: banner.end_time,
			};
			return {
				type,
				message,
				start_time: banner.start_time,
				end_time: banner.end_time,
				font_color: banner.font_color,
				background_color: banner.background_color,
			};
		}
	} else if (type === bannerTypes.custom) {
		return {
			type,
			message: {
				key: banner.text,
				needTranslation: false,
			},
			font_color: banner.font_color,
			background_color: banner.background_color,
		};
	}

	if (type === bannerTypes.releaseNotes) {
		const localRelease = localStorage.getItem(
			AdminWebBannerNotifiedTypes.Release
		);
		const notifiedRelease = localRelease ? JSON.parse(localRelease) : null;
		const compareRelease = (r) => r.link === banner.link && r.type === type;
		if (notifiedRelease === null || !compareRelease(notifiedRelease)) {
			return {
				type,
				message: {
					key: 'BANNER_RELEASE_NOTE_WITH_DETAILS',
					needTranslation: true,
				},
				link: banner.link,
				font_color: banner.font_color,
				background_color: banner.background_color,
			};
		}
	} else if (type === bannerTypes.maintenance) {
		if (isUpcomingMaintenance(banner)) {
			const localMaintenance = localStorage.getItem(
				AdminWebBannerNotifiedTypes.Maintenance
			);
			const notifiedMaintenance = localMaintenance
				? JSON.parse(localMaintenance)
				: null;
			const message = computeMaintenanceTime(banner);
			const compareMaintenance = (m) =>
				m.type === type &&
				m.start_time === banner.start_time &&
				m.end_time === banner.end_time;
			if (
				notifiedMaintenance === null ||
				!compareMaintenance(notifiedMaintenance)
			) {
				return {
					type,
					message,
					start_time: banner.start_time,
					end_time: banner.end_time,
					font_color: banner.font_color,
					background_color: banner.background_color,
				};
			}
		}
	}
	return null;
};

const computeMaintenanceTime = (banner) => {
	const daysFromNow = moment.utc(banner.start_time).diff(moment.utc(), 'days');
	const hoursFromNow = moment
		.utc(banner.start_time)
		.diff(moment.utc(), 'hours');
	if (daysFromNow < 0) {
		throw new Error('duration can not be negative');
	}
	if (daysFromNow === 0) {
		if (hoursFromNow < 1) {
			return {
				key: 'MAINTENANCE_UPCOMING_MAINTENANCES_LESS_THAN_ONE_HOUR',
				needTranslation: true,
			};
		}
		return {
			key: 'MAINTENANCE_UPCOMING_MAINTENANCES_HOURS',
			needTranslation: true,
			hours: moment.utc(banner.start_time).diff(moment.utc(), 'hours'),
		};
	} else if (daysFromNow === 1) {
		return {
			key: 'MAINTENANCE_UPCOMING_MAINTENANCES_ONE_DAY',
			needTranslation: true,
		};
	} else {
		return {
			key: 'MAINTENANCE_UPCOMING_MAINTENANCES',
			needTranslation: true,
			days: daysFromNow,
		};
	}
};

// get the latest ongoing and the earliest upcoming maintenances
export function getDisplayMaintenance(banners) {
	const allMaintenances = banners.filter(
		(banner) => banner.type === bannerTypes.maintenance
	);

	const ongoingMaintenance = allMaintenances.filter((maintenance) =>
		isOngoingMaintenance(maintenance.banner)
	);
	const upcomingMaintenance = allMaintenances.filter((maintenance) =>
		isUpcomingMaintenance(maintenance.banner)
	);

	const lastOngoingMaintenance = ongoingMaintenance.length
		? ongoingMaintenance.reduce((bestMatch, current) =>
				moment.utc(bestMatch.banner.end_time).isAfter(current.banner.end_time)
					? bestMatch
					: current
		  )
		: null;

	const earliestUpcomingMaintenance = upcomingMaintenance.length
		? upcomingMaintenance.reduce((bestMatch, current) =>
				moment
					.utc(bestMatch.banner.start_time)
					.isBefore(current.banner.start_time)
					? bestMatch
					: current
		  )
		: null;

	const allDisplayMaintenance = [
		lastOngoingMaintenance,
		earliestUpcomingMaintenance,
	];

	const GET_MAINTENANE_DATA_TYPE_FOR_TEST = 'GET_MAINTENANE_DATA_TYPE_FOR_TEST';
	return {
		type: GET_MAINTENANE_DATA_TYPE_FOR_TEST,
		payload: allDisplayMaintenance.filter((banner) => !!banner),
	};
}

/*
 * Banner options: maintenance, release-notes, custom
 * banners maps to [{ type, message, link}, {type, message, link}...]
 */
export function showOngoingBanners() {
	return loadUOngoingBanners((banners: any[], dispatch, getState) => {
		if (banners.length) {
			// display only latest ongoing and earliest upcoming maintenance
			const { payload } = getDisplayMaintenance(banners);
			const nonMaintenanceBanners = banners.filter(
				(banner) => banner.type !== bannerTypes.maintenance
			);
			const allDisplayBanners = payload.concat(nonMaintenanceBanners);
			const showBanners = allDisplayBanners
				.map((banner) => mapBanner(banner.type, banner.banner))
				.filter((banner) => !!banner);

			displayBanner(showBanners);
		}

		function displayBanner(banners, message = '') {
			const action = modalActions.openModal(MAINTENANCE_MODAL, undefined);

			dispatch(
				showMessage(
					message,
					LocaleHelper.formatMessage(
						getState(),
						'BANNER_RELEASE_NOTE_LINK_LABEL'
					),
					action,
					undefined,
					banners
				)
			);
		}
	});
}
