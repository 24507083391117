/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import VText from 'components/input/textComponent';
import React from 'react';
import { Link } from 'react-router';
import { LocationDescriptorObject } from 'history';
import VButton from 'components/common/buttonComponent';
import VLabel from 'components/common/labelComponent';
import {
	getSSO,
	getSSOByHost,
	loginLocal,
	loginRemote,
	loginRemoteMigration,
	setSSOChecked,
} from 'actions/authActions';
import {
	hidePasswordChange,
	updatePassword,
} from 'actions/changePasswordActions';
import {
	hideForgotPasswordRequest,
	hideForgotPasswordSubmit,
	requestPasscode,
	showForgotPasswordRequest,
	showForgotPasswordSubmit,
	submitPasscode,
} from 'actions/forgotPasswordActions';
import { showOngoingBanners } from 'actions/maintenanceActions';
import {
	clearMessage,
	restApiError,
	showTranslatedConfirmationMessage,
} from 'actions/messageActions';
import VBaseComponent from 'components/common/baseComponent';
import { IGlobalStateShape } from 'reducers';
import { RETRIEVE_CREDENTIALS_LINK } from '../constants/routerPaths';
import { manualCheckLoggedInThenCloseModal } from '../helpers/authHelper';
import RequiredTextValueRule from '../models/validation/requiredTextValueRule';
import { PASSCODE_LENGTH } from 'constants/login';
import { connect } from 'react-redux';
import TestContext from 'utils/testContext';
import styled from 'react-emotion';
import { intentPrimary, intentPrimaryHover } from '@varicent/components';

import '../styles/components/loginContainer.scss';

const ForgotPassword = styled.button`
	background: inherit;
	border: none;
	color: ${intentPrimary};
	font: inherit;
	font-size: inherit;
	padding: 0;

	&:focus {
		color: ${intentPrimaryHover};
	}
	&:hover {
		color: ${intentPrimaryHover};
	}
`;

const mapStateToProps = (state: IGlobalStateShape) => ({
	login: state.login,
	message: state.message && state.message.message,
	user: state.authentication && state.authentication.user,
});

const mapDispatchToProps = {
	clearMessage,
	restApiError,
	showTranslatedConfirmationMessage,
	loginRemote,
	loginRemoteMigration,
	setSSOChecked,
	getSSO,
	getSSOByHost,
	loginLocal,
	showOngoingBanners,
	updatePassword,
	hidePasswordChange,
	requestPasscode,
	hideForgotPasswordRequest,
	showForgotPasswordRequest,
	showForgotPasswordSubmit,
	submitPasscode,
	hideForgotPasswordSubmit,
};

// Instead of prop types:
interface IOwnProps {
	location: LocationDescriptorObject;
	modal: boolean;
	remote?: boolean;
	closeAuthModal?: () => void;
}

const enhance = connect(mapStateToProps, mapDispatchToProps);

interface ILocalState {
	username: string;
	password: string;
	newPassword: string;
	newPasswordConfirm: string;
	database: string;
	passcode: string;
	remoteHost: string;
}

const tryCancelEvent = (e) => {
	if (e) {
		if (e.hasOwnProperty('nativeEvent')) {
			/*
			 * e is a SyntheticMouseEvent wrapper around the browser native event. The
			 * native event is exposed by the nativeEvent property. The problem here
			 * seems to be related to how React wraps the event as sometimes the exposed
			 * event is null (producing an error here because a call to preventDefault
			 * is not possible and will obviously fail). So not only we need to check
			 * that the property nativeEvent is exposed, but also we need to make sure
			 * that it is not null and that the method we intend to call is there.
			 */
			if (e.nativeEvent && e.nativeEvent.preventDefault) {
				e.nativeEvent.preventDefault();
			}
		} else if (e.preventDefault) {
			/*
			 * we're receiving the native event directly, so invoke the preventDefault
			 * function with no further checks
			 */
			e.preventDefault();
		}
	}
};

class LoginContainer extends VBaseComponent<
	IOwnProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps,
	ILocalState
> {
	private nameElement: VText | null = null;
	private passwordElement: VText | null = null;
	private dbElement: VText | null = null;

	constructor(props, context) {
		super(props, context);

		this.state = {
			username: '',
			password: '',
			newPassword: '',
			newPasswordConfirm: '',
			database: '',
			passcode: '',
			remoteHost: '',
		};
	}

	componentDidMount() {
		const host = process.env.UI_HOST || location.hostname;
		const { modal, getSSOByHost, message, user } = this.props;

		if (!process.env.ICM10_REMOTE) {
			this.setState(JSON.parse(localStorage.getItem('icmdevlogin') as string));
		}

		/*
		 * If we're getting back here after IDP login - we don't want to
		 * redirect again if /sso_redirect produced an error message and
		 * didn't complete login since the message is login-related.
		 */
		if (!(message || user) || modal) {
			getSSOByHost(host, this.getNextPath(), modal);
		}
	}

	render() {
		const {
			showPasswordChange,
			ssoChecked,
			waitForPopup,
			showForgotPasswordSubmit: submit,
		} = this.props.login;
		const { remote } = this.props;
		const sfpr = this.props.login.showForgotPasswordRequest;
		const { ICM10_REMOTE } = process.env;
		if (waitForPopup) {
			return this.renderWaitForPopup();
		} else {
			return (
				<TestContext value="login">
					<div className="v-login-container v-login flex-fit">
						{remote ? (
							<div className="flex-fit">{this.renderLogin()}</div>
						) : (
							<div className="flex-fit">
								{!ICM10_REMOTE && this.renderLocalLogin()}
								{ICM10_REMOTE && !ssoChecked && this.renderUsernameForm()}
								{ICM10_REMOTE &&
									!showPasswordChange &&
									!sfpr &&
									!submit &&
									ssoChecked &&
									this.renderLogin()}
								{ICM10_REMOTE &&
									showPasswordChange &&
									!sfpr &&
									!submit &&
									ssoChecked &&
									this.renderChangePassword()}
								{ICM10_REMOTE &&
									!showPasswordChange &&
									sfpr &&
									!submit &&
									ssoChecked &&
									this.renderForgotPasswordRequest()}
								{ICM10_REMOTE &&
									!showPasswordChange &&
									!sfpr &&
									submit &&
									ssoChecked &&
									this.renderForgotPasswordSubmit()}
							</div>
						)}
						{!this.props.modal && this.renderFooter()}
					</div>
				</TestContext>
			);
		}
	}

	private getNextPath = () => {
		const { query, pathname } = this.props.location;
		const path = query && query.nextPathname ? query.nextPathname : pathname;

		if (query && query.nextQuery) {
			return `${path}?${query.nextQuery}`;
		}

		return path;
	};

	private validateHost = (remoteHost) => {
		remoteHost = remoteHost.toLowerCase();
		if (!remoteHost.startsWith('https://') && !remoteHost.startsWith('http://'))
			remoteHost = `https://${remoteHost}`;
		if (remoteHost.endsWith('/')) remoteHost = remoteHost.slice(0, -1);

		return remoteHost;
	};

	private onSubmitRemote = (e) => {
		tryCancelEvent(e);
		this.props.clearMessage();

		if (this.isValidData(false)) {
			const { username, password, remoteHost } = this.state;
			const { remote } = this.props;
			if (remote) {
				const url = this.validateHost(remoteHost);
				return this.props.loginRemoteMigration(
					username,
					password,
					{
						model: this.formatMessage('YOU_HAVE_NO_MODELS'),
						unknown: this.formatMessage('UNKNOWN_ERROR'),
						login: this.formatMessage('INVALID_CREDENTIALS'),
						maximum_attempts: this.formatMessage('MAXIMUM_ATTEMPTS'),
						remoteSettings: this.formatMessage('REMOTE_TS_ERROR'),
					},
					() => {
						if (remoteHost && this.props.closeAuthModal)
							this.props.closeAuthModal();
					},
					url
				);
			}
			this.props.loginRemote(
				username,
				password,
				{
					model: this.formatMessage('YOU_HAVE_NO_MODELS'),
					unknown: this.formatMessage('UNKNOWN_ERROR'),
					login: this.formatMessage('INVALID_CREDENTIALS'),
					maximum_attempts: this.formatMessage('MAXIMUM_ATTEMPTS'),
				},
				() => this.props.showOngoingBanners()
			);
		}
	};

	private onSubmitLocal = (e) => {
		tryCancelEvent(e);
		this.submitLocal();
	};

	private onUsernameChange = (value) => {
		this.props.setSSOChecked(false);
		this.setState({ username: value });
	};

	private onSubmitUsername = (e) => {
		tryCancelEvent(e);
		const { username } = this.state;

		if (username) {
			this.props.getSSO(username, this.getNextPath(), this.props.modal);
		}
	};

	private submitLocal = () => {
		this.props.clearMessage();
		if (this.isValidData(true)) {
			const { username, database } = this.state;
			this.props.loginLocal(username, database);
			this.props.showOngoingBanners();
			localStorage.setItem(
				'icmdevlogin',
				JSON.stringify({ username, database })
			);
		}
	};

	private onUpdateConfirmed = (e) => {
		tryCancelEvent(e);
		this.updatePassword();
	};

	private updatePassword = () => {
		const { username, password, newPassword, newPasswordConfirm } = this.state;
		this.setState({ newPassword: '', newPasswordConfirm: '' });
		if (newPassword !== newPasswordConfirm) {
			this.props.restApiError(
				null,
				null,
				'',
				null,
				this.formatMessage('CHANGE_PASSWORD_CONFIRM_PASSWORD_ERROR')
			);
		} else {
			this.props.updatePassword(username, password, newPassword, () => {
				this.setState({
					password: '',
					newPassword: '',
					newPasswordConfirm: '',
				});
				this.props.showTranslatedConfirmationMessage(
					'PASSWORD_CHANGE_SUCCESSFUL'
				);
				this.props.hidePasswordChange();
			});
		}
	};

	private requestForgotPasswordEmail = (e) => {
		tryCancelEvent(e);
		const { username } = this.state;

		(this.props.requestPasscode(username) as any).then((result) => {
			this.props.showTranslatedConfirmationMessage(
				'FORGOT_PASSWORD_EMAIL_SENT'
			);
			this.props.hideForgotPasswordRequest();
			this.props.showForgotPasswordSubmit(result.preview);
		});
	};

	private forgotPasswordSubmit = (e) => {
		tryCancelEvent(e);
		const { username, passcode, newPassword, newPasswordConfirm } = this.state;
		const { passcodePreview } = this.props.login;

		const fullPasscode = passcode.startsWith(`${passcodePreview}-`)
			? passcode
			: `${passcodePreview}-${passcode}`;

		if (newPassword !== newPasswordConfirm) {
			this.props.restApiError(
				null,
				null,
				'',
				null,
				this.formatMessage('CHANGE_PASSWORD_CONFIRM_PASSWORD_ERROR')
			);
		} else {
			(
				this.props.submitPasscode(username, fullPasscode, newPassword) as any
			).then(() => {
				this.setState({
					username: '',
					passcode: '',
					newPassword: '',
					newPasswordConfirm: '',
				});
				this.props.showTranslatedConfirmationMessage(
					'PASSWORD_CHANGE_SUCCESSFUL'
				);
				this.props.hideForgotPasswordSubmit();
			});
		}
	};

	private isValidData = (isLocal) => {
		const { username, database, password } = this.state;

		if (!this.nameElement) {
			return false;
		}

		if (isLocal) {
			if (!this.dbElement) {
				return false;
			}
			return (
				username &&
				this.nameElement.state.valid &&
				database &&
				this.dbElement.state.valid
			);
		}

		if (!this.passwordElement) {
			return false;
		}

		return (
			username &&
			this.nameElement.state.valid &&
			password &&
			this.passwordElement.state.valid
		);
	};

	private renderWaitForPopup = () => {
		return (
			<div>
				<div className="v-login-modal-caption">
					{this.formatMessage('COMPLETE_SSO_POPUP')}
				</div>
				<form
					className="pure-form pure-form-aligned v-lg-content-width"
					onSubmit={manualCheckLoggedInThenCloseModal}
				>
					<div>
						<div className="v-lg-options">
							<VButton
								className="v-btn-lg-padding"
								action={manualCheckLoggedInThenCloseModal}
								label={this.formatMessage('OK')}
								type="primary"
							/>
						</div>
					</div>
				</form>
			</div>
		);
	};

	private handleNameChange = (val) => this.setState({ username: val });

	private renderUsernameForm = () => {
		const { username } = this.state;
		return (
			<form
				className="pure-form pure-form-aligned v-lg-content-width"
				onSubmit={this.onSubmitUsername}
			>
				<div>
					<TestContext value="username">
						{(testId) => (
							<VText
								className="v-lg-content-width"
								placeholder={this.formatMessage('USER_NAME')}
								id="name"
								ref={(el) => (this.nameElement = el)}
								defaultValue={username}
								validationRules={[new RequiredTextValueRule()]}
								onChange={this.handleNameChange}
								autofocus
								data-test={testId}
								label={this.formatMessage('USER_NAME')}
							/>
						)}
					</TestContext>
					<div className="v-lg-options">
						<VButton
							className="v-btn-lg-padding"
							action={this.onSubmitUsername}
							label={this.formatMessage('SUBMIT')}
							type="primary"
							data-test="login-submit-btn"
						/>
					</div>
				</div>
			</form>
		);
	};

	private handleDbChange = (val) => this.setState({ database: val });

	private renderLocalLogin = () => {
		const { username, database } = this.state;

		return (
			<form
				className="pure-form pure-form-aligned v-lg-content-width"
				onSubmit={this.onSubmitLocal}
			>
				<div className="v-username-textbox">
					<VText
						className="v-lg-content-width"
						placeholder={this.formatMessage('USER_NAME')}
						id="name"
						ref={(el) => (this.nameElement = el)}
						value={username}
						validationRules={[new RequiredTextValueRule()]}
						onChange={this.handleNameChange}
						onEnter={this.submitLocal}
						autofocus
						label={this.formatMessage('USER_NAME')}
						data-test="username-cloud-screen"
					/>
				</div>
				<VText
					className="v-lg-content-width"
					placeholder={this.formatMessage('DATABASE')}
					id="db"
					ref={(el) => (this.dbElement = el)}
					type="database"
					value={database}
					validationRules={[new RequiredTextValueRule()]}
					onChange={this.handleDbChange}
					onEnter={this.submitLocal}
					maxLength={10000}
					data-test="password-cloud-screen"
				/>
				<div className="v-lg-options">
					<VButton
						className="v-btn-lg-padding"
						action={this.onSubmitLocal}
						label={this.formatMessage('LOGIN')}
						type="primary"
						style={{ backgroundColor: '#0063a8', color: 'white' }}
						data-test="login-btn-cloud-screen"
					/>
					<div className="v-add-link-lg">
						<Link to={RETRIEVE_CREDENTIALS_LINK}>
							{this.formatMessage('FORGOT_CREDENTIALS')}
						</Link>
					</div>
				</div>
			</form>
		);
	};

	private handlePasswordChange = (val) => this.setState({ password: val });

	private handleHostChange = (val) => this.setState({ remoteHost: val });

	private renderHost = () => {
		const { remoteHost } = this.state;
		return (
			<>
				<VText
					className="v-lg-content-width v-host-name"
					placeholder={this.formatMessage('REMOTE_HOST')}
					id="host"
					defaultValue={remoteHost}
					validationRules={[new RequiredTextValueRule()]}
					onChange={this.handleHostChange}
					onEnter={this.onSubmitRemote}
					label={this.formatMessage('REMOTE_HOST')}
				/>
			</>
		);
	};

	private renderLogin = () => {
		const { username, password } = this.state;
		const passwordLabel = this.formatMessage('USER_PASSWORD');
		const { remote } = this.props;
		return (
			<form
				className="pure-form pure-form-aligned v-lg-content-width"
				onSubmit={this.onSubmitRemote}
			>
				{remote && this.renderHost()}
				<TestContext value="username">
					{(testId) => (
						<div className="v-username-textbox">
							<VText
								className="v-lg-content-width"
								placeholder={this.formatMessage('USER_NAME')}
								id="name"
								ref={(el) => (this.nameElement = el)}
								defaultValue={username}
								validationRules={[new RequiredTextValueRule()]}
								onChange={this.onUsernameChange}
								onEnter={this.onSubmitRemote}
								data-test={testId}
								label={this.formatMessage('USER_NAME')}
							/>
						</div>
					)}
				</TestContext>
				<VText
					className="v-lg-content-width"
					placeholder={passwordLabel}
					id="psw"
					ref={(el) => (this.passwordElement = el)}
					type="password"
					label={passwordLabel}
					defaultValue={password}
					validationRules={[new RequiredTextValueRule()]}
					onChange={this.handlePasswordChange}
					onEnter={this.onSubmitRemote}
					autofocus
					data-test="login-pwd-input-field"
				/>
				<div className="v-lg-options" style={{ textAlign: 'center' }}>
					<VButton
						className="v-btn-lg-padding"
						action={this.onSubmitRemote}
						label={this.formatMessage('LOGIN')}
						type="primary"
						data-test="login-btn"
					/>
					{remote ? null : (
						<div className="v-add-link-lg">
							<ForgotPassword
								type="button"
								onClick={this.props.showForgotPasswordRequest}
							>
								{this.formatMessage('FORGOT_PASSWORD')}
							</ForgotPassword>
						</div>
					)}
				</div>
			</form>
		);
	};

	private handleNewPasswordChange = (val) =>
		this.setState({ newPassword: val });

	private renderChangePassword = () => {
		const { username, password, newPassword, newPasswordConfirm } = this.state;

		const currentPasswordLabel = this.formatMessage('CURRENT_PASSWORD');
		const newPasswordLabel = this.formatMessage('NEW_PASSWORD');
		const confirmNewPasswordLabel = this.formatMessage('NEW_PASSWORD_CONFIRM');

		return (
			<form
				className="pure-form pure-form-aligned v-lg-change-password-content-width"
				onSubmit={this.onUpdateConfirmed}
			>
				<div>
					<div>
						<VLabel
							label={this.formatMessage('UPDATE_PASSWORD_PROCEED')}
							className="v-lg-change-password-header"
							noline
						/>
					</div>
					<table>
						<tr>
							<td className="v-lg-change-password-label">
								<VLabel
									label={this.formatMessage('CHANGE_PASSWORD_USER_NAME_LABEL')}
									className="v-lg-change-password-label"
									noline
								/>
							</td>
							<td className="v-lg-change-password-dropdown">
								<VText
									className="v-lg-change-password-dropdown"
									placeholder={this.formatMessage('USER_NAME')}
									id="name"
									ref={(el) => (this.nameElement = el)}
									defaultValue={username}
									validationRules={[new RequiredTextValueRule()]}
									onChange={this.handleNameChange}
									onEnter={this.onUpdateConfirmed}
									autofocus
									label={this.formatMessage('USER_NAME')}
								/>
							</td>
						</tr>
						<tr>
							<td className="v-lg-change-password-label">
								<VLabel
									label={this.formatMessage('CHANGE_PASSWORD_PASSWORD_LABEL')}
									className="v-lg-change-password-label"
									noline
								/>
							</td>
							<td className="v-lg-change-password-dropdown">
								<VText
									className="v-lg-change-password-dropdown"
									placeholder={currentPasswordLabel}
									id="psw"
									ref={(el) => (this.passwordElement = el)}
									type="password"
									label={currentPasswordLabel}
									defaultValue={password}
									validationRules={[new RequiredTextValueRule()]}
									onChange={this.handlePasswordChange}
									onEnter={this.onUpdateConfirmed}
								/>
							</td>
						</tr>
						<tr>
							<td className="v-lg-change-password-label">
								<VLabel
									label={this.formatMessage(
										'CHANGE_PASSWORD_NEW_PASSWORD_LABEL'
									)}
									className="v-lg-change-password-label"
									noline
								/>
							</td>
							<td className="v-lg-change-password-dropdown">
								<VText
									className="v-lg-change-password-dropdown"
									placeholder={newPasswordLabel}
									id="new-psw"
									type="password"
									label={newPasswordLabel}
									defaultValue={newPassword}
									validationRules={[new RequiredTextValueRule()]}
									onChange={this.handleNewPasswordChange}
									onEnter={this.onUpdateConfirmed}
									data-test="new-password-input"
								/>
							</td>
						</tr>
						<tr>
							<td className="v-lg-change-password-label">
								<VLabel
									label={this.formatMessage(
										'CHANGE_PASSWORD_NEW_PASSWORD_CONFIRM_LABEL'
									)}
									className="v-lg-change-password-label"
									noline
								/>
							</td>
							<td className="v-lg-change-password-dropdown">
								<VText
									className="v-lg-change-password-dropdown"
									placeholder={confirmNewPasswordLabel}
									id="new-psw-confirm"
									type="password"
									label={confirmNewPasswordLabel}
									defaultValue={newPasswordConfirm}
									validationRules={[new RequiredTextValueRule()]}
									onChange={this.handleNewPasswordConfirmChange}
									onEnter={this.onUpdateConfirmed}
									data-test="confirm-new-password-input"
								/>
							</td>
						</tr>
					</table>
					<div className="v-lg-options v-lg-change-password-button-width">
						<VButton
							className="v-btn-lg-padding"
							action={this.onUpdateConfirmed}
							label={this.formatMessage('UPDATE_PASSWORD_CONFIRM')}
							type="primary"
							data-test="new-password-update-btn"
						/>
					</div>
				</div>
			</form>
		);
	};

	private renderForgotPasswordRequest = () => {
		return (
			<form
				className="pure-form pure-form-aligned v-lg-forgot-password-content-width"
				onSubmit={this.requestForgotPasswordEmail}
			>
				<div>
					<div>
						<VLabel
							label={this.formatMessage('FORGOT_PASSWORD_PROCEED')}
							className="v-lg-forgot-password-header"
							noline
						/>
					</div>
					<table>
						<tr>
							<td className="v-lg-forgot-password-label">
								<VLabel
									label={this.formatMessage('FORGOT_PASSWORD_USER_NAME_LABEL')}
									className="v-lg-forgot-password-label"
									noline
								/>
							</td>
							<td className="v-lg-forgot-password-dropdown">
								<VText
									className="v-lg-forgot-password-dropdown"
									placeholder={this.formatMessage('USER_NAME')}
									id="name"
									ref={(el) => (this.nameElement = el)}
									validationRules={[new RequiredTextValueRule()]}
									onChange={this.handleNameChange}
									autofocus
									label={this.formatMessage('USER_NAME')}
								/>
							</td>
						</tr>
					</table>
					<div className="v-lg-options v-lg-forgot-password-button-width">
						<VButton
							className="v-btn-lg-padding"
							action={this.requestForgotPasswordEmail}
							label={this.formatMessage('FORGOT_PASSWORD_SEND_EMAIL')}
							type="primary"
						/>
					</div>
				</div>
			</form>
		);
	};

	private handleNewPasswordConfirmChange = (val) =>
		this.setState({ newPasswordConfirm: val });

	private handlePasscodeChange = (val) => this.setState({ passcode: val });

	private renderForgotPasswordSubmit = () => {
		const { passcode, newPassword, newPasswordConfirm } = this.state;
		const { passcodePreview } = this.props.login;
		const newPasswordLabel = this.formatMessage('NEW_PASSWORD');
		const confirmNewPasswordLabel = this.formatMessage('NEW_PASSWORD_CONFIRM');
		const passcodePrefix = `${passcodePreview}-`;
		const passcodeSuffix = passcode.startsWith(passcodePrefix)
			? passcode.substr(passcodePrefix.length)
			: passcode;

		const canSubmit =
			passcodeSuffix.length === PASSCODE_LENGTH &&
			newPassword === newPasswordConfirm &&
			newPassword.length > 0;

		const forceReset =
			passcodeSuffix.length === PASSCODE_LENGTH && passcode !== passcodeSuffix;

		return (
			<form
				className="pure-form pure-form-aligned v-lg-forgot-password-content-width"
				onSubmit={this.forgotPasswordSubmit}
			>
				<div>
					<table>
						<tr>
							<td className="v-lg-forgot-password-label">
								<VLabel
									label={this.formatMessage('FORGOT_PASSWORD_PASSCODE_LABEL')}
									className="v-lg-forgot-password-label"
									noline
								/>
							</td>
							<td className="v-lg-forgot-password-preview-label">
								<VLabel
									label={`${passcodePreview}-`}
									className="v-lg-forgot-password-preview-label"
									noline
								/>
							</td>
							<td className="v-lg-forgot-password-passcode-dropdown">
								<VText
									className="v-lg-forgot-password-passcode-dropdown"
									placeholder={this.formatMessage(
										'FORGOT_PASSWORD_PASSCODE_LABEL'
									)}
									id="passcode"
									ref={(el) => (this.nameElement = el)}
									value={passcodeSuffix}
									forceReset={forceReset}
									validationRules={[new RequiredTextValueRule()]}
									onChange={this.handlePasscodeChange}
									onEnter={this.forgotPasswordSubmit}
									autofocus
								/>
							</td>
						</tr>
						<tr>
							<td className="v-lg-forgot-password-label">
								<VLabel
									label={this.formatMessage(
										'FORGOT_PASSWORD_NEW_PASSWORD_LABEL'
									)}
									className="v-lg-forgot-password-label"
									noline
								/>
							</td>
							<td colSpan={2} className="v-lg-forgot-password-dropdown">
								<VText
									className="v-lg-forgot-password-dropdown"
									placeholder={newPasswordLabel}
									id="psw"
									ref={(el) => (this.passwordElement = el)}
									type="password"
									label={newPasswordLabel}
									defaultValue={newPassword}
									validationRules={[new RequiredTextValueRule()]}
									onChange={this.handleNewPasswordChange}
									onEnter={this.forgotPasswordSubmit}
								/>
							</td>
						</tr>
						<tr>
							<td className="v-lg-forgot-password-label">
								<VLabel
									label={this.formatMessage(
										'FORGOT_PASSWORD_NEW_PASSWORD_CONFIRM_LABEL'
									)}
									className="v-lg-forgot-password-label"
									noline
								/>
							</td>
							<td colSpan={2} className="v-lg-forgot-password-dropdown">
								<VText
									className="v-lg-forgot-password-dropdown"
									placeholder={confirmNewPasswordLabel}
									id="psw"
									type="password"
									label={confirmNewPasswordLabel}
									defaultValue={newPasswordConfirm}
									validationRules={[new RequiredTextValueRule()]}
									onChange={this.handleNewPasswordConfirmChange}
									onEnter={this.forgotPasswordSubmit}
								/>
							</td>
						</tr>
					</table>
					<div className="v-lg-options v-lg-forgot-password-button-width">
						<VButton
							className="v-btn-lg-padding"
							action={this.forgotPasswordSubmit}
							label={this.formatMessage('FORGOT_PASSWORD_SUBMIT')}
							type="primary"
							enabled={canSubmit}
						/>
						<VButton
							className="v-forgot-password-btn-lg-padding"
							action={this.requestForgotPasswordEmail}
							label={this.formatMessage('FORGOT_PASSWORD_RESEND_PASSCODE')}
							type="primary"
						/>
					</div>
				</div>
			</form>
		);
	};

	private renderFooter = () => {
		return (
			<div className="v-login-bottom-bar">
				<div className="v-bottom-link-lg">
					<a
						href="https://www.varicent.com/privacy-policy"
						rel="noreferrer"
						target="_blank"
					>
						{this.formatMessage('PRIVACY')}
					</a>
				</div>
			</div>
		);
	};
}

export default enhance(LoginContainer as any);
