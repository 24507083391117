import { createReducer } from 'redux-act';
import { remoteMigrationActionTypes as actionTypes } from 'constants/remoteMigrationActionTypes';
import { MIGRATION_SELECT_SOURCE_MODEL } from 'constants/migration/migrationActionTypes';
import { LOGOUT } from 'constants/actionTypes';
import { Varicent } from 'icm-rest-client';

export type StateShape = {
	remoteModels: string[];
	remoteToken: string | null;
	remoteMigrations: Varicent.RESTAPI.v1.DTOs.Migration.RemoteMigrationDTO[];
	tenantServicesRemoteURL: string;
	currentMigration?: Varicent.RESTAPI.v1.DTOs.Migration.RemoteMigrationDTO;
};

const defaultState: StateShape = {
	remoteModels: [],
	remoteToken: null,
	remoteMigrations: [],
	tenantServicesRemoteURL: '',
	currentMigration: undefined,
};

const reducer = createReducer<StateShape>({}, defaultState);

reducer.on(LOGOUT, () => defaultState);

reducer.on(actionTypes.REMOTE_MIGRATION_LOGIN, (state, { jwt, models }) => ({
	...state,
	remoteToken: jwt,
	remoteModels: models,
}));

reducer.on(actionTypes.REMOTE_MIGRATION_GET, (state, { remoteMigrations }) => ({
	...state,
	remoteMigrations,
}));

reducer.on(
	actionTypes.REMOTE_MIGRATION_CREATE,
	(state, { model, dataCenter, remoteMigrationId }) => ({
		...state,
		remoteMigrations: [
			...state.remoteMigrations,
			{ model, dataCenter, remoteMigrationId },
		],
	})
);

reducer.on(
	actionTypes.REMOTE_MIGRATION_SET,
	(state, { remoteMigrationId, model, dataCenter }) => ({
		...state,
		currentMigration: { model, dataCenter, remoteMigrationId },
	})
);

reducer.on(actionTypes.REMOTE_MIGRATION_CLEAR, () => ({
	...defaultState,
}));

reducer.on(MIGRATION_SELECT_SOURCE_MODEL, (state, { selectedModel }) => {
	if (!state.remoteModels.includes(selectedModel)) {
		return {
			...state,
			currentMigration: undefined,
		};
	}
	const [model, dataCenter] = selectedModel.split(' ');
	const migrationFound = state.remoteMigrations.find(
		(migration) =>
			migration.dataCenter === dataCenter && migration.model === model
	);
	if (!migrationFound) {
		return {
			...state,
			currentMigration: undefined,
		};
	}

	return {
		...state,
		currentMigration: migrationFound,
	};
});

reducer.on(
	actionTypes.REMOTE_MIGRATION_GET_TS_URL,
	(state, { tenantServicesRemoteURL }) => ({
		...state,
		tenantServicesRemoteURL,
	})
);

export const remoteMigrations = reducer;
