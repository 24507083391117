/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const CALCULATION_LABEL = 'Calculation';
export const SYNC_LABEL = 'Sync';
export const SYMON_LABEL = 'Symon';
export const IMPORT_LABEL = 'Import';
export const LIBRARY_LABEL = 'Library';
export const MIGRATION_LABEL = 'Migration';
export const TASK_LABEL = 'Task';
export const TIME_LABEL = 'Calendar';
export const OPTIMIZATION_LABEL = 'Optimize Model';
export const PREVIEW_LABEL = 'Preview';
export const BPM_WORKFLOW_LABEL = 'Workflow';
export const PURGE_PERIODS_LABEL = 'Purge Periods';
export const MAKE_EFFECTIVE_LABEL = 'Make Table Effective Dated';
export const PUSH_ANALYTIS_LABEL = 'Push data to dataPorts engine';
export const BPM_INQUIRY_AUTOADJUST_LABEL = 'Inquiry Workflow Auto Adjustment';

export const SHOW_ALL = 'Show All';
export const CALCULATION_VALUE = 'Calculation';
export const IMPORT_VALUE = 'Import';
export const LIBRARY_VALUE = 'Library';
export const MIGRATION_VALUE = 'Migration';
export const TASK_VALUE = 'Task';
export const TIME_VALUE = 'Time';
export const OPTIMIZATION_VALUE = 'Optimization';
export const PREVIEW_VALUE = 'Preview';
export const BPM_WORKFLOW_VALUE = 'BPMWorkflow';
export const PURGE_PERIODS_VALUE = 'PurgePeriods';
export const MAKE_EFFECTIVE_VALUE = 'MakeEffective';

export const RUNNING = 'Running';
export const COMPLETED = 'Completed';
export const FAILED = 'Failed';
export const CANCELLED = 'Cancelled';
export const ALL_STATUS_TYPES = [COMPLETED, FAILED, RUNNING, CANCELLED];
export const ALL_COMPLETED_STATUS_TYPES = [COMPLETED, FAILED, CANCELLED];

export const ACTIVITIES_IN_PROGRESS = 'activities in progress';
export const NEW_COMPLETED_ACTIVITIES = 'new completed activities';
export const NO_LIVE_ACTIVITIES = 'No activities in progress';
export const FAILED_ACTIVITY = 'failed activity';
export const FAILED_ACTIVITIES = 'failed activities';
export const LIVE_ACTIVITIES = 'LiveActivities';
export const ACTIVITIES = 'Activities';
export const STATUS = 'Status';
export const SHOW = 'Show';
export const MORE_LIVE_ACTIVITIES = 'more live activities';
export const SHOW_LESS_ACTIVITIES = 'Show less activities';
export const CANCEL_CURRENT_TASK = 'Are you sure to cancel this task?';

export const DELETE_ACTIVITY_CONFIRMATION_QUESTION =
	'Are you sure you want to delete this activity?';

export const CANCELLING = 'Cancelling';
export const COMPLETED_ACTIVITIES = 'Completed Activities';
export const HOURS_AGO = ' hours ago';
export const COMPLETE = 'Complete';
export const CHOOSE_DATE = 'Choose date';

export const AT = 'at';
export const DATE_FROM = 'Date From';
export const DATE_TO = 'Date To';

export const YESTERDAY = 'Yesterday';
export const TODAY = 'Today';
