/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

// Use this in reducers to help translate. Components should use this.formatMessage instead
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import moment from 'moment';

import * as LiteralStyles from 'constants/rowViewer/literalStyles';
import * as LocaleDates from 'constants/localeDates';
import defaultMessages from 'localizations/messages.properties';

const NumValStates = {
	INITIAL: 'INITIAL',
	NEGATIVE: 'NEGATIVE',
	INT_UNDETERMINED_1_NUM: 'INT_UNDETERMINED_1_NUM',
	INT_UNDETERMINED_2_NUM: 'INT_UNDETERMINED_2_NUM',
	INT_UNDETERMINED_3_NUM: 'INT_UNDETERMINED_3_NUM',
	INT_WITH_SEP: 'INT_WITH_SEP',
	INT_WITH_SEP_1_NUM: 'INT_WITH_SEP_1_NUM',
	INT_WITH_SEP_2_NUM: 'INT_WITH_SEP_2_NUM',
	INT_WITH_SEP_3_NUM: 'INT_WITH_SEP_3_NUM',
	INT_WITHOUT_SEP: 'INT_WITHOUT_SEP',
	DEC_INVALID: 'DEC_INVALID',
	DEC_VALID: 'DEC_VALID',
	FAILED: 'FAILED',
};

const stateChangeHelper = (
	{
		numericState = NumValStates.FAILED,
		decimalState = NumValStates.FAILED,
		thousandSepState = NumValStates.FAILED,
		negativeState = NumValStates.FAILED,
	},
	{ decimalPoint, thousandsSep },
	nextChar
) => {
	if (nextChar >= '0' && nextChar <= '9') {
		return numericState;
	} else if (nextChar === decimalPoint) {
		return decimalState;
	} else if (nextChar === thousandsSep) {
		return thousandSepState;
	} else if (nextChar === '-') {
		return negativeState;
	} else {
		return NumValStates.FAILED;
	}
};

export default class LocaleHelper {
	static getText(state, key) {
		const locale = state.locale.messages;

		if (locale[key]) {
			return locale[key];
		}
		if (ICM_CONFIG.ENABLE_LOCALE_MESSAGE_FALLBACK && defaultMessages[key]) {
			return defaultMessages[key];
		}
		return key;
	}

	static replaceText(state, key, replaceMap = {}) {
		const locale = state.locale.messages;
		let entry = locale[key];
		if (!entry) {
			entry =
				ICM_CONFIG.ENABLE_LOCALE_MESSAGE_FALLBACK && defaultMessages[key]
					? defaultMessages[key]
					: key;
		}

		_.forIn(replaceMap, (v, k) => {
			const oldVar = `{${k}}`;
			entry = entry.split(oldVar).join(v);
		});
		return entry;
	}

	static formatMessage(state, ...rest) {
		if (rest.length === 0) {
			return null;
		}

		if (rest.length === 1) {
			return LocaleHelper.getText(state, rest[0]);
		}

		// If argument 3 is an object we assume it is in the form {orig1: replace1, orig2: replace2} and pass it in directly.
		if (rest[1] instanceof Object) {
			return LocaleHelper.replaceText(state, rest[0], rest[1]);
		}

		// ... otherwise convert it to {0: rest1, 1: rest2, ...}
		return LocaleHelper.replaceText(state, rest[0], { ...rest.slice(1) });
	}

	static generateMessageFormatter(state) {
		return (...args) => LocaleHelper.formatMessage(state, ...args);
	}

	static getLocaleDateLongWeekday(state, date) {
		const locale = state.locale.localeCode;
		const formattedDate = moment
			.utc(date)
			.format(LocaleDates.localeDates[locale]);
		return formattedDate;
	}

	/**
	 * Gets the decimal seperator for the locale code passed in
	 * @param locale Language code as string such as 'fr' or 'en-US'
	 * @return as a string the decimal seperator for the current language
	 */
	static getLocaleDecimalPoint(locale) {
		/*
		 *1 - parse a short number with the current locale
		 *2 - return the decimal seperator by slicing the first and last characters(the 1's)
		 */
		const test = 1.1;
		const formatted = test.toLocaleString(locale, {
			minimumFractionDigits: 1,
			maximumFractionDigits: 1,
		});
		return formatted.slice(1, -1);
	}

	/**
	 * Gets the decimal seperator for the locale code passed in
	 * @param locale Language code as string such as 'fr' or 'en-US'
	 * @return as a string the decimal seperator for the current language
	 */
	static getLocaleThousandsSeperator(locale) {
		/*
		 *1 - parse a short number with the current locale
		 *2 - return the thousands seperator by slicing the first and last characters(the 1's)
		 *3 - replace NBSB with normal spaces
		 */
		const test = 11111;
		const formatted = test.toLocaleString(locale, {
			maximumFractionDigits: 1,
		});
		return formatted.slice(2, -3).replace(new RegExp('\\s'), ' ');
	}

	/**
	 * Formats a number to match the current locales number format
	 * @param locale the current locale code (fetched from state.locale.localeCode)
	 * @param data float, string or bignum number to be formatted
	 * @param minimumFractionDigits minimum number of characters to the right of the decimal point to display
	 * @param maximumFractionDigits maximum number of characters to the right of the decimal point to display
	 * @param useGrouping whether to use grouping separators
	 * @return number formatted for locale
	 */
	static formatNumberLocale(
		locale,
		data,
		minimumFractionDigits = LiteralStyles.MIN_FRACTION_DIGITS,
		maximumFractionDigits = LiteralStyles.MAX_FRACTION_DIGITS,
		useGrouping = true
	) {
		if (data == null) {
			return data;
		}

		// if not a bignum
		if (!data.c) {
			const numberFloat = parseFloat(data);
			return numberFloat.toLocaleString(locale, {
				minimumFractionDigits,
				maximumFractionDigits,
				useGrouping,
			});
		}

		const negative = data.isNegative();
		// Since we need to perform toLocaleString on floats, split our number as a string and perform the operations on the decimal and integer portions seperately, then concat
		let dataStr;
		try {
			dataStr = data.toFixed(LiteralStyles.MAX_FRACTION_DIGITS);
		} catch (e) {
			// bignumber.js's toFixed incorrectly handled the value on the (0, 1) range. Github issue: https://github.com/MikeMcl/bignumber.js/issues/269
			BigNumber.config({
				DECIMAL_PLACES: LiteralStyles.MAX_FRACTION_DIGITS,
			});
			const dataBigNumber = new BigNumber(data);
			dataStr = dataBigNumber.toString(10); // Base 10
		}

		const split = dataStr.split('.');
		const upper = split[0];
		const lower = `0.${split[1]}`;

		const upperFloat = Math.abs(parseFloat(upper));
		const lowerFloat = parseFloat(lower);

		const decimalPoint = this.getLocaleDecimalPoint(locale);

		const upperSplit = upperFloat
			.toLocaleString(locale, {
				minimumFractionDigits,
				maximumFractionDigits,
				useGrouping,
			})
			.split(decimalPoint);
		const lowerSplit = lowerFloat
			.toLocaleString(locale, {
				minimumFractionDigits,
				maximumFractionDigits,
				useGrouping,
			})
			.split(decimalPoint);

		let retString = `${upperSplit[0]}${lowerSplit[1] ? decimalPoint : ''}${
			lowerSplit[1] || ''
		}`;

		if (negative) {
			retString = `-${retString}`;
		}

		// replace nbsp and nnbsp with normal space
		retString = retString.replace(/\u202F/g, ' ');
		return retString.replace(/\u00A0/g, ' ');
	}

	/**
	 * Checks input is a valid number including locale
	 * @param locale the current locale code (fetched from state.locale.localeCode)
	 * @param {string} data string to be validated
	 */
	static validateNumberLocale(locale, data) {
		// if not a string
		if (!_.isString(data)) {
			return false;
		}

		const decimalPoint = this.getLocaleDecimalPoint(locale);
		const thousandsSep = this.getLocaleThousandsSeperator(locale);

		let currState = NumValStates.INITIAL;

		/*
		 * State Machine time!
		 *
		 *	           TO:        | INITIAL | NEGATIVE | INT_UNDETERMINED_1_NUM | INT_UNDETERMINED_2_NUM | INT_UNDETERMINED_3_NUM | INT_WITH_SEP | INT_WITH_SEP_1_NUM | INT_WITH_SEP_2_NUM | INT_WITH_SEP_3_NUM | INT_WITHOUT_SEP | DEC_INVALID | DEC_VALID | FAILED |
		 *         FROM:          +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * INITIAL                |         |     -    |          0-9           |                        |                        |              |                    |                    |                    |                 |      .      |           |* Others|
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * NEGATIVE               |         |          |          0-9           |                        |                        |              |                    |                    |                    |                 |             |           |* Others|
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * INT_UNDETERMINED_1_NUM |         |          |                        |          0-9           |                        |       ,      |                    |                    |                    |                 |             |     .     |* Others|
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * INT_UNDETERMINED_2_NUM |         |          |                        |                        |          0-9           |       ,      |                    |                    |                    |                 |             |     .     |* Others|
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * INT_UNDETERMINED_3_NUM |         |          |                        |                        |                        |       ,      |                    |                    |                    |       0-9       |             |     .     |* Others|
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * INT_WITH_SEP           |         |          |                        |                        |                        |              |         0-9        |                    |                    |                 |             |           |* Others|
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * INT_WITH_SEP_1_NUM     |         |          |                        |                        |                        |              |                    |         0-9        |                    |                 |             |           |* Others|
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * INT_WITH_SEP_2_NUM     |         |          |                        |                        |                        |              |                    |                    |         0-9        |                 |             |           |* Others|
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * INT_WITH_SEP_3_NUM     |         |          |                        |                        |                        |       ,      |                    |                    |                    |                 |             |     .     |* Others|
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * INT_WITHOUT_SEP        |         |          |                        |                        |                        |              |                    |                    |                    |       0-9       |             |     .     |* Others|
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * DEC_INVALID            |         |          |                        |                        |                        |              |                    |                    |                    |                 |             |    0-9    |* Others|
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * DEC_VALID              |         |          |                        |                        |                        |              |                    |                    |                    |                 |             |    0-9    |* Others|
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 * FAILED                 |         |          |                        |                        |                        |              |                    |                    |                    |                 |             |           |        |
		 *                        +---------+----------+------------------------+------------------------+------------------------+--------------+--------------------+--------------------+--------------------+-----------------+-------------+-----------+--------+
		 */

		for (let i = 0; i < data.length; ++i) {
			let nextStateDef;
			switch (currState) {
				case NumValStates.INITIAL:
					nextStateDef = {
						numericState: NumValStates.INT_UNDETERMINED_1_NUM,
						decimalState: NumValStates.DEC_INVALID,
						negativeState: NumValStates.NEGATIVE,
					};
					break;
				case NumValStates.NEGATIVE:
					nextStateDef = {
						numericState: NumValStates.INT_UNDETERMINED_1_NUM,
					};
					break;
				case NumValStates.INT_UNDETERMINED_1_NUM:
					nextStateDef = {
						numericState: NumValStates.INT_UNDETERMINED_2_NUM,
						decimalState: NumValStates.DEC_VALID,
						thousandSepState: NumValStates.INT_WITH_SEP,
					};
					break;
				case NumValStates.INT_UNDETERMINED_2_NUM:
					nextStateDef = {
						numericState: NumValStates.INT_UNDETERMINED_3_NUM,
						decimalState: NumValStates.DEC_VALID,
						thousandSepState: NumValStates.INT_WITH_SEP,
					};
					break;
				case NumValStates.INT_UNDETERMINED_3_NUM:
					nextStateDef = {
						numericState: NumValStates.INT_WITHOUT_SEP,
						decimalState: NumValStates.DEC_VALID,
						thousandSepState: NumValStates.INT_WITH_SEP,
					};
					break;
				case NumValStates.INT_WITH_SEP:
					nextStateDef = {
						numericState: NumValStates.INT_WITH_SEP_1_NUM,
					};
					break;
				case NumValStates.INT_WITH_SEP_1_NUM:
					nextStateDef = {
						numericState: NumValStates.INT_WITH_SEP_2_NUM,
					};
					break;
				case NumValStates.INT_WITH_SEP_2_NUM:
					nextStateDef = {
						numericState: NumValStates.INT_WITH_SEP_3_NUM,
					};
					break;
				case NumValStates.INT_WITH_SEP_3_NUM:
					nextStateDef = {
						decimalState: NumValStates.DEC_VALID,
						thousandSepState: NumValStates.INT_WITH_SEP,
					};
					break;
				case NumValStates.INT_WITHOUT_SEP:
					nextStateDef = {
						numericState: NumValStates.INT_WITHOUT_SEP,
						decimalState: NumValStates.DEC_VALID,
					};
					break;
				case NumValStates.DEC_INVALID:
					nextStateDef = {
						numericState: NumValStates.DEC_VALID,
					};
					break;
				case NumValStates.DEC_VALID:
					nextStateDef = {
						numericState: NumValStates.DEC_VALID,
					};
					break;
				case NumValStates.FAILED:
				default:
					return false;
			}
			currState = stateChangeHelper(
				nextStateDef,
				{ decimalPoint, thousandsSep },
				data[i]
			);
		}

		switch (currState) {
			case NumValStates.INT_UNDETERMINED_1_NUM:
			case NumValStates.INT_UNDETERMINED_2_NUM:
			case NumValStates.INT_UNDETERMINED_3_NUM:
			case NumValStates.INT_WITH_SEP_3_NUM:
			case NumValStates.INT_WITHOUT_SEP:
			case NumValStates.DEC_VALID:
				return true;
			case NumValStates.INITIAL:
			case NumValStates.NEGATIVE:
			case NumValStates.INT_WITH_SEP:
			case NumValStates.INT_WITH_SEP_1_NUM:
			case NumValStates.INT_WITH_SEP_2_NUM:
			case NumValStates.DEC_INVALID:
			case NumValStates.FAILED:
			default:
				return false;
		}
	}

	/**
	 * Given a locale string returns the BignNumber or float
	 * @static
	 * @param locale the current locale code
	 * @param {string} data string to be parsed
	 * @param {boolean} isBigNum whether to return a BigNumber or float
	 * @returns
	 * @memberof LocaleHelper
	 */
	static localeStringToNum(locale, data, isBigNum) {
		const decimalSeperator = LocaleHelper.getLocaleDecimalPoint(locale);
		const thousandsSep = LocaleHelper.getLocaleThousandsSeperator(locale);
		const convertedStr =
			data && data !== ''
				? String(data)
						.replace(new RegExp(_.escapeRegExp(thousandsSep), 'g'), '')
						.replace(decimalSeperator, '.')
				: 0;
		return isBigNum ? new BigNumber(convertedStr) : parseFloat(convertedStr);
	}
}
