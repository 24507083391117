/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { AllFilterOperators } from '../../../constants/filterOperators';
import { toFilterString } from '../../../helpers/filter/filterToString';
import FilterOperator from './filterOperator';

export default class GreaterFilter extends FilterOperator {
	constructor(left, right, parent) {
		super(AllFilterOperators.Greater, left, right, parent);
	}

	toString() {
		return toFilterString(
			this.type,
			this.left.toString(),
			this.right.toString()
		);
	}

	filter(row, columns) {
		const item = this.getRowValueForColumn(row, columns);
		return !item.found || item.filterValue < item.rowValue;
	}

	copy(parent) {
		return new GreaterFilter(this.left, this.right, parent);
	}
}
