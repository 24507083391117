/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { createAction } from 'redux-act';

import { bannerTypes } from 'services/maintenanceService';
import { isUpcomingMaintenance } from 'actions/maintenanceActions';
import { AdminWebBannerNotifiedTypes } from '../constants/banner/adminWebBannerOptions';
import {
	SHOW_BANNER_MESSAGE,
	CLEAR_BANNER_MESSAGE,
} from '../constants/actionTypes';

export interface Banner {
	type: string;
	message: string;
}

export const showMessage = createAction(
	SHOW_BANNER_MESSAGE,
	(message, actionLinklabel, action, messagesNeedTranslation, banners) => ({
		message,
		actionLinklabel,
		action,
		messagesNeedTranslation,
		banners,
	})
);

export const clearMessage = createAction(CLEAR_BANNER_MESSAGE);

export function clearBannerMessage(selectedBanner: Banner) {
	const setReleaseNotesBanner = () => {
		// update release banner
		localStorage.setItem(
			AdminWebBannerNotifiedTypes.Release,
			JSON.stringify(selectedBanner)
		);

		return selectedBanner;
	};
	const setMaintenanceBanner = () => {
		if (isUpcomingMaintenance(selectedBanner)) {
			// update maintenace banner
			localStorage.setItem(
				AdminWebBannerNotifiedTypes.Maintenance,
				JSON.stringify(selectedBanner)
			);
		}

		return selectedBanner;
	};
	const setCustomBanner = () => {
		const bannersLocalStorage =
			localStorage.getItem(AdminWebBannerNotifiedTypes.Custom) ?? '[]';

		// reading data (if any) from local storage and combining (concat) with {selectedBanner}
		const computedCustomBanner: Banner[] = [
			...JSON.parse(bannersLocalStorage),
			selectedBanner,
		];

		localStorage.setItem(
			AdminWebBannerNotifiedTypes.Custom,
			JSON.stringify(computedCustomBanner)
		);

		return selectedBanner;
	};
	const bannerActions = {
		[bannerTypes.releaseNotes]: setReleaseNotesBanner,
		[bannerTypes.maintenance]: setMaintenanceBanner,
		[bannerTypes.custom]: setCustomBanner,
	};

	const banner = bannerActions[selectedBanner.type]();

	return (dispatch) => {
		dispatch({
			type: CLEAR_BANNER_MESSAGE,
			payload: {
				banner,
			},
		});
	};
}
