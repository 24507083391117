/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

// Audit actions

export const MODEL_SUMMARY_REQUEST_DATA = 'MODEL_SUMMARY_REQUEST_DATA';
export const MODEL_SUMMARY_RECEIVE_DATA = 'MODEL_SUMMARY_RECEIVE_DATA';
export const MODEL_SUMMARY_RESPONSE_ERROR = 'MODEL_SUMMARY_RESPONSE_ERROR';
