/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { showSpinner } from 'actions/globalActionActions';
import { assetError } from 'actions/globalActions';
import { COMPONENT_FETCHING } from 'constants/spinnerTypes';
import Loadable from 'react-loadable';
import React from 'react';

// Invisible compoent that just triggers when it's mounted and unmounted
const Loader = compose(
	connect(null, { showSpinner, assetError }),
	lifecycle({
		componentWillMount() {
			this.props.showSpinner(true, COMPONENT_FETCHING);
		},
		componentWillUnmount() {
			this.props.showSpinner(false, COMPONENT_FETCHING);
		},
		componentWillReceiveProps(nextProps) {
			if (
				this.props.error !== nextProps.error &&
				nextProps.error &&
				nextProps.error.toString().match(/Loading chunk (\d+) failed/)
			) {
				if (process.env.NODE_ENV === 'development') {
					// During development, we should just reload. Set a delay to prevent immediately looping again.
					setTimeout(() => window.location.reload(true), 500);
				} else {
					nextProps.assetError();
				}
			} else if (nextProps.error) {
				throw nextProps.error;
			}
		},
	})
)(() => null);

const createLoadable = (opts) => {
	const newParams = {
		loading: Loader,
		delay: 200,
		...opts,
	};
	if (typeof opts.loader === 'function') {
		return Loadable(newParams);
	}
	return Loadable.Map(newParams);
};

export default (loader) =>
	createLoadable({
		loader: {
			comp: loader,
			// this will load CSS via
			other: () => import('../delayedIndex'),
		},
		render: (loaded, props) => {
			const Comp = loaded.comp.default;
			return <Comp {...props} />;
		},
	});
