/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { useRouterHistory } from 'react-router';
import { createHistory } from 'history';

/* eslint-disable react-hooks/rules-of-hooks */
export const history = useRouterHistory(createHistory)({
	basename: ICM_CONFIG.BASENAME,
});
