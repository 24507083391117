/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { createAction } from 'redux-act';

import {
	REDIRECT,
	CLEAR_REDIRECT,
	BLOCKING_REDIRECT,
} from '../constants/actionTypes';

/**
 * Safely redirects to the target url - also initiates a model switch if model is provided
 * This action is not blocked by any middleware.
 * @param url Target path for the redirect - eg '/' for home
 * @param model If we wish to change the model provide the model name
 * @return bundled object for window.store.actions
 */
export const redirect = createAction<string, any, { url: string; model: any }>(
	REDIRECT,
	(url, model = null) => ({
		url,
		model,
	})
);

/**
 * Safely redirects to the target url - also initiates a model switch if model is provided
 * This action is blocked if the user is not authenticated.
 */
export const blockingRedirect = createAction<{
	url: string;
	model: { label: string; isDefault: boolean } | null;
}>(BLOCKING_REDIRECT);

export const clearRedirect = createAction(CLEAR_REDIRECT);
