// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

import axios, { AxiosRequestConfig } from 'axios';
import { axiosInstance } from './configure';

export enum HttpVerb {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
	PATCH = 'patch',
	DELETE = 'delete',
	HEAD = 'head',
	OPTIONS = 'options',
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

// extend (as opposed to type) because we want this to be extensible
export interface RequestOptions
	extends Omit<
		AxiosRequestConfig,
		| 'url'
		| 'params'
		| 'data'
		| 'method'
		| 'paramsSerializer'
		| 'auth'
		| 'withCredentials'
	> {
	apiVersion?: 'v1' | 'v2' | 'internal' | 'payeeweb'; // payeeweb is really just for payeewebSalesforce.ts
}

interface RequestConfig extends RequestOptions {
	method: HttpVerb;
	url: string;
	data?: any;
	query?: { [key: string]: any };
}

export function configurableRequest<E = any>(config: RequestConfig) {
	return configurableRequestRaw<E>(config).then((resp) => resp.data);
}

export function configurableRequestRaw<E = any>({
	query,
	apiVersion,
	...otherOptions
}: RequestConfig) {
	const requestConfig: AxiosRequestConfig = {
		params: query,
		...otherOptions,
	};

	if (axiosInstance === null) {
		throw new Error(
			'Trying to call api without configured axios instance. Call configure first.'
		);
	}
	if (apiVersion && apiVersion !== 'v1') {
		requestConfig.baseURL = requestConfig.baseURL
			? requestConfig?.baseURL?.replace(/\/api\/v1$/, `/api/${apiVersion}`)
			: axiosInstance.defaults?.baseURL?.replace(
					/\/api\/v1$/,
					`/api/${apiVersion}`
			  );
	}

	return axiosInstance.request<E>(requestConfig);
}

/**
 * Creates a cancelTokenSource that contains a token and a cancel function
 * Any api request that uses this should take the token in RequestOptions
 * The token is just a promise that will reject as soon as cancel is called on the source
 * All api requests that use ths same source token will be cancelled and a new source will be needed
 *
 * @export
 * @returns CancelTokenSource
 */
export function getCancelTokenSource() {
	return axios.CancelToken.source();
}
