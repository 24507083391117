// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 *
 * url: `api/v1/remoteimports`
 */
export function getAll(
	query?: { filter?: string; orderBy?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'remoteimports',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<string[]>(request);
}

/**
 *
 * url: `api/v1/serverfilenames`
 */
export function getAllFileNames(
	query?: { filter?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'serverfilenames',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ImportFileDTO[]>(request);
}

/**
 *
 * url: `api/v1/serverfiles`
 */
export function getAllFiles(
	query?: { filter?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'serverfiles',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ImportFileDTO[]>(request);
}

/**
 *
 * url: `api/v1/serverfiles/${filename}`
 */
export function get(filename: string, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `serverfiles/${filename}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ImportFileDTO>(request);
}

/**
 * Run a saved import
 * url: `api/v1/rpc/imports/${id}/run`
 */
export function runImport(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.POST,
		url: `rpc/imports/${id}/run`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<{ [key: string]: string }>(request);
}

/**
 * Run an ad hoc import
 * url: `api/v1/rpc/imports/runAdHoc`
 */
export function runAdHocImport(
	adHocImport: Varicent.RESTAPI.v1.DTOs.Composer.DataImport.ImportParamsDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/imports/runAdHoc',
		apiVersion: 'v1' as const,
		data: adHocImport,
		...options,
	};
	return configurableRequest<{ [key: string]: string }>(request);
}

/**
 *
 * url: `api/v1/serverfiles/${fileName}`
 */
export function deleteFile(fileName: string, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `serverfiles/${fileName}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/serverfiles`
 */
export function appendToFile(
	file: Varicent.RESTAPI.v1.DTOs.FileDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: 'serverfiles',
		apiVersion: 'v1' as const,
		data: file,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ImportFileDTO>(request);
}

/**
 *
 * url: `api/v1/serverfiles`
 */
export function createFile(
	file: Varicent.RESTAPI.v1.DTOs.NewFileDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'serverfiles',
		apiVersion: 'v1' as const,
		data: file,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.ImportFileDTO>(request);
}

/**
 * The column headers were obtained.
 * url: `api/v1/rpc/imports/getheaders`
 */
export function getImportColumnFileHeaders(
	importDto: Varicent.RESTAPI.v1.DTOs.Composer.DataImport.ImportParamsDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/imports/getheaders',
		apiVersion: 'v1' as const,
		data: importDto,
		...options,
	};
	return configurableRequest<string[]>(request);
}

/**
 * The data was obtained.
 * url: `api/v1/imports/getdbpreview`
 */
export function getDBPreview(
	dto: Varicent.RESTAPI.v1.DTOs.Composer.DataImport.ImportPreviewDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'imports/getdbpreview',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<string[][]>(request);
}

/**
 * The data was obtained.
 * url: `api/v1/imports/getodbcpreview`
 */
export function getODBCPreview(
	dto: Varicent.RESTAPI.v1.DTOs.Composer.DataImport.ImportPreviewDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'imports/getodbcpreview',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<string[][]>(request);
}

/**
 * The preview was obtained.
 * url: `api/v1/rpc/imports/gettextpreview`
 */
export function getTextPreview(
	dto: Varicent.RESTAPI.v1.DTOs.Composer.DataImport.ImportPreviewDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/imports/gettextpreview',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<string[][]>(request);
}

/**
 * The preview was obtained.
 * url: `api/v1/rpc/imports/getsymonpreview`
 */
export function getSymonPreview(
	dto: Varicent.RESTAPI.v1.DTOs.Composer.DataImport.ImportPreviewDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/imports/getsymonpreview',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<string[][]>(request);
}

/**
 * The preview was obtained.
 * url: `api/v1/rpc/imports/getsymonconnectorpreview`
 */
export function getSymonConnectorPreview(
	dto: Varicent.RESTAPI.v1.DTOs.Composer.DataImport.ImportPreviewDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/imports/getsymonconnectorpreview',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<string[][]>(request);
}

/**
 * The preview was obtained.
 * url: `api/v1/rpc/imports/getexcelpreview`
 */
export function getExcelPreview(
	dto: Varicent.RESTAPI.v1.DTOs.Composer.DataImport.ImportPreviewDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/imports/getexcelpreview',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<string[][]>(request);
}

/**
 * The worksheet info was obtained.
 * url: `api/v1/rpc/imports/getworksheetinfo`
 */
export function getWorksheetInfo(
	dto: Varicent.RESTAPI.v1.DTOs.Composer.DataImport.ImportParamsDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/imports/getworksheetinfo',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Composer.DataImport.ExcelInfoDTO>(
		request
	);
}

/**
 * The row nodes for the given XML file name was obtained.
 * url: `api/v1/rpc/imports/getrownodes`
 */
export function getXmlRowNodes(
	dto: Varicent.RESTAPI.v1.DTOs.Composer.DataImport.ImportParamsDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/imports/getrownodes',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<string[]>(request);
}

/**
 * The xml preview was obtained.
 * url: `api/v1/rpc/imports/getxmlpreview`
 */
export function getXMLPreview(
	dto: Varicent.RESTAPI.v1.DTOs.Composer.DataImport.ImportPreviewDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/imports/getxmlpreview',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<string[][]>(request);
}
