/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { createAction } from 'redux-act';

import {
	SET_GLOBAL_ON_CLICK,
	SET_DOCUMENT_TITLE,
} from '../constants/actionTypes';

export const setGlobalOnClick = createAction(
	SET_GLOBAL_ON_CLICK,
	(funct: (event: Event) => void) => ({
		funct,
	})
);

export const setDocumentTitle = createAction(
	SET_DOCUMENT_TITLE,
	(title: string) => ({
		title,
	})
);

export const assetError = createAction('ASSET_ERROR');
