/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const urlMatches = ICM_CONFIG.AUTH_URL.match(
	/^https?:\/\/([^/:?#]+)(?:[/:?#]|$)/i
);
export const host = urlMatches && urlMatches[1];

export function getUserPrefix(database, userName) {
	return `${host}__${database}__${userName}`;
}

export function clearSession() {
	Object.keys(sessionStorage)
		.filter((key) => !key.endsWith('lastModel'))
		.map((key) => sessionStorage.removeItem(key));
}
