/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

// This file is for any generic array helper functions

import R from 'ramda';

/**
 * In place swap for array elements
 *
 * @param array An array to swap elements of
 * @param index1 Index of 1st element
 * @param index2 Index of 2nd element
 */
export function elementSwap(array, index1, index2) {
	const temp = array[index1];
	array[index1] = array[index2];
	array[index2] = temp;
}

/*
 *TODO This is intended for short arrays at the moment, if good performance on large arrays is required, rewrite to binary search for value since array is sorted
 *TODO Caching value of array[i+1] for next iteration is a possible improvement
 */
/**
 * Finds last index where output of func(array[i]) is value
 *
 * @param array An array sorted by same parameter the function is looking for
 * @param func Function that accepts an element of the array as parameter and returns a value
 * @param value The value to find the last match for
 */
export function sortedFindLastIndexByFunction(array, func, value) {
	for (let i = 0; i < array.length; i++) {
		if (
			i < array.length - 1 &&
			func(array[i]) === value &&
			func(array[i + 1]) !== value
		) {
			return i;
		}
	}
	return array.length - 1;
}

/**
 * Convert Array to Object
 * [{o1},{o2},..] to {k1: {o1}, k2: {o2},...} where kn is provided by func(on)
 * @param func Function that accepts an element of the array as parameter and returns value to be key
 * @param els Array to be converted to object
 */
export const arrayToObject = R.curry((func, els) =>
	els.reduce(
		(acc, el) => ({
			...acc,
			[func(el)]: el,
		}),
		{}
	)
);
