/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	ROUTER_NAVIGATE,
	WEB_REPORT_ASK_LEAVE_WITHOUT_SAVE,
} from './actionTypes';
import * as MiddlewareBypassActionTypes from '../constants/middlewareBypassActionTypes';
import _ from 'lodash';

// we need these actions to be able to reach the reducer despite user not being authenticated
export const ALLOWABLE_ACTIONS = _.values(MiddlewareBypassActionTypes);

// we don't want these actions to reach the reducer but we don't want to trigger a login modal on them
export const SQUELCHED_ACTIONS = [
	ROUTER_NAVIGATE,
	WEB_REPORT_ASK_LEAVE_WITHOUT_SAVE,
];
