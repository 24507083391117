/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/*
 * Include this file in every entry file.
 * It makes sure that the dynamic url will be set on
 * every entry file.
 */
/* eslint-disable */
__webpack_public_path__ = window.ICM_CONFIG.CDN_SERVER;

import 'intl/dist/Intl.min.js';
import '@babel/polyfill';
import 'proxy-polyfill/proxy.min.js';
// varicent components polyfill must come before abortcontroller since this polyfill
// adds the Event global that abort controller depends on.
import '@varicent/components/dist/polyfills.cjs.js';
import 'yet-another-abortcontroller-polyfill';
import 'whatwg-fetch';
import safeDataTransferShim from 'utils/safeDataTransferShim';
import { setAxiosDefaults } from './config';

setAxiosDefaults();
safeDataTransferShim();
// react-measure's usage of ResizeObserver is not spec compliant,
// as setState() is called inside the observer callback,
// synchronously triggering a render and a resize,
// calling the observer callback again, tripping the loop limit.
// The polyfill does things differently, allowing this to work,
// so remove the builtin implementation to allow the polyfill to take effect.
delete window.ResizeObserver;
window.requestAnimationFrame = window.requestAnimationFrame.bind(window); //fix for IE bug not binding requestAnimationFrame
