// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 * Get global action status
 * url: `api/v1/globalactionstatus`
 */
export function get(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'globalactionstatus',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<boolean>(request);
}

/**
 * Get all global tokens
 * url: `api/v1/globalstatus/tokens`
 */
export function getAllTokens(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'globalstatus/tokens',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.GlobalAction.GlobalTokenDTO[]
	>(request);
}

/**
 * Get all global token locks
 * url: `api/v1/globalstatus/locks`
 */
export function getAllLocks(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'globalstatus/locks',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.GlobalAction.AggeregateTokenLocksDTO>(
		request
	);
}

/**
 * Find the global token.
 * url: `api/v1/getglobaltoken`
 */
export function getGlobalToken(
	query?: { isImport?: boolean },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'getglobaltoken',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<string[][]>(request);
}
