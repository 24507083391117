/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import classnames from 'classnames';
import '../../styles/components/progressBar.scss';
import VBaseComponent from 'components/common/baseComponent';

export default class VProgressBar extends VBaseComponent {
	static propTypes = {
		percent: PropTypes.number.isRequired,
		className: PropTypes.string,
		showLabel: PropTypes.bool,
		isPaused: PropTypes.bool,
		inverseColor: PropTypes.bool,
		labelClass: PropTypes.string,
	};

	static defaultProps = {
		className: 'blue',
	};

	render() {
		const { className, showLabel, isPaused, inverseColor, labelClass } =
			this.props;
		let percent = this.props.percent;

		if (percent < 0) percent = 0;
		if (percent > 100) percent = 100;

		const style = {
			width: this.replaceText('PROGRESSBAR_STYLE_WIDTH', {
				value: percent,
			}),
		};

		const progressBarClassName = classnames({
			'loading-meter-grey': isPaused && !inverseColor,
			'loading-meter-darkgrey': isPaused && inverseColor,
			'loading-meter': !isPaused,
			'with-label': showLabel,
		});

		const labelClassName = classnames(labelClass, 'linear-bar-wrapper');

		return (
			<div className={labelClassName}>
				{showLabel ? (
					<div className="v-progressbar-label">{style.width}</div>
				) : null}
				<div className={progressBarClassName}>
					{isPaused ? <div className={className} style={style} /> : null}
					{!isPaused ? (
						<div style={style} className={className}>
							<div
								className={`loading-indicator ${className}`}
								data-test="loading-progress-bar"
							/>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}
