/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as Actions from '../constants/actionTypes';

function initialize() {
	return {
		globalActionStatus: {
			count: 0, // current number of polls for global action status when users are inactive
			status: false, // True iff a global action exists
			requesting: false,
		},
		showSpinner: false,
		spinners: {},
		header: undefined,
	};
}

const initialState = initialize();

const handlers = {};

/*
 * reset spinner - true/false
 * show spinner - true/false
 * spinners - current state of spinners (array of active spinners
 * toggle - spinner id to toggle
 */
function updateSpinner(reset, show, spinners, toggle, force) {
	if (reset) {
		spinners[toggle] = 0;
	} else {
		if (force) {
			spinners[toggle] = show ? 1 : 0;
		} else {
			const prevValue = spinners[toggle] ? spinners[toggle] : 0;
			if (show) {
				spinners[toggle] = prevValue + 1;
			} else if (prevValue > 0) {
				spinners[toggle] = prevValue - 1;
			}
		}
	}
}

function showSpinner(spinners) {
	for (const spinner in spinners) {
		if (spinners.hasOwnProperty(spinner) && spinners[spinner] > 0) {
			return true;
		}
	}

	return false;
}

export function globalAction(state = initialState, action) {
	const { type, payload } = action;
	if (!handlers[type]) {
		return state;
	}
	return handlers[type](state, payload);
}

handlers[Actions.REQUEST_GLOBAL_ACTION_STATUS] = (state) => ({
	...state,
	globalActionStatus: {
		...state.globalActionStatus,
		requesting: true,
	},
});

handlers[Actions.RECEIVE_GLOBAL_ACTION_STATUS] = (state, { status }) => ({
	...state,
	globalActionStatus: {
		...state.globalActionStatus,
		status,
		requesting: false,
	},
});

handlers[Actions.SHOW_SPINNER] = (state, payload) => {
	const { show, spinners } = payload;
	updateSpinner(false, show, state.spinners, spinners);
	return {
		...state,
		showSpinner: showSpinner(state.spinners),
		spinners: state.spinners,
	};
};

handlers[Actions.FORCE_SPINNER] = (state, payload) => {
	const { show, spinners } = payload;
	updateSpinner(false, show, state.spinners, spinners, true);
	return {
		...state,
		showSpinner: showSpinner(state.spinners),
		spinners: state.spinners,
	};
};

// Increment global action status poll count by 1
handlers[Actions.GLOBAL_ACTION_STATUS_INCREMENT_POLL_COUNT] = (state) => {
	const count = state.globalActionStatus.count + 1;
	return {
		...state,
		globalActionStatus: {
			...state.globalActionStatus,
			count,
		},
	};
};

// Reset global action status poll count to 0
handlers[Actions.GLOBAL_ACTION_STATUS_RESET_POLL_COUNT] = (state) => ({
	...state,
	globalActionStatus: {
		...state.globalActionStatus,
		count: 0,
	},
});

handlers[Actions.RESET_SPINNER] = (state, payload) => {
	const { spinners } = payload;
	updateSpinner(true, false, state.spinners, spinners);
	return {
		...state,
		showSpinner: showSpinner(state.spinners),
		spinners: state.spinners,
	};
};

handlers[undefined] = (state) => state;
