/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import VTooltip from 'components/common/tooltipComponent';
import '../../styles/components/icon.scss';
import classNames from 'classnames';
import VReactComponent from 'components/common/reactComponent';
import { Enter, Space } from 'constants/keyboardConstants';

export default class VIcon extends VReactComponent {
	static propTypes = {
		iconClass: PropTypes.string.isRequired,
		supportingClasses: PropTypes.array,
		type: PropTypes.oneOf([
			'disabled',
			'normal',
			'active',
			'static',
			'toggled',
		]), // defaults to normal
		size: PropTypes.oneOf([
			'xxsmall',
			'xsmall',
			'small',
			'medium',
			'large',
			'xlarge',
			'glyph-small',
			'glyph-medium',
			'glyph-large',
		]), // defaults to small
		action: PropTypes.func,
		onMouseOver: PropTypes.func,
		onMouseLeave: PropTypes.func,
		onKeyDown: PropTypes.func,
		allowStaticOnClick: PropTypes.bool,
		style: PropTypes.object,
		tooltip: PropTypes.node,
		'data-test': PropTypes.string,
		buttonRef: PropTypes.shape({ current: PropTypes.object }),
		ariaLabel: PropTypes.string,
	};

	static defaultProps = {
		type: 'normal',
		size: 'small',
	};

	componentDidMount() {
		/*
		 * if buttonRef is passed to this component in the props, focus on the close icon after render
		 * to allow for the closing of the message container using the keyboard
		 */
		if (this.props.buttonRef) {
			this.props.buttonRef.current.focus();
		}
	}

	render() {
		const {
			tooltip,
			iconClass,
			supportingClasses,
			size,
			type,
			action,
			onMouseOver,
			onMouseLeave,
			style,
			allowStaticOnClick,
			onKeyDown,
			buttonRef,
			ariaLabel,
		} = this.props;
		const classes = classNames(iconClass, supportingClasses, {
			'v-xlarge-icon': size === 'xlarge',
			'v-large-icon': size === 'large',
			'v-medium-icon': size === 'medium',
			'v-small-icon': size === 'small',
			'v-xsmall-icon': size === 'xsmall',
			'v-xxsmall-icon': size === 'xxsmall',
			'v-glyph-large-icon': size === 'glyph-large',
			'v-glyph-medium-icon': size === 'glyph-medium',
			'v-glyph-small-icon': size === 'glyph-small',
			'v-disabled-icon': type === 'disabled',
			'v-selected-icon': type === 'active',
			'v-static-icon': type === 'static',
			'v-regular-icon': type === 'normal',
			'v-toggled-icon': type === 'toggled',
		});

		const keyDownHandler = (e) => {
			if (onKeyDown && !tooltip) {
				onKeyDown(e, action);
			} else if (action && (e.key === Enter || e.key === Space)) {
				action(e);
				e.preventDefault();
			}
		};

		const moreProps = { style };

		// This condition prevents an undefined value being set in the ref prop for instances that do not need it
		if (typeof buttonRef !== 'undefined') {
			moreProps.ref = buttonRef;
		}
		switch (type) {
			case 'normal':
			case 'active':
			case 'toggled':
				moreProps.onClick = action;
				// do not bind onKeyDown when disabled
				moreProps.onKeyDown = keyDownHandler;
				break;
			case 'static':
				if (allowStaticOnClick) {
					moreProps.onClick = action;
				}
				moreProps.onKeyDown = keyDownHandler;
				break;
			case 'disabled':
				moreProps['aria-disabled'] = true;
				break;
			default:
				break;
		}

		if (tooltip) {
			return (
				<VTooltip text={tooltip} placement="bottom">
					<i
						className={classes}
						onMouseOver={onMouseOver}
						onMouseLeave={onMouseLeave}
						tabIndex={(action || onKeyDown) && 0}
						onFocus={(e) => e.stopPropagation()}
						data-test={this.props['data-test']}
						aria-label={ariaLabel}
						aria-disabled={type === 'disabled'}
						role="button"
						{...moreProps}
					/>
				</VTooltip>
			);
		}
		return (
			<i
				className={classes}
				onMouseOver={onMouseOver}
				onMouseLeave={onMouseLeave}
				tabIndex={(action || onKeyDown) && 0}
				onFocus={(e) => e.stopPropagation()}
				data-test={this.props['data-test']}
				aria-label={ariaLabel}
				aria-disabled={type === 'disabled'}
				role="button"
				{...moreProps}
			/>
		);
	}
}
