// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 *
 * url: `api/v1/culture`
 */
export function get(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'culture',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<string>(request);
}

/**
 *
 * url: `api/v1/cultures`
 */
export function getAll(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'cultures',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<string>(request);
}
