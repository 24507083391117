// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
	configurableRequestRaw,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}`
 */
export function get(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `workflowmanager/workflows/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMWorkflowDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflowtreestructure`
 */
export function getTreeStructure(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'workflowmanager/workflowtreestructure',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.TreeNodeDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows`
 */
export function getAllWorkflows(
	query?: { filter?: string; orderBy?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'workflowmanager/workflows',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMWorkflowDTO[]>(
		request
	);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}/swimlanes`
 */
export function getAllSwimlanes(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `workflowmanager/workflows/${id}/swimlanes`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.SwimLaneDTO[]>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows`
 */
export function addWorkflow(
	dto: Varicent.RESTAPI.v1.DTOs.CreateBPMWorkflowDTO,
	query?: { source?: number },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'workflowmanager/workflows',
		apiVersion: 'v1' as const,
		data: dto,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.TreeNodeDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/bulkDuplicate`
 */
export function duplicateWorkflowBulk(
	dto: Varicent.RESTAPI.v1.DTOs.DuplicateBPMWorkflowDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'workflowmanager/workflows/bulkDuplicate',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.TreeNodeDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/folders`
 */
export function addFolder(
	dto: Varicent.RESTAPI.v1.DTOs.CreateBPMFolderDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'workflowmanager/folders',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.TreeNodeDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}`
 */
export function deleteWorkflow(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.DeleteBPMWorkflowDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: `workflowmanager/workflows/${id}`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.TreeNodeDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/bulkDelete`
 */
export function deleteWorkflowBulk(
	dto: Varicent.RESTAPI.v1.DTOs.DeleteBPMWorkflowDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: 'workflowmanager/workflows/bulkDelete',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.TreeNodeDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/folders/${id}`
 */
export function deleteFolder(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.DeleteBPMFolderDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: `workflowmanager/folders/${id}`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.TreeNodeDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}`
 */
export function updateWorkflowEntry(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.UpdateBPMWorkflowDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `workflowmanager/workflows/${id}`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.TreeNodeDTO[]>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}/foldernode`
 */
export function updateWorkflowEntryFolder(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.UpdateBPMWorkflowDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `workflowmanager/workflows/${id}/foldernode`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.TreeNodeDTO[]>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/bulkMove/foldernode`
 */
export function updateWorkflowEntryFolderBulk(
	dto: Varicent.RESTAPI.v1.DTOs.UpdateBPMWorkflowDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: 'workflowmanager/workflows/bulkMove/foldernode',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.TreeNodeDTO[]>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflow/${id}`
 */
export function updateWorkflowCanvas(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.BPMWorkflowDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `workflowmanager/workflow/${id}`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMWorkflowDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/folders/${id}`
 */
export function updateFolder(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.UpdateBPMFolderDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `workflowmanager/folders/${id}`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.TreeNodeDTO[]>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/folders/${id}/foldernode`
 */
export function updateFolderNode(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.UpdateBPMFolderDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `workflowmanager/folders/${id}/foldernode`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.TreeNodeDTO[]>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}/swimlanes/${order}`
 */
export function addSwimlane(
	id: Varicent.ID,
	order: number,
	dtos: Varicent.RESTAPI.v1.DTOs.SwimLaneDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `workflowmanager/workflows/${id}/swimlanes/${order}`,
		apiVersion: 'v1' as const,
		data: dtos,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.SwimLaneDTO[]>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}/swimlanes/${order}`
 */
export function deleteSwimlane(
	id: Varicent.ID,
	order: number,
	dtos: Varicent.RESTAPI.v1.DTOs.SwimLaneDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: `workflowmanager/workflows/${id}/swimlanes/${order}`,
		apiVersion: 'v1' as const,
		data: dtos,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.SwimLaneDTO[]>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}/swimlanes`
 */
export function updateSwimlanes(
	id: Varicent.ID,
	dtos: Varicent.RESTAPI.v1.DTOs.SwimLaneDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `workflowmanager/workflows/${id}/swimlanes`,
		apiVersion: 'v1' as const,
		data: dtos,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.SwimLaneDTO[]>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${workflowId}/nodes`
 */
export function getNodes(workflowId: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `workflowmanager/workflows/${workflowId}/nodes`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMNodeDTO[]>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}/nodes`
 */
export function addNode(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.BPMNodeDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `workflowmanager/workflows/${id}/nodes`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMNodeDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}/nodes/${nodeid}`
 */
export function updateNode(
	id: Varicent.ID,
	nodeid: number,
	dto: Varicent.RESTAPI.v1.DTOs.BPMNodeDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `workflowmanager/workflows/${id}/nodes/${nodeid}`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMNodeDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}/nodes/${nodeid}`
 */
export function deleteNode(
	id: Varicent.ID,
	nodeid: number,
	dto: Varicent.RESTAPI.v1.DTOs.BPMNodeDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: `workflowmanager/workflows/${id}/nodes/${nodeid}`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}`
 */
export function updateColumn(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.BPM.UpdateBPMColumn,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PATCH,
		url: `workflowmanager/workflows/${id}`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMWorkflowDTO[]>(
		request
	);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}/tokens/${tokenId}`
 */
export function reassignToken(
	id: Varicent.ID,
	tokenId: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.PayeeNameDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `workflowmanager/workflows/${id}/tokens/${tokenId}`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}/tokens/${tokenId}`
 */
export function removeToken(
	id: Varicent.ID,
	tokenId: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: `workflowmanager/workflows/${id}/tokens/${tokenId}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${id}/information`
 */
export function getWorkflowInformation(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflowmanager/workflows/${id}/information`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMWorkflowInformationDTO>(
		request
	);
}

/**
 * Add payees to a workflow swim lane
 * url: `api/v1/workflows/${workflowId}/swimlanes/${swimLaneId}/payees`
 */
export function putPayees(
	workflowId: Varicent.ID,
	swimLaneId: Varicent.ID,
	payeeIds: any[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `workflows/${workflowId}/swimlanes/${swimLaneId}/payees`,
		apiVersion: 'v1' as const,
		data: payeeIds,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMWorkflowDTO>(request);
}

/**
 * Add payees to a workflow swim lane
 * url: `api/v1/workflows/${workflowId}/swimlanes/${swimLaneId}/payeesByQuery`
 */
export function putPayeesByQuery(
	workflowId: Varicent.ID,
	swimLaneId: Varicent.ID,
	queryDeltaDTO: Varicent.RESTAPI.v1.DTOs.QueryDeltaDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `workflows/${workflowId}/swimlanes/${swimLaneId}/payeesByQuery`,
		apiVersion: 'v1' as const,
		data: queryDeltaDTO,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMWorkflowDTO>(request);
}

/**
 *
 * url: `api/v1/workflows/${workflowId}/swimlanes/${swimLaneId}/payees`
 */
export function patchPayees(
	workflowId: Varicent.ID,
	swimLaneId: Varicent.ID,
	addedRemovedPayeeMembers: Varicent.RESTAPI.v1.DTOs.Core.CollectionModificationDTO<string>,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PATCH,
		url: `workflows/${workflowId}/swimlanes/${swimLaneId}/payees`,
		apiVersion: 'v1' as const,
		data: addedRemovedPayeeMembers,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflows/${workflowId}/swimlanes/${swimLaneId}/payees`
 */
export function getPayees(
	workflowId: Varicent.ID,
	swimLaneId: Varicent.ID,
	query?: { offset?: number; limit?: number },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflows/${workflowId}/swimlanes/${swimLaneId}/payees`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<Varicent.RESTAPI.v1.DTOs.ResultSetDTO>(request);
}

/**
 *
 * url: `api/v1/workflows/getselectedpayees`
 */
export function getSelectedPayees(
	dto: Varicent.RESTAPI.v1.DTOs.BPM.BPMGetSelectedPayeesDTO,
	query?: {
		offset?: number;
		limit?: number;
		filter?: string;
		fields?: string;
		unique?: boolean;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'workflows/getselectedpayees',
		apiVersion: 'v1' as const,
		data: dto,
		query,
		...options,
	};
	return configurableRequestRaw<Varicent.RESTAPI.v1.DTOs.ResultSetDTO>(request);
}

/**
 *
 * url: `api/v1/workflows/${workflowId}/availablePayees`
 */
export function getPayeesEligibleToAdd(
	workflowId: Varicent.ID,
	queryDTO: Varicent.RESTAPI.v1.DTOs.RunQueryDefinitionDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `workflows/${workflowId}/availablePayees`,
		apiVersion: 'v1' as const,
		data: queryDTO,
		...options,
	};
	return configurableRequestRaw<Varicent.RESTAPI.v1.DTOs.ResultSetDTO>(request);
}

/**
 * Start a workflow
 * url: `api/v1/rpc/workflows/${id}/start`
 */
export function startWorkflow(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.POST,
		url: `rpc/workflows/${id}/start`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMWorkflowDTO>(request);
}

/**
 * Stop a workflow
 * url: `api/v1/rpc/workflows/${id}/stop`
 */
export function stopWorkflow(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.POST,
		url: `rpc/workflows/${id}/stop`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMWorkflowDTO>(request);
}

/**
 * Get data, ordered by key column, for given table (limit, offset, filter supported)
 * url: `api/v1/workflows/${id}/pending`
 */
export function getPendingActions(
	id: Varicent.ID,
	query?: {
		offset?: number;
		limit?: number;
		orderBy?: string;
		filter?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflows/${id}/pending`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<
		Varicent.RESTAPI.v1.DTOs.BPM.BPMPendingActionStatusDTO[]
	>(request);
}

/**
 *
 * url: `api/v1/payee/workflows/${id}/initiate`
 */
export function userInitiateWorkflow(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.UserInitiateWorkflowDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/workflows/${id}/initiate`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflows/${id}/pending`
 */
export function forcePendingAction(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.BPM.BPMForcePendingActionDTO,
	query?: { payeeAction?: boolean },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `workflows/${id}/pending`,
		apiVersion: 'v1' as const,
		data: dto,
		query,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflows/${id}/pending`
 */
export function submitPendingAction(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.BPM.BPMForcePendingActionDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/workflows/${id}/pending`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/bpmInquiries`
 */
export function submitInquiry(
	inquirySubmissionDTO: Varicent.RESTAPI.v1.DTOs.BPMInquirySubmissionDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'payee/workflowmanager/bpmInquiries',
		apiVersion: 'v1' as const,
		data: inquirySubmissionDTO,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMInquiryDTO>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/bpmInquiries/${workflowID}/inquiryPending`
 */
export function submitPendingActionInquiryPayee(
	workflowID: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.BPM.BPMForcePendingActionInquiryDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `payee/workflowmanager/bpmInquiries/${workflowID}/inquiryPending`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/bpmInquiries/${workflowID}/inquiryPending`
 */
export function submitPendingActionInquiryAdmin(
	workflowID: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.BPM.BPMForcePendingActionInquiryDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `workflowmanager/bpmInquiries/${workflowID}/inquiryPending`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/bpmInquiries/reopenInquiry/${inquiryID}`
 */
export function reopenInquiryAdmin(
	inquiryID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `workflowmanager/bpmInquiries/reopenInquiry/${inquiryID}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/bpmInquiries/reopenInquiry/${inquiryID}`
 */
export function reopenInquiryPayee(
	inquiryID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/workflowmanager/bpmInquiries/reopenInquiry/${inquiryID}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Get service node errors
 * url: `api/v1/workflows/${id}/errors/services`
 */
export function getServiceNodeErrors(
	id: Varicent.ID,
	query?: {
		offset?: number;
		limit?: number;
		orderBy?: string;
		filter?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflows/${id}/errors/services`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<
		Varicent.RESTAPI.v1.DTOs.BPM.Errors.BPMServiceNodeErrorStatusDTO[]
	>(request);
}

/**
 * Get action node errors
 * url: `api/v1/workflows/${id}/errors/actions`
 */
export function getActionNodeErrors(
	id: Varicent.ID,
	query?: {
		offset?: number;
		limit?: number;
		orderBy?: string;
		filter?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflows/${id}/errors/actions`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<
		Varicent.RESTAPI.v1.DTOs.BPM.Errors.BPMActionNodeErrorStatusDTO[]
	>(request);
}

/**
 * Get alert node errors
 * url: `api/v1/workflows/${id}/errors/alerts`
 */
export function getAlertNodeErrors(
	id: Varicent.ID,
	query?: {
		offset?: number;
		limit?: number;
		orderBy?: string;
		filter?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflows/${id}/errors/alerts`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<
		Varicent.RESTAPI.v1.DTOs.BPM.Errors.BPMAlertNodeErrorStatusDTO[]
	>(request);
}

/**
 *
 * url: `api/v1/rpc/workflows/${id}/errors/alerts/resend`
 */
export function resendEmail(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.BPM.Errors.BPMAlertNodeErrorStatusDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `rpc/workflows/${id}/errors/alerts/resend`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflows/${id}/errors/alerts/${errorId}`
 */
export function deleteAlertError(
	id: Varicent.ID,
	errorId: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: `workflows/${id}/errors/alerts/${errorId}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Get workflow node history
 * url: `api/v1/workflows/${id}/history`
 */
export function getWorkflowNodeHistory(
	id: Varicent.ID,
	query?: {
		offset?: number;
		limit?: number;
		orderBy?: string;
		filter?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflows/${id}/history`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<
		Varicent.RESTAPI.v1.DTOs.BPM.Errors.BPMNodeHistoryStatusDTO[]
	>(request);
}

/**
 * Get workflow node history
 * url: `api/v1/payee/workflows/${id}/history`
 */
export function getWorkflowNodeHistoryForPayee(
	id: Varicent.ID,
	query?: {
		offset?: number;
		limit?: number;
		orderBy?: string;
		filter?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/workflows/${id}/history`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<
		Varicent.RESTAPI.v1.DTOs.BPM.Errors.BPMNodeHistoryStatusDTO[]
	>(request);
}

/**
 * Clear workflow node history
 * url: `api/v1/workflows/${id}/history`
 */
export function deleteWorkflowNodeHistory(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: `workflows/${id}/history`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.BPM.Errors.BPMNodeHistoryStatusDTO[]
	>(request);
}

/**
 * Export workflow node history
 * url: `api/v1/workflows/${id}/history/export`
 */
export function exportWorkflowNodeHistory(
	id: Varicent.ID,
	query?: {
		offset?: number;
		limit?: number;
		orderBy?: string;
		filter?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflows/${id}/history/export`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.BPM.Errors.BPMNodeHistoryStatusDTO[]
	>(request);
}

/**
 *
 * url: `api/v1/rpc/workflowmanager/workflows/validateformula`
 */
export function validateConditionalNodeFormula(
	nodeDTO: Varicent.RESTAPI.v1.DTOs.Workflow.ValidateConditionalNodeDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'rpc/workflowmanager/workflows/validateformula',
		apiVersion: 'v1' as const,
		data: nodeDTO,
		...options,
	};
	return configurableRequest<boolean>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/preview`
 */
export function preview(
	state: Varicent.RESTAPI.v1.DTOs.Preview.BPMPreviewStateDTO,
	query?: {
		offset?: number;
		limit?: number;
		orderBy?: string;
		filter?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'workflowmanager/workflows/preview',
		apiVersion: 'v1' as const,
		data: state,
		query,
		...options,
	};
	return configurableRequestRaw<Varicent.RESTAPI.v1.DTOs.ResultSetDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents`
 */
export function getDocuments(
	workflowID: Varicent.ID,
	tokenID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.Domain.BPM.BPMDocument[]>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents`
 */
export function getDocumentsForPayee(
	workflowID: Varicent.ID,
	tokenID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.Domain.BPM.BPMDocument[]>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents/${documentID}/file`
 */
export function getBPMDocumentFile(
	workflowID: Varicent.ID,
	tokenID: Varicent.ID,
	documentID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents/${documentID}/file`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.FileContentDTO>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents/${documentID}/file`
 */
export function getBPMDocumentFileForPayee(
	workflowID: Varicent.ID,
	tokenID: Varicent.ID,
	documentID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents/${documentID}/file`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.FileContentDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents`
 */
export function postBPMDocumentFile(
	workflowID: Varicent.ID,
	tokenID: Varicent.ID,
	document: Varicent.RESTAPI.v1.DTOs.DocumentUploadDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents`,
		apiVersion: 'v1' as const,
		data: document,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPM.BPMDocumentDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents`
 */
export function postBPMDocumentFileForPayee(
	workflowID: Varicent.ID,
	tokenID: Varicent.ID,
	document: Varicent.RESTAPI.v1.DTOs.DocumentUploadDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents`,
		apiVersion: 'v1' as const,
		data: document,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPM.BPMDocumentDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents/${documentID}`
 */
export function deleteBPMDocument(
	workflowID: Varicent.ID,
	tokenID: Varicent.ID,
	documentID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: `workflowmanager/workflows/${workflowID}/tokens/${tokenID}/documents/${documentID}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/initiable`
 */
export function getAvailableWorkflowsForPayee(
	query?: { offset?: number; limit?: number; orderBy?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/workflowmanager/initiable',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/pending`
 */
export function getPendingWorkflowsForPayee(
	query?: { offset?: number; limit?: number; orderBy?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/workflowmanager/pending',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/completed`
 */
export function getCompletedWorkflowsForPayee(
	query?: { offset?: number; limit?: number; orderBy?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/workflowmanager/completed',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequestRaw<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/initiable/${id}`
 */
export function getAvailableWorkflowForPayee(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/workflowmanager/initiable/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/pending/${id}`
 */
export function getPendingWorkflowForPayee(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/workflowmanager/pending/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/completed/${id}`
 */
export function getCompletedWorkflowForPayee(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/workflowmanager/completed/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/token/${id}`
 */
export function getWorkflowByTokenForPayee(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/workflowmanager/token/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/workflows/${workflowID}/paths/${tokenID}`
 */
export function getActionPaths(
	workflowID: Varicent.ID,
	tokenID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflowmanager/workflows/${workflowID}/paths/${tokenID}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/workflows/${workflowID}/paths/${tokenID}`
 */
export function getActionPathsForPayee(
	workflowID: Varicent.ID,
	tokenID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/workflowmanager/workflows/${workflowID}/paths/${tokenID}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/token/${tokenID}/rerunbpmprocess`
 */
export function postRerunBPMProcess(
	tokenID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `workflowmanager/token/${tokenID}/rerunbpmprocess`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Get all inquiries created by a payee user
 * url: `api/v1/payee/workflowmanager/bpmInquiries/getListCreated`
 */
export function getInquiryListCreated(
	query?: {
		offset?: number;
		limit?: number;
		orderBy?: string;
		filter?: string;
		actionRequiredSelection?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/workflowmanager/bpmInquiries/getListCreated',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMInquiryListDTO>(
		request
	);
}

/**
 * Get all assigned to a payee user
 * url: `api/v1/payee/workflowmanager/bpmInquiries/getListAssigned`
 */
export function getInquiryListAssigned(
	query?: {
		offset?: number;
		limit?: number;
		orderBy?: string;
		filter?: string;
		actionRequiredSelection?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/workflowmanager/bpmInquiries/getListAssigned',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMInquiryListDTO>(
		request
	);
}

/**
 * Get inquiry count of a payee
 * url: `api/v1/payee/workflowmanager/bpmInquiries/inquiryCount`
 */
export function getWorkflowInquiryCount(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/workflowmanager/bpmInquiries/inquiryCount',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMInquiryCountDTO>(
		request
	);
}

/**
 * Get inquiry detail of an inquiy
 * url: `api/v1/inquirymanager/getInquiries/${inquiryID}`
 */
export function getInquiryAdmin(
	inquiryID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `inquirymanager/getInquiries/${inquiryID}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMInquiryViewDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/inquirymanager/workflowInquiryCategories/${workflowID}`
 */
export function getComponentFromWorkflowAdmin(
	workflowID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `inquirymanager/workflowInquiryCategories/${workflowID}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPM.BPMComponentDTO[]>(
		request
	);
}

/**
 *
 * url: `api/v1/inquirymanager/workflowInquiryCategories/${inquiryID}`
 */
export function modifyComponentFromWorkflowAdmin(
	inquiryID: Varicent.ID,
	newSelections: Varicent.RESTAPI.v1.DTOs.BPMComponentSelectionDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `inquirymanager/workflowInquiryCategories/${inquiryID}`,
		apiVersion: 'v1' as const,
		data: newSelections,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMInquiryDTO[]>(request);
}

/**
 *
 * url: `api/v1/payee/inquirymanager/workflowInquiryCategories/${inquiryID}`
 */
export function modifyComponentFromPayee(
	inquiryID: Varicent.ID,
	newSelections: Varicent.RESTAPI.v1.DTOs.BPMComponentSelectionDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/inquirymanager/workflowInquiryCategories/${inquiryID}`,
		apiVersion: 'v1' as const,
		data: newSelections,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMInquiryDTO[]>(request);
}

/**
 * Get inquiry detail of an inquiy
 * url: `api/v1/payee/workflowmanager/bpmInquiries/${inquiryID}`
 */
export function getInquiry(inquiryID: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/workflowmanager/bpmInquiries/${inquiryID}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMInquiryViewDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/workflows/${id}/InquiryFormComponent`
 */
export function getWorkflowInquiryFormComponents(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflows/${id}/InquiryFormComponent`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPM.BPMComponentDTO[]>(
		request
	);
}

/**
 *
 * url: `api/v1/workflows/${id}/InquiryFormComponent`
 */
export function submitWorkflowInquiryFormComponents(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.BPM.BPMComponentDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `workflows/${id}/InquiryFormComponent`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/bpmInquiries/${inquiryID}/inquiryPending`
 */
export function postCommentForPayee(
	inquiryID: Varicent.ID,
	commentDTO: Varicent.RESTAPI.v1.DTOs.BPM.BPMInquiryCommentDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/workflowmanager/bpmInquiries/${inquiryID}/inquiryPending`,
		apiVersion: 'v1' as const,
		data: commentDTO,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.BPM.BPMInquiryCommentDTO[]
	>(request);
}

/**
 * Get all inquiries
 * url: `api/v1/workflowmanager/bpmInquiries/getinquiries`
 */
export function getInquiryForAdmin(
	query?: {
		offset?: number;
		limit?: number;
		filter?: string;
		orderBy?: string;
		wfSelection?: string;
		actionRequiredSelection?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'workflowmanager/bpmInquiries/getinquiries',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMInquiryListDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/workflowmanager/bpmInquiries/getmonthinfo`
 */
export function getInquiryMonthInfo(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'workflowmanager/bpmInquiries/getmonthinfo',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMAdminInquiryMonthInfoDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/workflowmanager/bpmInquiries/${inquiryID}/comments`
 */
export function postComment(
	inquiryID: Varicent.ID,
	commentDTO: Varicent.RESTAPI.v1.DTOs.BPM.BPMInquiryCommentDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `workflowmanager/bpmInquiries/${inquiryID}/comments`,
		apiVersion: 'v1' as const,
		data: commentDTO,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.BPM.BPMInquiryCommentDTO[]
	>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/bpmInquiries/${inquiryID}/attachments`
 */
export function postAttachmentsForPayee(
	inquiryID: Varicent.ID,
	attachmentDTOs: Varicent.RESTAPI.v1.DTOs.PortalAccess.AttachmentItemDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/workflowmanager/bpmInquiries/${inquiryID}/attachments`,
		apiVersion: 'v1' as const,
		data: attachmentDTOs,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/bpmInquiries/${inquiryID}/attachments`
 */
export function postAttachments(
	inquiryID: Varicent.ID,
	attachmentDTOs: Varicent.RESTAPI.v1.DTOs.PortalAccess.AttachmentItemDTO[],
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `workflowmanager/bpmInquiries/${inquiryID}/attachments`,
		apiVersion: 'v1' as const,
		data: attachmentDTOs,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/bpmInquiries/${inquiryID}/attachments/${fileName}`
 */
export function getAttachmentForPayee(
	inquiryID: Varicent.ID,
	fileName: string,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/workflowmanager/bpmInquiries/${inquiryID}/attachments/${fileName}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.FileContentDTO>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/bpmInquiries/${inquiryID}/attachments/${fileName}`
 */
export function getAttachment(
	inquiryID: Varicent.ID,
	fileName: string,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflowmanager/bpmInquiries/${inquiryID}/attachments/${fileName}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.FileContentDTO>(request);
}

/**
 *
 * url: `api/v1/payee/workflowmanager/bpmInquiries/${inquiryID}/attachments/${fileName}`
 */
export function deleteAttachmentForPayee(
	inquiryID: Varicent.ID,
	fileName: string,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: `payee/workflowmanager/bpmInquiries/${inquiryID}/attachments/${fileName}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/bpmInquiries/${inquiryID}/attachments/${fileName}`
 */
export function deleteAttachment(
	inquiryID: Varicent.ID,
	fileName: string,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: `workflowmanager/bpmInquiries/${inquiryID}/attachments/${fileName}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Get all notifications for current payee
 * url: `api/v1/payee/bpmInquiries/getallnotification`
 */
export function getNotificationMessages(
	query?: { offset?: number; limit?: number },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/bpmInquiries/getallnotification',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPM.BPMInquiryNotificationCountDTO>(
		request
	);
}

/**
 * Update the admin owner of an inquiry
 * url: `api/v1/workflowmanager/bpmInquiries/${inquiryID}/owner`
 */
export function updateInquiryAdminOwner(
	inquiryID: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.BPMInquiryOwnerDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `workflowmanager/bpmInquiries/${inquiryID}/owner`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPMInquiryOwnerDTO>(
		request
	);
}

/**
 * Get new notification count
 * url: `api/v1/payee/bpmInquiries/newnotificationcount`
 */
export function getNewNotificationCount(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/bpmInquiries/newnotificationcount',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<number>(request);
}

/**
 * Mark the notification as read
 * url: `api/v1/payee/bpmInquiries/markNotification`
 */
export function postReadNotification(
	query: { id: Varicent.ID; category: number },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'payee/bpmInquiries/markNotification',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Mark all as read
 * url: `api/v1/payee/bpmInquiries/markAllNotification`
 */
export function postReadAllNotifications(options?: RequestOptions) {
	const request = {
		method: HttpVerb.POST,
		url: 'payee/bpmInquiries/markAllNotification',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/payee/workflowinquirycategories`
 */
export function getAllFromWorkflow(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'payee/workflowinquirycategories',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPM.BPMComponentDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/payee/workflowinquirycategories/${workflowObjectID}`
 */
export function getInquiryComponentsByReportID(
	workflowObjectID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/workflowinquirycategories/${workflowObjectID}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPM.BPMComponentDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/payee/workflowinquirycategoriesbyworkflowid/${workflowID}`
 */
export function getInquiryComponentsByWorkflowID(
	workflowID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/workflowinquirycategoriesbyworkflowid/${workflowID}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BPM.BPMComponentDTO>(
		request
	);
}

/**
 *
 * url: `api/v1/payee/workflowinquirycategories/${componentID}/sourcedata/${sourceID}`
 */
export function getSourceRows(
	componentID: Varicent.ID,
	sourceID: Varicent.ID,
	query?: {
		limit?: number;
		offset?: number;
		orderBy?: string;
		filterBy?: string;
		orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
		expensiveIncludeTotalRows?: boolean;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/workflowinquirycategories/${componentID}/sourcedata/${sourceID}`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/bpmInquiries/${componentID}/sourcedata/${sourceID}`
 */
export function getSourceRowsAdmin(
	componentID: Varicent.ID,
	sourceID: Varicent.ID,
	query?: {
		limit?: number;
		offset?: number;
		orderBy?: string;
		filterBy?: string;
		orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
		expensiveIncludeTotalRows?: boolean;
		currentPayeeWebUser?: string;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflowmanager/bpmInquiries/${componentID}/sourcedata/${sourceID}`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/bpmInquiries/report/${id}`
 */
export function getReportFromWorkflowObjectId(
	id: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `workflowmanager/bpmInquiries/report/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<number>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/bpmInquiries/${workflowID}`
 */
export function deleteInquiriesInArchivedWorkflow(
	workflowID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.DELETE,
		url: `workflowmanager/bpmInquiries/${workflowID}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * url: `api/v1/workflowmanager/bpmInquiries/${workflowID}`
 */
export function restartArchivedInquiryWorkflow(
	workflowID: Varicent.ID,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `workflowmanager/bpmInquiries/${workflowID}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.Domain.BPM.BPMWorkflow>(request);
}
