/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import _ from 'lodash';
import R from 'ramda';

/**
 * Immutable, plain-old-data object representing a lazy selection of indices,
 * as a set of ranges and single selections,
 * as if selected with conventional shift- (range) and ctrl- (individual) selection.
 *
 * Ranges are inclusive, that is, [0, 3] represents indices 0, 1, 2, 3; not 0, 1, 2.
 *
 * Invariant 1: ranges must not overlap (maintained internally).
 * Invariant 2: ranges must contain at least two elements (maintained internally).
 * Invariant 3: ranges and single selections must be ordered (maintained internally).
 *
 * Conceptually, singleSelections are "layered" on top of rangeSelections, that is:
 * starting with all indices unselected, we first select all ranges (which are guaranteed not to overlap);
 * then, for each single selection, we toggle the selected state of that index.
 *
 * @typedef {{
 *   __rangeSelections__: Array<[number, number]>,
 *   __singleSelections__: Array<number>,
 *   __lastSelectedIndex__: number
 * }} LazySelectionObj
 */

function isWithinRange(index, [from, to]) {
	return index >= from && index <= to;
}

function createFrozenLazySelection(
	rangeSelections,
	singleSelections,
	lastSelectedInx
) {
	return Object.freeze({
		__rangeSelections__: Object.freeze(rangeSelections),
		__singleSelections__: Object.freeze(singleSelections),
		__lastSelectedIndex__: lastSelectedInx,
	});
}

function validateIndex(index) {
	if (typeof index !== 'number')
		throw new TypeError(`${index} is not a number.`);
	if (!isFinite(index)) throw new TypeError(`${index} is not finite.`);
	if (index < 0) throw new TypeError(`${index} is negative.`);
}

function validateRange(from, to) {
	validateIndex(from);
	validateIndex(to);
	if (to - from < 1)
		throw new TypeError(
			`Range [${from}, ${to}] is invalid. Ranges must be from low to high indices and contain more than one element.`
		);
}

/**
 * Traverses the ranges and singles of the selection in order of start index.
 * i.e. 0  1  [3, 6]  3  4  5  6  [8, 10]  [13, 23]  16
 * Ranges always come before selections that may be inside them.
 * @param {LazySelectionObj} self
 * @param {Function} callback
 * @private
 */
export function _walk(self, callback) {
	let rangeInx = 0;
	let singleInx = 0;

	while (
		rangeInx < self.__rangeSelections__.length ||
		singleInx < self.__singleSelections__.length
	) {
		let range = null;
		let single = null;
		if (rangeInx >= self.__rangeSelections__.length) {
			// no ranges left, we must process single selections
			single = self.__singleSelections__[singleInx++];
		} else if (singleInx >= self.__singleSelections__.length) {
			// no single selections left, we must process ranges
			range = self.__rangeSelections__[rangeInx++];
		} else {
			/*
			 * both ranges and single selections left
			 * pick the range if its start index is lower or equal
			 */
			if (
				self.__rangeSelections__[rangeInx][0] <=
				self.__singleSelections__[singleInx]
			) {
				range = self.__rangeSelections__[rangeInx++];
			} else {
				single = self.__singleSelections__[singleInx++];
			}
		}

		callback(range, single);
	}
}

/**
 * Create an empty LazySelection.
 * @returns {LazySelectionObj}
 */
export function empty() {
	return createFrozenLazySelection([], [], -1);
}

/**
 * Create a LazySelection with one index selected.
 * @param {number} index
 * @returns {LazySelectionObj}
 */
export function fromSingleSelection(index) {
	validateIndex(index);
	return createFrozenLazySelection([], [index], index);
}

/**
 * Create a LazySelection with one range selected.
 * @param {number} from
 * @param {number} to
 * @returns {LazySelectionObj}
 */
export function fromRangeSelection([from, to]) {
	// remember that the second index was the last selected
	const lastSelected = to;
	// swap from and to if the range is backwards
	if (to < from) [from, to] = [to, from];
	validateRange(from, to);
	return createFrozenLazySelection([[from, to]], [], lastSelected);
}

/**
 * Create a new LazySelection with this index toggled.
 * @param {LazySelectionObj} self
 * @param {number} index
 * @returns {LazySelectionObj}
 */
export function withSingleSelection(self, index) {
	validateIndex(index);
	// Maintain invariant 1 and invariant 2 because ranges are not modified.

	const existingSelectionInx = self.__singleSelections__.indexOf(index);
	if (existingSelectionInx !== -1) {
		// this index is selected; unselect it
		return createFrozenLazySelection(
			self.__rangeSelections__,
			// Maintain invariant 3 because removing an element cannot break ordering of other elements.
			R.remove(existingSelectionInx, 1, self.__singleSelections__),
			index
		);
	} else {
		// this index is unselected; select it
		const insertInx = _.sortedIndex(self.__singleSelections__, index);
		return createFrozenLazySelection(
			self.__rangeSelections__,
			// Maintain invariant 3 by inserting at sorted index.
			R.insert(insertInx, index, self.__singleSelections__),
			index
		);
	}
}

/**
 * Create a new LazySelection with this range selected.
 * @param {LazySelectionObj} self
 * @param {number} from
 * @param {number} to
 * @returns {LazySelectionObj}
 */
export function withRangeSelection(self, [from, to]) {
	// remember that the second index was the last selected
	const lastSelected = to;
	// swap from and to if the range is backwards
	if (to < from) [from, to] = [to, from];
	/*
	 * Maintain invariant 2 because ranges are either preserved or grown in this function,
	 * so we cannot create a single-element range unless the user's argument is bad.
	 */
	validateRange(from, to);

	// Maintain invariant 1 by removing contained ranges, and extending to adjacent ranges.
	const firstRangeBeforeInx = _.findIndex(
		self.__rangeSelections__,
		([, /* oldFrom*/ oldTo]) => from <= oldTo
	);
	let insertStartInx;
	let newFrom;
	if (firstRangeBeforeInx !== -1) {
		/*
		 * left edge overlaps or encompasses another range
		 * start the insertion at that range, and use the leftmost (min) starting index
		 */
		insertStartInx = firstRangeBeforeInx;
		newFrom = Math.min(from, self.__rangeSelections__[firstRangeBeforeInx][0]);
	} else {
		/*
		 * left edge does not overlap or encompass another range, therefore all ranges are to the left
		 * start the insertion beyond the end, and don't adjust the starting index
		 */
		insertStartInx = self.__rangeSelections__.length;
		newFrom = from;
	}

	const firstRangeAfterInx = _.findLastIndex(
		self.__rangeSelections__,
		([oldFrom /* ,oldTo*/]) => oldFrom <= to
	);
	let insertEndInx;
	let newTo;
	if (firstRangeAfterInx !== -1) {
		/*
		 * right edge overlaps or encompasses another range
		 * end the insertion at that range, and the rightmost (max) starting index
		 */
		insertEndInx = firstRangeAfterInx;
		newTo = Math.max(to, self.__rangeSelections__[firstRangeAfterInx][1]);
	} else {
		/*
		 * right edge overlaps or encompasses another range, therefore all ranges are to the right
		 * end the insertion beyond the beginning, and don't adjust the ending index
		 */
		insertEndInx = -1;
		newTo = to;
	}

	return createFrozenLazySelection(
		// Maintain invariant 3 by inserting at the sorted index.
		R.pipe(
			R.remove(insertStartInx, insertEndInx - insertStartInx + 1),
			R.insert(insertStartInx, [newFrom, newTo])
		)(self.__rangeSelections__),
		/*
		 * Kill all single selections intersecting with the range.
		 * This preserves expected behaviour when shift-selecting over existing selections.
		 */
		self.__singleSelections__.filter(
			(index) => !isWithinRange(index, [from, to])
		),
		lastSelected
	);
}

/**
 * Create a new LazySelection with the result of a user selection at `index`, possibly with ctrl or shift held.
 * @param {LazySelectionObj} self
 * @param {number} index
 * @param {boolean} [ctrl]
 * @param {boolean} [shift]
 */
export function withUserSelection(
	self,
	index,
	{ ctrl = false, shift = false } = {}
) {
	if (self.__lastSelectedIndex__ === -1) {
		// no last selection, so nothing should be selected
		/* istanbul ignore next: should never happen */
		if (
			self.__rangeSelections__.length !== 0 ||
			self.__singleSelections__.length !== 0
		)
			throw new Error(
				'Invariant violation: selections exist but no last selected row.'
			);
		return fromSingleSelection(index);
	} else {
		if (ctrl && shift) {
			// ctrl and shift both held: add a new range from the last index to the new one
			if (index === self.__lastSelectedIndex__) {
				// range would contain only one element: make a single selection instead
				return withSingleSelection(self, index);
			} else {
				return withRangeSelection(self, [self.__lastSelectedIndex__, index]);
			}
		} else if (ctrl) {
			// only ctrl held: toggle the current row
			return withSingleSelection(self, index);
		} else if (shift) {
			// only shift held: replace with a range
			if (index === self.__lastSelectedIndex__) {
				// range would contain only one element: make a single selection instead
				return fromSingleSelection(index);
			} else {
				return fromRangeSelection([self.__lastSelectedIndex__, index]);
			}
		} else {
			// nothing held: replace with a single selection
			return fromSingleSelection(index);
		}
	}
}

/**
 * Get the total number of indices selected.
 * @param {LazySelectionObj} self
 * @returns {number}
 */
export function countSelected(self) {
	let count = 0;
	let lastTo = null;
	_walk(self, (range, index) => {
		if (range !== null) {
			const [from, to] = range;
			// invariant 1 guarantees that ranges do not overlap, so add this one
			count += to - from + 1;
			// if any single selections overlap, they will be subtracted after
			lastTo = to;
		} else {
			// _walk iteration order guarantees that this index is after the last range's start, so we only need to check the end
			if (lastTo !== null && index <= lastTo) {
				// this single selection overlaps with the last range, cancelling out
				count -= 1;
			} else {
				// this single selection does not overlap with a range
				count += 1;
			}
		}
	});
	return count;
}

/**
 * Check whether `index` is selected.
 * @param {LazySelectionObj} self
 * @param {number} index
 * @returns {boolean}
 */
export function isSelected(self, index) {
	const isInRange = self.__rangeSelections__.some((range) =>
		isWithinRange(index, range)
	);
	const isInSingle = self.__singleSelections__.includes(index);
	/*
	 * is in range XOR is in selection
	 * because single selections overlapping ranges cancel out that index
	 */
	return Boolean(isInRange ^ isInSingle);
}

/**
 * Get an array of selected indices.
 * @param {LazySelectionObj} self
 * @returns {Array<number>}
 */
export function toIndices(self) {
	const arr = [];
	_walk(self, (range, index) => {
		if (range !== null) {
			// insert all indices from range
			const [from, to] = range;
			for (let i = from; i <= to; ++i) arr.push(i);
		} else {
			if (arr.length > 0 && arr[arr.length - 1] >= index) {
				// single selection overlaps with last range; remove that index
				const removeInx = arr.lastIndexOf(index);
				/* istanbul ignore next: should never happen */
				if (removeInx === -1)
					throw new Error(
						'Invariant violation: _walk iteration order or broken ranges.'
					);
				arr.splice(removeInx, 1);
			} else {
				// single selection does not overlap; insert it
				arr.push(index);
			}
		}
	});
	return arr;
}

/**
 * Reduce the selection to an array of inclusive ranges of selected indices.
 * Adjacent ranges will be merged.
 * Ranges may have 1 element, unlike the the usual invariant.
 * @param {LazySelectionObj} self
 * @returns {Array<[number, number]>}
 */
export function toRanges(self) {
	const arr = [];
	_walk(self, (range, index) => {
		const lastRange = arr.length > 0 && arr[arr.length - 1];
		if (range !== null) {
			// new range, guaranteed not to overlap (invariant 1)
			const [from, to] = range;
			if (lastRange) {
				const [lastFrom, lastTo] = lastRange;
				if (lastTo + 1 === from) {
					// last range is adjacent; merge it
					arr.pop();
					arr.push([lastFrom, to]);
				} else {
					// ranges are not adjacent
					arr.push([from, to]);
				}
			} else {
				// no ranges inserted yet
				arr.push([from, to]);
			}
		} else {
			/*
			 * single selection
			 * if it's within a range, split it and remove that index
			 */
			if (lastRange) {
				const [lastFrom, lastTo] = lastRange;
				if (isWithinRange(index, lastRange)) {
					/*
					 * within the last range
					 * negative selection: split the last range to remove this index
					 */

					/*
					 * we need to ensure that adding/subtracting 1 won't produce an impossible range.
					 * that is, with index 2, we may convert:
					 * [1, 3] to [1, 1] [3, 3]
					 * but never:
					 * [2, 3] to [2, 1] [3, 3]
					 *            ^ bad!
					 */
					arr.pop();
					if (lastFrom <= index - 1) arr.push([lastFrom, index - 1]);
					if (index + 1 <= lastTo) arr.push([index + 1, lastTo]);
				} else if (lastTo + 1 === index) {
					// last range is adjacent; merge it
					arr.pop();
					arr.push([lastFrom, index]);
				} else {
					/*
					 * not within the last range
					 * positive selection: and add a new range for this index
					 */
					arr.push([index, index]);
				}
			} else {
				// no ranges inserted yet, this must be a positive selection
				arr.push([index, index]);
			}
		}
	});
	return arr;
}

/**
 * Get the last selected index, or -1 if no indices are selected.
 * @param {LazySelectionObj} self
 * @returns {number}
 */
export function lastSelectedIndex(self) {
	return self.__lastSelectedIndex__;
}
