/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as Paths from 'constants/routerPaths';
import * as Permissions from 'constants/permissions';

export const PathPermissions = {
	[Paths.ACTIVITY_LINK]: [Permissions.ACTIVITY_VIEWALL],

	[Paths.ADMIN_LINK]: [Permissions.ADMIN_ROLE],
	[Paths.ADMIN_LINK + Paths.AUDIT_LINK]: [
		Permissions.ADMIN_ROLE,
		Permissions.AUDITLOG_VIEW,
	],
	[Paths.ADMIN_LINK + Paths.IMPORT_ERROR_LOGS_LINK]: [Permissions.ADMIN_ROLE],
	[Paths.ADMIN_LINK + Paths.EXPORT_ERROR_LOGS_LINK]: [Permissions.ADMIN_ROLE],
	[Paths.ADMIN_LINK + Paths.PERFORMANCE_LINK]: [
		Permissions.ADMIN_ROLE,
		Permissions.AUDITLOG_VIEW,
	],
	[Paths.ADMIN_LINK + Paths.HEALTH_DASHBOARD_LINK]: [Permissions.ADMIN_ROLE],
	[Paths.ADMIN_LINK + Paths.SCHEDULER_ERROR_LOGS_LINK]: [
		Permissions.ADMIN_ROLE,
	],
	[Paths.ADMIN_LINK + Paths.TASK_ERROR_LOGS_LINK]: [Permissions.ADMIN_ROLE],
	[Paths.ADMIN_LINK + Paths.MANAGE_DOCUMENTS_LINK]: [
		Permissions.ADMIN_ROLE,
		Permissions.HOMEPAGE_MANAGEDOCUMENTSVIEW,
	],
	[Paths.ADMIN_LINK + Paths.PAYEE_GROUPS_LINK]: [
		Permissions.ADMIN_ROLE,
		Permissions.HOMEPAGE_PAYEEGROUPSVIEW,
	],
	[Paths.ADMIN_LINK + Paths.SAVED_PUBLICATIONS_LINK]: [
		Permissions.ADMIN_ROLE,
		Permissions.HOMEPAGE_MANAGESAVEDPUBLICATIONSVIEW,
	],
	[Paths.ADMIN_LINK + Paths.SAVE_IMPORTS_LINK]: [
		Permissions.ADMIN_ROLE,
		Permissions.HOMEPAGE_MANAGESAVEDIMPORTSVIEW,
	],
	[Paths.ADMIN_LINK + Paths.HIERARCHIES_LINK]: [
		Permissions.ADMIN_ROLE,
		Permissions.HOMEPAGE_HIERARCHYVIEW,
	],

	[Paths.COMPOSER_LINK]: [Permissions.COMPOSER_VIEW],
	[Paths.COMPOSER_LINK_WITH_COMPONENT]: [Permissions.COMPOSER_VIEW],

	[Paths.PORTAL_ACCESS_LINK]: [Permissions.PORTALACCESS_VIEW],
	[Paths.PORTAL_ACCESS_WEBTABS_LINK]: [Permissions.PORTALACCESS_VIEW],
	[Paths.PORTAL_ACCESS_GROUPS_LINK]: [Permissions.PORTALACCESS_VIEW],
	[Paths.PORTAL_ACCESS_TREES_LINK]: [Permissions.PORTALACCESS_VIEW],
	[Paths.PORTAL_ACCESS_ASSIGNMENT_LINK]: [Permissions.PORTALACCESS_VIEW],
	[Paths.PORTAL_ACCESS_SIGN_OFF_LINK]: [Permissions.PORTALACCESS_VIEW],
	[Paths.PORTAL_ACCESS_INQUIRIES_LINK]: [Permissions.PORTALACCESS_VIEW],

	[Paths.PULSE_ANALYTICS_LINK]: [],
	[Paths.SCENARIOS_LINK]: [Permissions.SCENARIOS_VIEW],
	[Paths.SCHEDULER_LINK]: [Permissions.SCHEDULER_VIEW],

	[Paths.TASKS_LINK]: [Permissions.TASKS_VIEW],
	[Paths.TASKS_LINK + Paths.DEFERRED_TASKS_LINK]: [Permissions.TASKS_VIEW],
	[Paths.TASKS_LINK + Paths.GROUPS_LINK]: [Permissions.TASKS_VIEW],
	[Paths.TASKS_LINK + Paths.RULES_LINK]: [Permissions.TASKS_VIEW],
	[Paths.TASKS_LINK + Paths.TASK_ALERTS_LINK]: [Permissions.TASKS_VIEW],

	[Paths.WORKFLOWS_LINK]: [Permissions.WORKFLOWMANAGER_VIEW],
	[Paths.WEBREPORT_LINK]: [Permissions.COMPOSER_VIEW],
	[Paths.WEBREPORT_PREVIEW_LINK]: [Permissions.COMPOSER_VIEW],
	[Paths.ROW_VIEWER_LINK]: [Permissions.COMPOSER_VIEW],
	[Paths.WEBFORM_LINK]: [Permissions.COMPOSER_VIEW],
	[Paths.CALC_ROW_VIEWER_LINK]: [Permissions.CALCULATE_VIEW],
	[Paths.HIERARCHY_LINK]: [],

	[Paths.RAPID_REPORTS_LINK]: [Permissions.RAPID_REPORT_VIEW],
	[Paths.INQUIRY_MANAGEMENT_LINK]: [Permissions.INQUIRY_MANAGEMENT_VIEW],
};

export const ActionPermissions = {
	openTablePublicationWizard: [
		Permissions.HOMEPAGE_MANAGESAVEDPUBLICATIONSVIEW,
		Permissions.HOMEPAGE_MANAGESAVEDPUBLICATIONSEDIT,
	],
	runSchedulerProcess: [Permissions.SCHEDULER_VIEW],
};
