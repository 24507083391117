/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React from 'react';
import ReactDOM from 'react-dom';
import { InputGroup, FormControl, Overlay, Tooltip } from 'react-bootstrap';
import VBaseComponent from 'components/common/baseComponent';
import VIcon from 'components/common/icon';
import VTooltip from 'components/common/tooltipComponent';
import 'styles/components/textInput.scss';
import classnames from 'classnames';
import _ from 'lodash';
import LocaleHelper from 'helpers/localeHelper';

export default class VText extends VBaseComponent {
	static propTypes = {
		id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		label: PropTypes.string,
		isPassword: PropTypes.bool,
		passwordTitle: PropTypes.string,
		onChange: PropTypes.func,
		onBlur: PropTypes.func,
		onKeyPress: PropTypes.func,
		defaultValue: PropTypes.string,
		value: PropTypes.string,
		className: PropTypes.string,
		holderClassName: PropTypes.string,
		width: PropTypes.oneOf([
			'xxsmall',
			'xsmall',
			'small',
			'medium',
			'large',
			'xlarge',
			'long',
			'full',
			'100',
			'template',
		]),
		placeholder: PropTypes.string,
		validationRules: PropTypes.array,
		autofocus: PropTypes.bool,
		doSelectTextOnFocus: PropTypes.bool,
		maxLength: PropTypes.number,
		isDarkTheme: PropTypes.bool,
		borderless: PropTypes.bool,
		disabled: PropTypes.bool,
		disabledInfoText: PropTypes.string,
		clearErrors: PropTypes.bool,
		delay: PropTypes.number, // delay in millisecond for calling onChange default is 0
		needValidate: PropTypes.bool, // trigger validation when clicking a button outside
		onError: PropTypes.func, // call back when validation fails
		style: PropTypes.object,
		successfulValidationFeedbackAddon: PropTypes.object,
		renameOnEnter: PropTypes.bool,
		onEnter: PropTypes.func,
		forceReset: PropTypes.bool,
		flexify: PropTypes.bool,
		onFocus: PropTypes.func,
		stopAutoComplete: PropTypes.bool,
		'data-test': PropTypes.string,
	};

	static defaultProps = {
		type: 'text',
		width: 'medium',
		holderClassName: '',
		renameOnEnter: false,
		flexify: true,
	};

	constructor(props, context) {
		super(props, context);

		this.state = {
			loaded: false,
			valid: true,
			errorMessages: [],
			value: this.props.value,
			reset: true,
			showPassword: false,
			showErrorMessage: false,
			hasFeedbackAddon: false,
			isDirty: false,
		};
	}

	componentWillMount() {
		this.initialize(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.initialize(nextProps);
	}

	initialize(props) {
		if (this.state.reset || props.forceReset) {
			const value = props.value ? props.value : props.defaultValue;
			this.setState({ value });

			if (
				props.value !== this.state.value &&
				this.onTextChangeDelayed &&
				this.onTextChangeDelayed.cancel
			) {
				this.onTextChangeDelayed.cancel();
			}
		}

		if (props.onChange) {
			this.onTextChangeDelayed = props.delay
				? _.debounce(props.onChange, props.delay)
				: props.onChange;
		} else {
			this.onTextChangeDelayed = () => false;
		}

		if ((props.value || props.needValidate) && !props.disabled) {
			this.validateRules(props.value, props.validationRules);
		}

		if (
			props.clearErrors ||
			(!props.value && !props.needValidate) ||
			props.disabled
		) {
			this.setState({ hasFeedbackAddon: false });
		}
	}

	onTextChange(e) {
		if (e.keyCode === 13) {
			e.preventDefault();
		} else {
			const val = e.target.value;
			this.validateRules(val);
			this.onTextChangeDelayed(val);
			this.setState({ value: val, isDirty: true });
		}
		if (!this.state.loaded) this.setState({ loaded: true });
		if (this.state.reset) this.setState({ reset: false });
	}

	onBlur() {
		this.setState({ reset: true });
		const val = this.state.value;
		const valid = this.validateRules(val);

		const { validationRules, onBlur } = this.props;
		const { hasFeedbackAddon, showErrorMessage } = this.state;
		if (
			validationRules &&
			validationRules.length &&
			hasFeedbackAddon &&
			!valid &&
			!showErrorMessage
		) {
			setTimeout(() => {
				this.setState({ showErrorMessage: true });
			}, 200);
		}

		if (onBlur && valid) {
			onBlur(val);
		}
	}

	onKeyPress(e) {
		if (this.props.onEnter && e.key === 'Enter') {
			this.props.onEnter(this.state.value);
		}
		if (this.props.renameOnEnter && e.key === 'Enter') {
			this.onBlur();
		}
		if (this.props.onKeyPress) {
			this.props.onKeyPress(e, this.state.value);
		}
	}

	static validate(val, rules) {
		const errors = [];

		for (const rule of rules) {
			const validation = rule.validate(val);
			if (validation.invalid) {
				errors.push(
					validation.error
						? validation.error
						: LocaleHelper.formatMessage(store.getState(), validation.errorKey)
				);
			}
		}

		return errors;
	}

	validateRules(val, rules) {
		const validationRules = rules || this.props.validationRules;
		if (!validationRules) {
			return true;
		}
		const errors = VText.validate(val, validationRules);

		if (errors.length) {
			this.setState({
				valid: false,
				errorMessages: errors,
				isDirty: false,
				hasFeedbackAddon: true,
			});
		} else {
			this.setState({
				valid: true,
				errorMessages: [],
				showErrorMessage: false,
				isDirty: false,
				hasFeedbackAddon: true,
			});
		}
		if (this.props.onError) {
			// TODO: fix stack overflow exception, which occurs here
			this.props.onError(this.props.id, errors.length === 0, errors);
		}
		return errors.length === 0;
	}

	getErrorTarget() {
		return ReactDOM.findDOMNode(this.refs.inputTarget);
	}

	getTooltip(overlayClassName, feedbackError) {
		return (
			<Tooltip className={overlayClassName}>
				<div className="v-error-tooltip-horizontal">
					<span className="v-tooltip-close">
						<VIcon
							iconClass="icon-closemodal"
							data-test="text-comp-tooltip-close"
							size="glyph-small"
							action={() => this.setState({ showErrorMessage: false })}
						/>
					</span>
					<span className="v-tooltip-message" data-test="text-comp-tooltip">
						{feedbackError}
					</span>
				</div>
			</Tooltip>
		);
	}

	getSuccessfulValidationFeedbackAddon() {
		const { successfulValidationFeedbackAddon } = this.props;
		if (successfulValidationFeedbackAddon) {
			return successfulValidationFeedbackAddon;
		}

		return (
			<VIcon
				iconClass="icon-signature"
				supportingClasses={['v-success-icon']}
				size="xsmall"
				type="static"
			/>
		);
	}

	onFocus(e) {
		this.setState({ reset: false });

		if (this.props.doSelectTextOnFocus) {
			/*
			 * get the target BEFORE scheduling the timeout, since now is when we know
			 * it contains the correct value.
			 */
			const target = e.target;
			/*
			 * setTimeout is needed as a workaround to make it work in some instances like
			 * when the target VText is the name field in a WizardComponent or VNewWizardComponent;
			 * e.g. calculations, tables, data stores, etc.
			 */
			setTimeout(() => target.select(), 0);
		}

		if (this.props.onFocus) {
			this.props.onFocus();
		}
	}

	renderInputBox() {
		const {
			id,
			label,
			placeholder,
			autofocus,
			maxLength,
			disabled,
			disabledInfoText,
			type,
			validationRules,
			isPassword,
			stopAutoComplete,
		} = this.props;
		const { value, hasFeedbackAddon, showPassword, valid, showErrorMessage } =
			this.state;
		const hasValidationRules = validationRules && validationRules.length;

		let feedbackAddon = '';
		if (hasValidationRules) {
			if (hasFeedbackAddon) {
				feedbackAddon = valid ? (
					this.getSuccessfulValidationFeedbackAddon()
				) : (
					<VIcon
						iconClass="icon-warning"
						supportingClasses={['v-error-icon']}
						size="xsmall"
						type="static"
						allowStaticOnClick
						action={() =>
							this.setState({
								showErrorMessage: !showErrorMessage,
							})
						}
						onMouseOver={() => this.setState({ showErrorMessage: true })}
						data-test={`icon-${this.props['data-test']}`}
					/>
				);
			} else {
				feedbackAddon = <span />;
			}
		}

		let inputType = type;
		if (isPassword) {
			inputType = showPassword ? 'text' : 'password';
		}

		const inputElem = (
			<InputGroup>
				<FormControl
					id={id}
					autoComplete={
						/* This is to stop chrome from auto completing */
						stopAutoComplete ? 'new-password' : undefined
					}
					type={inputType}
					aria-label={label}
					placeholder={this.translatePath(placeholder)}
					value={value || ''}
					disabled={disabled}
					onChange={this.onTextChange.bind(this)}
					onBlur={this.onBlur.bind(this)}
					onKeyPress={this.onKeyPress.bind(this)}
					onFocus={this.onFocus.bind(this)}
					autoFocus={autofocus}
					maxLength={maxLength || 100}
					size={maxLength || 100}
					style={this.props.style}
					data-test={`text-component-form-${this.props['data-test']}`}
				/>
				<InputGroup.Addon>{feedbackAddon}</InputGroup.Addon>
			</InputGroup>
		);

		if (disabled) {
			return (
				<VTooltip text={disabledInfoText || value} placement="right">
					<span className="v-hidden-text-input">{inputElem}</span>
				</VTooltip>
			);
		}
		return inputElem;
	}

	renderAutoCompleteDummyInputBox() {
		return (
			<>
				{/* This input field below is to catch chrome's autofill email address instead of it being mapped to a random field. */}
				<input
					type="text"
					tabIndex="-1"
					className="chrome-auto-complete-dummy-input-field"
					style={{
						width: 0,
						height: 0,
						position: 'absolute',
						left: 0,
						top: 0,
						zIndex: -1,
					}}
				/>
			</>
		);
	}

	render() {
		const {
			className,
			isPassword,
			passwordTitle,
			disabled,
			validationRules,
			isDarkTheme,
			borderless,
			width,
			holderClassName,
			flexify,
			stopAutoComplete,
		} = this.props;
		const hasValidationRules = validationRules && validationRules.length;
		const {
			valid,
			showPassword,
			showErrorMessage,
			errorMessages,
			hasFeedbackAddon,
		} = this.state;

		const feedbackError =
			valid || !errorMessages.length ? '' : errorMessages[0];
		const widthClass = width === 'medium' ? '' : `${width}-input-width`;

		const newClassName = classnames(
			className,
			widthClass,
			'v-textComponent',
			'v-text-input',
			{
				'v-disabled': disabled,
				dark: isDarkTheme,
				borderless,
				'v-success': hasValidationRules && hasFeedbackAddon && valid,
				'v-error': hasValidationRules && hasFeedbackAddon && !valid,
			}
		);
		const overlayClassName = classnames('v-error-overlay', widthClass, {
			dark: isDarkTheme,
		});
		const headerClass = classnames('v-text-password-header', widthClass);

		return (
			<div
				style={this.props.style}
				className={classnames(holderClassName, {
					'v-full-width': width === 'full' && flexify,
				})}
				data-test={`edit-data-source-name-${this.props.value}`}
			>
				{isPassword ? (
					<div className={headerClass}>
						<span className="v-text-password-title">
							{passwordTitle || this.formatMessage('TEXT_COMPONENT_PASSWORD')}
						</span>
						<span
							className="v-text-password-show"
							onClick={() => this.setState({ showPassword: !showPassword })}
						>
							{this.formatMessage(
								showPassword ? 'TEXT_COMPONENT_HIDE' : 'TEXT_COMPONENT_SHOW'
							)}
						</span>
					</div>
				) : null}
				<div
					className={newClassName}
					ref="inputTarget"
					data-test={`text-ref-${this.props['data-test']}`}
				>
					{stopAutoComplete ? this.renderAutoCompleteDummyInputBox() : null}
					{this.renderInputBox()}
				</div>
				<Overlay
					show={showErrorMessage}
					placement="top"
					onHide={() => this.setState({ showErrorMessage: false })}
					target={() => this.getErrorTarget()}
					rootClose
				>
					{this.getTooltip(overlayClassName, feedbackError)}
				</Overlay>
			</div>
		);
	}
}
