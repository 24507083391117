/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { host } from 'helpers/storageHelper';
import { isJWTNotExpired } from 'helpers/jwtHelper';

const hourInMs = 60 * 60 * 1000;
const base64timeout = btoa('timeout');

/**
 * Checks if the local session timeout has expired
 * @return return true if session is expired
 */
export function isTimeoutExpired() {
	const timeoutDate = new Date(
		Number(atob(localStorage.getItem(`${host}__${base64timeout}`) || ''))
	);
	if (!timeoutDate) {
		return false;
	}
	return timeoutDate < new Date() && isJWTNotExpired();
}

/**
 * Resets the timeout in localStorage to one hour from the current time,
 * unless we're a dev
 */
export function resetTimeout() {
	const date = new Date();
	date.setTime(
		date.getTime() +
			hourInMs * (process.env.NODE_ENV?.trim() === 'development' ? 1000000 : 1)
	);
	localStorage.setItem(
		`${host}__${base64timeout}`,
		btoa(date.getTime().toString())
	);
}
