/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { Component } from 'react';

/* eslint-disable react/prefer-stateless-function */
export default class VReactComponent<P = any, S = any> extends Component<
	P,
	S
> {}
