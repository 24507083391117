/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const dropStateActionTypes = {
	DROPSTATE_SET_DATA: 'DROPSTATE_SET_DATA',
	DROPSTATE_CLEAR_DATA: 'DROPSTATE_CLEAR_DATA',
	WEB_REPORT_DROPSTATE_SET_DATA: 'WEB_REPORT_DROPSTATE_SET_DATA',
};
