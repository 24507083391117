/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as ModelSummaryActionTypes from '../constants/modelSummary/modelSummaryActionTypes';
import ColumnDefinition from '../models/column/columnDefinition';
import CustomColumnDefinition from '../models/column/customColumnDefinition';
import * as LazySelection from 'helpers/utility/lazySelection';
import Buffer from '../models/buffer';
import { LOGOUT, CHANGE_MODEL } from 'constants/actionTypes';

const handlers = {};

function getInitialState() {
	return {
		dimensions: {
			table: {
				width: 1570,
				height: 800,
				offsetHeight: 100,
			},
		},
		table: {
			columnDefinitions: {
				key: new CustomColumnDefinition({
					key: 'key',
					label: 'Key',
					width: 500,
				}),
				value: new ColumnDefinition({
					key: 'value',
					label: 'Value',
					width: 1170,
				}),
			},
			rowSelection: LazySelection.empty(),
		},
		data: {},
	};
}

// helper function
function resetBuffer(table) {
	const range = {
		offset: 0,
		count: table.buffer ? table.buffer.range.count : 100,
	};
	return {
		...table,
		totalRows: 0,
		scrollToRow: 0,
		rowSelection: LazySelection.empty(),
		buffer: Buffer.createEmptyBuffer(range),
	};
}

// the reducer
export function modelSummary(state = getInitialState(), action) {
	const { type } = action;
	if (!handlers[type]) {
		return state;
	}
	return handlers[type](state, action);
}

handlers[ModelSummaryActionTypes.MODEL_SUMMARY_REQUEST_DATA] = (state) => {
	const newState = { ...state };
	newState.table.isFetching = true;

	return newState;
};

handlers[ModelSummaryActionTypes.MODEL_SUMMARY_RECEIVE_DATA] = (
	state,
	{ payload }
) => {
	const newState = { ...state };
	newState.table.isFetching = false;
	newState.table.scrollToRow = null;
	newState.data = payload;
	return newState;
};

handlers[ModelSummaryActionTypes.MODEL_SUMMARY_RESPONSE_ERROR] = (state) => {
	const table = resetBuffer(state.table);
	const newState = { ...state };
	newState.table = table;
	newState.table.isFetching = false;

	return newState;
};

handlers[LOGOUT] = getInitialState;
handlers[CHANGE_MODEL] = getInitialState;
