/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { put, takeLatest, call } from 'redux-saga/effects';
import PermissionsService from 'services/permissionsService';
import {
	receivePermissions,
	requestPermissionsReload,
} from 'actions/permissionsActions';
import { FETCH_PERMISSIONS } from 'constants/spinnerTypes';
import { showSpinner } from 'actions/globalActionActions';
import { LOGIN, CHANGE_MODEL } from 'constants/actionTypes';

function* permissions() {
	const payload = yield call(PermissionsService.getCurrentUserPermissions);
	yield put(showSpinner(true, FETCH_PERMISSIONS));
	yield put(receivePermissions(payload));
	yield put(showSpinner(false, FETCH_PERMISSIONS));
}

export default function* watchPermissions() {
	yield takeLatest(
		[LOGIN, CHANGE_MODEL, requestPermissionsReload.getType()],
		permissions
	);
}
