/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import VBaseComponent from 'components/common/baseComponent';
import logoVaricent from 'icm-icons/illustrations/brand/adminlogin-logo.png';

export default class VLoginHeader extends VBaseComponent {
	render() {
		return (
			<div
				className="v-login"
				style={{
					color: '#686868',
					textAlign: 'center',
					paddingTop: '10%',
				}}
			>
				<img
					src={logoVaricent}
					alt="logoVaricent"
					style={{ height: '48px', width: '68px', margin: '3px 0' }}
				/>
				<div style={{ margin: 10, lineHeight: 1.42857 }}>
					{this.formatMessage('SALES_PERFORMANCE_MANAGEMENT')}
				</div>
			</div>
		);
	}
}
