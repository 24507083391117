/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	COMPOSER_EDIT,
	COMPOSER_COMPONENT_EDIT,
	HOMEPAGE_PROCESSMAPEDIT,
	HOMEPAGE_PROCESSMAPDELETE,
	HOMEPAGE_PROCESSMAPVIEW,
	ACTIVITY_VIEWALL,
	CALCULATE_VIEW,
	IMPORT_DATA,
	HOMEPAGE_MIGRATIONSEXECUTE,
	TASKS_VIEW,
	HOMEPAGE_CALENDARSLOCKPERIOD,
	HOMEPAGE_OPTIMIZEMODEL,
	COMPOSER_VIEW,
	HOMEPAGE_CALENDARSPURGEPERIOD,
	DATASTORE_VIEW,
	DATASTORE_EDIT,
	DATASTORE_DELETE,
	TABLEINPUTFORM_VIEW,
} from 'constants/permissions';
import {
	PathPermissions,
	ActionPermissions,
} from 'constants/routerPathPermissions';
import LocaleHelper from '../helpers/localeHelper';

/**
 * Gets the path to the current component, seperated by /. Used for permissions.
 *
 * @export
 * @param {*} [breadcrumb=null]
 * @returns string
 */
export function getPermissionToEditCurrentComponent(componentId = 0) {
	if (!componentId) {
		return COMPOSER_EDIT;
	}

	return COMPOSER_COMPONENT_EDIT.replace('{0}', componentId);
}

export function getPermissionToEditCurrentProcessList(processListId) {
	return HOMEPAGE_PROCESSMAPEDIT.replace('{0}', processListId);
}

export function getPermissionToDeleteCurrentProcessList(processListId) {
	return HOMEPAGE_PROCESSMAPDELETE.replace('{0}', processListId);
}

export function getPersmissionToViewCurrentProcessList(processListId) {
	return HOMEPAGE_PROCESSMAPVIEW.replace('{0}', processListId);
}

export function getPermissionToViewDataStore(dateStore) {
	return DATASTORE_VIEW.replace('{0}', dateStore?.id);
}

export function getPermissionToEditDataStore(dateStore) {
	return DATASTORE_EDIT.replace('{0}', dateStore?.id);
}

export function getPermissionToDeleteDataStore(dateStore) {
	return DATASTORE_DELETE.replace('{0}', dateStore?.id);
}

export function getPermissionToViewTableInputForm(tableName, inputFormId = 0) {
	return TABLEINPUTFORM_VIEW.replace('{0}', tableName).replace(
		'{1}',
		inputFormId
	);
}

// from v9, same permissions used in restapi for api/v1/completedactivities
export function getViewActivityPermissions() {
	return [
		ACTIVITY_VIEWALL,
		CALCULATE_VIEW,
		IMPORT_DATA,
		HOMEPAGE_MIGRATIONSEXECUTE,
		TASKS_VIEW,
		HOMEPAGE_CALENDARSLOCKPERIOD,
		HOMEPAGE_OPTIMIZEMODEL,
		COMPOSER_VIEW,
		HOMEPAGE_CALENDARSPURGEPERIOD,
	];
}

/**
 * Checks if the user has a component permission. See permissions.js for options.
 *
 * @export
 * @param {*} [breadcrumb=null]
 * @param {*} grantedPermissions
 * @param {*} permission
 * @returns boolean
 */
export function checkComponentEditPermission(
	componentId = 0,
	grantedPermissions
) {
	const permissionString = getPermissionToEditCurrentComponent(componentId);
	return !!grantedPermissions[permissionString];
}

/**
 * Checks if use has permission to go to the url. See routerPathPermissions.js
 *
 * @export
 * @param {string} url
 * @param {*} grantedPermissions
 * @returns boolean
 */
export function checkUrlPermission(url, grantedPermissions) {
	const requiredPermissions = PathPermissions[url];
	if (requiredPermissions !== undefined) {
		const nonGrantedPermission = requiredPermissions.find(
			(perm) => !grantedPermissions[perm]
		);
		return !nonGrantedPermission;
	}
	return false;
}
/**
 * Checks if use has permission to go to the action popup. See routerPathPermissions.js
 *
 * @export
 * @param {string} actionName
 * @param {*} grantedPermissions
 * @returns boolean
 */
export function checkActionPermission(actionName, grantedPermissions) {
	const requiredPermissions = ActionPermissions[actionName];
	if (requiredPermissions) {
		const nonGrantedPermission = requiredPermissions.find(
			(perm) => !grantedPermissions[perm]
		);
		return !nonGrantedPermission;
	}
	return false;
}

export function showMissingPermissionErrorMessage(permission) {
	window.store.actions.messageActions.error(
		LocaleHelper.formatMessage(
			window.store.getState(),
			'PERMISSION_NOT_GRANTED',
			{ permission }
		)
	);
}
