/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import R from 'ramda';

/**
 * Inserts an item into the list or updates an existing item based on
 * a given predicate.
 */
export function insertOrUpdate<I>(
	predicate: (item: I) => boolean,
	createNewItem: (item?: I) => I,
	itemList: I[]
) {
	const index = R.findIndex(predicate, itemList);
	if (index < 0) {
		const newItem = createNewItem();
		return R.append(newItem, itemList);
	}
	return R.update(index, createNewItem(itemList[index]), itemList);
}

/**
 * Updates an existing item in a list, based on a given predicate.
 *
 * @export
 * @template I
 * @param {(item: I) => boolean} predicate determines what counts as found
 * @param {(item?: I) => I} updateItem what will replace the item.
 * @param {I[]} itemList
 * @returns
 */
export function update<I>(
	predicate: (item: I) => boolean,
	update: (item: I) => I,
	itemList: I[]
) {
	const index = R.findIndex(predicate, itemList);
	if (index > -1) {
		return R.update(index, update(itemList[index]), itemList);
	}
	return itemList;
}
export const isNotNil = R.complement(R.isNil);

export function findAndRemove<I>(
	predicate: (item: I) => boolean,
	itemList: I[]
) {
	const index = R.findIndex(predicate, itemList);
	if (index > -1) {
		return R.remove(index, 1, itemList);
	}
	return itemList;
}

/**
 * Flattens an object or array into an object with keys as a dot seperated path to the value.
 * Eg, { obj: { internal: 'name' }, array: [{ internal: 'arrayItem}]} =>
 *   {'obj.internal': 'name', 'array.0.internal': 'arrayItem'}
 *
 * Useful for detecting if an object has any associated values.
 *
 * @export
 * @param {{[key: string]: any}} obj
 * @returns {{[key: string]: any}}
 */
export function flattenObject(obj) {
	const go = (obj_) =>
		R.chain(([k, v]) => {
			if (
				Object.prototype.toString.call(v) === '[object Object]' ||
				Array.isArray(v)
			) {
				return R.map(([k_, v_]) => [`${k}.${k_}`, v_], go(v));
			} else {
				return [[k, v]];
			}
		}, R.toPairs(obj_));

	return R.fromPairs(go(obj)) as Record<string, string>;
}

/**
 * Determines if a flattened object has any keys.
 *
 * @export
 * @param {{[key: string]: any}} obj
 * @returns {boolean}
 */
export const isErrorObjectEmpty = R.pipe(
	flattenObject,
	R.keys,
	R.length,
	R.equals(0)
);
