/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const FilterTypes = {
	String: 'String',
	LongString: 'LongString',
	Date: 'Date',
	DateTime: 'DateTime',
	Decimal: 'Decimal',
	Int: 'Int',
	Join: 'Join',
	Fixed: 'Fixed',
};

export const AllFilterOperators = {
	Equal: 'Equal',
	NotEqual: 'NotEqual',
	StartsWith: 'StartsWith',
	EndsWith: 'EndsWith',
	Less: 'Less',
	LessOrEqual: 'LessOrEqual',
	Greater: 'Greater',
	GreaterOrEqual: 'GreaterOrEqual',
	And: 'And',
	Or: 'Or',
	Range: 'Range',
	ContainedIn: 'ContainedIn',
	Like: 'Like',
};

export const FilterOperators = {
	[FilterTypes.String]: {
		Equal: 'TABLE_FILTER_OPERATOR_EQUAL',
		NotEqual: 'TABLE_FILTER_OPERATOR_NOT_EQUAL',
		StartsWith: 'TABLE_FILTER_OPERATOR_STARTS_WITH',
		EndsWith: 'TABLE_FILTER_OPERATOR_ENDS_WITH',
	},
	[FilterTypes.LongString]: {
		Equal: 'TABLE_FILTER_OPERATOR_EQUAL',
		NotEqual: 'TABLE_FILTER_OPERATOR_NOT_EQUAL',
		StartsWith: 'TABLE_FILTER_OPERATOR_STARTS_WITH',
		EndsWith: 'TABLE_FILTER_OPERATOR_ENDS_WITH',
	},
	[FilterTypes.Date]: {
		Equal: 'TABLE_FILTER_OPERATOR_EQUAL',
		NotEqual: 'TABLE_FILTER_OPERATOR_NOT_EQUAL',
		Less: 'TABLE_FILTER_OPERATOR_LESS',
		LessOrEqual: 'TABLE_FILTER_OPERATOR_LESS_OR_EQUAL',
		Greater: 'TABLE_FILTER_OPERATOR_GREATER',
		GreaterOrEqual: 'TABLE_FILTER_OPERATOR_GREATER_OR_EQUAL',
	},
	[FilterTypes.DateTime]: {
		Equal: 'TABLE_FILTER_OPERATOR_EQUAL',
		NotEqual: 'TABLE_FILTER_OPERATOR_NOT_EQUAL',
		Less: 'TABLE_FILTER_OPERATOR_LESS',
		LessOrEqual: 'TABLE_FILTER_OPERATOR_LESS_OR_EQUAL',
		Greater: 'TABLE_FILTER_OPERATOR_GREATER',
		GreaterOrEqual: 'TABLE_FILTER_OPERATOR_GREATER_OR_EQUAL',
	},
	[FilterTypes.Decimal]: {
		Equal: 'TABLE_FILTER_OPERATOR_EQUAL',
		NotEqual: 'TABLE_FILTER_OPERATOR_NOT_EQUAL',
		Less: 'TABLE_FILTER_OPERATOR_LESS',
		LessOrEqual: 'TABLE_FILTER_OPERATOR_LESS_OR_EQUAL',
		Greater: 'TABLE_FILTER_OPERATOR_GREATER',
		GreaterOrEqual: 'TABLE_FILTER_OPERATOR_GREATER_OR_EQUAL',
	},
	[FilterTypes.Int]: {
		Equal: 'TABLE_FILTER_OPERATOR_EQUAL',
		NotEqual: 'TABLE_FILTER_OPERATOR_NOT_EQUAL',
		Less: 'TABLE_FILTER_OPERATOR_LESS',
		LessOrEqual: 'TABLE_FILTER_OPERATOR_LESS_OR_EQUAL',
		Greater: 'TABLE_FILTER_OPERATOR_GREATER',
		GreaterOrEqual: 'TABLE_FILTER_OPERATOR_GREATER_OR_EQUAL',
	},
	[FilterTypes.Join]: {
		And: 'TABLE_FILTER_OPERATOR_AND',
		Or: 'TABLE_FILTER_OPERATOR_OR',
	},
	[FilterTypes.Fixed]: {
		Equal: 'TABLE_FILTER_OPERATOR_EQUAL',
		NotEqual: 'TABLE_FILTER_OPERATOR_NOT_EQUAL',
	},
};

export const FilterValueNull = 'NULL';
