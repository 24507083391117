/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as MiddlewareBypass from './middlewareBypassActionTypes';

/*
 * Home page actions
 * export const FILTER_TEXT_CHANGED = 'FILTER_TEXT_CHANGED';
 */

// Sidebar
export const SELECT_SIDEBAR = 'SELECT_SIDEBAR';

// export const UPDATE_LINK = 'UPDATE_LINK';
export const SHOW_LINKS = 'SHOW_LINKS';
export const FILTER_LINKS = 'FILTER_LINKS';
export const FILTER_QUICK_NAV = 'FILTER_QUICK_NAV';

// General
export const SET_ACTION_LISTENER = 'SET_ACTION_LISTENER';

// Process list actions
export const GET_ALL_PROCESS_LISTS = 'GET_ALL_PROCESS_LISTS';
export const SAVE_PROCESS_LIST = 'SAVE_PROCESS_LIST';
export const FILTER_PROCESS_LIST = 'FILTER_PROCESS_LIST';
export const SELECT_PROCESS_LIST = 'SELECT_PROCESS_LIST';
export const ADD_TASK = 'ADD_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const FILTER_TASK_LINKS = 'FILTER_TASK_LINKS';
export const INIT_TASK_LINKS = 'INIT_TASK_LINKS';
export const UPDATE_SELECTED_PROCESS_LIST = 'UPDATE_SELECTED_PROCESS_LIST';
export const TOGGLE_EDITPL = 'TOGGLE_EDITPL';
export const CANCEL_PROCESS_LIST_EDIT = 'CANCEL_PROCESS_LIST_EDIT';
export const SAVE_TASKS = 'SAVE_TASKS';
export const ADD_TASK_ITEM = 'ADD_TASK_ITEM';
export const EDIT_TASK_ITEM = 'EDIT_TASK_ITEM';
export const CANCEL_TASK_ITEM = 'CANCEL_TASK_ITEM';
export const CLEAR_PREVIOUS_TASK_ITEM = 'CLEAR_PREVIOUS_TASK_ITEM';
export const SHOW_OVERVIEW_TOGGLED = 'SHOW_OVERVIEW_TOGGLED';
export const EXPAND_TO_SHOW_TASKS_DESCRIPTION_TOGGLED =
	'EXPAND_TO_SHOW_TASKS_DESCRIPTION_TOGGLED';
export const EXPAND_COLLAPSE_TASKS = 'EXPAND_COLLAPSE_TASKS';
export const FILTER_SIDEBAR = 'FILTER_SIDEBAR';
export const SELECT_TASK = 'SELECT_TASK';
export const REMOVE_PROCESS_LIST = 'REMOVE_PROCESS_LIST';
export const ADD_PROCESS_LIST = 'ADD_PROCESS_LIST';
export const DISPLAY_SIDEBAR = 'DISPLAY_SIDEBAR';

export const MESSAGE_UPLOAD = 'MESSAGE_UPLOAD';
export const MESSAGE_DOWNLOAD = 'MESSAGE_DOWNLOAD';
export const INFORMATION = 'INFORMATION';
// Error actions
export const ERROR = MiddlewareBypass.ERROR;
export const CLEAR_MESSAGE = MiddlewareBypass.CLEAR_MESSAGE;
export const SHOW_MESSAGE = MiddlewareBypass.SHOW_MESSAGE;

export const SHOW_BANNER_MESSAGE = MiddlewareBypass.SHOW_BANNER_MESSAGE;
export const CLEAR_BANNER_MESSAGE = MiddlewareBypass.CLEAR_BANNER_MESSAGE;

// Confirm action
export const CONFIRM_MESSAGE = MiddlewareBypass.CONFIRM_MESSAGE;
export const CONFIRM_MESSAGE_LINK = MiddlewareBypass.CONFIRM_MESSAGE_LINK;

// Login actions
export const LOGIN = MiddlewareBypass.LOGIN;
export const LOGOUT = MiddlewareBypass.LOGOUT;
export const CLEAR_JWT = MiddlewareBypass.CLEAR_JWT;
export const RENEW_JWT = MiddlewareBypass.RENEW_JWT;
export const CHANGE_MODEL = MiddlewareBypass.CHANGE_MODEL;
export const SHOW_PASSWORD_CHANGE = MiddlewareBypass.SHOW_PASSWORD_CHANGE;
export const HIDE_PASSWORD_CHANGE = MiddlewareBypass.HIDE_PASSWORD_CHANGE;
export const SSO_CHECKED = MiddlewareBypass.SSO_CHECKED;
export const COMPLETE_SSO_LOGIN = MiddlewareBypass.COMPLETE_SSO_LOGIN;
export const CLOSE_POPUP = MiddlewareBypass.CLOSE_POPUP;
export const SET_WAIT_FOR_POPUP = MiddlewareBypass.SET_WAIT_FOR_POPUP;

export const SHOW_FORGOT_PASSWORD_REQUEST =
	MiddlewareBypass.SHOW_FORGOT_PASSWORD_REQUEST;
export const HIDE_FORGOT_PASSWORD_REQUEST =
	MiddlewareBypass.HIDE_FORGOT_PASSWORD_REQUEST;
export const SHOW_FORGOT_PASSWORD_SUBMIT =
	MiddlewareBypass.SHOW_FORGOT_PASSWORD_SUBMIT;
export const HIDE_FORGOT_PASSWORD_SUBMIT =
	MiddlewareBypass.HIDE_FORGOT_PASSWORD_SUBMIT;

// Authentication
export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL';
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL';

// Intercom
export const SET_INTERCOM_HASH = 'SET_INTERCOM_HASH';
export const SET_INTERCOM_ACTIVE = 'SET_INTERCOM_ACTIVE';

// Routing
export const ROUTER_NAVIGATE = 'ROUTER_NAVIGATE';

// Status
export const STATUS_PAGE = 'STATUS_PAGE';

// Activity
export const UPDATE_ACTIVITY_DATE = 'UPDATE_ACTIVITY_DATE';
export const GET_ALL_ACTIVITIES_COMPLETED = 'GET_ALL_ACTIVITIES_COMPLETED';
export const REQUEST_ALL_ACTIVITIES_LIVE = 'REQUEST_ALL_ACTIVITIES_LIVE';
export const RECEIVE_ALL_ACTIVITIES_LIVE = 'RECEIVE_ALL_ACTIVITIES_LIVE';
export const RESET_ACTIVITY_FILTERS = 'RESET_ACTIVITY_FILTERS';
export const TYPE_FILTER_CHANGED = 'TYPE_FILTER_CHANGED';
export const FORCED_TYPE_FILTER_CHANGED = 'FORCED_TYPE_FILTER_CHANGED';
export const STATUS_FILTER_CHANGED = 'STATUS_FILTER_CHANGED';
export const SHOW_ALL_SELECTED = 'SHOW_ALL_SELECTED';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SHOW_MORE_LIVE = 'SHOW_MORE_LIVE';
export const SHOW_LESS_LIVE = 'SHOW_LESS_LIVE';
export const UPDATE_COMPLETED = 'UPDATE_COMPLETED';
export const GO_TO_NEW_COMPLETED = 'GO_TO_NEW_COMPLETED';
export const FINISHED_SCROLLING = 'FINISHED_SCROLLING';
export const SHOW_DELETE_LIVE_CONFIRMATION = 'SHOW_DELETE_LIVE_CONFIRMATION';
export const DELETE_LIVE_ACTIVITY = 'DELETE_LIVE_ACTIVITY';
export const SHOW_DELETE_CONFIRMATION = 'SHOW_DELETE_CONFIRMATION';
export const CANCEL_DELETE_CONFIRMATION = 'CANCEL_DELETE_CONFIRMATION';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITYY';
export const ACTIVITY_TOGGLE_DROPDOWN = 'ACTIVITY_TOGGLE_DROPDOWN';
export const ACTIVITY_FOCUS_CHANGE = 'ACTIVITY_FOCUS_CHANGE';

// Web WebReport
export const GET_WEB_REPORT = 'GET_WEB_REPORT';
export const WEB_REPORT_SELECT_TAB = 'WEB_REPORT_SELECT_TAB';
export const WEB_REPORT_SIDEBAR_BACK_TO_ADD = 'WEB_REPORT_SIDEBAR_BACK_TO_ADD';
export const WEB_REPORT_DISPLAY_ADDED = 'WEB_REPORT_DISPLAY_ADDED';
export const WEB_REPORT_DISPLAY_UPDATED = 'WEB_REPORT_DISPLAY_UPDATED';
export const WEB_REPORT_MOVE_DATA_TAB_ROW = 'WEB_REPORT_MOVE_DATA_TAB_ROW';
export const WEB_REPORT_UPDATE_SOURCE = 'WEB_REPORT_UPDATE_SOURCE';
export const WEB_REPORT_UPDATE_SOURCE_TITLE = 'WEB_REPORT_UPDATE_SOURCE_TITLE';
export const WEB_REPORT_SOURCE_TREE_ADD_FOLDER =
	'WEB_REPORT_SOURCE_TREE_ADD_FOLDER';
export const WEB_REPORT_SOURCE_TREE_DELETE = 'WEB_REPORT_SOURCE_TREE_DELETE';
export const WEB_REPORT_SOURCE_TREE_TOGGLE_EXPAND_FOLDER =
	'WEB_REPORT_SOURCE_TREE_TOGGLE_EXPAND_FOLDER';
export const WEB_REPORT_SOURCE_TREE_TOGGLE_FORCE_EXPAND_FOLDER =
	'WEB_REPORT_SOURCE_TREE_TOGGLE_FORCE_EXPAND_FOLDER';
export const WEB_REPORT_SOURCE_TREE_CHANGE_SELECTED_NODE_ID =
	'WEB_REPORT_SOURCE_TREE_CHANGE_SELECTED_NODE_ID';
export const WEB_REPORT_ADD_SOURCE = 'WEB_REPORT_ADD_SOURCE';
export const WEB_REPORT_COPY_SOURCE = 'WEB_REPORT_COPY_SOURCE';
export const WEB_REPORT_PASTE_SOURCE = 'WEB_REPORT_PASTE_SOURCE';
export const WEB_REPORT_REQUESTED = 'WEB_REPORT_REQUESTED';
export const WEB_REPORT_RECEIVED = 'WEB_REPORT_RECEIVED';
export const WEB_REPORT_DEPENDENCIES_RECEIVED =
	'WEB_REPORT_DEPENDENCIES_RECEIVED';
export const WEB_REPORT_IS_SAVING = 'WEB_REPORT_IS_SAVING';
export const WEB_REPORT_HAS_SAVED = 'WEB_REPORT_HAS_SAVED';
export const WEB_REPORT_HAS_SAVE_ERROR = 'WEB_REPORT_HAS_SAVE_ERROR';
export const WEB_REPORT_RECEIVED_UPDATED_SCHEMA =
	'WEB_REPORT_RECEIVED_UPDATED_SCHEMA';
export const WEB_REPORT_RECEIVED_ADDED_SCHEMA =
	'WEB_REPORT_RECEIVED_ADDED_SCHEMA';
export const WEB_REPORT_REPLACE_EXISTING_SCHEMA =
	'WEB_REPORT_REPLACE_EXISTING_SCHEMA';
export const WEB_REPORT_VALIDATED = 'WEB_REPORT_VALIDATED';
export const WEB_REPORT_UNDO = 'WEB_REPORT_UNDO';
export const WEB_REPORT_REDO = 'WEB_REPORT_REDO';
export const WEB_REPORT_SAVE_AS = 'WEB_REPORT_SAVE_AS';
export const WEB_REPORT_NAME_UPDATED = 'WEB_REPORT_NAME_UPDATED';
export const WEB_REPORT_ADD_VALUE = 'WEB_REPORT_ADD_VALUE';
export const WEB_REPORT_PREVIEW_REPORT = 'WEB_REPORT_PREVIEW_REPORT';
export const WEB_REPORT_EXIT_PREVIEW_REPORT = 'WEB_REPORT_EXIT_PREVIEW_REPORT';
export const WEB_REPORT_FIXED_GRID_CONTENT_UPDATED =
	'WEB_REPORT_FIXED_GRID_CONTENT_UPDATED';
export const WEB_REPORT_GET_DOCUMENTS = 'WEB_REPORT_GET_DOCUMENTS';
export const WEB_REPORT_GET_CALENDARS = 'WEB_REPORT_GET_CALENDARS';
export const WEB_REPORT_GET_CONTEXT_SOURCES = 'WEB_REPORT_GET_CONTEXT_SOURCES';
export const WEB_REPORT_ON_FINISH_CONFIRMATION =
	'WEB_REPORT_ON_FINISH_CONFIRMATION';
export const WEB_REPORT_GET_TRANSPOSE_ROWS = 'WEB_REPORT_GET_TRANSPOSE_ROWS';
export const WEB_REPORT_TRANSPOSE_UPDATE_NEW_COLUMN =
	'WEB_REPORT_TRANSPOSE_UPDATE_NEW_COLUMN';
export const WEB_REPORT_GET_TEMPLATES = 'WEB_REPORT_GET_TEMPLATES';
export const WEB_REPORT_UPDATE_TEMPLATE = 'WEB_REPORT_UPDATE_TEMPLATE';
export const WEB_REPORT_ADD_TEMPLATE = 'WEB_REPORT_ADD_TEMPLATE';
export const WEB_REPORT_DELETE_TEMPLATE = 'WEB_REPORT_DELETE_TEMPLATE';
export const WEB_REPORT_EDIT_TEMPLATE = 'WEB_REPORT_EDIT_TEMPLATE';
export const WEB_REPORT_GET_DATA_GRID_ITEMS = 'WEB_REPORT_GET_DATA_GRID_ITEMS';
export const WEB_REPORT_CLEAR_DATA_GRID_ITEMS =
	'WEB_REPORT_CLEAR_DATA_GRID_ITEMS';
export const WEB_REPORT_SELECT_DATA_GRID_ITEM =
	'WEB_REPORT_SELECT_DATA_GRID_ITEM';
export const WEB_REPORT_SELECT_ALL_DATA_GRID_ITEM =
	'WEB_REPORT_SELECT_ALL_DATA_GRID_ITEM';
export const WEB_REPORT_GET_PAYEES = 'WEB_REPORT_GET_PAYEES';
export const WEB_REPORT_UPDATE_PREVIEW_PARAMETER =
	'WEB_REPORT_UPDATE_PREVIEW_PARAMETER';
export const WEB_REPORT_PREVIEW_FINISH_REFRESH =
	'WEB_REPORT_PREVIEW_FINISH_REFRESH';
export const WEB_REPORT_SERVICE_CALL_PROCESSING =
	'WEB_REPORT_SERVICE_CALL_PROCESSING';
export const WEB_REPORT_ASK_CONFIRMATION = 'WEB_REPORT_ASK_CONFIRMATION';
export const WEB_REPORT_REJECT_CONFIRMATION = 'WEB_REPORT_REJECT_CONFIRMATION';
export const WEB_REPORT_ASK_LEAVE_WITHOUT_SAVE =
	'WEB_REPORT_ASK_LEAVE_WITHOUT_SAVE';
export const WEB_REPORT_IS_EDITING_SOURCE_NAME =
	'WEB_REPORT_IS_EDITING_SOURCE_NAME';
export const WEB_REPORT_FILL_PARAMETER = 'WEB_REPORT_FILL_PARAMETER';

export const WEB_REPORT_WIZARD_CONTENT_DROPPED =
	'WEB_REPORT_WIZARD_CONTENT_DROPPED';
export const WEB_REPORT_CHART_WIZARD_EDIT_BEGIN =
	'WEB_REPORT_CHART_WIZARD_EDIT_BEGIN';
export const WEB_REPORT_CHART_WIZARD_CHANGE_CHART_TYPE =
	'WEB_REPORT_CHART_WIZARD_CHANGE_CHART_TYPE';
export const WEB_REPORT_CHART_WIZARD_UPDATE_DISPLAY =
	'WEB_REPORT_CHART_WIZARD_UPDATE_DISPLAY';
export const WEB_REPORT_CHART_WIZARD_UPDATE = 'WEB_REPORT_CHART_WIZARD_UPDATE';

export const WEB_REPORT_CHART_WIZARD_ADD_BEGIN =
	'WEB_REPORT_CHART_WIZARD_ADD_BEGIN';
export const WEB_REPORT_CHART_WIZARD_ADD_SOURCE =
	'WEB_REPORT_CHART_WIZARD_ADD_SOURCE';
export const WEB_REPORT_CHART_WIZARD_EDIT_SOURCE_START =
	'WEB_REPORT_CHART_WIZARD_EDIT_SOURCE_START';
export const WEB_REPORT_CHART_WIZARD_EDIT_SOURCE_PROP =
	'WEB_REPORT_CHART_WIZARD_EDIT_SOURCE_PROP';
export const WEB_REPORT_CHART_WIZARD_EDIT_SOURCE_FINISH =
	'WEB_REPORT_CHART_WIZARD_EDIT_SOURCE_FINISH';
export const WEB_REPORT_CHART_WIZARD_EDIT_SOURCE_CANCEL =
	'WEB_REPORT_CHART_WIZARD_EDIT_SOURCE_CANCEL';
export const WEB_REPORT_CHART_WIZARD_DELETE_SOURCE =
	'WEB_REPORT_CHART_WIZARD_DELETE_SOURCE';
export const WEB_REPORT_CHART_WIZARD_TOGGLE_ENABLE_CUSTOM_COLORS =
	'WEB_REPORT_CHART_WIZARD_TOGGLE_ENABLE_CUSTOM_COLORS';
export const WEB_REPORT_CHART_WIZARD_ADD_CUSTOM_COLOR =
	'WEB_REPORT_CHART_WIZARD_ADD_CUSTOM_COLOR';
export const WEB_REPORT_CHART_WIZARD_DELETE_CUSTOM_COLOR =
	'WEB_REPORT_CHART_WIZARD_DELETE_CUSTOM_COLOR';
export const WEB_REPORT_CHART_WIZARD_EDIT_CUSTOM_COLOR =
	'WEB_REPORT_CHART_WIZARD_EDIT_CUSTOM_COLOR';
export const WEB_REPORT_CHART_WIZARD_SET_SOURCE_ERROR =
	'WEB_REPORT_CHART_WIZARD_SET_SOURCE_ERROR';
export const WEB_REPORT_CHART_WIZARD_TOGGLE_SHOW_CHART_TITLE =
	'WEB_REPORT_CHART_WIZARD_TOGGLE_SHOW_CHART_TITLE';
export const WEB_REPORT_CHART_WIZARD_SET_EDIT_LINK_INDEX =
	'WEB_REPORT_CHART_WIZARD_SET_EDIT_LINK_INDEX';
export const WEB_REPORT_CHART_WIZARD_APPLY_LINK =
	'WEB_REPORT_CHART_WIZARD_APPLY_LINK';

export const WEB_REPORT_GAUGE_WIZARD_EDIT_BEGIN =
	'WEB_REPORT_GAUGE_WIZARD_EDIT_BEGIN';
export const WEB_REPORT_GAUGE_WIZARD_UPDATE_DISPLAY =
	'WEB_REPORT_GAUGE_WIZARD_UPDATE_DISPLAY';
export const WEB_REPORT_GAUGE_WIZARD_UPDATE = 'WEB_REPORT_GAUGE_WIZARD_UPDATE';
export const WEB_REPORT_GAUGE_TOGGLE_TRENDPOINT =
	'WEB_REPORT_GAUGE_TOGGLE_TRENDPOINT';
export const WEB_REPORT_GAUGE_WIZARD_END = 'WEB_REPORT_GAUGE_WIZARD_END';
export const WEB_REPORT_GAUGE_WIZARD_INIT_VALUE_RANGES =
	'WEB_REPORT_GAUGE_WIZARD_INIT_VALUE_RANGES';

export const WEB_REPORT_AGGREGATE_WIZARD_ADD_BEGIN =
	'WEB_REPORT_AGGREGATE_WIZARD_ADD_BEGIN';
export const WEB_REPORT_AGGREGATE_WIZARD_EDIT_BEGIN =
	'WEB_REPORT_AGGREGATE_WIZARD_EDIT_BEGIN';
export const WEB_REPORT_AGGREGATE_WIZARD_UPDATE =
	'WEB_REPORT_AGGREGATE_WIZARD_UPDATE';
export const WEB_REPORT_AGGREGATE_WIZARD_END =
	'WEB_REPORT_AGGREGATE_WIZARD_END';

export const WEB_REPORT_DATA_SOURCE_WIZARD_EDIT_BEGIN =
	'WEB_REPORT_DATA_SOURCE_WIZARD_EDIT_BEGIN';
export const WEB_REPORT_DATA_SOURCE_WIZARD_UPDATE =
	'WEB_REPORT_DATA_SOURCE_WIZARD_UPDATE';
export const WEB_REPORT_DATA_SOURCE_WIZARD_END =
	'WEB_REPORT_DATA_SOURCE_WIZARD_END';

export const WEB_REPORT_ROW_FORM_SOURCE_WIZARD_EDIT_BEGIN =
	'WEB_REPORT_ROW_FORM_SOURCE_WIZARD_EDIT_BEGIN';
export const WEB_REPORT_ROW_FORM_SOURCE_WIZARD_UPDATE =
	'WEB_REPORT_ROW_FORM_SOURCE_WIZARD_UPDATE';
export const WEB_REPORT_ROW_FORM_SOURCE_WIZARD_UPDATE_SELECTED_COLUMNS =
	'WEB_REPORT_ROW_FORM_SOURCE_WIZARD_UPDATE_SELECTED_COLUMNS';
export const WEB_REPORT_ROW_FORM_SOURCE_WIZARD_REMOVE_SELECTED_COLUMNS =
	'WEB_REPORT_ROW_FORM_SOURCE_WIZARD_REMOVE_SELECTED_COLUMNS';
export const WEB_REPORT_ROW_FORM_SOURCE_WIZARD_END =
	'WEB_REPORT_ROW_FORM_SOURCE_WIZARD_END';

export const WEB_REPORT_PREVIEW_REQUEST_FORCE_NAVIGATE =
	'WEB_REPORT_PREVIEW_REQUEST_FORCE_NAVIGATE';
export const WEB_REPORT_PREVIEW_EXECUTE_FORCE_NAVIGATE =
	'WEB_REPORT_PREVIEW_EXECUTE_FORCE_NAVIGATE';

export const WEB_REPORT_PDF_OPTIONS_MODAL_OPEN =
	'WEB_REPORT_PDF_OPTIONS_MODAL_OPEN';
export const WEB_REPORT_PDF_OPTIONS_MODAL_UPDATE =
	'WEB_REPORT_PDF_OPTIONS_MODAL_UPDATE';
export const WEB_REPORT_PDF_OPTIONS_MODAL_FINISH =
	'WEB_REPORT_PDF_OPTIONS_MODAL_FINISH';

export const WEB_REPORT_PREFERENCES_MODAL_OPEN =
	'WEB_REPORT_PREFERENCES_MODAL_OPEN';
export const WEB_REPORT_PREFERENCES_MODAL_UPDATE =
	'WEB_REPORT_PREFERENCES_MODAL_UPDATE';
export const WEB_REPORT_PREFERENCES_MODAL_FINISH =
	'WEB_REPORT_PREFERENCES_MODAL_FINISH';

export const WEB_REPORT_SOURCE_PREVIEW_REQUEST_FINISHED =
	'WEB_REPORT_SOURCE_PREVIEW_REQUEST_FINISHED';
export const WEB_REPORT_SOURCE_PREVIEW_STATE_UPDATE =
	'WEB_REPORT_SOURCE_PREVIEW_STATE_UPDATE';

export const WEB_REPORT_UPDATE_REPORT_PARAMETERS =
	'WEB_REPORT_UPDATE_REPORT_PARAMETERS';

export const WEB_REPORT_TOGGLE_FIT_DATA_GRID_TO_SCREEN =
	'WEB_REPORT_TOGGLE_FIT_DATA_GRID_TO_SCREEN';

// Global Action
export const SET_DOCUMENT_TITLE = 'SET_DOCUMENT_TITLE';
export const SET_GLOBAL_ON_CLICK = 'SET_GLOBAL_ON_CLICK';

// Global Action Status
export const REQUEST_GLOBAL_ACTION_STATUS = 'REQUEST_GLOBAL_ACTION_STATUS';
export const RECEIVE_GLOBAL_ACTION_STATUS = 'RECEIVE_GLOBAL_ACTION_STATUS';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const FORCE_SPINNER = 'FORCE_SPINNER';

export const RESET_SPINNER = 'RESET_SPINNER';
export const GLOBAL_ACTION_STATUS_INCREMENT_POLL_COUNT =
	'GLOBAL_ACTION_STATUS_INCREMENT_POLL_COUNT';
export const GLOBAL_ACTION_STATUS_RESET_POLL_COUNT =
	'GLOBAL_ACTION_STATUS_RESET_POLL_COUNT';

// Fonts
export const FONTS_GET_REQUEST_FINISHED = 'FONTS_GET_REQUEST_FINISHED';

// Colors
export const COLORS_UPDATE_CUSTOM = 'COLORS_UPDATE_CUSTOM';
export const COLORS_GET_CUSTOM = 'COLORS_GET_CUSTOM';

// Maintenance
export const LOAD_UPCOMING_MAINTENANCES = 'LOAD_UPCOMING_MAINTENANCES';

// Date Picker Calendar
export const DATE_PICKER_CALENDAR_UPDATE_DATES =
	'DATE_PICKER_CALENDAR_UPDATE_DATES';

// Row Viewer Row Editor
export const ROW_VIEWER_ROW_EDITOR_SET_INPUTS =
	'ROW_VIEWER_ROW_EDITOR_SET_INPUTS';
export const ROW_VIEWER_ROW_EDITOR_SET_INVALID_DATES =
	'ROW_VIEWER_ROW_EDITOR_SET_INVALID_DATES';
export const ROW_VIEWER_ROW_EDITOR_UPDATE_INPUT =
	'ROW_VIEWER_ROW_EDITOR_UPDATE_INPUT';
export const SET_FINISHED_LOADING_DROP_DOWN = 'SET_FINISHED_LOADING_DROP_DOWN';

// Redirect
export const REDIRECT = MiddlewareBypass.REDIRECT;
export const CLEAR_REDIRECT = MiddlewareBypass.CLEAR_REDIRECT;
export const BLOCKING_REDIRECT = 'BLOCKING_REDIRECT';

// Permissions
export const PERMISSIONS_RECIEVE_CURRENT_PERMISSIONS =
	'PERMISSIONS_RECIEVE_CURRENT_PERMISSIONS';
export const REQUEST_PERMISSIONS_RELOAD = 'REQUEST_PERMISSIONS_RELOAD';
export const ADD_PROCESSLIST_PERMISSION = 'ADD_PROCESSLIST_PERMISSION';

// Formula
export const UPDATE_FORMULA = 'UPDATE_FORMULA';
export const TOGGLE_SETSCROLL = 'TOGGLE_SETSCROLL';

// Model Status
export const MODEL_STATUS_DATA_RECIEVED = 'MODEL_STATUS_DATA_RECIEVED';

// Model Themes
export const MODEL_THEME_RECIEVED = 'MODEL_THEME_RECIEVED';

// Documentation
export const GET_DOCUMENTATION_BUCKET_URL = 'GET_DOCUMENTATION_BUCKET_URL';
