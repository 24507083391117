/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { createAction } from 'redux-act';

import {
	SHOW_SPINNER,
	RESET_SPINNER,
	RECEIVE_GLOBAL_ACTION_STATUS,
	REQUEST_GLOBAL_ACTION_STATUS,
	GLOBAL_ACTION_STATUS_INCREMENT_POLL_COUNT,
	GLOBAL_ACTION_STATUS_RESET_POLL_COUNT,
	FORCE_SPINNER,
} from 'constants/actionTypes';
import GlobalActionService from 'services/globalActionService';
import R from 'ramda';

export function getGlobalActionStatus(callback?: (resp: any) => void) {
	return (dispatch) => {
		dispatch({
			type: REQUEST_GLOBAL_ACTION_STATUS,
			payload: {},
		});

		const _handleResponse = (resp) => {
			if (callback) callback(resp);
		};

		return GlobalActionService.getGlobalActionStatus().then(
			(data) => {
				dispatch({
					type: RECEIVE_GLOBAL_ACTION_STATUS,
					payload: {
						// data is either 'true' or 'false'
						status: data,
					},
				});

				// the status was successfully otained, so pass it down to the callback handler
				_handleResponse(data);
			},
			() => {
				// an error means that it wasn't possible to obtain the current status
				_handleResponse(undefined);
			}
		);
	};
}

export const showSpinner = createAction(
	SHOW_SPINNER,
	(show: boolean, spinner: string, force?: boolean) => {
		if (R.isNil(spinner)) {
			throw new Error('Undefined spinner name for showSpinner');
		}

		return {
			show,
			spinners: spinner,
			force,
		};
	}
);

export const forceSpinner =
	createAction<{ show: boolean; spinners: string }>(FORCE_SPINNER);

export const resetSpinner = createAction(RESET_SPINNER, (spinner: string) => {
	if (R.isNil(spinner)) {
		throw new Error('Undefined spinner name for resetSpinner');
	}
	return {
		spinners: spinner,
	};
});

export const incrementGlobalActionStatusPollCount = createAction(
	GLOBAL_ACTION_STATUS_INCREMENT_POLL_COUNT
);

export const resetGlobalActionStatusPollCount = createAction(
	GLOBAL_ACTION_STATUS_RESET_POLL_COUNT
);

/**
 * Shows a spinner and then hides it when the promise is finished.
 * @export
 * @param {Promise} promise - a promise to wrap in spinners
 * @param {string} spinnerName - a constant from spinnerTypes.js
 * @returns {Promise}
 */
export function runActionWithSpinner<T>(
	promise: Promise<T>,
	spinnerName: string
) {
	return (dispatch) => {
		dispatch(showSpinner(true, spinnerName));
		return promise.then(
			(results) => {
				dispatch(showSpinner(false, spinnerName));
				return results;
			},
			(reason) => {
				dispatch(showSpinner(false, spinnerName));
				return Promise.reject(reason);
			}
		);
	};
}
