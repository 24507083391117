/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { LOAD_UPCOMING_MAINTENANCES } from '../constants/actionTypes';

export function maintenance(
	state = {
		upcomingMaintenances: [],
	},
	action
) {
	const { type, payload } = action;

	switch (type) {
		case LOAD_UPCOMING_MAINTENANCES:
			return {
				...state,
				upcomingMaintenances: payload.upcomingMaintenances,
			};
		default:
			return state;
	}
}
