/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import PropTypes from 'prop-types';

import React, { cloneElement } from 'react';
import { Modal } from 'react-bootstrap';
import VBaseComponent from '../common/baseComponent';
import '../../styles/components/modal.scss';
import VModalHeader from './modalHeaderComponent';
import classNames from 'classnames';

export default class VModal extends VBaseComponent {
	static propTypes = {
		show: PropTypes.bool,
		isExpanded: PropTypes.bool,
		children: PropTypes.element,
		onHide: PropTypes.func,
		onExpandToggle: PropTypes.func,
		size: PropTypes.oneOf([
			'xsmall',
			'small',
			'medium',
			'large',
			'xlarge',
			'w1200',
		]),
		noHideOnClickOutside: PropTypes.bool,
		className: PropTypes.string,
		allowOverflow: PropTypes.bool,
		disableAnimation: PropTypes.bool,
		isNestedModal: PropTypes.bool,
		enforceFocus: PropTypes.bool,
		/*
		 * Set to true if you have something within the dialog that needs to fit to the available space
		 * of the dialog. This will also set a height for the dialog.
		 */
		flexibleHeight: PropTypes.bool,
	};

	// Overwrite these as needed
	static Body = Modal.Body;
	static Header = VModalHeader;
	static Title = Modal.Title;
	static Footer = Modal.Footer;

	static defaultProps = {
		size: 'xsmall',
		className: '',
		enforceFocus: false,
	};

	constructor(props, context) {
		super(props, context);

		if (props.isNestedModal && props.show) {
			this.setActiveModal();
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.isNestedModal && !this.props.show && nextProps.show) {
			this.setActiveModal();
		}
	}

	/* Some modals get their DOM elements created but not shown yet, so get the active modal when about to be shown. */
	setActiveModal() {
		this.activeModal = document.body.querySelector(
			'body > div:not(#app) > div > div.modal'
		);
	}

	getModalHeaderTitle() {
		const headerChild = React.Children.toArray(this.props.children).find(
			(child) =>
				child &&
				child.props &&
				(child.props.modalClassName === 'modal-header' ||
					child.props.bsClass === 'modal-header')
		);
		if (headerChild && headerChild.props) {
			return headerChild.props.title;
		} else {
			return undefined;
		}
	}

	// TODO add shouldComponentRender using "show" here

	renderChildren() {
		return React.Children.map(this.props.children, (child) => {
			if (!child || !child.props) return child;

			switch (child.props.modalClassName || child.props.bsClass) {
				case 'modal-header':
					return cloneElement(child, {
						onHide: this.props.onHide,
						onExpandToggle: this.props.onExpandToggle,
					});
				case 'modal-body':
					return cloneElement(child, {
						className: `v-modal-body ${child.props.className || ''}`,
					});
				case 'modal-footer':
					return cloneElement(child, {
						className: `v-modal-footer ${child.props.className || ''}`,
					});
				default:
					return child;
			}
		});
	}

	render() {
		const {
			size,
			isExpanded,
			noHideOnClickOutside,
			className,
			allowOverflow,
			disableAnimation,
			showInnerBackdrop,
			flexibleHeight,
		} = this.props;

		const modalClass = classNames(
			'v-modal',
			`v-${isExpanded ? 'fullscreen' : size}-modal`,
			{
				'v-modal-allow-overflow': allowOverflow,
				'v-modal-flexible-height': flexibleHeight,
			}
		);

		const backdrop = noHideOnClickOutside ? 'static' : true;
		const containerClass = classNames('v-modal-container', className);
		const spinnerBackdrop = classNames('modal-backdrop', 'in');
		return (
			<div className={containerClass}>
				<Modal
					{...this.props}
					enforceFocus={this.props.enforceFocus}
					dialogClassName={modalClass}
					backdrop={backdrop}
					animation={!disableAnimation}
					container={this.activeModal}
					aria-label={this.getModalHeaderTitle()}
					data-test="modal"
				>
					{this.renderChildren()}
					{showInnerBackdrop ? <div className={spinnerBackdrop} /> : null}
				</Modal>
			</div>
		);
	}
}
