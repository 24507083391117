/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as Paths from '../constants/routerPaths';
import LocaleHelper from '../helpers/localeHelper';
import { createReducer } from 'redux-act';
import {
	setGlobalOnClick,
	setDocumentTitle,
	assetError,
} from 'actions/globalActions';
import { navigate } from 'actions/routerActions';

interface IState {
	globalOnClick?: (event: Event) => void;
	title: string;
	showAssetError: boolean;
}

function initialize(): IState {
	return {
		globalOnClick: undefined,
		title: '',
		showAssetError: false,
	};
}

const initialState = initialize();
const SPM = 'SPM';
const headerMap = {};
headerMap[Paths.HOME_LINK] = 'HOME';
headerMap[Paths.PROCESS_LISTS_LINK] = 'PROCESS_LISTS';
headerMap[Paths.COMPOSER_LINK] = 'COMPOSER';
headerMap[Paths.WORKFLOWS_LINK] = 'WORKFLOWS';
headerMap[Paths.PORTAL_ACCESS_LINK] = 'PORTAL_ACCESS';
headerMap[Paths.SCHEDULER_LINK] = 'SCHEDULER';
headerMap[Paths.TASKS_LINK] = 'TASKS';
headerMap[Paths.ACTIVITY_LINK] = 'ACTIVITY';
headerMap[Paths.CALCULATE_LINK] = 'CALCULATE';
headerMap[Paths.SCENARIOS_LINK] = 'SCENARIOS';
headerMap[Paths.ROW_VIEWER_ROOT_LINK] = 'ROW_VIEWER';
headerMap[Paths.CALC_ROW_VIEWER_ROOT_LINK] = 'ROW_VIEWER';
headerMap[Paths.PORT_ROW_VIEWER_ROOT_LINK] = 'ROW_VIEWER';
headerMap[Paths.PUBLISHER_LINK] = 'PUBLISHER';
headerMap[Paths.DOWNLOADS_LINK] = 'DOWNLOADS';
headerMap[Paths.WEBREPORT_ROOT] = 'PRESENTER';
headerMap[Paths.WEBFORM_ROOT_LINK] = 'WEB_FORMS';
headerMap[Paths.PULSE_ANALYTICS_LINK] = 'PULSE_ANALYTICS';
headerMap[Paths.ANALYTICS_MEASURES_LINK] = 'ANALYTICS_MEASURES';
headerMap[Paths.AUDIT_LINK] = 'AUDIT';
headerMap[Paths.SIGNATURE_PART] = 'SIGNATURE';
headerMap[Paths.ERROR_LOGS_LINK] = 'ERROR_LOGS';
headerMap[Paths.IMPORT_ERROR_LOGS_LINK] = 'IMPORT_ERROR_LOGS';
headerMap[Paths.EXPORT_ERROR_LOGS_LINK] = 'EXPORT_ERROR_LOGS';
headerMap[Paths.SCHEDULER_ERROR_LOGS_LINK] = 'SCHEDULER_ERROR_LOGS';
headerMap[Paths.TASK_ERROR_LOGS_LINK] = 'TASK_ERROR_LOGS';
headerMap[Paths.PERFORMANCE_LINK] = 'PERFORMANCE';
headerMap[Paths.MY_ACCOUNT_LINK] = 'MY_ACCOUNT';
headerMap[Paths.UPDATE_PROFILE_LINK] = 'UPDATE_PROFILE';
headerMap[Paths.MANAGE_LINK] = 'MANAGE';
headerMap[Paths.MANAGE_USERS_LINK] = 'MANAGE_USERS';
headerMap[Paths.MANAGE_ROLES_LINK] = 'MANAGE_ROLES';
headerMap[Paths.MORE_OPTIONS_LINK] = 'MORE_OPTIONS';
headerMap[Paths.MODEL_SUMMARY_LINK] = 'MODEL_SUMMARY';
headerMap[Paths.USER_SETTINGS_LINK] = 'USER_SETTINGS';
headerMap[Paths.WEB_MESSAGES_LINK] = 'WEB_MESSAGES';
headerMap[Paths.SAVE_IMPORTS_LINK] = 'SAVE_IMPORTS';
headerMap[Paths.SAVED_PUBLICATIONS_LINK] = 'SAVED_PUBLICATIONS';
headerMap[Paths.SAVED_TRANSFORMATIONS_LINK] = 'SAVED_TRANSFORMATIONS';
headerMap[Paths.MANAGE_DOCUMENTS_LINK] = 'MANAGE_FILES';
headerMap[Paths.HISTORY_TRACKING_LINK] = 'HISTORY_TRACKING';
headerMap[Paths.DATA_GRID_TEMPLATES_LINK] = 'DATA_GRID_TEMPLATES';
headerMap[Paths.TOOLS_LINK] = 'TOOLS';
headerMap[Paths.CALENDARS_LINK] = 'CALENDARS';
headerMap[Paths.OPTIMIZE_MODEL_LINK] = 'OPTIMIZE_MODEL';
headerMap[Paths.PAYEE_GROUPS_LINK] = 'PAYEE_GROUPS';
headerMap[Paths.STAR_SCHEMA_LINK] = 'STAR_SCHEMA';
headerMap[Paths.QUERY_TOOL_LINK] = 'QUERY_TOOL';
headerMap[Paths.SAVED_QUERYTOOL_LINK] = 'MANAGE_SAVED_QUERIES';
headerMap[Paths.EXTERNAL_TOOLS_LINK] = 'EXTERNAL_TOOLS';
headerMap[Paths.TENANT_LINK] = 'TENANT_MANAGER';
headerMap[Paths.HIERARCHY_ROOT_LINK] = 'HIERARCHY';
headerMap[Paths.ERROR] = 'ERROR_PAGE';
headerMap[Paths.STATUS_LINK] = 'STATUS_PAGE';
headerMap[Paths.COMPUTATION_CHART] = 'COMPUTATION_CHART';
headerMap[Paths.RAPID_REPORTS_LINK] = 'RAPID_REPORTS';
headerMap[Paths.DATA_DISCOVERY_DASHBOARD_LINK] = 'DATA_DISCOVERY_DASHBOARD';
headerMap[Paths.HIERARCHIES_LINK] = 'HIERARCHIES';
headerMap[Paths.HEALTH_DASHBOARD_LINK] = 'HEALTH_DASHBOARD';
headerMap[Paths.PRESENTER_FLEXIBLE] = 'PRESENTER_ADAPTIVE';
headerMap[Paths.REPORTS] = 'REPORTS';
headerMap[Paths.INQUIRY_MANAGEMENT_LINK] = 'INQUIRY_MANAGEMENT';
headerMap[Paths.DATA_MODULE] = 'DATA_MODULE';
const reducer = createReducer<IState>({}, initialState);

reducer.on(setGlobalOnClick, (state, { funct }) => ({
	...state,
	globalOnClick: funct,
}));

reducer.on(setDocumentTitle, (state, { title }) => {
	if (title !== document.title) {
		document.title = title;
	}
	return {
		...state,
		title,
	};
});

reducer.on(navigate, (state, { pathname }, { localeMessagesState }: any) => {
	if (pathname) {
		const url = pathname.split('/');
		let linkRoot = `/${url[1]}`;
		if (linkRoot === Paths.ADMIN_LINK) {
			linkRoot = `/${url[2]}`;
		}
		let title = headerMap[linkRoot];
		if (title) {
			title = `${SPM} ${LocaleHelper.getText(localeMessagesState, title)}`;
		} else {
			title = SPM;
		}
		if (title !== document.title) {
			document.title = title;
		}
		return {
			...state,
			title,
		};
	}
	return state;
});

reducer.on(assetError, (state) => ({
	...state,
	showAssetError: true,
}));

export default reducer;
