/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import ClauseFilter from './clauseFilter';
import { AllFilterOperators } from '../../../constants/filterOperators';
import { toFilterString } from '../../../helpers/filter/filterToString';

export default class OrFilter extends ClauseFilter {
	constructor(left, right, parent) {
		super(AllFilterOperators.Or, left, right, parent);
	}

	toString() {
		return toFilterString(
			this.type,
			this.left.toString(),
			this.right.toString()
		);
	}

	filter(row, columns) {
		return this.left.filter(row, columns) || this.right.filter(row, columns);
	}

	copy(parent) {
		const newFilter = new OrFilter(null, null, parent);
		newFilter.left = this.left.copy(newFilter);
		newFilter.right = this.right.copy(newFilter);
		return newFilter;
	}
}
