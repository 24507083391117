// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 * Gets all available models for remote migration
 * url: `api/v1/remotemigration`
 */
export function getAll(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'remotemigration',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.Migration.RemoteMigrationDTO[]
	>(request);
}

/**
 * Create a remote migration
 * url: `api/v1/remotemigration`
 */
export function create(
	dto: Varicent.RESTAPI.v1.DTOs.Migration.RemoteMigrationDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'remotemigration',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Migration.RemoteMigrationDTO>(
		request
	);
}

/**
 * Delete a remote migration
 * url: `api/v1/remotemigration/${id}`
 */
export function doDelete(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `remotemigration/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Gets separate tenant services public URL in case it is hosted on different server
 * url: `api/v1/remotemigration/url`
 */
export function getUrl(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'remotemigration/url',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<string>(request);
}
