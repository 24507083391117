/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import classNames from 'classnames';
import VTooltip from 'components/common/tooltipComponent';
import TableContextMenuComponent from '../../components/common/tableContextMenuComponent';
import LocaleHelper from '../../helpers/localeHelper';

export const DrillState = {
	None: 'None',
	Enabled: 'Enabled',
	Disabled: 'Disabled',
};

export default class ColumnDefinition {
	constructor(...props) {
		Object.assign(
			this,
			{
				minWidth: 100,
			},
			...props
		);
	}

	renderCell(
		cellData,
		cellDataKey,
		rowData,
		rowIndex,
		columnData,
		width,
		isPreview,
		extraClasses,
		isUTC
	) {
		const customClass = columnData.getCellClassName
			? columnData.getCellClassName(
					cellData,
					cellDataKey,
					rowData,
					rowIndex,
					columnData.columnDefinition
			  )
			: '';
		const value = this.getFormattedContent(
			cellData,
			cellDataKey,
			rowData,
			rowIndex,
			columnData,
			width,
			isUTC
		);
		const content = this.getCellContent(
			value,
			cellData,
			cellDataKey,
			rowData,
			rowIndex,
			columnData,
			width
		);
		const wrappingClasses = classNames(
			extraClasses,
			`v-fdt-cell-wrap-${rowIndex}`,
			// last column needs extra padding for scrollbar
			{
				'v-fdt-cell-last-column':
					// exotic tables (audit, etc.) may have no columnIndex and have data stored in an object instead of an array
					typeof this.columnIndex === 'number' &&
					Array.isArray(rowData.data) &&
					this.columnIndex === rowData.data.length - 1,
			}
		);
		const validate =
			isPreview || this.removeValidation
				? undefined
				: this.validate(
						value,
						cellData,
						cellDataKey,
						rowData,
						rowIndex,
						columnData
				  );
		const innerClasses = `v-fdt-cell${customClass ? ` ${customClass}` : ''}${
			validate ? ' v-error' : ''
		}`;
		if (validate) {
			return (
				<VTooltip placement={validate ? 'bottom' : null} text={validate}>
					<div
						className={wrappingClasses}
						style={{ width: `${this.width}px` }}
						onMouseDown={() =>
							this.onCellMouseDown(rowIndex, cellDataKey, columnData)
						}
						onClick={this.getOnClick(
							cellData,
							cellDataKey,
							rowData,
							rowIndex,
							columnData
						)}
					>
						<div
							title={value}
							style={{
								maxWidth: `${this.width}px`,
								overflow: 'hidden',
								whiteSpace: 'pre',
								position: 'relative',
							}}
							className={innerClasses}
						>
							{this.renderDrillableCellContent(
								content,
								cellData,
								cellDataKey,
								rowData,
								rowIndex,
								columnData
							)}
						</div>
					</div>
				</VTooltip>
			);
		}
		const contextMenu = this.getContextMenu(cellDataKey, rowData, columnData);
		const tooltip = typeof value === 'string' ? value : '';
		return (
			<div
				className={wrappingClasses}
				style={{ width: `${this.width}px` }}
				onMouseDown={() =>
					this.onCellMouseDown(rowIndex, cellDataKey, columnData)
				}
				onClick={this.getOnClick(
					cellData,
					cellDataKey,
					rowData,
					rowIndex,
					columnData
				)}
			>
				<div
					title={tooltip}
					style={{
						maxWidth: `${this.width}px`,
						overflow: 'hidden',
						whiteSpace: 'pre',
						position: 'relative',
					}}
					className={innerClasses}
				>
					{this.renderDrillableCellContent(
						content,
						cellData,
						cellDataKey,
						rowData,
						rowIndex,
						columnData
					)}
				</div>
				{rowData.edit !== cellDataKey && contextMenu && value
					? this.renderContextMenu(value, rowData, contextMenu, columnData)
					: null}
			</div>
		);
	}

	renderDrillableCellContent(
		value,
		cellData,
		cellDataKey,
		rowData,
		rowIndex,
		columnData
	) {
		const drillState = this.getDrillState(rowData);
		const classname = cellDataKey.toString().split(' ').join('-');

		if (drillState === DrillState.Enabled) {
			if (this.drill.multiLine) {
				return (
					<div
						role="gridcell"
						aria-label={cellData}
						className={`column${classname}`}
					>
						<span>
							<i
								onClick={
									this.drill.onClick
										? this.drill.onClick(cellData, cellDataKey, rowData)
										: undefined
								}
								className={`${this.drill.icon} v-table-hover-icon`}
								data-test={`history-icon-rowIndex-${cellDataKey}${rowIndex}`}
							/>
						</span>
						{value}
					</div>
				);
			} else {
				return (
					<div
						role="gridcell"
						aria-label={cellData}
						style={{ position: 'absolute' }}
						className={`column${classname}`}
					>
						<span>
							<i
								onClick={
									this.drill.onClick
										? this.drill.onClick(cellData, cellDataKey, rowData)
										: undefined
								}
								className={`${this.drill.icon} v-table-hover-icon`}
								data-test={`history-icon-rowIndex-${cellDataKey}${rowIndex}`}
								tabIndex={this.drill.onClick && 0}
								onKeyDown={
									this.drill.onClick
										? this.drill.onClick(cellData, cellDataKey, rowData)
										: undefined
								}
							/>
							&nbsp;&nbsp;{value}
						</span>
					</div>
				);
			}
		}
		if (drillState === DrillState.Disabled) {
			return (
				<div
					role="gridcell"
					aria-label={cellData}
					style={{ position: 'absolute' }}
					className={`v-table-empty-drill column${classname}`}
				>
					&nbsp;&nbsp;{value}
				</div>
			);
		}
		return this.renderNonDrilledCellContent(
			value,
			cellData,
			cellDataKey,
			rowData,
			rowIndex,
			columnData
		);
	}

	/* virtual */ renderNonDrilledCellContent(value, cellData, cellDataKey) {
		// Replace whitespaces with - in cellDataKey
		const classname = cellDataKey.toString().split(' ').join('-');
		return (
			<div
				role="gridcell"
				aria-label={cellData}
				style={{ position: 'absolute' }}
				className={`column${classname}`}
			>
				{value}
			</div>
		);
	}

	onToggleDescription(columnData, cellDataKey) {
		if (this.dropDown) {
			columnData.onToggleDescription(
				cellDataKey,
				!this.dropDown.showDescription
			);
		}
	}

	onToggleContextMenu(columnData, cellDataKey, setting) {
		columnData.onToggleContextMenu(cellDataKey, setting);
	}

	renderContextMenu(value, rowData, contextMenu, columnData) {
		const column = columnData.columnDefinition;
		return (
			<TableContextMenuComponent
				contextMenu={contextMenu}
				value={value}
				rowData={rowData}
				column={column}
				showDescription={this.dropDown ? this.dropDown.showDescription : false}
				onToggleDescription={() =>
					this.onToggleDescription(columnData, column.key)
				}
				showContextMenu={this.showContextMenu}
				onToggleContextMenu={(setting) =>
					this.onToggleContextMenu(columnData, column.key, setting)
				}
			/>
		);
	}

	getDrillState(rowData) {
		if (this.drill) {
			if (this.drill.condition) {
				return this.drill.condition(rowData)
					? DrillState.Enabled
					: DrillState.Disabled;
			}
			return DrillState.Enabled;
		}
		return DrillState.None;
	}

	validate(value, cellData, cellDataKey, rowData, rowIndex, columnData) {
		if (
			rowData.error &&
			rowData.error.rowIndex === rowIndex &&
			rowData.error.columnIndex === cellDataKey
		) {
			return rowData.error.errorMessage;
		} else if (this.editable && !columnData.columnDefinition.nullable) {
			if (value === undefined || value === null) {
				return 'cell is not nullable';
			}
		}
	}

	getCellContent(
		value /* , cellData, cellDataKey, rowData, rowIndex, columnData, width */
	) {
		return value;
	}

	getFormattedContent(
		cellData /* , cellDataKey, rowData, rowIndex, columnData, width */
	) {
		return cellData;
	}

	getOnClick(/* cellData, cellDataKey, rowData, rowIndex, columnData */) {}

	getContextMenu(cellDataKey, rowData, columnData) {
		return columnData.getContextMenu(cellDataKey, rowData);
	}

	onCellMouseDown(rowIndex, cellDataKey, columnData) {
		if (columnData.onMouseDown) {
			columnData.onMouseDown(rowIndex, cellDataKey);
		}
	}

	formatMessage(id, values) {
		return LocaleHelper.replaceText(window.store.getState(), id, {
			values,
		});
	}

	isSelector() {
		return false;
	}

	update(...props) {
		return new ColumnDefinition(this, ...props);
	}
}
