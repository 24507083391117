/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { createReducer } from 'redux-act';
import { dropStateActionTypes as actionTypes } from '../constants/dropStateActionTypes';
import { LOGOUT } from '../constants/actionTypes';

export type StateShape = {
	data: any;
	id: string;
	type: string;
};

const defaultState: StateShape = {
	data: null,
	id: '',
	type: '',
};

const reducer = createReducer<StateShape>({}, defaultState);

reducer.on(LOGOUT, () => defaultState);

reducer.on(actionTypes.DROPSTATE_CLEAR_DATA, () => defaultState);

reducer.on(actionTypes.DROPSTATE_SET_DATA, (state, { data, id, type }) => ({
	...state,
	data,
	id,
	type,
}));

export const dropState = reducer;
