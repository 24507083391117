/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { eLearningActionTypes as actionTypes } from '../constants/eLearningActionTypes';
import { createReducer } from 'redux-act';
import { Varicent } from 'icm-rest-client';
import { LOGOUT } from '../constants/actionTypes';

type CourseUnits = {
	courseID: string;
	units: Varicent.RESTAPI.v1.DTOs.ELearningUnitDTO[];
};

export type StateShape = {
	coursesInfo: Varicent.RESTAPI.v1.DTOs.ELearningCoursesInfoDTO | null;
	courseLink: Varicent.RESTAPI.v1.DTOs.ELearningCourseLinkDTO | null;
	userValid: boolean | false;
	settingsValid: boolean | false;
	unitsInfo: CourseUnits[];
};

const defaultState: StateShape = {
	coursesInfo: null,
	courseLink: null,
	userValid: false,
	settingsValid: false,
	unitsInfo: [],
};

const reducer = createReducer<StateShape>({}, defaultState);

/*
 * Handlers
 */

// Cleanup
reducer.on(LOGOUT, () => defaultState);

reducer.on(actionTypes.ELEARNING_GET_COURSES, (state, { coursesInfoDTO }) => ({
	...state,
	coursesInfo: coursesInfoDTO,
}));

reducer.on(
	actionTypes.ELEARNING_GET_COURSE_UNITS,
	(state, { courseUnitsDTO, currCourse }) => {
		const newUnitsInfo = [...state.unitsInfo];
		const courseUnits: CourseUnits = {
			courseID: currCourse.courseID,
			units: courseUnitsDTO,
		};

		if (courseUnits.courseID) {
			newUnitsInfo.push(courseUnits);
			const unitsInfo: CourseUnits[] = newUnitsInfo;
			return {
				...state,
				unitsInfo,
			};
		} else {
			return state;
		}
	}
);

reducer.on(
	actionTypes.ELEARNING_GET_COURSE_LINK,
	(state, { courseLinkDTO }) => ({
		...state,
		courseLink: courseLinkDTO,
	})
);

reducer.on(actionTypes.ELEARNING_CLEAR_COURSE_LINK, (state) => ({
	...state,
	courseLink: null,
}));

reducer.on(actionTypes.ELEARNING_USER_LOGIN, (state, { userValid }) => ({
	...state,
	userValid,
}));

reducer.on(actionTypes.ELEARNING_USER_VALID, (state, { userValid }) => ({
	...state,
	userValid,
}));

reducer.on(
	actionTypes.ELEARNING_SETTINGS_VALID,
	(state, { settingsValid }) => ({
		...state,
		settingsValid,
	})
);

export const eLearning = reducer;
