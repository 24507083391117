/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import _ from 'lodash';
import { createAction } from 'redux-act';
import jsrsasign from 'jsrsasign';

import { getJWTPayloadObject, isJWTNotExpired } from 'helpers/jwtHelper';
import { HOME_LINK, LOGIN_LINK } from 'constants/routerPaths';
import { redirect } from 'actions/redirectActions';
import { restApiError } from 'actions/messageActions';
import { showPasswordChange } from 'actions/changePasswordActions';
import { host, clearSession } from 'helpers/storageHelper';
import AuthService, { ILoginData } from 'services/authService';
import MigrationService from 'services/migrationService';
import {
	CHANGE_MODEL,
	CLOSE_AUTH_MODAL,
	CLOSE_POPUP,
	LOGIN,
	LOGOUT,
	RENEW_JWT,
	SET_WAIT_FOR_POPUP,
	SSO_CHECKED,
	STATUS_PAGE,
	SET_INTERCOM_HASH,
	SET_INTERCOM_ACTIVE,
} from 'constants/actionTypes';
import {
	storeJWT,
	getRemoteMigrations,
	getTSUrl,
} from 'actions/remoteMigrationActions';
import {
	checkLoggedInThenCloseModal,
	getJWT,
	getLastModel,
	shouldGetNewToken,
	updateNextTimeOfTokenRenewal,
	setCurrentJWTFromTokenMap,
	getJWTRenewMinutesInterval,
} from 'helpers/authHelper';

interface IDbServer {
	[s: string]: string;
}

function genAuthToken(
	adminId: string,
	tenantId: number,
	is_v2: boolean,
	uuid: string | null = null
): string {
	if (_.isEmpty(jsrsasign)) {
		throw new Error('Cannot perform local login with production files.');
	} else {
		const header = { alg: 'HS256', typ: 'JWT' };

		// now expressed as seconds from the epoch
		const iat = Math.floor(Date.now() * 0.001);
		// Payload
		const payload = {
			adminId,
			is_v2,
			tenant_id: tenantId,
			uuid,
			iat,

			// expires in 30 days
			exp: iat + 30 * 86400,
		};

		const hash = jsrsasign.jws.JWS.sign(
			'HS256',
			JSON.stringify(header),
			JSON.stringify(payload),
			'varicent'
		);
		return hash;
	}
}

export interface ILoginPayload {
	database: string;
	isLocal: boolean;
	models: string[];
	tenantID?: number;
	token?: string;
	tokenMap: object;
	user: string;
	userName: string;
	jwtRenewMinutesInterval?: number;
	adminUserAuditLogEnabled?: boolean;
	servers?: object;
}

export const setIntercomHash = createAction<string>(SET_INTERCOM_HASH);
export const setIntercomActive = createAction<boolean>(SET_INTERCOM_ACTIVE);
export const setSSOChecked = createAction<boolean>(SSO_CHECKED);
export const setWaitForPopup = createAction<boolean>(SET_WAIT_FOR_POPUP);
export const closePopup = createAction(CLOSE_POPUP);
export const login = createAction<ILoginPayload>(LOGIN);
export const logout = createAction(LOGOUT);
export const changeModel =
	createAction<{ label: string; isDefault: boolean }>(CHANGE_MODEL);
export const statusPage = createAction<boolean>(STATUS_PAGE);
export const closeAuthModal = createAction(CLOSE_AUTH_MODAL);
// TODO: JWT batch renewal
export const renewJWT = createAction<{
	token: string;
	model?: string;
	jwtRefreshInterval?: number;
}>(RENEW_JWT);

export const getSSO =
	(username: string, pathname: string, modal: boolean): ThunkAction<void> =>
	(dispatch) => {
		AuthService.getSSOLink(username, pathname, modal).then((data) => {
			if (data.idpLoginUrl) {
				// If we're doing sso from an expired session, open SSO login in popup and add event listener to attempt to automatically detect the popup closing
				if (modal) {
					dispatch(setWaitForPopup(true));
					window.addEventListener('focus', checkLoggedInThenCloseModal, false);
					window.ssoOpenedPopup = window.open(
						data.idpLoginUrl,
						'',
						'width=800,height=500'
					);
				} else {
					location.replace(data.idpLoginUrl);
				}
			} else {
				// Tenant is not using SSO
				dispatch({
					type: SSO_CHECKED,

					payload: true,
				});
			}
		});
	};

export const getSSOByHost =
	(hostname: string, pathname: string, modal: boolean): ThunkAction<void> =>
	(dispatch) => {
		AuthService.getSSOLinkForHost(hostname, pathname, modal).then((data) => {
			if (data.idpLoginUrl) {
				// If we're doing sso from an expired session, open SSO login in popup and add event listener to attempt to automatically detect the popup closing
				if (modal) {
					dispatch(setWaitForPopup(true));
					window.addEventListener('focus', checkLoggedInThenCloseModal, false);
					window.ssoOpenedPopup = window.open(
						data.idpLoginUrl,
						'',
						'width=800,height=500'
					);
				} else {
					location.replace(data.idpLoginUrl);
				}
			}
		});
	};

function getCurrentModel() {
	return window.store?.getState().authentication?.database;
}

export const SSOLogout =
	(username: string): ThunkAction<void> =>
	() => {
		return AuthService.getSLOLink(username).then((data) => {
			if (data.idpLogoutUrl) {
				location.replace(data.idpLogoutUrl);
			}
		});
	};

export const userLogout =
	(username: string): ThunkAction<Promise<void>> =>
	(dispatch) => {
		const clearStateAndSSO = () => {
			dispatch(logout());
			MigrationService.logoutDuringMigration();
			dispatch(SSOLogout(username));
			clearSession();
		};

		clearState();

		return AuthService.logout().then(clearStateAndSSO, clearStateAndSSO);
	};

// clear out any sensitive info that could persist through logins
function clearState() {
	window.store.actions.queryToolActions.updateQuery('', null);
}

export const userRenewJWT =
	(jwtRefreshInterval: number): ThunkAction<void> =>
	(dispatch) => {
		// if it's time to get a new token from tenant services, update the next refresh time as well
		if (shouldGetNewToken()) {
			AuthService.getNewToken().then((data) =>
				dispatch(
					renewJWT({
						token: data.token,
						model: getCurrentModel(),
						jwtRefreshInterval,
					})
				)
			);
		} else {
			dispatch(renewJWT({ token: getJWT(), model: getCurrentModel() }));
		}
	};

export const changeModelAndRenewJWT =
	(model: { label: string; isDefault: boolean }): ThunkAction<void> =>
	(dispatch) => {
		const token = setCurrentJWTFromTokenMap(model.label);
		const jwtRefreshInterval = getJWTRenewMinutesInterval();
		dispatch(changeModel(model));
		AuthService.getNewTokenFromOld(token).then((data) => {
			dispatch(
				renewJWT({
					token: data.token,
					model: getCurrentModel(),
					jwtRefreshInterval,
				})
			);
		});
	};

export const loginLocal =
	(username: string, database: string): ThunkAction<void> =>
	(dispatch) => {
		const dbserver: IDbServer = {};

		/*
		 * This will let you log into to multiple models when you want to test Migration/DB Import.
		 * Note that this is DEV ONLY. Enter something like "ModelA;ModelB" in the database section of the login screen.
		 * You can also add a colon followed by the server name if the database exists on a server other than default.
		 */
		let models = database.split(';');
		const tokenMap = {};
		const tenantId = 0;
		for (const db of models) {
			const dbAndServer = db.split(':');
			dbserver[dbAndServer[0]] =
				dbAndServer.length > 1 ? dbAndServer[1] : 'default';
			const authToken = genAuthToken(username, tenantId, true);
			tokenMap[dbAndServer[0]] = authToken;
		}

		models = models.map((m) => m.split(':')[0]);
		const lastModel = getLastModel(username);
		dispatch(closeAuthModal());
		dispatch(
			login({
				token: tokenMap[lastModel] ?? Object.values(tokenMap)[0],
				user: 'local user',
				userName: username,
				database:
					lastModel && models.includes(lastModel) ? lastModel : models[0],
				tokenMap,
				isLocal: true,
				models,
				servers: dbserver,
			})
		);
	};

const getTokenByModel = ({ token, tokenMap, model }): string => {
	if (
		!!tokenMap &&
		!!Object.keys(tokenMap).length &&
		!!model &&
		!!tokenMap[model]
	) {
		return tokenMap[model];
	} else if (!!tokenMap && !!Object.keys(tokenMap).length) {
		return tokenMap[Object.keys(tokenMap)[0]];
	} else {
		return token;
	}
};

export const processLogin =
	(data: ILoginData, username: string): ThunkAction<void> =>
	(dispatch) => {
		const {
			token,
			token_map: tokenMap = {},
			tenant_id: tenantID,
			models,
			first_name: firstName,
			last_name: lastName,
			jwt_renew_minutes_interval: jwtRenewMinutesInterval,
			accountNumber = null,
		} = data;

		const lastModel = getLastModel(username);
		const tokenComputed: string = getTokenByModel({
			token,
			tokenMap,
			model: lastModel,
		});
		const jsonToken: any = getJWTPayloadObject(tokenComputed);

		updateNextTimeOfTokenRenewal(jwtRenewMinutesInterval);

		const firstNameComputed = firstName || jsonToken.first_name;
		const lastNameComputed = lastName || jsonToken.last_name;
		const adminUserAuditLogEnabled = jsonToken.admin_user_auditLog_enabled;

		!!accountNumber &&
			localStorage.setItem(`${host}__accountNumber`, accountNumber);

		dispatch(
			login({
				token: tokenComputed,
				user: `${firstNameComputed} ${lastNameComputed}`,
				userName: username,
				database:
					lastModel && models.includes(lastModel) ? lastModel : models[0],
				isLocal: false,
				models,
				tokenMap,
				tenantID,
				jwtRenewMinutesInterval,
				adminUserAuditLogEnabled,
			})
		);
	};

interface IRemoteErrorTexts {
	model: string;
	unknown: string;
	maximum_attempts: string;
	login: string;
	remoteSettings?: string;
}

export const loginRemoteMigration =
	(
		username: string,
		password: string,
		errorTexts: IRemoteErrorTexts,
		onLogin: () => void,
		remoteHost: string
	): ThunkAction<void> =>
	async (dispatch) => {
		try {
			const url = await dispatch(getTSUrl());
			const data = await AuthService.remoteLogin(
				username,
				password,
				url,
				remoteHost
			);
			if (data.models.length < 1) {
				dispatch(
					restApiError(
						data,
						null,
						'',
						null,
						errorTexts.model,
						errorTexts.unknown
					)
				);
			} else {
				const { token, models } = data;
				dispatch(
					storeJWT(
						token,
						models.map((model) => `${model} ${remoteHost}`)
					)
				);
				dispatch(getRemoteMigrations());
				if (onLogin) onLogin();
			}
		} catch (error) {
			if (error.response?.data?.code === 3) {
				dispatch(
					restApiError(
						error,
						error.response,
						'',
						null,
						errorTexts.maximum_attempts,
						errorTexts.unknown
					)
				);
			} else {
				let message: string | null = null;

				if (error.response?.status === 403) {
					message = errorTexts.login;
				} else if (!error.response) {
					message = errorTexts.remoteSettings || null;
				}
				dispatch(
					restApiError(
						error,
						error.response,
						'',
						null,
						message,
						errorTexts.unknown
					)
				);
			}
		}
	};

export const loginRemote =
	(
		username: string,
		password: string,
		errorTexts: IRemoteErrorTexts,
		onLogin: () => void
	): ThunkAction<void> =>
	(dispatch, getState) =>
		AuthService.login(username, password).then(
			(data) => {
				if (data.models.length < 1) {
					dispatch(
						restApiError(
							data,
							null,
							'',
							null,
							errorTexts.model,
							errorTexts.unknown
						)
					);
				} else {
					// log-in success
					processLogin(data, username)(dispatch, getState, {});
					dispatch(setSSOChecked(false));
					dispatch(closeAuthModal());
					if (onLogin) {
						onLogin();
					}
				}
			},
			(data) => {
				if (data.response.data.code === 2) {
					dispatch(showPasswordChange());
				} else if (data.response.data.code === 3) {
					dispatch(
						restApiError(
							data,
							data.response,
							'',
							null,
							errorTexts.maximum_attempts,
							errorTexts.unknown
						)
					);
				} else {
					let message: string | null = null;

					if (data.response.status === 403) {
						message = errorTexts.login;
					}
					dispatch(
						restApiError(
							data,
							data.response,
							'',
							null,
							message,
							errorTexts.unknown
						)
					);
				}
			}
		);
interface ISSOErrorTexts {
	model: string;
	unknown: string;
	SSO: string;
}

export const completeSSOLogin =
	(errorTexts: ISSOErrorTexts): ThunkAction<void> =>
	(dispatch, getState) =>
		AuthService.completeSSOLogin().then((data) => {
			let error = false;
			if (!data.token && !data.token_map) {
				// No token and token_map in response -- some sort of SSO configuration email or userid mismatch
				dispatch(
					restApiError(data, null, '', null, errorTexts.SSO, errorTexts.unknown)
				);
				error = true;
			} else if (data.models.length < 1) {
				dispatch(
					restApiError(
						data,
						null,
						'',
						null,
						errorTexts.model,
						errorTexts.unknown
					)
				);
				error = true;
			} else {
				// check if user is logged in
				if (!isJWTNotExpired()) {
					processLogin(data, data.username)(dispatch, getState, {});
				}
			}
			if (data.modal) {
				dispatch(closePopup());
			} else {
				/*
				 * If there was an error login won't be completed - redirecting
				 * to anywhere but /login will cause a redirect to /login,
				 * resulting in a loop. */
				dispatch(redirect(error ? LOGIN_LINK : data.nextPathname || HOME_LINK));
			}
		});
