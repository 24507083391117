/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import NotEqualFilter from '../models/filter/filterOperator/notEqualFilter';
import ClauseFilter from '../models/filter/filterOperator/clauseFilter';
import { elementSwap } from './utility/arrayUtilities';
import { filterOpenBracket, filterCloseBracket } from './filter/filterUtil';

// Convert a user input search string to a filter string used against the API
export function searchToFilter(search) {
	if (search === null) {
		return search;
	}

	let filter = search;
	/*
	 * Our API filter engine has some special characters used for advanced filtering functionality (ex. Multi, Interval,
	 * List). Generally most users will not be needing these advanced filtering options, and we will need to interpret
	 * the special characters as literals. Unfortunately, at the time of writing this function, there is no way to
	 * escape special characters.
	 * Here, as a work around, we'll try to sanitize the search string such that the API can handle it.
	 * Ideally, the API should change so we don't have to do this.
	 */

	for (;;) {
		// If the string contains '&&' or '||', the API will interpret it as a multi filter
		if (filter.indexOf('&&') !== -1) {
			filter = filter.replace('&&', '');
			continue;
		}

		if (filter.indexOf('||') !== -1) {
			filter = filter.replace('||', '');
			continue;
		}

		/*
		 * If the string starts with a round/square bracket and ends with a round/square bracket, the API will interpret
		 * it as a interval filter
		 */
		if (
			(filter.startsWith('(') || filter.startsWith('[')) &&
			(filter.endsWith(')') || filter.endsWith(']'))
		) {
			// trim off all round/square brackets off the end
			for (let i = 0; i < filter.length; i++) {
				const endChar = filter.charAt(filter.length - 1 - i);

				if (endChar !== ')' && endChar !== ']') {
					filter = filter.substr(0, filter.length - i);
					break;
				}
			}

			continue;
		}

		// If the string contains ',', the API will interpret it as a list filter
		if (filter.indexOf(',') !== -1) {
			filter = filter.replace(',', '');
			continue;
		}

		break;
	}

	return filter;
}

/**
 * Orders filters for query building
 * <p>
 * This function takes a array of filters and orders them alphabetically by column, then moves notequal filters to be the first filter of its column
 *
 * @param filters an array of filters to order
 * @return the ordered array of filters
 */
export function orderFilters(filters) {
	function compare(a, b) {
		if (a.getColumnName() < b.getColumnName()) {
			return -1;
		} else if (a.getColumnName() > b.getColumnName()) {
			return 1;
		}
		return 0;
	}

	const retFilters = filters.sort(compare);
	let firstIndexOfColumn = 0;
	let curColumn;
	/*
	 *If column is the same as curColumn, check if filter is a notEquals filter, if it is, swap with first of column
	 *else update firstIndexOfColumn and curColumn as we are now looking at filters for a new column
	 */
	for (let i = 0; i < retFilters.length; i++) {
		if (retFilters[i].getColumnName() === curColumn) {
			if (retFilters[i] instanceof NotEqualFilter) {
				elementSwap(retFilters, i, firstIndexOfColumn);
			}
		} else {
			firstIndexOfColumn = i;
			curColumn = retFilters[i].getColumnName();
		}
	}
	return retFilters;
}

/**
 * Builds filters between the two indexes as 1stfilter&&((filter||filter||filter)), for use when there is an exclude filter
 *
 * @param filters an array of filters
 * @param indexI index of first element to be changed to a string
 * @param indexJ index of last element to be changed to a string
 * @return the string representation of the filters passed in as 1stfilter&&((filter||filter||filter))
 */
export function excludeColumnToString(filters, indexI, indexJ) {
	let retString = '';
	if (indexJ - indexI === 0) {
		retString = filters[indexI].toString();
	} else if (
		indexJ - indexI === 1 &&
		!(filters[indexJ] instanceof ClauseFilter)
	) {
		retString += filters[indexI].toString();
		retString += '&&';
		retString += filters[indexJ].toString();
	} else {
		retString += filters[indexI].toString();
		retString += `&&${filterOpenBracket}`;
		for (let i = indexI + 1; i < indexJ; i++) {
			retString += filters[i].toString();
			retString += '||';
		}
		retString += filters[indexJ].toString();
		retString += filterCloseBracket;
	}
	return retString;
}
