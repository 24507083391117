/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	CHANGE_MODEL,
	LOGOUT,
	ROUTER_NAVIGATE,
} from '../constants/actionTypes';
import {
	ADMIN_LINK,
	AUDIT_LINK,
	PERFORMANCE_LINK,
	HEALTH_DASHBOARD_LINK,
	IMPORT_ERROR_LOGS_LINK,
	EXPORT_ERROR_LOGS_LINK,
	EXTERNAL_TOOLS_LOGS_LINK,
	CALC_ENGINE_LOGS_LINK,
	SCHEDULER_ERROR_LOGS_LINK,
	TASK_ERROR_LOGS_LINK,
	MANAGE_USERS_LINK,
	MANAGE_ROLES_LINK,
	MORE_OPTIONS_LINK,
	INTEGRATIONS_LINK,
	MODEL_SUMMARY_LINK,
	WEB_MESSAGES_LINK,
	SAVE_IMPORTS_LINK,
	SAVED_PUBLICATIONS_LINK,
	SAVED_TRANSFORMATIONS_LINK,
	MANAGE_DOCUMENTS_LINK,
	MANAGE_PAYEE_DOCUMENTS_LINK,
	HISTORY_TRACKING_LINK,
	DATA_GRID_TEMPLATES_LINK,
	CALENDARS_LINK,
	OPTIMIZE_MODEL_LINK,
	PAYEE_GROUPS_LINK,
	STAR_SCHEMA_LINK,
	SIGNATURE_LINK,
	EXTERNAL_TOOLS_LINK,
	MANAGE_PAYEE_TYPES_LINK,
	QUERY_TOOL_LINK,
	HIERARCHIES_LINK,
	SAVED_QUERYTOOL_LINK,
	MOBILE_OPTIONS_LINK,
	MIGRATION_LINK,
} from '../constants/routerPaths';
import {
	AUDITLOG_VIEW,
	HEALTH_DASHBOARD_VIEW,
	HOMEPAGE_MANAGEDOCUMENTSVIEW,
	HOMEPAGE_MANAGESAVEDIMPORTSVIEW,
	HOMEPAGE_MANAGESAVEDPUBLICATIONSVIEW,
	HOMEPAGE_MANAGESAVEDTRANSFORMATIONSVIEW,
	HOMEPAGE_MANAGEHISTORYTRACKINGVIEW,
	HOMEPAGE_OPTIMIZEMODEL,
	HOMEPAGE_CALENDARSVIEW,
	HOMEPAGE_STARSCHEMAVIEW,
	HOMEPAGE_PAYEEGROUPSVIEW,
	HOMEPAGE_MANAGEDATAGRIDTEMPLATESVIEW,
	HOMEPAGE_ADMINOPTIONSVIEW,
	SCHEDULER_VIEW,
	ADMIN_ROLE,
	PORTALACCESS_VIEW,
	HOMEPAGE_HIERARCHYVIEW,
	HOMEPAGE_QUERYTOOLVIEW,
	HOMEPAGE_MODELSUMMARY,
	HOMEPAGE_MIGRATIONSEXECUTE,
	HOMEPAGE_MIGRATIONSEDIT,
} from 'constants/permissions';

import FeatureFlags from '../constants/featureFlags';
import * as ActionTypes from '../constants/admin/adminActionTypes';
import * as AdminOptionsActionTypes from '../constants/admin/adminOptionsActionTypes';
import * as AdminOptionKeys from '../constants/admin/adminOptionKeys';
import _ from 'lodash';
import R from 'ramda';
import { Varicent } from 'icm-rest-client';
import { createReducer } from 'redux-act';

const webMessagesLink = {
	text: 'WEB_MESSAGES',
	link: `${ADMIN_LINK + WEB_MESSAGES_LINK}/`,
	icon: '',
	selected: false,
	permission: PORTALACCESS_VIEW,
};

interface Shared {
	text: string;
	icon: string;
	permission: string | null | string[];
	feature?: string;
}

interface SubMenuLink extends Shared {
	submenu: Link[];
	expanded: boolean;
}

interface Link extends Shared {
	link: string;
	selected: boolean;
}

type State = {
	links: (Link | SubMenuLink)[];
};

const isSubmenu = (link: any): link is SubMenuLink => {
	return link.submenu;
};

const initialState: State = {
	links: [
		{
			text: 'AUDIT',
			link: `${ADMIN_LINK + AUDIT_LINK}/`,
			icon: '',
			selected: false,
			permission: AUDITLOG_VIEW,
		},
		{
			text: 'PERFORMANCE',
			link: `${ADMIN_LINK + PERFORMANCE_LINK}/`,
			icon: '',
			selected: false,
			permission: AUDITLOG_VIEW,
		},
		{
			text: 'HEALTH_DASHBOARD',
			link: `${ADMIN_LINK + HEALTH_DASHBOARD_LINK}/`,
			icon: '',
			selected: false,
			permission: HEALTH_DASHBOARD_VIEW,
		},
		{
			text: 'CALENDARS',
			link: `${ADMIN_LINK + CALENDARS_LINK}/`,
			icon: '',
			selected: false,
			permission: HOMEPAGE_CALENDARSVIEW,
		},
		{
			text: 'HIERARCHIES',
			link: `${ADMIN_LINK + HIERARCHIES_LINK}/`,
			icon: '',
			selected: false,
			permission: HOMEPAGE_HIERARCHYVIEW,
			feature: FeatureFlags.hierarchies,
		},
		{
			text: 'ERROR_LOGS',
			icon: '',
			expanded: false,
			permission: AUDITLOG_VIEW,
			submenu: [
				{
					text: 'IMPORT_ERROR_LOGS',
					link: `${ADMIN_LINK + IMPORT_ERROR_LOGS_LINK}/`,
					icon: '',
					selected: false,
					permission: null,
				},
				{
					text: 'EXPORT_ERROR_LOGS',
					link: `${ADMIN_LINK + EXPORT_ERROR_LOGS_LINK}/`,
					icon: '',
					selected: false,
					permission: null,
				},
				{
					text: 'SCHEDULER_ERROR_LOGS',
					link: `${ADMIN_LINK + SCHEDULER_ERROR_LOGS_LINK}/`,
					icon: '',
					selected: false,
					permission: null,
				},
				{
					text: 'TASK_ERROR_LOGS',
					link: `${ADMIN_LINK + TASK_ERROR_LOGS_LINK}/`,
					icon: '',
					selected: false,
					permission: null,
				},
				{
					text: 'EXTERNAL_TOOLS_LOGS',
					link: `${EXTERNAL_TOOLS_LOGS_LINK}/`,
					icon: '',
					selected: false,
					permission: null,
				},
				{
					text: 'CALC_ENGINE_LOGS',
					link: CALC_ENGINE_LOGS_LINK,
					icon: '',
					selected: false,
					permission: null,
					feature: FeatureFlags.ppoEnabled,
				},
			],
		},
		{
			text: 'TOOLS',
			icon: '',
			expanded: false,
			permission: [
				HOMEPAGE_OPTIMIZEMODEL,
				HOMEPAGE_PAYEEGROUPSVIEW,
				HOMEPAGE_QUERYTOOLVIEW,
				HOMEPAGE_STARSCHEMAVIEW,
			],
			submenu: [
				{
					text: 'OPTIMIZE_MODEL',
					link: `${ADMIN_LINK + OPTIMIZE_MODEL_LINK}/`,
					icon: '',
					selected: false,
					permission: HOMEPAGE_OPTIMIZEMODEL,
				},
				{
					text: 'PAYEE_GROUPS',
					link: `${ADMIN_LINK + PAYEE_GROUPS_LINK}/`,
					icon: '',
					selected: false,
					permission: HOMEPAGE_PAYEEGROUPSVIEW,
				},
				{
					text: 'STAR_SCHEMA',
					link: `${ADMIN_LINK + STAR_SCHEMA_LINK}/`,
					icon: '',
					selected: false,
					permission: HOMEPAGE_STARSCHEMAVIEW,
				},
				{
					text: 'QUERY_TOOL',
					link: `${ADMIN_LINK + QUERY_TOOL_LINK}/`,
					icon: '',
					selected: false,
					permission: HOMEPAGE_QUERYTOOLVIEW,
					feature: FeatureFlags.queryTool,
				},
			],
		},
		{
			text: 'MANAGE',
			icon: '',
			expanded: false,
			permission: [
				HOMEPAGE_MANAGEDOCUMENTSVIEW,
				HOMEPAGE_MANAGESAVEDIMPORTSVIEW,
				HOMEPAGE_MANAGESAVEDPUBLICATIONSVIEW,
				HOMEPAGE_MANAGESAVEDTRANSFORMATIONSVIEW,
				PORTALACCESS_VIEW,
				HOMEPAGE_MANAGEHISTORYTRACKINGVIEW,
				HOMEPAGE_MANAGEDATAGRIDTEMPLATESVIEW,
				SCHEDULER_VIEW,
			],
			submenu: [
				{
					text: 'SAVE_IMPORTS',
					link: `${ADMIN_LINK + SAVE_IMPORTS_LINK}/`,
					icon: '',
					selected: false,
					permission: HOMEPAGE_MANAGESAVEDIMPORTSVIEW,
				},
				{
					text: 'SAVED_PUBLICATIONS',
					link: `${ADMIN_LINK + SAVED_PUBLICATIONS_LINK}/`,
					icon: '',
					selected: false,
					permission: HOMEPAGE_MANAGESAVEDPUBLICATIONSVIEW,
				},
				{
					text: 'SAVED_TRANSFORMATIONS',
					link: `${ADMIN_LINK + SAVED_TRANSFORMATIONS_LINK}/`,
					icon: '',
					selected: false,
					permission: HOMEPAGE_MANAGESAVEDTRANSFORMATIONSVIEW,
				},
				{
					text: 'MANAGE_FILES',
					link: `${ADMIN_LINK + MANAGE_DOCUMENTS_LINK}/`,
					icon: '',
					selected: false,
					permission: HOMEPAGE_MANAGEDOCUMENTSVIEW,
				},
				{
					text: 'MANAGE_PAYEE_DOCUMENTS',
					link: `${ADMIN_LINK + MANAGE_PAYEE_DOCUMENTS_LINK}`,
					icon: '',
					selected: false,
					permission: HOMEPAGE_MANAGEDOCUMENTSVIEW,
				},
				{
					text: 'HISTORY_TRACKING',
					link: `${ADMIN_LINK + HISTORY_TRACKING_LINK}/`,
					icon: '',
					selected: false,
					permission: HOMEPAGE_MANAGEHISTORYTRACKINGVIEW,
				},
				{
					text: 'DATA_GRID_TEMPLATES',
					link: `${ADMIN_LINK + DATA_GRID_TEMPLATES_LINK}/`,
					icon: '',
					selected: false,
					permission: HOMEPAGE_MANAGEDATAGRIDTEMPLATESVIEW,
				},
				{
					text: 'EXTERNAL_TOOLS',
					link: `${ADMIN_LINK + EXTERNAL_TOOLS_LINK}/`,
					icon: '',
					selected: false,
					permission: SCHEDULER_VIEW,
				},
				{
					text: 'MANAGE_SAVED_QUERIES',
					link: `${ADMIN_LINK + SAVED_QUERYTOOL_LINK}/`,
					icon: '',
					selected: false,
					permission: HOMEPAGE_QUERYTOOLVIEW,
					feature: FeatureFlags.queryTool,
				},
			],
		},
		{
			text: 'USER_AND_PERMISSIONS',
			icon: '',
			expanded: false,
			permission: ADMIN_ROLE,
			submenu: [
				{
					text: 'MANAGE_USERS',
					link: `${ADMIN_LINK + MANAGE_USERS_LINK}/`,
					icon: '',
					selected: false,
					permission: null,
				},
				{
					text: 'MANAGE_ROLES',
					link: `${ADMIN_LINK + MANAGE_ROLES_LINK}/`,
					icon: '',
					selected: false,
					permission: null,
				},
				{
					text: 'MANAGE_PAYEE_TYPES',
					link: `${ADMIN_LINK + MANAGE_PAYEE_TYPES_LINK}/`,
					icon: '',
					selected: false,
					permission: null,
				},
			],
		},
		{
			text: 'MODEL_SUMMARY',
			link: `${ADMIN_LINK + MODEL_SUMMARY_LINK}/`,
			icon: '',
			selected: false,
			permission: HOMEPAGE_MODELSUMMARY,
		},
		{
			text: 'SIGNATURE_CONTROL',
			link: `${SIGNATURE_LINK}/`,
			icon: '',
			selected: false,
			permission: AUDITLOG_VIEW,
		},
		{
			text: 'INTEGRATIONS',
			link: `${ADMIN_LINK + INTEGRATIONS_LINK}/`,
			icon: '',
			selected: false,
			permission: HOMEPAGE_ADMINOPTIONSVIEW,
		},
		{
			text: 'MORE_OPTIONS',
			link: `${ADMIN_LINK + MORE_OPTIONS_LINK}/`,
			icon: '',
			selected: false,
			permission: HOMEPAGE_ADMINOPTIONSVIEW,
		},
		{
			text: 'MOBILE_APP',
			link: `${ADMIN_LINK + MOBILE_OPTIONS_LINK}/`,
			icon: '',
			selected: false,
			permission: HOMEPAGE_ADMINOPTIONSVIEW,
		},
		{
			text: 'MIGRATION',
			link: `${ADMIN_LINK + MIGRATION_LINK}/`,
			icon: '',
			selected: false,
			permission: [HOMEPAGE_MIGRATIONSEDIT, HOMEPAGE_MIGRATIONSEXECUTE],
		},
	],
};

const reducer = createReducer<Readonly<State>>({}, initialState);

function update(links: (Link | SubMenuLink)[], showWebMessages: boolean) {
	const linkLabels = links.map((elem) => elem.text);
	const idxTools = linkLabels.indexOf('TOOLS');
	const idxWebMessages = linkLabels.indexOf('WEB_MESSAGES');

	if (showWebMessages && idxWebMessages < 0) {
		return R.insert(idxTools + 1, webMessagesLink as Link | SubMenuLink, links);
	} else if (!showWebMessages && idxWebMessages > -1) {
		return R.remove(idxWebMessages, 1, links);
	}

	return links;
}

reducer.on(ROUTER_NAVIGATE, (state, payload: { pathname: string }) => {
	const pathname =
		payload.pathname.slice(-1) === '/'
			? payload.pathname
			: `${payload.pathname}/`;
	const newState = { ...state };

	newState.links = state.links.map((elem) => {
		if (!isSubmenu(elem)) {
			return {
				...elem,
				selected: pathname.indexOf(elem.link) > -1,
			};
		}

		return {
			...elem,
			submenu: elem.submenu.map((child) => ({
				...child,
				selected: pathname.indexOf(child.link) === 0,
			})),
			expanded: elem.submenu.some(
				(child) => pathname.indexOf(child.link) === 0
			),
		};
	});

	return newState;
});

reducer.on(
	ActionTypes.EXPAND_ADMIN_SIDEBAR,
	(state, payload: { text: string }) => {
		const newState = { ...state };
		newState.links = state.links.map((elem) => {
			if (isSubmenu(elem)) {
				return {
					...elem,
					expanded: elem.text === payload.text ? !elem.expanded : false,
				};
			} else {
				return elem;
			}
		});
		return newState;
	}
);

type RecieveDataPayload = { data: Varicent.RESTAPI.v1.DTOs.ConfigValueDTO[] };
reducer.on(
	AdminOptionsActionTypes.ADMIN_OPTIONS_RECEIVE_DATA,
	(state, payload: RecieveDataPayload) => {
		const adminOption = _.find(
			payload.data,
			(o) => o.key === AdminOptionKeys.MessageCentreEnabled
		);
		const showWebMessages = adminOption ? adminOption.value === true : true;
		return {
			...state,
			links: update(state.links, showWebMessages),
		};
	}
);

reducer.on(LOGOUT, () => initialState);
reducer.on(CHANGE_MODEL, () => initialState);

export default reducer;
