// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 * Get all hierarchies
 * url: `api/v1/hierarchies`
 */
export function getAll(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'hierarchies',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.Hierarchy.HierarchyDTOV1[]
	>(request);
}

/**
 * Get hierarchy by ID
 * url: `api/v1/hierarchies/${id}`
 */
export function get(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `hierarchies/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Hierarchy.HierarchyDTOV1>(
		request
	);
}

/**
 * Create a hierarchy
 * url: `api/v1/hierarchies`
 */
export function post(
	dto: Varicent.RESTAPI.v1.DTOs.Hierarchy.HierarchyDTOV1,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'hierarchies',
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Hierarchy.HierarchyDTOV1>(
		request
	);
}

/**
 * Update a hierarchy
 * url: `api/v1/hierarchies/${id}`
 */
export function put(
	id: Varicent.ID,
	dto: Varicent.RESTAPI.v1.DTOs.Hierarchy.HierarchyDTOV1,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `hierarchies/${id}`,
		apiVersion: 'v1' as const,
		data: dto,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Hierarchy.HierarchyDTOV1>(
		request
	);
}

/**
 * Delete a hierarchy
 * url: `api/v1/hierarchies/${id}`
 */
export function doDelete(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `hierarchies/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Hierarchy.HierarchyDTOV1>(
		request
	);
}

/**
 * Get available sources
 * url: `api/v1/hierarchies/sources`
 */
export function getSources(
	query?: { displayNameFilter?: string; limit?: number },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'hierarchies/sources',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.Hierarchy.HierarchySourceDTOV1[]
	>(request);
}

/**
 * Get compact hierarchies
 * url: `api/v1/hierarchiescompact`
 */
export function getAllCompact(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'hierarchiescompact',
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.Hierarchy.HierarchyCompactDTOV1[]
	>(request);
}

/**
 * Get periods for hierarchy
 * url: `api/v1/payee/hierarchies/${id}/periods`
 */
export function getAvailablePeriods(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/hierarchies/${id}/periods`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Hierarchy.HierarchyPeriodDTOV1>(
		request
	);
}

/**
 * Get visible payees for hierarchy and period
 * url: `api/v1/payee/hierarchies/${id}/payees`
 */
export function getVisiblePayees(
	id: Varicent.ID,
	query: { period: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/hierarchies/${id}/payees`,
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.Hierarchy.HierarchyPayeeDTOV1>(
		request
	);
}
