/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as SystemTranslations from 'localizations/systemTables/systemTranslations';
import AggregateError from 'exceptions/aggregateError';
import LocaleHelper from './localeHelper';
import ErrorLogService from 'services/errorLogService';

export function FormatErrorMessage(e, defaultMessage) {
	const globalState = window.store.getState();
	let message = null;
	if (e instanceof AggregateError) {
		message = '';
		for (const error of e.errors) {
			message += `${SystemTranslations.translateMessage(
				LocaleHelper.formatMessage(globalState, ...error)
			)}\n`;
		}
		throw new Error(message);
	} else if (e.message && Array.isArray(e.message)) {
		message = SystemTranslations.translateMessage(
			LocaleHelper.formatMessage(globalState, ...e.message)
		);
	} else if (e.message) {
		message = LocaleHelper.formatMessage(globalState, e.message);
	} else {
		message = LocaleHelper.formatMessage(globalState, defaultMessage);
	}
	return message;
}

export function FormatAggregateErrorMessageNoThrow(e) {
	const globalState = window.store.getState();
	const message = [];
	if (e instanceof AggregateError) {
		for (const error of e.errors) {
			message.push(
				`${SystemTranslations.translateMessage(
					LocaleHelper.formatMessage(globalState, ...error)
				)}`
			);
		}
	}
	return message;
}

const componentErrorDetailReporter = {};

function formatHeaderValue(value) {
	return value
		.replace(new RegExp(/\n/, 'g'), ' ')
		.replace(new RegExp(/\r/, 'g'), ' ');
}

function getCurrentComponent() {
	const parts = document.location.pathname.split('/');
	return parts.length > 1 ? parts[1] : parts[0];
}

export function isReportErrorDetailEnabled() {
	return process.env.ENABLE_ERROR_REPORT;
}

export function RegisterComponentErrorDetailReporter(component, callback) {
	componentErrorDetailReporter[component] = callback;
}

export function reportErrorDetails(error, message, callback) {
	try {
		if (!isReportErrorDetailEnabled()) {
			callback();
			return;
		}
		const errorReq = new XMLHttpRequest();
		errorReq.open('GET', '/error');
		errorReq.addEventListener('loadend', () => callback());
		errorReq.setRequestHeader(
			'error-details-location',
			document.location.pathname
		);

		if (error) {
			errorReq.setRequestHeader('error-details-message', error.message);
			if (error.stack) {
				try {
					errorReq.setRequestHeader(
						'error-details-stack',
						formatHeaderValue(error.stack.substr(0, 600))
					);
				} catch (e) {
					// catch
				}
			}
		} else {
			errorReq.setRequestHeader(
				'error-details-message',
				message ||
					LocaleHelper.formatMessage(
						window.store.getState(),
						'NO_ERROR_MESSAGE'
					)
			);
			errorReq.setRequestHeader(
				'error-details-stack',
				LocaleHelper.formatMessage(window.store.getState(), 'NO_ERROR_STACK')
			);
		}

		try {
			const reporter = componentErrorDetailReporter[getCurrentComponent()];
			if (reporter) {
				errorReq.setRequestHeader(
					`error-details-report-${getCurrentComponent()}`,
					formatHeaderValue(reporter())
				);
			}
		} catch (e) {
			// catch
		}
		errorReq.setRequestHeader(
			'Model',
			store.getState().authentication.database
		);
		errorReq.setRequestHeader(
			'error-details-recent-actions',
			ErrorLogService.getRecentActions().join(' ')
		);
		errorReq.setRequestHeader(
			'error-details-recent-request-ids',
			ErrorLogService.getRecentRequestIds().join(' ')
		);
		errorReq.send();
	} catch (e) {
		// If we get in here, it means the error is not logged by our Splunk dashboard
		callback();
	}
}
