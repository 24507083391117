/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import * as ModalActionTypes from '../constants/modal/modalActionTypes';

export function modal(
	state = {
		modalName: '',
		modalWindowName: '',
		modalOptionName: '',
		deletingItem: {},
		modalMessage: '',
		modalAction: '',
		isExpanded: false,
		modalContextProps: {},
	},
	action
) {
	const { type, payload } = action;

	switch (type) {
		case ModalActionTypes.MODAL_OPEN:
			if (!state.modalName) {
				return {
					modalName: payload.modalName,
					modalContextProps: payload.modalContextProps || {},
					isExpanded: false,
				};
			}
			return state;

		case ModalActionTypes.MODAL_OPEN_DELETE_CONFIRMATION:
			if (!state.modalName) {
				return {
					modalName: payload.modalName,
					modalWindowName: payload.name,
					modalOptionName: payload.optionName,
					deletingItem: payload.data,
					modalMessage: payload.message,
					modalAction: payload.action,
				};
			}
			return state;

		case ModalActionTypes.MODAL_CLOSE:
			if (state.modalName === payload.modalName) {
				return {
					modalName: '',
					modalWindowName: '',
					modalOptionName: '',
					deletingItem: {},
					modalMessage: '',
					modalAction: '',
					isExpanded: false,
					modalContextProps: {},
				};
			}
			return state;

		case ModalActionTypes.MODAL_TOGGLE_EXPAND:
			if (state.modalName === payload.modalName) {
				return {
					...state,
					isExpanded: !state.isExpanded,
				};
			}
			return state;

		default:
			return state;
	}
}
