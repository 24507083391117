/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { Link } from 'react-router';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PermissionCheck from 'components/common/permissions/permissionCheck';
import { useIsFeatureEnabled } from 'utils/featureUtils';
import FeatureFlagCheck from 'components/common/permissions/featureFlagCheck';
import type { IGlobalStateShape } from 'reducers';
import { useIntl } from 'icm-core/lib/contexts/intlContext';
import FeatureFlags from 'constants/featureFlags';
import styled, { css } from 'react-emotion';
import { useSpring, animated } from 'react-spring/web.cjs';
import {
	text,
	intentPrimary,
	intentPrimaryTranslucentHover,
	intentPrimaryTranslucentActive,
	colorLightGray4,
	Icon,
} from '@varicent/components';
import { SettingsAdjust32 } from '@carbon/icons-react';
import SecondaryNavConfigs from 'components/common/secondaryNavConfigs';
import { Drawer, Position, Toaster, Button } from '@blueprintjs/core';
import { getSidebarCustomization } from 'icm-rest-client/lib/controllers/model';
import styleVars from 'styles/config/default';
import { Varicent } from 'icm-rest-client';
import { useRouter } from 'utils/routeUtils';

import 'styles/icons/icm-icons.css';

const CLOSED_WIDTH = 48;
const OPEN_WIDTH = 240;

const TopRightToaster = Toaster.create({
	position: Position.TOP_RIGHT,
});

const OldIcon = styled.i`
	font-size: 1.25rem;
`;

const NewIcon = styled(Icon)`
	width: 1.25rem !important;
	height: 1.25rem !important;
`;

const NavWrapper = styled(animated.nav)`
	position: absolute;
	z-index: 15; // above 10/11 used for toolbar layouts with slide out, but under drawers and dialogs (20)
	top: 0;
	left: 0;
	display: flex;
	overflow: hidden;
	flex-direction: column;
	height: 100%;
	will-change: width;
	background-color: white;
	transition: z-index 500ms 1500ms; // has to be outside useSpring due to timing issues

	&:hover {
		z-index: 2000000000; // super high z-index value to ensure above almost everything when hovered (max is 2147483647)
	}

	> ul {
		position: relative;
		flex: 1 1 auto;
		overflow-y: auto;
		overflow-x: hidden;

		/* visually hide scrollbars */
		scrollbar-width: none; /* Firefox 64+ */
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		/* WebKit */
		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		&::before,
		&::after {
			content: '';
			position: sticky;
			top: 0;
			z-index: 0;
			display: block;
			width: 100%;
			height: 0.25rem;
			margin-top: -0.25rem;
			background-image: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 0.06)
			);
		}
		&::after {
			top: auto;
			bottom: 0;
			margin-top: 0;
			background-image: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.06),
				rgba(0, 0, 0, 0)
			);
		}

		li:first-child,
		li:last-child {
			position: relative;
			z-index: 1;
			&::after {
				content: '';
				position: absolute;
				top: 0;
				width: calc(100% - 1px);
				height: 0.25rem;
				background-color: white;
			}
		}

		li:last-child {
			&::after {
				top: 100%;
			}
		}
	}
`;

const NavItem = styled.li<{ selected: boolean }>`
	position: relative;
	display: flex;
	white-space: normal;
	position: relative;
	padding: 0;
	color: ${text};
	font-size: 0.875rem;
	cursor: auto;
	&::before {
		content: '';
		position: absolute;
		top: 0.5rem;
		left: 0.5rem;
		width: calc(100% - 1rem);
		min-width: 2rem;
		height: calc(100% - 1rem);
		background-color: ${({ selected }) =>
			selected
				? `${intentPrimaryTranslucentActive} !important`
				: 'transparent'};
		border-radius: 0.25rem;
	}
	&:hover {
		color: rgb(${intentPrimary});
		background-color: transparent; // bootstrap override
		cursor: pointer;
		&::before {
			background-color: ${intentPrimaryTranslucentHover};
		}
	}

	a:focus {
		color: inherit;
	}

	${({ selected }) =>
		selected
			? `
				border-right: solid 2px ${intentPrimary};
				color: ${intentPrimary};
			`
			: ''};

	.bp3-button:not([class*='bp3-intent-']):active,
	.bp3-button:not([class*='bp3-intent-']).bp3-active {
		background-color: transparent !important;
	}
`;

const NavItemAsBlock = NavItem.withComponent('div');

const MenuLink = styled(Link)`
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	height: 3rem;
	padding: 0.5rem 0.875rem;
	min-width: ${OPEN_WIDTH}px;
	color: inherit;
	> :first-child {
		margin-right: 1rem;
	}
	&:hover,
	&:active {
		color: ${intentPrimary};
	}
`;

const MenuButton = styled(Button)`
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	height: 3rem;
	padding: 0.5rem 0.875rem;
	min-width: ${OPEN_WIDTH}px;
	justify-content: flex-start;
	.bp3-button-text {
		> :first-child {
			margin-right: 1rem;
		}
	}
	&:hover,
	&:active {
		background-color: transparent !important;
	}

	svg {
		width: 100% !important;
		height: 100% !important;
	}
`;

function isNavSelected(
	elem: {
		link: string;
		childLinks?: string[];
	},
	pathname: string
) {
	const basePath = pathname.split('/')[1];
	return !!(
		elem.link.split('/')[1] === basePath ||
		(elem.childLinks &&
			elem.childLinks.find((childLink) => childLink.split('/')[1] === basePath))
	);
}

export default function SidebarContainer() {
	const intl = useIntl();
	const { location } = useRouter();
	const linksWithNewPulse = useSelector(
		(state: IGlobalStateShape) => state.sidebar.linksWithNewPulse
	);
	const linksWithOldPulse = useSelector(
		(state: IGlobalStateShape) => state.sidebar.linksWithOldPulse
	);
	const useNewPulseAnalytics = useIsFeatureEnabled(
		FeatureFlags.isPayeeWebV2Enabled
	);
	const [isOpen, setOpen] = useState(false);
	const { width, opacity, boxShadow } = useSpring({
		from: {
			width: CLOSED_WIDTH,
			opacity: 0,
			boxShadow: `inset -1px 0 rgb(${colorLightGray4}), 0 0 0.5rem rgba(0, 0, 0, 0), 0 0 0.25rem rgba(0, 0, 0, 0)`,
		},
		to: {
			width: isOpen ? OPEN_WIDTH : CLOSED_WIDTH,
			opacity: isOpen ? 1 : 0,
			boxShadow: isOpen
				? `inset -1px 0 rgb(${colorLightGray4}), 0 0 0.5rem rgba(0, 0, 0, 0.1), 0 0 0.25rem rgba(0, 0, 0, 0.06)`
				: `inset -1px 0 rgb(${colorLightGray4}), 0 0 0.5rem rgba(0, 0, 0, 0), 0 0 0.25rem rgba(0, 0, 0, 0)`,
		},
		config: {
			// to match timing and feel of the Blueprint drawers
			mass: 0.7, // default: 1
			tension: 220, // default: 170
			friction: 15, // default: 26
			clamp: true, // default: false
		},
		delay: isOpen ? 1500 : 300,
	}) as {
		width: number;
		opacity: number;
		boxShadow: string;
	};

	const [configDrawerIsOpen, setConfigDrawerIsOpen] = useState(false);
	const [visibleLinksMapped, setVisibleLinksMapped] = useState<
		| IGlobalStateShape['sidebar']['linksWithNewPulse']
		| IGlobalStateShape['sidebar']['linksWithOldPulse']
	>();

	const visibleLinks = useNewPulseAnalytics
		? linksWithNewPulse
		: linksWithOldPulse;

	const mapVisibleLinks = (
		newVisibleLinks: Varicent.RESTAPI.v1.DTOs.AdminCustomization.AdminSidebarItemDTO[]
	) => {
		const visibleLinksSortedAndFiltered:
			| IGlobalStateShape['sidebar']['linksWithNewPulse']
			| IGlobalStateShape['sidebar']['linksWithOldPulse'] = [];
		newVisibleLinks.forEach((nav) => {
			const navData = visibleLinks.find((link) => link.key === nav.tabKey);
			if (navData)
				visibleLinksSortedAndFiltered.push({
					isEnabled: nav.show,
					...navData,
				});
		});
		/*
		 * Add any sidebar items that don't rely on a key
		 * useful for adding new sections before the backend is
		 * ready
		 */
		for (const possibleLink of visibleLinks) {
			if (possibleLink.key === undefined) {
				visibleLinksSortedAndFiltered.push({
					isEnabled: true,
					...possibleLink,
				} as any);
			}
		}
		return visibleLinksSortedAndFiltered;
	};

	useEffect(() => {
		getSidebarCustomization()
			.then(
				(
					res: Varicent.RESTAPI.v1.DTOs.AdminCustomization.AdminSidebarItemDTO[]
				) => {
					setVisibleLinksMapped(mapVisibleLinks(res));
				}
			)
			.catch((err) => {
				const errorMessage = err.response.data.Message;
				TopRightToaster.show({
					className: 'bp3-intent-danger',
					message:
						errorMessage ||
						intl.formatMessage({ id: 'ERROR_FETCHING_NAV_CONFIGS' }),
				});
				setVisibleLinksMapped(visibleLinks);
			});
	}, []);

	return (
		<>
			<Drawer
				className={css`
					margin-top: ${styleVars.HeaderBarHeight};
					box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1),
						0 0 0.25rem rgba(0, 0, 0, 0.12);
				`}
				hasBackdrop={false}
				title={intl.formatMessage({ id: 'CONFIGURE_SECONDARY_NAV' })}
				isOpen={configDrawerIsOpen}
				onClose={() => {
					setConfigDrawerIsOpen(false);
				}}
			>
				{visibleLinksMapped && (
					<SecondaryNavConfigs
						visibleLinks={visibleLinksMapped}
						setConfigDrawerIsOpen={setConfigDrawerIsOpen}
						setVisibleLinksMapped={setVisibleLinksMapped}
						mapVisibleLinks={mapVisibleLinks}
					/>
				)}
			</Drawer>
			<NavWrapper
				className="v-side-nav"
				aria-label={intl.formatMessage({ id: 'NAVIGATION_LABEL_SIDE' })}
				onMouseOver={() => setOpen(true)}
				onMouseLeave={() => setOpen(false)}
				style={{ minWidth: width, maxWidth: width, boxShadow }}
			>
				{visibleLinksMapped && (
					<ul>
						{visibleLinksMapped
							.filter((item) => item.isEnabled)
							.map((item) => (
								<PermissionCheck permission={item.permission} key={item.text}>
									<FeatureFlagCheck
										feature={item.feature}
										invertFeatureFlag={item.hideWhenFeatureEnabled ?? false}
									>
										<NavItem selected={isNavSelected(item, location.pathname)}>
											<MenuLink
												to={item.link}
												title={intl.formatMessage({ id: item.text })}
											>
												{item.icon ? (
													<OldIcon className={item.icon} />
												) : item.Icon ? (
													<NewIcon>
														<item.Icon
															height={20}
															width={20}
															data-test={`${item.text}-menu-item`}
														/>
													</NewIcon>
												) : null}
												<animated.span
													style={{ opacity }}
													className={css`
														white-space: nowrap;
														overflow: hidden;
														text-overflow: ellipsis;
													`}
												>
													{intl.formatMessage({ id: item.text })}
												</animated.span>
											</MenuLink>
										</NavItem>
									</FeatureFlagCheck>
								</PermissionCheck>
							))}
					</ul>
				)}
				<NavItemAsBlock
					selected={configDrawerIsOpen}
					// push item up to prevent being blocked by url on bottom left of page
					className={css`
						margin-bottom: 1rem;
					`}
				>
					<MenuButton
						onClick={() => setConfigDrawerIsOpen(!configDrawerIsOpen)}
						minimal
						icon={
							<NewIcon>
								<SettingsAdjust32 />
							</NewIcon>
						}
					>
						<animated.span style={{ opacity }}>
							{intl.formatMessage({ id: 'CONFIGURE_SECONDARY_NAV' })}
						</animated.span>
					</MenuButton>
				</NavItemAsBlock>
			</NavWrapper>
		</>
	);
}
