/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { modelThemeRecieved } from 'actions/modelThemeActions';
import { createReducer } from 'redux-act';
import { Varicent } from 'icm-rest-client';

const defaultState: {
	[key: string]: Varicent.RESTAPI.v1.DTOs.ConfigValueDTO;
} = {};

const reducer = createReducer<Readonly<typeof defaultState>>({}, defaultState);

reducer.on(modelThemeRecieved, (state, payload) => ({
	...state,
	...payload,
}));

export default reducer;
