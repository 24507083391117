/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { colorCobalt3 } from '@varicent/components';
import React, {
	useCallback,
	forwardRef,
	createContext,
	useMemo,
	useContext,
	useEffect,
	useState,
} from 'react';
import styled from 'react-emotion';
import {
	LinkProps,
	WithRouterProps,
	withRouter,
	Link as RRLink,
} from 'react-router';
import { RouteHook } from 'react-router/lib/Router';

function isModifiedEvent(event) {
	return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

function resolveToLocation(
	to: LinkProps['to'],
	location: WithRouterProps['location']
) {
	return typeof to === 'function' ? to(location) : to;
}

type MakeCustomLinkType = <P extends object = {}>(
	Component: React.ComponentType<P>,
	options?: {
		refPropName?: string;
		additionalProps?: Partial<P>;
	}
) => React.ComponentType<P & LinkProps>;

/**
 * Use this helper to turn any component which accepts an href and a onClick into
 * a react-router compatible element. Also note that the custom element
 * SHOULD render an anchor tag as well. This only works with react-router v2, newer
 * versions of react-router have better solutions than this.
 *
 * @param Component Component to modify
 * @param innerRefProp Name of the prop to associate any provided ref. By default it's just ref.
 * @param additionalProps Some additional props to pass to component. Useful if your component doesn't usually render an anchor element.
 */
export const makeCustomLink: MakeCustomLinkType = (Component, options) =>
	withRouter(
		forwardRef(
			(
				{
					router,
					location,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					params,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					routes,
					to,
					onClick,
					...props
				}: WithRouterProps & LinkProps,
				ref
			) => {
				const C = Component as any;
				const optionsWithDefaults = {
					refPropName: 'ref',
					additionalProps: {},
					...options,
				};
				// eslint-disable-next-line react-hooks/rules-of-hooks
				const actualOnClick = useCallback(
					(event) => {
						try {
							if (onClick) onClick(event);
						} catch (ex) {
							event.preventDefault();
							throw ex;
						}
						if (
							!event.defaultPrevented && // onClick prevented default
							event.button === 0 && // ignore everything but left clicks
							(!props.target || props.target === '_self') && // let browser handle "target=_blank" etc.
							!isModifiedEvent(event) // ignore clicks with modifier keys
						) {
							event.preventDefault();
							router.push(resolveToLocation(to, location));
						}
					},
					[to, router, location, props.target, onClick]
				);
				const href = router.createHref(to as any);
				const allOtherProps = {
					[optionsWithDefaults.refPropName]: ref,
					...optionsWithDefaults.additionalProps,
					...props,
				};
				return <C onClick={actualOnClick} href={href} {...allOtherProps} />;
			}
		)
	) as any;

const routerContext = createContext<WithRouterProps>(null as any);
type ConfirmationDialog =
	| {
			nextLocation: Parameters<RouteHook>[0];
			message: string;
	  }
	| undefined;

const confirmationDialogContext = createContext<{
	confirmationDialog: ConfirmationDialog;
	setConfirmationDialog: React.Dispatch<
		React.SetStateAction<ConfirmationDialog>
	>;
	resumeRouteChange: () => void;
}>({
	confirmationDialog: undefined,
	setConfirmationDialog: () => {},
	resumeRouteChange: () => {},
});

const ProviderRender: React.FC<WithRouterProps> = ({
	children,
	location,
	params,
	router,
	routes,
}) => {
	const [confirmationDialog, setConfirmationDialog] =
		useState<ConfirmationDialog>();
	const contextValue = useMemo(() => {
		return {
			location,
			params,
			router,
			routes,
		};
	}, [location, params, router, routes]);
	const confirmationContextValue = useMemo(() => {
		return {
			setConfirmationDialog,
			confirmationDialog,
			resumeRouteChange: () => {
				if (confirmationDialog?.nextLocation) {
					router.push(confirmationDialog.nextLocation);
				}
			},
		};
	}, [confirmationDialog, router]);
	return (
		<routerContext.Provider value={contextValue}>
			<confirmationDialogContext.Provider value={confirmationContextValue}>
				{children}
			</confirmationDialogContext.Provider>
		</routerContext.Provider>
	);
};

export const RouterProvider = withRouter(ProviderRender);

export const useRouter = () => {
	return useContext(routerContext);
};

export const useRouteBlockingDialog = () => {
	const { confirmationDialog, resumeRouteChange, setConfirmationDialog } =
		useContext(confirmationDialogContext);
	return {
		confirmationDialog,
		resumeRouteChange,
		cancelRouteChange: () => setConfirmationDialog(undefined),
	};
};

/**
 * Sets up a hook for leaving the current route.
 *
 * @param handler the function must return a string that will be displayed by the browser if they want to prevent routing.
 */
export const usePreventLeave = (
	handler: (location: Parameters<RouteHook>[0]) => string | undefined
) => {
	const { router, routes } = useRouter();
	const { setConfirmationDialog, confirmationDialog } = useContext(
		confirmationDialogContext
	);
	const unloadHandler = useCallback(
		(event: BeforeUnloadEvent) => {
			const result = handler(undefined);
			if (result) {
				event.returnValue = result;
				event.preventDefault();
			}
		},
		[handler]
	);
	useEffect(() => {
		// upgrading react-router should fix this typescript issue
		const dispose: () => void = router.setRouteLeaveHook(
			routes[routes.length - 1],
			(location) => {
				if (confirmationDialog === undefined) {
					const result = handler(location);
					if (result) {
						setConfirmationDialog({
							message: result,
							nextLocation: location,
						});
						return false;
					}
				}
				setConfirmationDialog(undefined);
			}
		) as any;
		window.addEventListener('beforeunload', unloadHandler);

		return () => {
			window.removeEventListener('beforeunload', unloadHandler);
			dispose();
		};
	}, [
		handler,
		confirmationDialog,
		routes,
		router,
		setConfirmationDialog,
		unloadHandler,
	]);
};

export const FakeLink = styled.button`
	text-decoration: underline;
	color: rgba(${colorCobalt3});
	line-height: 20px;
	font-size: 14px;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
`;
/**
 * Use this helper to create Link components that handle refs properly
 * Link element from react router does not properly forward the ref to support manual focus.
 */

const fixForwardRef: <T extends React.ComponentType<any>>(
	component: T,
	refName: string
) => T = (Component, refName) => {
	return React.forwardRef<any, any>((props, ref) => {
		const newProps = {
			...props,
			[refName]: ref,
		};
		return <Component {...newProps} />;
	}) as any;
};

export const ForwardRefLink = fixForwardRef(RRLink, 'innerRef');
