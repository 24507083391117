/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Sort from 'models/sort';
import APIPollingHelper from 'helpers/APIPollingHelper';

import * as MigrationsAPIs from 'icm-rest-client/lib/controllers/migrations';
import * as CalendarsAPIs from 'icm-rest-client/lib/controllers/calendars';
import * as ComponentsAPIs from 'icm-rest-client/lib/controllers/components';
import * as RemoteImportsAPIs from 'icm-rest-client/lib/controllers/remoteImports';
import * as PortalAccessTreesAPIs from 'icm-rest-client/lib/controllers/portalAccessTrees';
import * as WebTabsAPIs from 'icm-rest-client/lib/controllers/webTabs';
import * as WebFormsAPIs from 'icm-rest-client/lib/controllers/webForms';
import * as ProcessListsAPIs from 'icm-rest-client/lib/controllers/processLists';
import * as BPMAPIs from 'icm-rest-client/lib/controllers/bPM';
import * as PulsePlansAPIs from 'icm-rest-client/lib/controllers/pulsePlans';
import * as UsersAPIs from 'icm-rest-client/lib/controllers/users';
import * as LiveActivitiesAPIs from 'icm-rest-client/lib/controllers/liveActivities';
import * as HierarchiesAPI from 'icm-rest-client/lib/controllers/hierarchies';
import * as RemoteMigrationAPI from 'icm-rest-client/lib/controllers/remoteMigrations';
import * as PresenterFlexAPI from 'icm-rest-client/lib/controllers/presenterFlex';
import * as AdaptiveFormsAPI from 'icm-rest-client/lib/controllers/adaptiveForms';
import { defaultRESTAPIErrorHandler } from 'services/httpService';
import { Varicent } from 'icm-rest-client';
import { statusPage } from 'actions/authActions';
import axios from 'axios';
import type { RequestOptions } from 'icm-rest-client/lib/utils/baseRequest';

const axiosCancelToken = axios.CancelToken;

const WEBFORM_FILTERS_MAX_LIMIT = 100000;
const POLLING_REQUEST_TIMEOUT = 30000; // 30 seconds

function prepareQuery(sort, limit?) {
	const sortQuery = sort ? Sort.makeOrderByQuery([sort]) : {};
	const query = {
		...sortQuery,
		limit,
	};
	return query;
}

class TimeoutError extends Error {
	constructor(message) {
		super(message);
		this.name = 'Timeout Error';
	}
}

export default class MigrationService {
	static getComposerComponents(modelName, sort, options?) {
		return ComponentsAPIs.getAll(prepareQuery(sort), {
			headers: { Model: modelName },
			...options,
		});
	}

	static getImports(modelName, sort, options?) {
		return RemoteImportsAPIs.getAll(prepareQuery(sort), {
			headers: { Model: modelName },
			...options,
		});
	}

	static getPortalAccessTrees(modelName, sort, options?) {
		return PortalAccessTreesAPIs.getAllTrees(prepareQuery(sort), {
			headers: { Model: modelName },
			...options,
		});
	}

	static getWebTabs(modelName, sort, options?) {
		return WebTabsAPIs.getAll(prepareQuery(sort), {
			headers: { Model: modelName },
			...options,
		});
	}

	static getWebFormsFilters(modelName, sort, options?) {
		return WebFormsAPIs.getAllFilters(
			prepareQuery(sort, WEBFORM_FILTERS_MAX_LIMIT),
			{ headers: { Model: modelName }, ...options }
		).then(({ data }) => data);
	}

	static getCalendars(modelName, options?) {
		return CalendarsAPIs.getAll({ headers: { Model: modelName }, ...options });
	}

	static getDestinationCalendars() {
		return MigrationsAPIs.getAllCalendars();
	}

	static getDestinationPayeeGroups() {
		return MigrationsAPIs.getAllPayeeGroups();
	}

	static getProcessLists(modelName, sort, options?) {
		return ProcessListsAPIs.getAll(prepareQuery(sort), {
			headers: { Model: modelName },
			...options,
		});
	}

	static getBPMWorkflows(modelName, sort, options?) {
		return BPMAPIs.getAllWorkflows(prepareQuery(sort), {
			headers: { Model: modelName },
			...options,
		});
	}

	static getPulsePlans(modelName, sort) {
		return PulsePlansAPIs.getAll(prepareQuery(sort), {
			headers: { Model: modelName },
		});
	}

	static getRoles(modelName, options?) {
		return UsersAPIs.getAllRoles({ headers: { Model: modelName }, ...options });
	}

	static getHierarchies(modelName, options?) {
		return HierarchiesAPI.getAll({ headers: { Model: modelName }, ...options });
	}

	static getPresenterFlexReports(modelName, options?) {
		return PresenterFlexAPI.getAll({
			headers: { Model: modelName },
			...options,
		});
	}

	static getRemoteMigrations() {
		return RemoteMigrationAPI.getAll({});
	}

	static deleteRemoteMigration(id) {
		return RemoteMigrationAPI.doDelete(id);
	}

	static createRemoteMigration(
		remoteMigration: Varicent.RESTAPI.v1.DTOs.Migration.RemoteMigrationDTO
	) {
		return RemoteMigrationAPI.create(remoteMigration);
	}

	static getRemoteTSUrl() {
		return RemoteMigrationAPI.getUrl();
	}

	static getAdaptiveForms(modelName, options?) {
		return AdaptiveFormsAPI.getAll({
			headers: { Model: modelName },
			...options,
		});
	}

	static postDependencies(
		selectedDependencies: Varicent.RESTAPI.v1.DTOs.Migration.MigratableItemsDTO,
		modelName: any,
		options?
	) {
		return MigrationsAPIs.retrieveMigrationDependencies(selectedDependencies, {
			headers: { Model: modelName },
			...options,
		});
	}

	static postRequiredMapping(
		requestBody: Varicent.RESTAPI.v1.DTOs.Migration.MigrationRequiredMappingsQueryDTO
	) {
		return MigrationsAPIs.retrieveMigrationRequiredSourceTableMappings(
			requestBody
		);
	}

	static postComparisonData(
		requestBody: Varicent.RESTAPI.v1.DTOs.Migration.MigrationComparisonDataQueryDTO
	) {
		return MigrationsAPIs.retrieveMigrationComparisonData(requestBody);
	}

	static postRequiredMappingAndComparisonData(
		requestBody: Varicent.RESTAPI.v1.DTOs.Migration.MigrationComparisonDataQueryDTO,
		requestOptions?: RequestOptions
	) {
		return MigrationsAPIs.retrieveMigrationMappingsAndComparisonData(
			requestBody,
			requestOptions
		);
	}

	static migrate(
		requestBody: Varicent.RESTAPI.v1.DTOs.Migration.RunMigrationQueryDTO
	) {
		return MigrationsAPIs.migrate(requestBody);
	}

	static logoutDuringMigration() {
		APIPollingHelper.disableAndUnregisterAPIPollTimerByName(
			APIPollingHelper.migrationLiveActivityPollName
		);
		window.store.dispatch(statusPage(false));
	}

	static getMigrationProgress() {
		const cancelTokenSource = axiosCancelToken.source();
		return Promise.race([
			LiveActivitiesAPIs.getAll(undefined, {
				preventDefaultRESTAPIError: true,
				cancelToken: cancelTokenSource.token,
			}),
			/*
			 * SPM-70808: Migration status not updating when a getActivities request
			 * stalls in IE11. Manually time out and cancel the request.
			 */
			new Promise((_resolve, reject) => {
				setTimeout(() => {
					reject(new TimeoutError('Migration get status timed out'));
				}, POLLING_REQUEST_TIMEOUT);
			}),
		]).then(
			(response) => {
				APIPollingHelper.disableAndUnregisterAPIPollTimerByName(
					APIPollingHelper.migrationLiveActivityPollName
				);
				window.store.actions.migrationActions.migrationComplete();
				window.store.actions.permissionsActions.requestPermissionsReload();
				window.store.actions.authActions.statusPage(false);
				return response;
			},
			(error) => {
				if (error instanceof TimeoutError) {
					cancelTokenSource.cancel();
					return Promise.resolve(error.message);
				}
				const { response } = error;
				// sometimes api does not have status reason, use only the status code here
				if (response.status === 503) {
					window.store.actions.migrationActions.reportMigrationProgress(
						response.data
					);
					return Promise.resolve(response.data);
				} else {
					APIPollingHelper.disableAndUnregisterAPIPollTimerByName(
						APIPollingHelper.migrationLiveActivityPollName
					);
					window.store.actions.migrationActions.migrationComplete();
					window.store.actions.authActions.statusPage(false);
					return defaultRESTAPIErrorHandler(error);
				}
			}
		);
	}
}
