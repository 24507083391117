/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { put, throttle, select, delay } from 'redux-saga/effects';
import { isJWTNotExpired } from 'helpers/jwtHelper';
import {
	getNextTimeOfTokenRenewal,
	updateNextTimeOfTokenRenewal,
} from 'helpers/authHelper';
import { ALLOWABLE_ACTIONS } from 'constants/middlewareActionTypes';
import { userRenewJWT } from 'actions/authActions';
import R from 'ramda';
import { IGlobalStateShape } from 'reducers';
import { Action } from 'redux';
/**
 * Checks if we should process the action
 * @param {*} action Current Action
 * @return {bool} returns true if we should check timeout on this action
 */
function checkAction(action: Action) {
	if (ALLOWABLE_ACTIONS.includes(action.type)) {
		return false;
	}
	return true;
}

/**
 * get new JWT if not expired and enough time has passed
 */
function* renewToken(auth: any, _action: Action) {
	if (getNextTimeOfTokenRenewal()) {
		if (isJWTNotExpired()) {
			yield put(userRenewJWT(auth.jwtRenewMinutesInterval));
		}
	} else {
		updateNextTimeOfTokenRenewal(auth.jwtRenewMinutesInterval);
	}
}

export default function* checkRenewToken() {
	// delay one minute to allow state to fully populate
	yield delay(60000);
	const auth = yield select((state: IGlobalStateShape) => state.authentication);
	if (auth.isLocal || R.isNil(auth.tenantID)) {
		// dont check if running local db
	} else {
		yield throttle(60000, checkAction, renewToken, auth);
	}
}
