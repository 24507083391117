/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const AdminWebBannerNotifiedTypes = {
	Release: 'notifiedRelease_adminweb',
	Maintenance: 'notifiedMaintenance_adminweb',
	Custom: 'notifiedCustom_adminweb',
};

export const MAX_CUSTOM_BANNER_ALLOWED = 3;
