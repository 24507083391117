/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/*
 * Spinner Types
 * spinner ids
 */
export const COMPOSER_GRAPH = 'COMPOSER_GRAPH';
export const COMPOSER_USES_USEDBY = 'COMPOSER_USES_USEDBY';
export const COMPOSER_API = 'COMPOSER_API';
export const COMPOSER_WIZARD = 'COMPOSER_WIZARD';
export const CALCWIZARD_PREVIEW = 'CALCWIZARD_PREVIEW';
export const CALENDAR_SAVING_SPINNER = 'CALENDAR_SAVING_SPINNER';
export const DATASTORE_PREVIEW = 'DATASTORE_PREVIEW';
export const DATASTORE_WIZARD_SPINNER = 'DATASTORE_WIZARD_SPINNER';
export const WEB_REPORT_LOADING_SPINNER = 'WEB_REPORT_LOADING_SPINNER';
export const WEB_REPORT_SAVING_SPINNER = 'WEB_REPORT_SAVING_SPINNER';
export const WEB_REPORT_GET_DATAGRID_SPIINER =
	'WEB_REPORT_GET_DATAGRID_SPIINER';
export const WEB_REPORT_REAPPLY_DATAGRID_SPIINER =
	'WEB_REPORT_REAPPLY_DATAGRID_SPIINER';
export const WEB_REPORT_SOURCE_PREVIEW_SPINNER =
	'WEB_REPORT_SOURCE_PREVIEW_SPINNER';
export const WEB_FORM_LOADING_SPINNER = 'WEB_FORM_LOADING_SPINNER';
export const PORTAL_ACCESS_REQUEST = 'PORTAL_ACCESS_REQUEST';
export const PORTAL_CONTAINER_SPINNER = 'PORTAL_CONTAINER_SPINNER';
export const SCENARIOS_WIZARD = 'SCENARIOS_WIZARD';
export const SCENARIOS_GET_ITEM_SPINNER = 'SCENARIOS_GET_ITEM_SPINNER';
export const SCENARIOS_COMPLETE_ITEM_DATA_SOURCES_SPINNER =
	'SCENARIOS_COMPLETE_ITEM_DATA_SOURCES_SPINNER';
export const SCENARIOS_GET_WORKSPACE_SPINNER =
	'SCENARIOS_GET_WORKSPACE_SPINNER';
export const SCENARIOS_TAB_ADD_CHANGE_SPINNER =
	'SCENARIOS_TAB_ADD_CHANGE_SPINNER';
export const SCENARIOS_ADD_TRANSFORM_SPINNER =
	'SCENARIOS_ADD_TRANSFORM_SPINNER';
export const ROWVIEWER_LOADING_SPINNER = 'ROWVIEWER_LOADING_SPINNER';
export const ROWVIEWER_LOAD_CALENDARS = 'ROWVIEWER_LOAD_CALENDARS';
export const ROWVIEWER_LOAD_INPUTFORMS = 'ROWVIEWER_LOAD_INPUTFORMS';
export const ROWVIEWER_LOAD_DEPENDENTS = 'ROWVIEWER_LOAD_DEPENDENTS';
export const ROWVIEWER_LOAD_ALLROWS = 'ROWVIEWER_LOAD_ALLROWS';
export const MANAGE_WEB_ACCESS_WIZARD_OPENING =
	'MANAGE_WEB_ACCESS_WIZARD_OPENING';
export const MANAGE_WEB_ACCESS_WIZARD_SPINNER =
	'MANAGE_WEB_ACCESS_WIZARD_SPINNER';
export const WORKFLOW_ADD_COLUMN = 'WORKFLOW_ADD_COLUMN';
export const WORKFLOW_DELETE_COLUMN = 'WORKFLOW_DELETE_COLUMN';
export const WORKFLOW_LOAD_SPINNER = 'WORKFLOW_LOAD_SPINNER';
export const WORKFLOW_START_SPINNER = 'WORKFLOW_START_SPINNER';
export const WORKFLOW_END_SPINNER = 'WORKFLOW_END_SPINNER';
export const WORKFLOW_UPLOAD_BPM_SPINNER = 'WORKFLOW_UPLOAD_BPM_SPINNER';
export const WORKFLOW_MOVE_UPDATE = 'WORKFLOW_MOVE_UPDATE';
export const WORKFLOW_ADD_FOLDER = 'WORKFLOW_ADD_FOLDER';
export const WORKFLOW_WIZARD_SPINNER = 'WORKFLOW_WIZARD_SPINNER';
export const ROWVIEWER_DELETE_LOADING = 'ROWVIEWER_DELETE_LOADING';
export const FETCH_PAYEES = 'FETCH_PAYEES';
export const COMPONENT_FETCHING = 'COMPONENT_FETCHING';
export const PURGE_PERIODS = 'PURGE_PERIODS';
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
export const EXPORT_ROWS = 'EXPORT_ROWS';
export const FETCH_TRANSPOSE = 'FETCH_TRANSPOSE';
export const IMPORT_FETCH_HEADERS = 'IMPORT_FETCH_HEADERS';
export const IMPORT_LOAD_TABLES = 'IMPORT_LOAD_TABLES';
export const SCHEDULER_OPERATION = 'SCHEDULER_OPERATION';
export const SCHEDULER_SPINNER = 'SCHEDULER_SPINNER';
export const EXPORT_TENANT_LOG = 'EXPORT_TENANT_LOG';
export const TASK_MANAGER_SPINNER = 'TASK_MANAGER_SPINNER';
export const EXPORT_CATEGORIES_SPINNER = 'EXPORT_CATEGORIES_SPINNER';
export const IMPORT_CATEGORIES_SPINNER = 'IMPORT_CATEGORIES_SPINNER';
export const COMPOSER_COMPONENT_LOADED_SPINNER =
	'COMPOSER_COMPONENT_LOADED_SPINNER';
export const TABLE_ROW_VIEWER_REFRESH_SPINNER =
	'TABLE_ROW_VIEWER_REFRESH_SPINNER';
export const QUERY_TOOL_FETCH_QUERY = 'QUERY_TOOL_FETCH_QUERY';
export const WEB_FORM_VIEW_SOURCE_SPINNER = 'WEB_FORM_VIEW_SOURCE_SPINNER';
export const DATA_SOURCE_INIT_SPINNER = 'DATA_SOURCE_INIT_SPINNER';
export const DATA_SOURCE_LOAD_SPINNER = 'DATA_SOURCE_LOAD_SPINNER';
export const HIERARCHY_CONTAINER_SPINNER = 'HIERARCHY_CONTAINER_SPINNER';
export const MODEL_OPTION_SELECT_SPINNER = 'MODEL_OPTION_SELECT_SPINNER';
export const FILE_PREVIEW_SPINNER = 'FILE_PREVIEW_SPINNER';
export const MANAGE_ROLES_GET_PERMISSION_SPINNER =
	'MANAGE_ROLES_GET_PERMISSION_SPINNER';
export const MANAGE_ROLES_GRANT_ALL_SPINNER = 'MANAGE_ROLES_GRANT_ALL_SPINNER';
export const MANAGE_ROLES_DENY_ALL_SPINNER = 'MANAGE_ROLES_DENY_ALL_SPINNER';
export const MANAGE_USER_GET_PERMISSION_SPINNER =
	'MANAGE_USER_GET_PERMISSION_SPINNER';
export const PROCESS_LIST_ITEM_ASYNC_SPINNER =
	'PROCESS_LIST_ITEM_ASYNC_SPINNER';
export const PULSE_ANALYTICS_SERVICE_SPINNER =
	'PULSE_ANALYTICS_SERVICE_SPINNER';
export const MANAGE_PAYEE_TYPE_SPINNER = 'MANAGE_PAYEE_TYPE_SPINNER';
export const GET_FILE_NAMES_SPINNER = 'GET_FILE_NAMES_SPINNER';
export const EXTERNAL_TOOLS_SPINNER = 'EXTERNAL_TOOLS_SPINNER';
export const PAYEE_DOCUMENTS_MANAGER_SPINNER =
	'PAYEE_DOCUMENTS_MANAGER_SPINNER';
export const DATA_IMPORT_SPINNER = 'DATA_IMPORT_SPINNER';
export const ADMIN_OPTIONS_SPINNER = 'ADMIN_OPTIONS_SPINNER';
export const START_PUBLISH_SPINNER = 'START_PUBLISH_SPINNER';
export const MODEL_THEME_SPINNER = 'MODEL_THEME_SPINNER';
export const ADMIN_OPTIONS_THEME_UPDATE_SPINNER =
	'ADMIN_OPTIONS_THEME_UPDATE_SPINNER';
export const ADMIN_OPTIONS_UPDATE_SPINNER = 'ADMIN_OPTIONS_UPDATE_SPINNER';
export const ELEARNING_FETCHING_SPINNER = 'ELEARNING_FETCHING_SPINNER';
export const SYMON_SPINNER = 'SYMON_SPINNER';
export const HEALTH_DASHBOARD_SPINNER = 'HEALTH_DASHBOARD_SPINNER';
export const GET_TABLE_ADDITIONAL_DATA = 'GET_TABLE_ADDITIONAL_DATA';
export const MOBILE_OPTION_SPINNER = 'MOBILE_OPTION_SPINNER';
export const WEB_TAB_UPDATE_SPINNER = 'WEB_TAB_UPDATE_SPINNER';
export const DATA_MODULE_COMPONENT_LOADED_SPINNER =
	'DATA_MODULE_COMPONENT_LOADED_SPINNER';
