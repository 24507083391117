/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IGlobalStateShape } from 'reducers';
import ModelOptionsService from 'services/modelOptionsService';
import { isNil } from 'ramda';

export default function Intercom() {
	/*
	 * This is a component with no dom presence
	 * it uses hooks to connect to the redux state and update
	 * the user details as required for intercom/upscope
	 */
	useIntercomState();

	return null;
}

const useIntercomState = () => {
	const {
		authentication: { userName, database, tenantID, intercomHash },
	} = useSelector((state: IGlobalStateShape) => state);

	const sessionTimeout = useSelector(
		(state: IGlobalStateShape) => state.authentication.showAuthModal
	);

	const intercomActive = useSelector(
		(state: IGlobalStateShape) => state.authentication.intercomActive
	);

	const app_id = (window as any).ICM_CONFIG.INTERCOM_APP_ID;
	const dispatch = useDispatch();

	const setDetails = useCallback(
		(first, last, user_id, email, user_hash) => {
			const request: any = {
				app_id,
				name: `${first} ${last}`,
				user_id,
				email,
				ModelName: database,
				Tenant: tenantID,
				user_hash: user_hash && user_hash !== '' ? user_hash : undefined,
				referrer: document.location.href,
			};
			(window as any).Intercom('boot', request);
			(window as any).Intercom('show');
		},
		[app_id, database]
	);

	const setUserDetails = useCallback(async () => {
		// a user has logged in; Set user details to current user
		const currentUser = await ModelOptionsService.getCurrentUser(
			tenantID,
			userName
		);

		const userId = currentUser.contact_email
			? currentUser.contact_email
			: `${userName}_${tenantID}`;
		const email = currentUser.contact_email
			? currentUser.contact_email
			: currentUser.notification_email;

		setDetails(
			currentUser.first_name,
			currentUser.last_name,
			userId,
			email,
			intercomHash
		);
	}, [dispatch, setDetails, tenantID, userName, intercomHash]);

	const logoutUser = () => {
		// user has been logged out; shutdown intercom to clear cookie with chat history
		(window as any).Intercom('shutdown');
	};

	useEffect(() => {
		if (isNil(tenantID) || sessionTimeout || !intercomActive) logoutUser();
		else setUserDetails();
	}, [tenantID, setUserDetails, sessionTimeout, intercomActive]);

	useEffect(() => {
		// Shut down chat on component unmount
		return logoutUser;
	}, []);
};
