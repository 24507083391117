/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export default class FilterOperator {
	constructor(type, left, right, parent) {
		this.left = left;
		this.right = right;
		this.type = type; // See filterOperators in the constants folder
		this.parent = parent;
	}

	toString() {
		throw new Error('Implement Me!');
	}

	isColumn(columnName) {
		return this.left === columnName;
	}

	interpretAsType(val, right) {
		// todo: check for big numbers
		if (val === null || typeof val === 'string' || typeof val === 'boolean') {
			return right;
		} else if (typeof val === 'number') {
			if (isNaN(right)) {
				throw new Error(`${right} cannot be converted to a number.`);
			}
			return parseFloat(right, 10);
		}

		throw new Error(`Unexpected type ${typeof val}`);
	}

	getRowValueForColumn(row, columns) {
		for (let i = 0; i < columns.length; i++) {
			if (columns[i] === this.left) {
				const val = row[i];
				const right = this.interpretAsType(val, this.right);
				return { found: true, filterValue: right, rowValue: val };
			}
		}

		return { found: false, filterValue: null, rowValue: null };
	}

	mapColumns(columnMap) {
		if (columnMap[this.left] !== undefined) {
			this.left = columnMap[this.left];
		}
	}

	/**
	 * Iterates down the left of a tree until we have something that is not a filter( a column name )
	 *
	 * @return Column name of the leftmost element of filter tree
	 */
	getColumnName() {
		let currentLeft = this.left;
		while (currentLeft instanceof FilterOperator) {
			currentLeft = currentLeft.left;
		}
		return currentLeft;
	}

	filter(/* row, columns */) {
		throw new Error('Implement Me!');
	}

	copy() {
		throw new Error('Implement Me!');
	}
}
