/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';

const SvgComponent = (props) => (
	<svg viewBox="0 0 48 48" {...props}>
		<path
			d="M39 37H9l15-26 15 26zm-15-9V18"
			fill="none"
			stroke="#000"
			strokeWidth={0.72}
			strokeLinejoin="round"
			strokeMiterlimit={10}
		/>
		<circle cx={24} cy={32} r={1} />
	</svg>
);

export default SvgComponent;
