/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

// TODO: #eslintfix
/* eslint-disable @typescript-eslint/no-namespace */

import { createHmac } from 'crypto';

import logger from 'services/debugLogger';
import { host } from 'helpers/storageHelper';

declare namespace heap {
	function identify(string);
	function load(string);
	function addUserProperties(Object);
	function resetIdentity();
}

declare namespace Appcues {
	function identify(string, Object);
	function page();
	function reset();
}

let isHeapLoaded = false;

const hashProperty = (prop: string) =>
	createHmac('sha256', '').update(prop).digest('hex');

interface LoginAnalyticsProps {
	userName: string;
	tenantId: Nullable<number>;
}

export function sendLoginToAnalytics({
	userName,
	tenantId,
}: LoginAnalyticsProps) {
	const accountNumber = localStorage.getItem(`${host}__accountNumber`) ?? null;
	const modelName =
		localStorage.getItem(`${host}__${userName}__lastModel`) ?? null;

	logger.log('sendLoginToAnalytics()', { userName, tenantId, accountNumber });

	if (isHeapLoaded && window.ICM_CONFIG.HEAP_ANALYTICS_ID) {
		heap.identify(hashProperty(userName));

		if (!!accountNumber) {
			heap.addUserProperties({
				accountNumber,
			});
		}

		if (!!modelName) {
			heap.addUserProperties({
				modelName,
			});
		}

		heap.addUserProperties({
			tenantId,
		});
	}

	if (window.ICM_CONFIG.APPCUES_ID && typeof Appcues !== 'undefined') {
		const appCuesOptions = {
			tenantId,
			...(!!accountNumber ? { accountNumber } : {}),
			...(!!modelName ? { modelName } : {}),
		};

		Appcues.identify(hashProperty(userName), appCuesOptions);
	}
}

export function resetIdentityForAnalytics() {
	if (isHeapLoaded && window.ICM_CONFIG.HEAP_ANALYTICS_ID) {
		heap.resetIdentity();
	}
	if (window.ICM_CONFIG.APPCUES_ID && typeof Appcues !== 'undefined') {
		Appcues.reset();
	}
}

export function initializeAnalytics() {
	if (!isHeapLoaded && window.ICM_CONFIG.HEAP_ANALYTICS_ID) {
		heap.load(window.ICM_CONFIG.HEAP_ANALYTICS_ID);
		isHeapLoaded = true;
	}
}

export function appcuesTrack() {
	if (window.ICM_CONFIG.APPCUES_ID && typeof Appcues !== 'undefined') {
		Appcues.page();
	}
}
