/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/**
 * Given a message and response, create an error message to display to the user
 * @param {object} data - response data object
 * @param {object} int - response code
 * @param {string} responseText - Unparsed response text
 * @param {string} unknownErrorText - Message to display for unknown errors
 * @param {bool} useResponseTextOn404 - Whether or not to show error message for 404 error
 */
export function createErrorMessage(
	data,
	res,
	responseText,
	unknownErrorText,
	useResponseTextOn404
) {
	switch (res) {
		case 404:
			return useResponseTextOn404 ? responseText || unknownErrorText : data;
		case 400:
			return responseText || unknownErrorText;
		default:
			return responseText || unknownErrorText;
	}
}
