/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { setSSOChecked, setWaitForPopup } from 'actions/authActions';
import {
	hidePasswordChange,
	showPasswordChange,
} from 'actions/changePasswordActions';
import {
	hideForgotPasswordRequest,
	hideForgotPasswordSubmit,
	showForgotPasswordRequest,
	showForgotPasswordSubmit,
} from 'actions/forgotPasswordActions';

import { createReducer } from 'redux-act';

const defaultState = {
	showPasswordChange: false,
	showForgotPasswordRequest: false,
	showForgotPasswordSubmit: false,
	passcodePreview: '',
	ssoChecked: false,
	waitForPopup: false,
};

const reducer = createReducer<Readonly<typeof defaultState>>({}, defaultState);

reducer.on(showPasswordChange, (state) => ({
	...state,
	showPasswordChange: true,
}));

reducer.on(hidePasswordChange, (state) => ({
	...state,
	showPasswordChange: false,
}));

reducer.on(showForgotPasswordRequest, (state) => ({
	...state,
	showForgotPasswordRequest: true,
}));

reducer.on(hideForgotPasswordRequest, (state) => ({
	...state,
	showForgotPasswordRequest: false,
}));

reducer.on(showForgotPasswordSubmit, (state, payload) => ({
	...state,
	showForgotPasswordSubmit: true,
	passcodePreview: payload || '',
}));

reducer.on(hideForgotPasswordSubmit, (state) => ({
	...state,
	showForgotPasswordSubmit: false,
	passcodePreview: '',
}));

reducer.on(hideForgotPasswordSubmit, (state) => ({
	...state,
	showForgotPasswordSubmit: false,
	passcodePreview: '',
}));

reducer.on(setSSOChecked, (state, payload) => ({
	...state,
	ssoChecked: payload,
}));

reducer.on(setWaitForPopup, (state, payload) => ({
	...state,
	waitForPopup: payload,
}));

export default reducer;
