// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/adaptiveforms`
 */
export function getAll(options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: 'adaptiveforms',
		apiVersion: 'internal' as const,
		...options,
	};
	return configurableRequest<
		Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormInfoDTO[]
	>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/adaptiveforms/${id}`
 */
export function get(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `adaptiveforms/${id}`,
		apiVersion: 'internal' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormWithSourceSchemasDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/payee/adaptiveforms/${id}`
 */
export function getForPayee(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `payee/adaptiveforms/${id}`,
		apiVersion: 'internal' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormWithSourceSchemasDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/adaptiveforms/${id}/evaluatedvalues`
 */
export function getEvaluatedValues(
	id: Varicent.ID,
	context: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormContextDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `adaptiveforms/${id}/evaluatedvalues`,
		apiVersion: 'internal' as const,
		data: context,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormEvaluatedValuesDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/payee/adaptiveforms/${id}/evaluatedvalues`
 */
export function getEvaluatedValuesForPayee(
	id: Varicent.ID,
	context: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormContextDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/adaptiveforms/${id}/evaluatedvalues`,
		apiVersion: 'internal' as const,
		data: context,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormEvaluatedValuesDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/adaptiveforms`
 */
export function post(
	newForm: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'adaptiveforms',
		apiVersion: 'internal' as const,
		data: newForm,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormInfoDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/adaptiveforms/${id}`
 */
export function put(
	id: Varicent.ID,
	newForm: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PUT,
		url: `adaptiveforms/${id}`,
		apiVersion: 'internal' as const,
		data: newForm,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormInfoDTO>(
		request
	);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/adaptiveforms/${id}`
 */
export function doDelete(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `adaptiveforms/${id}`,
		apiVersion: 'internal' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/adaptiveforms/${id}/sourcedata/${sourceId}`
 */
export function getSourceRows(
	id: Varicent.ID,
	sourceId: Varicent.ID,
	context: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormContextDTO,
	query?: {
		limit?: number;
		offset?: number;
		orderBy?: string;
		filterBy?: string;
		selectedBy?: string;
		orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
		expensiveIncludeTotalRows?: boolean;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `adaptiveforms/${id}/sourcedata/${sourceId}`,
		apiVersion: 'internal' as const,
		data: context,
		query,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/payee/adaptiveforms/${id}/sourcedata/${sourceId}`
 */
export function getSourceRowsForPayee(
	id: Varicent.ID,
	sourceId: Varicent.ID,
	context: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormContextDTO,
	query?: {
		limit?: number;
		offset?: number;
		orderBy?: string;
		filterBy?: string;
		selectedBy?: string;
		orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
		expensiveIncludeTotalRows?: boolean;
	},
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/adaptiveforms/${id}/sourcedata/${sourceId}`,
		apiVersion: 'internal' as const,
		data: context,
		query,
		...options,
	};
	return configurableRequest<any>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/adaptiveforms/${id}/submit`
 */
export function submitData(
	id: Varicent.ID,
	submission: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormSubmissionDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `adaptiveforms/${id}/submit`,
		apiVersion: 'internal' as const,
		data: submission,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 *
 * Warning: Internal API. Subject to change without notice. To be used by Varicent employees only.
 * url: `api/internal/payee/adaptiveforms/${id}/submit`
 */
export function submitDataForPayee(
	id: Varicent.ID,
	submission: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormSubmissionDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: `payee/adaptiveforms/${id}/submit`,
		apiVersion: 'internal' as const,
		data: submission,
		...options,
	};
	return configurableRequest<void>(request);
}
