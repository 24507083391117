/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled, { injectGlobal } from 'react-emotion';
import ToolsSvg from '../icons/tools';
import WarningSvg from '../icons/warning';
import Provider from '../provider';

const defaults = {
	xxxSmall: '4px',
	xxSmall: '8px',
	xSmall: '16px',
	small: '20px',
	medium: '24px',
	large: '32px',
	xLarge: '48px',
};

interface IProps {
	errorId: string;
	errorDescriptionId: string;
	isMaintenance?: boolean;
	progressBar?: any;
	returnHomeUrl?: string;
}

// override icm-web's min-width for mobile
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
injectGlobal`
  body{
	min-width : 0px !important;
  }
`;

const Page = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	height: 100%;
`;

const Logo = styled('div')`
	background-repeat: no-repeat;
	background-size: 100% 100%;
	height: 100px;
	width: 100px;
	margin-right: ${defaults.large};
`;

const Text = styled('div')`
	width: 300px;
	font-family: IBMPlexSans, Helvetica, Arial;
	line-height: ${defaults.medium};
`;

const Error = styled('div')`
	font-size: 20px;
	color: #171717;
	letter-spacing: 0;
`;

const ErrorDescription = styled('div')`
	font-size: 14px;
	color: #565656;
	letter-spacing: 0.16px;
	margin-top: ${defaults.xxSmall};
`;

const GoHome = styled('a')`
	font-size: 14px;
	color: #337ab7;
	letter-spacing: 0.16px;
`;

const Status = styled('div')`
	margin-top: ${defaults.xSmall};
`;

export const messages = defineMessages({
	ERROR_PAGE: {
		id: 'ERROR_PAGE',
		defaultMessage: 'Error',
	},
	ERROR_ERROR_LOADING: {
		id: 'ERROR_ERROR_LOADING',
		defaultMessage: 'An error occurred when loading this page.',
	},
	ERROR_ERROR_404: {
		id: 'ERROR_ERROR_404',
		defaultMessage: 'This page does not exist.',
	},
	ERROR_GO_BACK: {
		id: 'ERROR_GO_BACK',
		defaultMessage: 'Go back to try again or go to the {HOME_PAGE}.',
	},
	HOME_PAGE: {
		id: 'HOME_PAGE',
		defaultMessage: 'home page',
	},
	SERVICE_UNAVAILABLE: {
		id: 'SERVICE_UNAVAILABLE',
		defaultMessage: 'Service is temporarily unavailable.',
	},
	MIGRATION_DOWN: {
		id: 'MIGRATION_DOWN',
		defaultMessage: 'Migration in progress.',
	},
	MAINTENANCE_COME_BACK_LATER: {
		id: 'MAINTENANCE_COME_BACK_LATER',
		defaultMessage: 'Please come back later.',
	},
	MAINTENANCE_COME_BACK_SOON: {
		id: 'MAINTENANCE_COME_BACK_SOON',
		defaultMessage: 'Try again in a few minutes.',
	},
	MAINTENANCE_WORKING: {
		id: 'MAINTENANCE_WORKING',
		defaultMessage: "We're working on making it available again.",
	},
	MAINTENANCE_DOWN: {
		id: 'MAINTENANCE_DOWN',
		defaultMessage: 'We are temporarily down for maintenance.',
	},
	NO_TABS_ENABLED: {
		id: 'NO_TABS_ENABLED',
		defaultMessage: 'You have no available web tabs.',
	},
	CONTACT_ADMIN: {
		id: 'CONTACT_ADMIN',
		defaultMessage: 'Please contact your administator.',
	},
});

const ErrorPage = ({
	errorId,
	errorDescriptionId,
	isMaintenance = false,
	progressBar = null,
	returnHomeUrl = '/',
}: IProps) => {
	return (
		<Provider>
			<Page>
				<Logo>
					{isMaintenance && <ToolsSvg />}
					{!isMaintenance && <WarningSvg />}
				</Logo>
				<Text>
					<Error>
						<FormattedMessage id={errorId} />
					</Error>
					<ErrorDescription>
						<FormattedMessage
							id={errorDescriptionId}
							values={{
								HOME_PAGE: (
									<GoHome href={returnHomeUrl}>
										{' '}
										<FormattedMessage {...messages.HOME_PAGE} />
									</GoHome>
								),
							}}
						/>
						{progressBar && <Status>{progressBar}</Status>}
					</ErrorDescription>
				</Text>
			</Page>
		</Provider>
	);
};

export default ErrorPage;
