/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

function prependBuffer(array, item, maxLength) {
	if (array.length >= maxLength) {
		// pop first to avoid reallocation
		array.pop();
	}
	array.unshift(item);
}

/*
 * This class is exported for testing purposes only;
 * use the default export (singleton) in application code.
 */
export class ErrorLogServiceImpl {
	/**
	 * The names of recent actions, in ascending order of age, that is:
	 * actions[0] -> newest
	 * ...
	 * actions[actions.length] -> oldest
	 * @type {Array<string>}
	 */
	actions = [];

	/**
	 * The maximum number of actions to buffer.
	 * @type {number}
	 */
	maxActions;

	/**
	 * The request ids corresponding to recent API requests, in ascending order of age.
	 * @type {Array<string>}
	 */
	requestIds = [];

	/**
	 * The maximum number of request ids to buffer.
	 * @type {number}
	 */
	maxRequestIds;

	/**
	 * @param {number} maxActions A strictly positive integer.
	 * @param {number} maxRequestIds A strictly positive integer.
	 */
	constructor({ maxActions, maxRequestIds }) {
		if (!maxActions || maxActions < 1) {
			throw new TypeError(
				`Expected maxActions to be strictly positive, found: ${maxActions}`
			);
		}
		if (!maxRequestIds || maxRequestIds < 1) {
			throw new TypeError(
				`Expected maxRequestIds to be strictly positive, found: ${maxRequestIds}`
			);
		}

		this.maxActions = maxActions;
		this.maxRequestIds = maxRequestIds;
	}

	/**
	 * Add a new action to the buffer.
	 * @param {string} actionType
	 */
	logAction(actionType) {
		prependBuffer(this.actions, actionType, this.maxActions);
	}

	/**
	 * Add a new request id to the buffer.
	 * @param {string} id
	 */
	logRequestId(id) {
		prependBuffer(this.requestIds, id, this.maxRequestIds);
	}

	/**
	 * Get all recent actions or, if provided,
	 * the most recent `Math.max(maxActions, this.maxActions)` actions.
	 * @param {number} [maxActions]
	 * @returns {Array<string>}
	 */
	getRecentActions(maxActions = this.maxActions) {
		return this.actions.slice(0, maxActions);
	}

	/**
	 * Get all recent request ids or, if provided,
	 * the most recent `Math.max(maxActions, this.maxActions)` actions.
	 * @param {number} [maxRequestIds]
	 * @returns {Array<string>}
	 */
	getRecentRequestIds(maxRequestIds = this.maxRequestIds) {
		return this.requestIds.slice(0, maxRequestIds);
	}
}

export default new ErrorLogServiceImpl({ maxActions: 20, maxRequestIds: 10 });
