// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2021
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

// THIS CODE HAS BEEN GENERATED. DO NOT MODIFY!!!
// Generated by: https://github.ibm.com/SPM/cs-to-ts
import { Varicent } from '../index';
import {
	HttpVerb,
	RequestOptions,
	configurableRequest,
} from '../utils/baseRequest';

/* This assumes one class per controller file */

/**
 *
 * url: `api/v1/components`
 */
export function getAll(
	query?: { filter?: string; orderBy?: string },
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.GET,
		url: 'components',
		apiVersion: 'v1' as const,
		query,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BlockElementDTO[]>(
		request
	);
}

/**
 * Get Block by ID
 * url: `api/v1/components/${id}`
 */
export function get(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.GET,
		url: `components/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BlockElementDTO>(request);
}

/**
 * Patch Block by ID
 * url: `api/v1/components/${id}`
 */
export function patch(
	id: Varicent.ID,
	patchDTO: Varicent.RESTAPI.v1.DTOs.Composer.ComponentPatchDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.PATCH,
		url: `components/${id}`,
		apiVersion: 'v1' as const,
		data: patchDTO,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BlockElementDTO>(request);
}

/**
 * Delete Block by ID
 * url: `api/v1/components/${id}`
 */
export function doDelete(id: Varicent.ID, options?: RequestOptions) {
	const request = {
		method: HttpVerb.DELETE,
		url: `components/${id}`,
		apiVersion: 'v1' as const,
		...options,
	};
	return configurableRequest<void>(request);
}

/**
 * Create block
 * url: `api/v1/components`
 */
export function post(
	block: Varicent.RESTAPI.v1.DTOs.BlockElementDTO,
	options?: RequestOptions
) {
	const request = {
		method: HttpVerb.POST,
		url: 'components',
		apiVersion: 'v1' as const,
		data: block,
		...options,
	};
	return configurableRequest<Varicent.RESTAPI.v1.DTOs.BlockElementDTO>(request);
}
