/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import LocaleHelper from '../helpers/localeHelper';
import { FilterValueNull } from '../constants/filterOperators';

export default class FilterOption {
	constructor(props) {
		Object.assign(this, props);
	}

	next() {
		const copy = new FilterOption(this);
		copy.isSelected = !this.isSelected;
		return copy;
	}

	reset() {
		const copy = new FilterOption(this);
		copy.isSelected = false;
		return copy;
	}

	static buildFilterOption(
		key,
		l = null,
		localize = false,
		isSelected = false
	) {
		let label = l;
		const isNull = key === null || key === FilterValueNull;
		const isZero = key === 0;

		if (label === null || key === '') {
			if (isNull || key === '') {
				label = LocaleHelper.getText(
					window.store.getState(),
					'TABLE_FILTER_NULL_VALUE'
				);
			} else if (isZero) {
				// since 0 is a falsy value make sure to set a truthy label here
				label = key.toString();
			} else {
				label = key;
			}
		}

		return new FilterOption({
			key: isNull ? FilterValueNull : key,
			label,
			localize,
			isSelected,
		});
	}
}
